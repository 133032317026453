import { Alert, Box, Card, Typography } from '@mui/material'
import { useCreateKeyword } from 'api/createKeyword'
import { useGetAnalysisThemes } from 'api/getAnalysisThemes'
import { useCurrentUser } from 'api/getCurrentUser'
import { useGetOrder } from 'api/order/getOrder'
import useFirstVisitAfterCheckout from 'hooks/useFirstVisitAfterCheckout'
import useQuery from 'hooks/useQuery'
import useSelectedTheme from 'hooks/useSelectedTheme'
import A8SalesScript from 'includes/A8SalesScript'
import Loading from 'includes/Loading'
import { withTax } from 'libs/formatYen'
import getValueFromOrderProcessingData from 'libs/getValueFromOrderProcessingData'
import { FC } from 'react'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { z } from 'zod'
import { AgeRecord, GenderRecord } from '../components/ResearchTargetInputForm'
import { V2Paths } from '../paths'
import { NewKeywordForm } from './components/NewKeywordForm'
import { newKeywordSchema } from './schema'

type FormData = z.infer<typeof newKeywordSchema>

export const NewKeywordPage: FC = () => {
  const history = useHistory()
  const location = useLocation<{ keywordFormData?: FormData }>()
  const query = useQuery()
  const checkoutMode = query.get('checkout') ? query.get('checkout') : null
  const orderId = query.get('order_id') ? query.get('order_id') : null
  const { data: order, isLoading } = useGetOrder({ orderId: orderId })

  const { mutate } = useGetAnalysisThemes({})
  const { selectedTheme } = useSelectedTheme()
  const { currentUser } = useCurrentUser()
  const isFirstVisit = useFirstVisitAfterCheckout({
    orderId: order?.id?.toString(),
  })

  const { request, requesting } = useCreateKeyword({
    async onSuccess({ id }) {
      await mutate()
      history.push(
        generatePath(V2Paths.Keyword, {
          themeId: selectedTheme?.id,
          keywordId: id,
        }),
      )
    },
    onError(error) {
      console.error('error', error)
    },
  })

  const formValue = getValueFromOrderProcessingData({
    type: 'checkout.keyword',
    orderProcessingData: order?.orderProcessingData ?? null,
  })

  const defaultValues = order ? formValue : location.state?.keywordFormData

  const onSubmit = async (data: FormData) => {
    if (selectedTheme === undefined) return
    await request({
      analysisThemeId: selectedTheme.id,
      name: data.keyword.name,
      excludedWords: [
        ...data.keyword.excludedWords,
        ...data.keyword.selectedExcludedWords,
      ],
      tags: [
        ...GenderRecord[data.researchTarget.gender],
        ...data.researchTarget.generations.map(
          (generation) => AgeRecord[generation],
        ),
      ],
      prefectures: data.researchTarget.prefectures,
      speciality_panel: data.researchTarget.specialityPanel,
      note: data.researchTarget.note,
    })
  }

  return (
    <>
      {isLoading && <Loading />}
      <Box
        sx={{
          mx: {
            md: 0,
            lg: 3,
          },
          my: 5,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '20px', mb: 2 }}>
            {selectedTheme?.name}
          </Typography>
        </Box>

        <Card
          sx={{
            borderRadius: 4,
            py: 4,
            px: { xs: 4, md: 8, lg: 16 },
          }}
        >
          <Typography
            style={{ textAlign: 'center', color: '#707070', fontSize: '24px' }}
          >
            新規キーワード追加
          </Typography>
          {order && (
            <Alert severity="success" sx={{ fontSize: '16px', mt: 2 }}>
              購入ありがとうございました。以下の内容で分析を開始します。
            </Alert>
          )}
          {isFirstVisit && order && (
            <A8SalesScript
              isFirstVisit={isFirstVisit}
              userId={currentUser?.id.toString()}
              totalPrice={order.total}
              items={order.orderItems.map((item) => ({
                code: 'registration',
                price: withTax(item.productPrice),
                quantity: item.quantity,
              }))}
            />
          )}
          <NewKeywordForm
            key={defaultValues ? 'default' : 'new'}
            onSubmit={onSubmit}
            requesting={requesting}
            checkoutMode={!!checkoutMode}
            defaultValues={defaultValues}
          />
        </Card>
      </Box>
    </>
  )
}
