import { zodResolver } from '@hookform/resolvers/zod'
import CloseIcon from '@mui/icons-material/Close'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { useCreateSummary } from 'api/createSummary'
import { useGetAnalysisThemes } from 'api/getAnalysisThemes'
import { useCurrentUser } from 'api/getCurrentUser'
import { useRecordLog } from 'api/recordLog'
import { useDisclosure } from 'hooks/useDisclosure'
import useFirstVisitAfterCheckout from 'hooks/useFirstVisitAfterCheckout'
import { useNeedTopicRequestCheckout } from 'hooks/useNeedCheckout'
import useOpenSummaryDialog from 'hooks/useOpenSummaryDialog'
import useProductInfo from 'hooks/useProductInfo'
import useSelectedKeyword from 'hooks/useSelectedKeyword'
import useSelectedTheme from 'hooks/useSelectedTheme'
import A8SalesScript from 'includes/A8SalesScript'
import formatYen, { withTax } from 'libs/formatYen'
import SummaryCheckoutDialog from 'pages/v2/checkout/components/SummaryCheckoutDialog'
import NoRemainingCountMessage from 'pages/v2/components/NoRemainingCountMessage'
import {
  AgeRecord,
  GenderRecord,
  ResearchTargetInputForm,
} from 'pages/v2/components/ResearchTargetInputForm'
import { researchTargetSchema } from 'pages/v2/NewAnalysisTheme/schema'
import { V2Paths } from 'pages/v2/paths'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { generatePath, useHistory } from 'react-router-dom'
import { Order } from 'types/order'
import { z } from 'zod'

const summarySchema = z.object({
  researchTarget: researchTargetSchema,
})

export type FormData = z.infer<typeof summarySchema>

type Props = DialogProps & {
  checkoutMode: boolean
  defaultValues?: FormData
  order: Order | undefined
  onOpen: () => void
}

export const CreateSummaryDialog = ({
  defaultValues,
  onClose,
  onOpen,
  checkoutMode,
  order,
  ...rest
}: Props) => {
  const history = useHistory()
  const [resetKey, setResetKey] = useState<number>(0)
  const {
    isOpen: isOpenSummary,
    onOpen: onOpenSummary,
    onClose: onCloseSummary,
  } = useDisclosure()
  const { mutate } = useGetAnalysisThemes({})
  const { productInfo } = useProductInfo('summary')
  const { selectedKeywordId } = useSelectedKeyword()
  const { selectedThemeId } = useSelectedTheme()
  const { currentUser } = useCurrentUser()
  const isFirstVisit = useFirstVisitAfterCheckout({
    orderId: order?.id?.toString(),
  })
  const { isDialogOpen, closeDialog } = useOpenSummaryDialog({
    orderId: order?.id?.toString(),
    orderProductSlug: order ? order.orderItems[0].product.slug : undefined,
  })
  const { request: requestRecordLog } = useRecordLog({})
  const form = useForm<FormData>({
    resolver: zodResolver(summarySchema),
    defaultValues: {
      researchTarget: {
        gender: defaultValues?.researchTarget?.gender ?? 'both',
        generations: defaultValues?.researchTarget?.generations ?? [],
        prefectures: defaultValues?.researchTarget?.prefectures ?? [],
        specialityPanel:
          defaultValues?.researchTarget?.specialityPanel ?? false,
        note: defaultValues?.researchTarget?.note ?? '',
      },
    },
  })
  const needCheckout = useNeedTopicRequestCheckout(checkoutMode)

  const { request, requesting } = useCreateSummary({
    onSuccess: async ({ id }) => {
      setResetKey((prev) => prev + 1)
      onClose?.({}, 'escapeKeyDown')

      // sidebarの更新
      await mutate()

      history.push(
        generatePath(V2Paths.Summary, {
          themeId: selectedThemeId,
          keywordId: selectedKeywordId,
          summaryId: id,
        }),
      )
    },
  })

  const { handleSubmit } = form

  const onSubmit = useCallback(
    (data: FormData) => {
      const { gender, generations, ...rest } = data.researchTarget
      if (selectedKeywordId === undefined) {
        console.error('keywordId is not found')
        return
      }

      request({
        keywordId: selectedKeywordId,
        tags: [
          ...GenderRecord[data.researchTarget.gender],
          ...generations.map((generation) => AgeRecord[generation]),
        ],
        ...rest,
      })
    },
    [request],
  )
  const researchTarget = form.watch('researchTarget')

  useEffect(() => {
    if (isDialogOpen) {
      onOpen()
    } else {
      onClose?.({}, 'escapeKeyDown')
    }
  }, [isDialogOpen])

  return (
    <Dialog
      {...rest}
      fullWidth
      maxWidth="lg"
      key={`create-summary-form-key-${resetKey}`}
    >
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(
            needCheckout
              ? async () => {
                  onOpenSummary()
                  await requestRecordLog({
                    event: 'summary_checkout_dialog_open',
                    userId: currentUser?.id ?? 0,
                    parameters: {
                      checkout_dialog_open_at: new Date().toISOString(),
                    },
                  })
                }
              : onSubmit,
          )}
        >
          <DialogTitle textAlign="center">共感度を調整する</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() =>
              isDialogOpen ? closeDialog() : onClose?.({}, 'escapeKeyDown')
            }
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ py: 4, px: { xs: 4, md: 8, lg: 16 } }}>
            {isDialogOpen && (
              <Alert severity="success" sx={{ fontSize: '16px', mt: 2 }}>
                購入ありがとうございました。以下の内容で共感付与を依頼します。
              </Alert>
            )}
            {isFirstVisit && order && (
              <A8SalesScript
                isFirstVisit={isFirstVisit}
                userId={currentUser?.id.toString()}
                totalPrice={order.total}
                items={order.orderItems.map((item) => ({
                  code: 'registration',
                  price: withTax(item.productPrice),
                  quantity: item.quantity,
                }))}
              />
            )}
            <ResearchTargetInputForm key="regenerate-summary" />
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              py: 4,
              justifyContent: 'center',
            }}
          >
            {needCheckout ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ px: 7, fontSize: '20px', color: 'white' }}
                  disabled={requesting}
                >
                  共感付与を依頼する /{' '}
                  {formatYen(
                    productInfo?.price ? withTax(Number(productInfo.price)) : 0,
                  )}
                  （税込）
                </Button>
                <NoRemainingCountMessage
                  remainingCount={
                    currentUser?.remaining_adjustment_request_count ?? 0
                  }
                  isCampaign={productInfo?.isCampaign ?? false}
                />
                <SummaryCheckoutDialog
                  onClose={onCloseSummary}
                  isOpen={isOpenSummary}
                  createSummaryParams={{
                    keywordId: selectedKeywordId as number,
                    tags: [
                      ...GenderRecord[researchTarget.gender],
                      ...researchTarget.generations.map(
                        (generation) => AgeRecord[generation],
                      ),
                    ],
                    ...researchTarget,
                  }}
                  summaryFormData={{
                    researchTarget,
                  }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ px: 7, fontSize: '20px', color: 'white' }}
                  disabled={
                    requesting ||
                    (currentUser?.remaining_adjustment_request_count ?? 0) <= 0
                  }
                >
                  共感付与を依頼する
                </Button>
                <Typography sx={{ mt: 2 }}>
                  ※共感度調整1回分を消費します
                </Typography>
              </Box>
            )}
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}
