import { useCurrentUser } from 'api/getCurrentUser'
import { useCallback } from 'react'
import useSWR, { SWRConfiguration } from 'swr'
import useSWRInfinite from 'swr/infinite'

const parseToArrayQuery = (tags: string[]): string => {
  return tags.map((tag) => `tag[]=${tag}`).join('&')
}

const getKey = ({
  isFolderStructure,
  pageIndex,
  previousPageData,
  sort,
  keyword,
  tags,
}: {
  isFolderStructure: boolean
  pageIndex: number
  previousPageData: any
  sort: string | null
  keyword: string | null
  tags: string[] | null
}) => {
  if (
    previousPageData &&
    previousPageData.data &&
    !previousPageData.data.length
  )
    return null
  return `/api/${isFolderStructure ? 'folders' : 'results'}?page=${
    pageIndex + 1
  }${sort === null ? '' : '&sort=' + sort}${
    keyword === null ? '' : '&keyword=' + keyword
  }${tags === null || tags.length === 0 ? '' : '&' + parseToArrayQuery(tags)}`
}

type GetAnalysisResponse = any

type Props = {
  isFolderStructure: boolean
  params: {
    sort: string | null
    keyword: string | null
    tags: string[] | null
  }
  options?: SWRConfiguration<GetAnalysisResponse, Error>
}

export const useGetAnalysis = ({ isFolderStructure, params, options }: Props) =>
  useSWRInfinite<GetAnalysisResponse>(
    (pageIndex, previousPageData) =>
      getKey({
        isFolderStructure,
        pageIndex,
        previousPageData,
        sort: params.sort,
        keyword: params.keyword,
        tags: params.tags,
      }),
    {
      ...options,
    },
  )

export const useGetSampleAnalysis = () => {
  const { currentUser, mutate: mutateUser } = useCurrentUser({
    revalidateIfStale: false,
  })

  const { data: sample, mutate: mutateSample } = useSWR<GetAnalysisResponse>(
    currentUser?.is_display_v2_sample
      ? '/assets/v2-example/analysis.json'
      : null,
    (url) => fetch(url).then((res) => res.json()),
    {
      revalidateIfStale: false,
      revalidateOnMount: false,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      suspense: true,
    },
  )

  return {
    data: sample?.data ?? [],
    mutateSample: useCallback(() => {
      mutateUser()
      mutateSample()
    }, [mutateUser, mutateSample]),
  }
}
