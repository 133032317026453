import { Button, Typography } from '@mui/material'
import React from 'react'

type PurchaseButtonProps = {
  onPurchase: () => void
  requesting: boolean
  label: string
  disabled?: boolean
  subText?: string
}

const PurchaseButton: React.VFC<PurchaseButtonProps> = ({
  onPurchase,
  requesting,
  label,
  disabled,
  subText,
}) => {
  return (
    <>
      <Button
        onClick={onPurchase}
        variant="contained"
        disabled={requesting || disabled}
        sx={{
          width: '100%',
          color: 'white',
          borderRadius: '20px',
          fontWeight: 'bold',
          textTransform: 'none',
        }}
      >
        {label}
      </Button>
      {subText && (
        <Typography variant="body2" sx={{ color: '#000', mt: 1 }}>
          {subText}
        </Typography>
      )}
    </>
  )
}

export default PurchaseButton
