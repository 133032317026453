import { AnalysisStatus } from 'types/analysisResult'
import { parseSurvey, Survey, SurveyResponse } from './survey'
import { parseTopic, TopicResponse } from './topic'

export type AnalysisSummaryResponse = {
  id: number
  title: string
  created_at: string
  is_public: boolean
}

export type AnalysisSummaryDetailResponse = AnalysisSummaryResponse & {
  has_created_summary_ideas: boolean
  file_url: string
  analysis_result: {
    id: number
    analysis_status_id: AnalysisStatus
    topics: {
      topics: TopicResponse[]
    }
  } | null
  surveys: SurveyResponse[]
}

export type AnalysisSummary = {
  id: number
  title: string
  createdAt: Date
  isPublic: boolean
}

export type AnalysisSummaryDetail = AnalysisSummary & {
  fileUrl: string
  hasCreatedSummaryIdeas: boolean
  analysisResult: {
    id: number
    analysisStatusId: AnalysisStatus
  } | null
  surveys: Survey[]
}

export const parseAnalysisSummary = (
  input: AnalysisSummaryResponse,
): AnalysisSummary => {
  return {
    id: input.id,
    title: input.title,
    isPublic: input.is_public,
    createdAt: new Date(input.created_at),
  }
}

export const parseAnalysisSummaryDetail = (
  input: AnalysisSummaryDetailResponse,
): AnalysisSummaryDetail => {
  const topics = (input.analysis_result?.topics?.topics ?? []).map(parseTopic)
  return {
    ...parseAnalysisSummary(input),
    hasCreatedSummaryIdeas: input.has_created_summary_ideas,
    fileUrl: input.file_url,
    analysisResult: input.analysis_result
      ? {
          id: input.analysis_result.id,
          analysisStatusId: input.analysis_result.analysis_status_id,
        }
      : null,
    surveys: input.surveys
      .map((survey) => parseSurvey(survey, topics))
      .filter((survey): survey is Survey => !!survey),
  }
}
