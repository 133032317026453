import { Box, CircularProgress } from '@mui/material'
import { BreadCrumb } from 'pages/v2/KeywordDetail/components/BreadCrumb'
import QuestionnaireResult from 'pages/v2/KeywordDetail/components/QuestionnaireResult'
import { Suspense } from 'react'

const QuestionnaireResultPage = () => {
  return (
    <Box sx={{ p: 4 }}>
      <BreadCrumb />
      <Suspense
        fallback={
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        }
      >
        <QuestionnaireResult />
      </Suspense>
    </Box>
  )
}

export default QuestionnaireResultPage
