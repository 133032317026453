import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React from 'react'
import { CoOccurrence } from 'types/coOccurrence'

export const AnalysisCoOccurrences = ({
  coOccurrences,
}: {
  coOccurrences: CoOccurrence[]
}) => {
  return (
    <div>
      <Typography
        variant="body1"
        fontSize="16px"
        fontWeight="bold"
        sx={{ my: 1 }}
      >
        よくあるワード
      </Typography>
      {coOccurrences.map((coOccurrence, index) => (
        <React.Fragment key={index}>
          <Box sx={{ display: 'flex', mb: 1.5 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mr: 1 }}>
              <Typography
                fontSize={'16px'}
                sx={{
                  px: 1,
                  py: 0.5,
                  bgcolor: '#449ba7',
                  color: '#FFF',
                  borderRadius: '5px',
                  display: 'inline-block',
                }}
              >
                {coOccurrence.centerNode.join('・')}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {coOccurrence.unigrams.map((unigram, index) => (
                <Box key={index} sx={{ mr: 1, my: 0.5 }}>
                  <Typography
                    fontSize={'12px'}
                    sx={{
                      px: 1,
                      py: 0.5,
                      bgcolor: '#449ba7',
                      color: '#FFF',
                      borderRadius: '5px',
                      display: 'inline-block',
                    }}
                  >
                    {unigram.word}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </React.Fragment>
      ))}
    </div>
  )
}
