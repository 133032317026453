import { Link } from 'react-router-dom'

const PopupConfirm = (props) => {
  return (
    <div
      className={props.openname === 'confirm' ? 'p-pop is-open' : 'p-pop'}
      data-name="pop6"
    >
      <div
        className="p-pop__overlay"
        onClick={() => props.modalCloseCallback()}
      />
      <div className="p-pop__inner">
        <div
          className="p-pop__closeBtn"
          onClick={() => props.modalCloseCallback()}
        />
        <div className="p-pop__cont">
          <h2 className="c-popHeading">{props.title}</h2>

          <div className="c-vTable">
            <div className="c-vTable__headInfo">
              <div className="l-flex l-flex--noEven">
                <div className="l-flex__item u-w20per" />
                <div className="l-flex__item u-w60per u-tc">
                  {props.message}
                </div>
                <div className="l-flex__item u-w20per u-tr" />
              </div>
            </div>
          </div>

          <div className="u-mt50 c-btn u-tc">
            <Link
              to="#"
              className="c-btn__link c-btn__link--size-l c-btn__link--gray"
              onClick={() => props.modalCloseCallback()}
              style={{ cursor: 'pointer' }}
            >
              キャンセル
            </Link>
            <Link
              to="#"
              className="c-btn__link c-btn__link--size-l"
              onClick={() => props.okCallback()}
              style={{ cursor: 'pointer' }}
            >
              {props.okLabel}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupConfirm
