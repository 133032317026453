import passwordRegex from 'constants/regex/passwordRegex'
import { z } from 'zod'

export const signupSchema = z
  .object({
    email: z
      .string()
      .email({ message: '正しいメールアドレスを入力してください' }),
    password: z
      .string()
      .min(8, '8文字以上で入力してください')
      .regex(passwordRegex, '半角英数字で入力してください'),
    passwordConfirmation: z
      .string()
      .min(8, '8文字以上で入力してください')
      .regex(passwordRegex, '半角英数字で入力してください'),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: 'パスワードが一致しません',
    path: ['passwordConfirmation'],
  })
