import { useEffect, useState } from 'react'

export const useDebounce = <T>({
  defaultValue,
  delay = 300,
}: {
  defaultValue?: T
  delay?: number
}) => {
  const [inputValue, setInputValue] = useState(defaultValue)
  const [debouncedValue, setDebouncedValue] = useState(defaultValue)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [inputValue, delay])

  const handleChange = (value: T) => {
    setInputValue(value)
  }

  return { value: inputValue, debouncedValue, onChange: handleChange }
}
