import { Box, List, ListItem, Typography } from '@mui/material'
import Contact from 'pages/v2/User/Contact'
import PaidSetting from 'pages/v2/User/PaidSetting'
import PasswordSetting from 'pages/v2/User/PasswordSetting'
import UserSetting from 'pages/v2/User/UserSetting'
import { V2Paths } from 'pages/v2/paths'
import { Link, Route, Switch, useLocation } from 'react-router-dom'

const Settings = () => {
  const pathname = useLocation().pathname

  return (
    <Box
      sx={{
        overflow: 'auto',
        boxSizing: 'border-box',
        height: '100%',
        minHeight: '530px',
        backgroundColor: '#fbfbfb',
        fontFamily:
          "'游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic',\n    'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo',\n    Osaka, sans-serif",
        display: 'flex',
      }}
    >
      <Box
        sx={{
          overflowY: 'auto',
          boxSizing: 'border-box',
          width: '200px',
          height: '100%',
          backgroundColor: '#f5f5f5',
        }}
      >
        <List
          sx={{
            textDecoration: 'none',
          }}
        >
          <ListItem
            sx={{
              position: 'relative',
              my: 3,
              backgroundColor:
                pathname === '/v2/themes/settings/user' ? '#fff' : '',
            }}
          >
            <Link
              to="/v2/themes/settings/user"
              style={{
                cursor: 'pointer',
                textDecoration: 'none',
                display: 'block',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  ml: 2,
                }}
              >
                ユーザー情報
              </Typography>
            </Link>
          </ListItem>
          <ListItem
            sx={{
              position: 'relative',
              my: 3,
              backgroundColor:
                pathname === '/v2/themes/settings/password' ? '#fff' : '',
            }}
          >
            <Link
              to="/v2/themes/settings/password"
              style={{
                cursor: 'pointer',
                textDecoration: 'none',
                display: 'block',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  ml: 2,
                }}
              >
                パスワード変更
              </Typography>
            </Link>
          </ListItem>
          <ListItem
            sx={{
              position: 'relative',
              my: 3,
              backgroundColor:
                pathname === '/v2/themes/settings/contact' ? '#fff' : '',
            }}
          >
            <Link
              to="/v2/themes/settings/contact"
              style={{
                cursor: 'pointer',
                textDecoration: 'none',
                display: 'block',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  ml: 2,
                }}
              >
                お問い合わせ
              </Typography>
            </Link>
          </ListItem>
          <ListItem
            sx={{
              position: 'relative',
              my: 3,
              backgroundColor:
                pathname === '/v2/themes/settings/paid' ? '#fff' : '',
            }}
          >
            <Link
              to="/v2/themes/settings/paid"
              style={{
                cursor: 'pointer',
                textDecoration: 'none',
                display: 'block',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  ml: 2,
                }}
              >
                Paid（後払い）設定
              </Typography>
            </Link>
          </ListItem>
        </List>
      </Box>

      <Box
        sx={{
          flex: 1,
          mt: 5,
          ml: 5,
        }}
      >
        <Switch>
          <Route
            exact
            path={V2Paths.UserSetting}
            component={() => <UserSetting />}
          />
          <Route
            exact
            path={V2Paths.PasswordSetting}
            component={() => <PasswordSetting />}
          />
          <Route exact path={V2Paths.Contact} component={() => <Contact />} />
          <Route
            exact
            path={V2Paths.PaidSetting}
            component={() => <PaidSetting />}
          />
        </Switch>
      </Box>
    </Box>
  )
}
export default Settings
