import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

export const usePageTracking = () => {
  const location = useLocation()
  const mesurementId = process.env.REACT_APP_GA_MEASUREMENT_ID

  useEffect(() => {
    if (!mesurementId) return

    // Google Analytics 測定 ID を入力して設定
    if (!ReactGA.isInitialized) ReactGA.initialize(mesurementId)

    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    })
  }, [location, mesurementId])
}
