import styled from 'styled-components'
import Loader from 'react-spinners/ClockLoader'
import { Wave } from 'react-animated-text'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Content = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const FullContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 200;
  background-color: ${(props) =>
    props.backgroundColor ?? 'rgba(255, 255, 255, 50%)'};
`

const LoadingText = styled.span`
  color: #ff6600;
  font-size: 1.5rem;
  font-family: 'Noto Sans JP', '游ゴシック体', YuGothic, '游ゴシック',
    'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ',
    'Meiryo', Osaka, sans-serif;
`

const Loading = () => {
  return (
    <FullContent>
      <Wrapper>
        <Main>
          <Content>
            <Loader size={20} color="#ff6600" />
            <LoadingText className="ml-5">
              <Wave
                text="Loading..."
                delay={1}
                effect="jump"
                effectChange={1.0}
              />
            </LoadingText>
          </Content>
        </Main>
      </Wrapper>
    </FullContent>
  )
}

export default Loading
