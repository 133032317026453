import { Box } from '@mui/material'
import { DisplayRelatedTweetsButton } from 'pages/v2/sp/KeywordDetail/components/TweetDialog/TopicTweetDialog/DisplayRelatedTweetsButton'
import { memo } from 'react'
import { AnalysisSummaryIdea } from 'types/analysisSummaryIdea'
import { Topic } from 'types/topic'

type Props = {
  topic?: Topic
  summaryIdea: AnalysisSummaryIdea
}

export const AnalysisSurveyActionButtons = memo(
  ({ topic, summaryIdea }: Props) => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <DisplayRelatedTweetsButton
          topic={topic}
          tweetIds={summaryIdea.tweetIds}
          title={summaryIdea.title}
        />
      </Box>
    )
  },
)

AnalysisSurveyActionButtons.displayName = 'AnalysisSurveyActionButtons'
