export type EmpathyResponse = {
  id: number
  empathy_count: number
  total_count: number
  tags: string[]
  created_at: Date
}

export type Empathy = {
  id: number
  empathyCount: number
  totalCount: number
  tags: string[]
  createdAt: Date
}

export const parseEmpathy = (response: EmpathyResponse): Empathy => ({
  id: response.id,
  empathyCount: response.empathy_count,
  totalCount: response.total_count,
  tags: response.tags,
  createdAt: new Date(response.created_at),
})
