import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import { useCurrentUser } from 'api/getCurrentUser'
import { useRecordLog } from 'api/recordLog'
import useQuery from 'hooks/useQuery'
import { useScrollHelper } from 'hooks/useScrollHelper'
import { ResearchTargetInputForm } from 'pages/v2/components/ResearchTargetInputForm'
import { useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'
import { newAnalysisThemeSchema } from '../schema'

type FormData = z.infer<typeof newAnalysisThemeSchema>

// admin/src/pages/themes/ThemeDetail.tsxと同じなので変更する場合は両方変更する
export const themeTypeOptions = [
  { label: '新規事業', value: 'newBusiness' },
  { label: '新商品、新サービス', value: 'newProduct' },
  { label: '競合調査', value: 'competitiveResearch' },
  { label: '既存商品、サービスの改良', value: 'existingProduct' },
  { label: 'ユーザの声の拾い上げ', value: 'userVoice' },
  { label: 'その他', value: 'other' },
] as const

// admin/src/pages/themes/ThemeDetail.tsxと同じなので変更する場合は両方変更する
export const userVoiceTypeOptions = [
  { label: 'ポジティブ', value: 'positive' },
  { label: 'ネガティブ', value: 'negative' },
  { label: 'どちらでもない', value: 'indifferent' },
]

type Props = {
  onSubmit: (data: FormData) => void
  requesting?: boolean
}
export const NewAnalysisThemeForm = ({ onSubmit, requesting }: Props) => {
  const { ref: themeRef, handleScroll: handleScrollToTheme } = useScrollHelper()

  const query = useQuery()
  const tour = query.get('tour')
  const { currentUser } = useCurrentUser()
  const method = useForm<FormData>({
    resolver: zodResolver(newAnalysisThemeSchema),
    defaultValues: {
      researchTarget: {
        gender: 'both',
        generations: [],
        prefectures: [],
        specialityPanel: false,
        note: '',
      },
      theme: {
        name: '',
        type: 'newBusiness',
      },
    },
  })
  const { request } = useRecordLog()

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = method

  useEffect(() => {
    if (!currentUser) {
      return
    }

    if (tour !== 'true') {
      request({
        userId: currentUser.id,
        event: 'first_theme_open',
        parameters: {
          first_theme_open_at: new Date().toISOString(),
        },
      })
    }
  }, [tour, request, currentUser])

  useEffect(() => {
    if (errors.theme !== undefined) {
      handleScrollToTheme()
    }
  }, [errors.theme])

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            py: 5,
            flexDirection: 'column',
            alignItems: 'center',
            gap: 5,
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 5,
              flexDirection: 'column',
            }}
            ref={themeRef}
          >
            <FormControl
              error={!!errors?.theme?.name}
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
                gap: {
                  sm: 2,
                  md: 10,
                  lg: 15,
                },
              }}
            >
              <FormLabel required>分析テーマを入力してください</FormLabel>
              <Controller
                name="theme.name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label="分析したいテーマを入力してください"
                    variant="outlined"
                    error={!!errors?.theme?.name}
                    helperText={
                      errors?.theme?.name ? errors?.theme?.name.message : ''
                    }
                    sx={{ maxWidth: '360px' }}
                  />
                )}
              />
            </FormControl>
            <FormControl
              error={!!errors?.theme?.type}
              sx={{
                display: 'flex',
                alignItems: 'start',
                flexDirection: 'row',
                width: '100%',
                gap: {
                  sm: 2,
                  md: 10,
                  lg: 15,
                },
              }}
            >
              <FormLabel required sx={{ py: 2 }}>
                テーマ種別を選択してください
              </FormLabel>
              <RadioGroup defaultValue={watch('theme.type')}>
                {themeTypeOptions.map(({ value, label }) => (
                  <Box
                    key={value}
                    sx={{
                      display: value === 'other' ? 'flex' : '',
                    }}
                  >
                    <FormControlLabel
                      value={value}
                      control={
                        <Controller
                          name="theme.type"
                          control={control}
                          render={({ field: { onChange, ref } }) => (
                            <Radio
                              inputRef={ref}
                              onChange={() => onChange(value)}
                              value={value}
                            />
                          )}
                        />
                      }
                      label={label}
                    />
                    {value === 'userVoice' && (
                      <RadioGroup
                        sx={{ pl: 4, display: 'flex', flexDirection: 'row' }}
                      >
                        {userVoiceTypeOptions.map(({ value, label }) => (
                          <FormControlLabel
                            key={value}
                            value={value}
                            disabled={watch('theme.type') !== 'userVoice'}
                            control={
                              <Controller
                                name="theme.userVoiceType"
                                control={control}
                                render={({ field: { onChange, ref } }) => (
                                  <Radio
                                    disabled={
                                      watch('theme.type') !== 'userVoice'
                                    }
                                    inputRef={ref}
                                    onChange={() => onChange(value)}
                                    value={value}
                                  />
                                )}
                              />
                            }
                            label={label}
                          />
                        ))}
                      </RadioGroup>
                    )}
                    {value === 'other' && (
                      <Controller
                        name="theme.memo"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            disabled={watch('theme.type') !== 'other'}
                            fullWidth
                            size="small"
                            variant="outlined"
                            error={
                              watch('theme.type') === 'other' &&
                              !!(errors?.theme as any)?.memo
                            }
                            helperText={
                              watch('theme.type') === 'other' &&
                              (errors?.theme as any)?.memo
                                ? (errors?.theme as any)?.memo?.message
                                : ''
                            }
                            sx={{ maxWidth: '180px' }}
                          />
                        )}
                      />
                    )}
                  </Box>
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 5,
              flexDirection: 'column',
            }}
          >
            <ResearchTargetInputForm />
          </Box>
          <Button
            variant="contained"
            type="submit"
            sx={{ px: 7, fontSize: '20px', color: 'white' }}
            disabled={requesting}
          >
            分析を開始
          </Button>
        </Box>
      </form>
    </FormProvider>
  )
}
