import { Box } from '@mui/material'
import { QuestionnaireDialogButton } from 'pages/v2/KeywordDetail/components/Questionnaire/QuestionnaireDialogButton'
import { AnalysisStatus } from 'types/analysisResult'
import { AnalysisSummaryIdea } from 'types/analysisSummaryIdea'
import { Survey } from 'types/survey'
import { Topic } from 'types/topic'
import { DisplayRelatedTweetsButton } from './TweetDialog'
import {
  CreateWeightedFilterButton,
  WeightedFilterResultButton,
} from './WeightedFilter'
import { memo, useMemo } from 'react'

type Props = {
  topic?: Topic
  summaryIdea: AnalysisSummaryIdea
  importInfo: Survey['importInfo']
  empathySurveys: Survey['empathySurveys']
  isPublic: boolean
  topicId: string | undefined
  analysisStatus: AnalysisStatus | null
}

export const AnalysisSurveyActionButtons = memo(
  ({
    topic,
    summaryIdea,
    importInfo,
    empathySurveys,
    isPublic,
    topicId,
    analysisStatus,
  }: Props) => {
    const isWeightedFilterCompleted = useMemo(() => {
      if (empathySurveys === undefined) return false
      return empathySurveys.some(({ empathies }) => {
        return empathies.length > 0
      })
    }, [empathySurveys])

    return (
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
        }}
      >
        <DisplayRelatedTweetsButton
          topic={topic}
          tweetIds={summaryIdea.tweetIds}
          title={summaryIdea.title}
        />
        <QuestionnaireDialogButton
          summaryIdea={summaryIdea}
          importInfo={importInfo}
          isPublic={isPublic}
          topicId={topicId}
          questionnaire={topic?.questionnaire ?? null}
          analysisStatus={analysisStatus}
        />
        {/* <Button variant="outlined" disabled>
        キーワードを抽出して再分析をする
      </Button> */}
        {topic &&
          (empathySurveys?.length === 0 ? (
            <CreateWeightedFilterButton
              topicId={topic.labelId}
              title={summaryIdea.title}
            />
          ) : (
            <WeightedFilterResultButton
              isWeightedFilterCompleted={isWeightedFilterCompleted}
              topic={topic}
            />
          ))}
      </Box>
    )
  },
)

AnalysisSurveyActionButtons.displayName = 'AnalysisSurveyActionButtons'
