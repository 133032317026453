import { useCheckPaidMemberStatus } from 'api/checkPaidMemberStatus'
import { useCurrentUser } from 'api/getCurrentUser'
import { useEffect, useState } from 'react'

type Status = 1 | 2 | 3 | 4
export const useRegisteredPaidMemberStatus = () => {
  const [status, setStatus] = useState<Status | null>(null)
  const { request } = useCheckPaidMemberStatus({
    onSuccess: ({ status }) => {
      setStatus(Number(status) as Status)
    },
    onError: (error) => {
      console.log(error)
    },
  })
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    if (!currentUser) {
      return
    }
    request()
  }, [currentUser])

  return currentUser?.paid_id ? status : null
}
