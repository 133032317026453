import { useCurrentUser } from 'api/getCurrentUser'
import { useCallback } from 'react'
import useSWR, { SWRConfiguration } from 'swr'
import { AnalysisThemeResponse, parseAnalysisTheme } from 'types/analysisTheme'

type GetAnalysisThemesResponse = { data: AnalysisThemeResponse[] }

export const useGetAnalysisThemes = ({
  options,
}: {
  options?: SWRConfiguration<GetAnalysisThemesResponse, Error>
}) => {
  const { currentUser, mutate: mutateUser } = useCurrentUser({
    revalidateIfStale: false,
  })

  const { data: sample, mutate: mutateSample } =
    useSWR<GetAnalysisThemesResponse>(
      currentUser?.is_display_v2_sample
        ? '/assets/v2-example/theme.json'
        : null,
      (url) => fetch(url).then((res) => res.json()),
      {
        revalidateIfStale: false,
        revalidateOnMount: false,
        revalidateOnReconnect: false,
        revalidateOnFocus: false,
        suspense: true,
      },
    )

  const { data, ...rest } = useSWR<GetAnalysisThemesResponse>(
    '/api/analysis_themes',
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      ...options,
      suspense: true,
    },
  )
  const sampleData = (sample?.data ?? []).map((s) => {
    return {
      ...s,
      isSample: true,
    }
  })

  return {
    data: [...sampleData, ...(data?.data ?? [])]?.map(parseAnalysisTheme),
    ...rest,
    mutateSample: useCallback(() => {
      mutateUser()
      mutateSample()
    }, [mutateUser, mutateSample]),
  }
}
