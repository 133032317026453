import {
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { useGetAnnouncements } from 'api/getAnnouncementDetail'
import { useGetAnnouncementDetail } from 'api/getAnnouncements'
import { useDisclosure } from 'hooks/useDisclosure'
import BaseDialog from 'includes/BaseDialog'
import React, { useMemo, useState } from 'react'
import { Announcements } from 'types/announcements'
import { dateFormatter } from 'util/dateFormatter'

export const NotificationCard = () => {
  const { data: announcements } = useGetAnnouncements({
    query: {
      status: null,
      keyword: null,
      sort: '-publish_at',
    },
    options: {
      revalidateOnFocus: false,
    },
  })

  return (
    <Card sx={{ backgroundColor: 'white', padding: '12px' }}>
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          sx={{ fontSize: '16px', color: '#3D3E3F', mb: 2, fontWeight: 'bold' }}
        >
          お知らせ
        </Typography>

        <AnnouncementsList announcements={announcements} />
      </CardContent>
    </Card>
  )
}

type AnnouncementsListProps = {
  announcements: Announcements
}

const AnnouncementsList: React.FC<AnnouncementsListProps> = ({
  announcements,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedAnnouncementId, setSelectedAnnouncementId] = useState<
    number | null
  >(null)

  return (
    <>
      {announcements.length > 0 ? (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {announcements.map((announcement, index) => (
            <React.Fragment key={announcement.id}>
              <ListItem
                alignItems="flex-start"
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#e0e0e0',
                  },
                }}
                onClick={() => {
                  setSelectedAnnouncementId(announcement.id)
                  onOpen()
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="subtitle1">
                      {announcement.publishAt &&
                        dateFormatter(announcement.publishAt)}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="text.primary">
                      {announcement.title}
                    </Typography>
                  }
                  sx={{ display: 'flex', alignItems: 'center', gap: 3 }}
                />
              </ListItem>
              {index < announcements.length - 1 && <Divider component="li" />}
            </React.Fragment>
          ))}
        </List>
      ) : (
        <Typography
          variant="body2"
          sx={{
            color: '#9B9B9B',
            fontWeight: 'bold',
            mt: 3,
            fontSize: '14px',
          }}
        >
          お知らせはありません
        </Typography>
      )}

      <AnnouncementDetailModal
        open={isOpen}
        handleClose={onClose}
        announcementId={selectedAnnouncementId}
      />
    </>
  )
}

type AnnouncementDetailModalProps = {
  open: boolean
  handleClose: () => void
  announcementId: number | null
}

const AnnouncementDetailModal = (props: AnnouncementDetailModalProps) => {
  const { open, handleClose, announcementId } = props
  const { data: announcement } = useGetAnnouncementDetail({
    input: { announcementId },
  })

  const body = useMemo(() => {
    if (!announcement) return null
    // なぜが、文字コードでの改行ができないので、brタグで改行する
    return announcement.body.split('\n').map((text, i) => (
      <React.Fragment key={i}>
        {text}
        <br />
      </React.Fragment>
    ))
  }, [announcement])

  if (!announcement) return null

  return (
    <BaseDialog open={open} onClose={handleClose} breakPoints="xl">
      <Box
        sx={{
          p: 3,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            padding: '25px 35px 40px',
          }}
        >
          <Box
            sx={{
              mb: 4,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: '14px',
              }}
            >
              {announcement.title}
            </Typography>
            {announcement.publishAt && (
              <Typography
                variant="body1"
                sx={{
                  mt: 0.5,
                  color: '#9697af',
                  fontSize: '12px',
                }}
              >
                {dateFormatter(announcement.publishAt)}
              </Typography>
            )}
          </Box>

          <Box>
            <Box
              sx={{
                margin: '1.5em 0',
                whiteSpace: 'pre-line',

                '&:first-child': {
                  mt: 0,
                },

                '&:last-child': {
                  mb: 0,
                },
              }}
              component="pre"
            >
              {body}
            </Box>
          </Box>
        </Box>
      </Box>
    </BaseDialog>
  )
}
