import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { Box, Typography } from '@mui/material'
import useSelectedKeyword from 'hooks/useSelectedKeyword'
import useSelectedSummary from 'hooks/useSelectedSummary'
import useTopicIdInQuestionnaires from 'hooks/useTopicIdInQuestionnaires'
import BackButton from 'pages/v2/sp/components/BackButton'

const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...'
  }
  return text
}

export const SummaryBackButton = () => {
  const { selectedSummary } = useSelectedSummary()
  const { selectedKeyword } = useSelectedKeyword()
  const { selectedTopicId } = useTopicIdInQuestionnaires()

  return (
    <BackButton>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        {selectedKeyword && (
          <>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'bold',
              }}
            >
              {truncateText(selectedKeyword.targetWords, 10)}
            </Typography>
          </>
        )}

        {selectedSummary && (
          <>
            <ArrowBackIosNewIcon
              sx={{
                mx: 0.5,
                fontSize: '12px',
              }}
            />
            <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>
              {truncateText(selectedSummary.title, 10)}
            </Typography>
          </>
        )}

        {!selectedTopicId && (
          <>
            <ArrowBackIosNewIcon
              sx={{
                mx: 0.5,
                fontSize: '12px',
              }}
            />
            <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>
              アンケートレポート
            </Typography>
          </>
        )}
      </Box>
    </BackButton>
  )
}
