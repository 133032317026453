import { Box, Grid } from '@mui/material'
import { useGetSummaryDetail } from 'api/getSummaryDetail'
import useSelectedSummary from 'hooks/useSelectedSummary'
import useSelectedTheme from 'hooks/useSelectedTheme'
import { Loading } from 'pages/v2/KeywordDetail/components/Loading'
import TableOfContents from 'pages/v2/KeywordDetail/components/TableOfContents'
import { useV2Tour } from 'pages/v2/Tour/V2TourProvider'
import { useMemo } from 'react'
import { Element } from 'react-scroll'
import { AnalysisSummaryDetailActionButtons } from './AnalysisSummaryDetailActionButtons'
import { AnalysisSurveyCard } from './AnalysisSurveyCard'
import { TipsForUseButton } from './Summary/Detail'
import ScrollTopButton from './Summary/Detail/ScrollTopButton'

const ELEMENT_ID = 'summary-detail-top'

const castDemoThemeId = (themeId: number | undefined) => {
  if (
    themeId === -1 ||
    themeId === -2 ||
    themeId === -3 ||
    themeId === -4 ||
    themeId === -5
  ) {
    return themeId
  }
}

export const AnalysisSummaryDetail = () => {
  const { selectedThemeId } = useSelectedTheme()
  const { selectedSummaryId, isDemo } = useSelectedSummary()

  const { tourStep } = useV2Tour()

  const { data: summary } = useGetSummaryDetail({
    input: { summaryId: selectedSummaryId },
  })

  const summaryDetailSections = useMemo(() => {
    return (summary?.surveys ?? [])
      .map((survey) => ({
        elementId: String(survey.id),
        title: survey.analysisSummaryIdea?.title ?? '',
      }))
      .filter((s) => s.title) as { elementId: string; title: string }[]
  }, [summary])

  const isPublic = summary?.isPublic ?? false

  const demoThemeId = useMemo(
    () => castDemoThemeId(selectedThemeId),
    [selectedThemeId],
  )

  if (!summary) {
    return <Loading type="summaryDoesNotExist" />
  }

  return (
    <Box
      id={ELEMENT_ID}
      sx={{
        mt: 4,
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="end"
        spacing={3}
      >
        <Grid item>
          {isPublic && <TableOfContents sections={summaryDetailSections} />}
        </Grid>
        <Grid item>
          <AnalysisSummaryDetailActionButtons fileUrl={summary.fileUrl} />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        {summary.surveys.map((survey) => (
          <Element key={survey.id} name={String(survey.id)}>
            <AnalysisSurveyCard
              key={`summary-card-${survey.id}`}
              survey={survey}
              isPublic={isPublic}
              analysisStatus={summary.analysisResult?.analysisStatusId ?? null}
            />
          </Element>
        ))}
      </Box>

      <ScrollTopButton elementId={ELEMENT_ID} bottom={isDemo ? 120 : 32} />
      {isDemo && demoThemeId !== undefined && tourStep === 'COMPLETE' && (
        <TipsForUseButton themeId={demoThemeId} />
      )}
    </Box>
  )
}
