import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { z } from 'zod'
import {
  AgeTypes,
  GenderTypes,
  researchTargetSchema,
} from '../NewAnalysisTheme/schema'
import { useScrollHelper } from 'hooks/useScrollHelper'
import { useEffect } from 'react'

export const genderOptions = [
  { label: '男女', value: 'both' },
  { label: '男性', value: 'male' },
  { label: '女性', value: 'female' },
] as const

export const generationOptions = [
  { label: '全年代', value: 'All' },
  { label: '10代', value: '10' },
  { label: '20代', value: '20' },
  { label: '30代', value: '30' },
  { label: '40代', value: '40' },
  { label: '50代', value: '50' },
  { label: '60代~', value: '60' },
] as const

export const prefectureOptions = [
  { label: '全国', value: 'all' },
  { label: '北海道', value: 'hokkaido' },
  { label: '青森', value: 'aomori' },
  { label: '岩手', value: 'iwate' },
  { label: '宮城', value: 'miyagi' },
  { label: '秋田', value: 'akita' },
  { label: '山形', value: 'yamagata' },
  { label: '福島', value: 'fukushima' },
  { label: '茨城', value: 'ibaraki' },
  { label: '栃木', value: 'tochigi' },
  { label: '群馬', value: 'gunma' },
  { label: '埼玉', value: 'saitama' },
  { label: '千葉', value: 'chiba' },
  { label: '東京', value: 'tokyo' },
  { label: '神奈川', value: 'kanagawa' },
  { label: '新潟', value: 'niigata' },
  { label: '富山', value: 'toyama' },
  { label: '石川', value: 'ishikawa' },
  { label: '福井', value: 'fukui' },
  { label: '山梨', value: 'yamanashi' },
  { label: '長野', value: 'nagano' },
  { label: '岐阜', value: 'gifu' },
  { label: '静岡', value: 'shizuoka' },
  { label: '愛知', value: 'aichi' },
  { label: '三重', value: 'mie' },
  { label: '滋賀', value: 'shiga' },
  { label: '京都', value: 'kyoto' },
  { label: '大阪', value: 'osaka' },
  { label: '兵庫', value: 'hyogo' },
  { label: '奈良', value: 'nara' },
  { label: '和歌山', value: 'wakayama' },
  { label: '鳥取', value: 'tottori' },
  { label: '島根', value: 'shimane' },
  { label: '岡山', value: 'okayama' },
  { label: '広島', value: 'hiroshima' },
  { label: '山口', value: 'yamaguchi' },
  { label: '徳島', value: 'tokushima' },
  { label: '香川', value: 'kagawa' },
  { label: '愛媛', value: 'ehime' },
  { label: '高知', value: 'kochi' },
  { label: '福岡', value: 'fukuoka' },
  { label: '佐賀', value: 'saga' },
  { label: '長崎', value: 'nagasaki' },
  { label: '熊本', value: 'kumamoto' },
  { label: '大分', value: 'oita' },
  { label: '宮崎', value: 'miyazaki' },
  { label: '鹿児島', value: 'kagoshima' },
  { label: '沖縄', value: 'okinawa' },
] as const

export const genderLabel = (
  gender: (typeof genderOptions)[number]['value'],
): (typeof genderOptions)[number]['label'] | '指定なし' => {
  return genderOptions.find((g) => g.value === gender)?.label ?? '指定なし'
}

export const generationLabel = (
  generation: (typeof generationOptions)[number]['value'],
): (typeof generationOptions)[number]['label'] | '指定なし' => {
  return (
    generationOptions.find((g) => g.value === generation)?.label ?? '指定なし'
  )
}

export const generatePrefectureLabel = (
  prefecture: (typeof prefectureOptions)[number]['value'],
): (typeof prefectureOptions)[number]['label'] | '指定なし' => {
  return (
    prefectureOptions.find((p) => p.value === prefecture)?.label ?? '指定なし'
  )
}

export const GenderRecord: Record<GenderTypes, number[]> = {
  female: [2],
  male: [1],
  both: [1, 2],
}

export const AgeRecord: Record<AgeTypes, number> = {
  10: 3,
  20: 4,
  30: 5,
  40: 6,
  50: 7,
  60: 8,
  All: 10,
}

type FormData = {
  researchTarget: z.infer<typeof researchTargetSchema>
}

export const ResearchTargetInputForm = () => {
  const { ref: researchTargetRef, handleScroll: handleScrollToResearchTarget } =
    useScrollHelper()

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<FormData>()

  const selectedPrefectures = watch('researchTarget.prefectures') ?? []
  const selectedGenerations = watch('researchTarget.generations') ?? []

  useEffect(() => {
    if (errors.researchTarget !== undefined) {
      handleScrollToResearchTarget()
    }
  }, [errors.researchTarget])

  return (
    <FormControl
      error={!!errors?.researchTarget}
      sx={{
        display: 'flex',
        gap: 3,
        width: '100%',
      }}
    >
      <FormLabel required sx={{ textAlign: 'start' }}>
        リサーチターゲットを指定してください。
      </FormLabel>
      <Grid
        container
        sx={{
          width: 'full',
          rowGap: 4,
        }}
        ref={researchTargetRef}
      >
        <Grid item xs={12} md={6} xl={2}>
          <FormControl
            error={!!errors.researchTarget?.gender}
            sx={{ width: '100%' }}
          >
            <FormLabel required sx={{ textAlign: 'start' }}>
              性別
            </FormLabel>
            <RadioGroup
              value={watch('researchTarget.gender')}
              sx={{
                maxHeight: { xs: '80px', md: '100px', xl: '200px' },
              }}
            >
              {genderOptions.map(({ value, label }) => (
                <FormControlLabel
                  key={value}
                  label={label}
                  value={value}
                  control={
                    <Controller
                      name="researchTarget.gender"
                      control={control}
                      render={({ field: { onChange, ref } }) => (
                        <Radio
                          inputRef={ref}
                          onChange={() => onChange(value)}
                          value={value}
                        />
                      )}
                    />
                  }
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                      whiteSpace: 'nowrap',
                    },
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} xl={2}>
          <FormControl
            error={!!errors.researchTarget?.generations}
            sx={{ width: '100%' }}
          >
            <FormLabel required sx={{ textAlign: 'start' }}>
              年代
            </FormLabel>
            {errors.researchTarget?.generations && (
              <FormLabel color="error" sx={{ pt: 2 }}>
                {errors.researchTarget.generations.message}
              </FormLabel>
            )}
            <FormGroup
              sx={{
                maxHeight: { xs: '150px', md: '200px', xl: '400px' },
                overflow: 'hidden',
              }}
            >
              {generationOptions.map(({ value, label }) => (
                <FormControlLabel
                  key={value}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                      whiteSpace: 'nowrap',
                    },
                  }}
                  label={label}
                  control={
                    <Controller
                      name="researchTarget.generations"
                      control={control}
                      render={({ field: { onChange, onBlur, ref } }) => (
                        <Checkbox
                          sx={{
                            '& .MuiSvgIcon-root': { fontSize: 24 },
                          }}
                          inputProps={{
                            style: { position: 'absolute' },
                          }}
                          onBlur={onBlur}
                          onChange={(e) => {
                            if (value === 'All' && e.target.checked) {
                              onChange(['All'])
                            } else {
                              const newValue = e.target.checked
                                ? [...selectedGenerations, value]
                                : selectedGenerations.filter(
                                    (generation) => generation !== value,
                                  )
                              onChange(newValue)
                            }
                          }}
                          checked={selectedGenerations.includes(value)}
                          inputRef={ref}
                          value={value}
                          disabled={
                            selectedGenerations.includes('All') &&
                            value !== 'All'
                          }
                        />
                      )}
                    />
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} xl={8}>
          <FormControl
            error={!!errors.researchTarget?.prefectures}
            sx={{ width: '100%' }}
          >
            <FormLabel required sx={{ textAlign: 'start' }}>
              居住地
            </FormLabel>
            {errors.researchTarget?.prefectures && (
              <FormLabel color="error" sx={{ pt: 2 }}>
                {errors.researchTarget.prefectures.message}
              </FormLabel>
            )}
            <FormGroup
              sx={{
                maxHeight: { xs: '520px', md: '420px', xl: '300px' },
                display: 'flex',
              }}
            >
              {prefectureOptions.map(({ value, label }) => (
                <FormControlLabel
                  key={value}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                      whiteSpace: 'nowrap',
                    },
                  }}
                  label={label}
                  control={
                    <Controller
                      name="researchTarget.prefectures"
                      control={control}
                      render={({ field: { onChange, onBlur, ref } }) => (
                        <Checkbox
                          sx={{
                            '& .MuiSvgIcon-root': { fontSize: 24 },
                          }}
                          inputProps={{
                            style: { position: 'absolute' },
                          }}
                          onBlur={onBlur}
                          onChange={(e) => {
                            if (value === 'all' && e.target.checked) {
                              onChange(['all'])
                            } else {
                              const newValue = e.target.checked
                                ? [...selectedPrefectures, value]
                                : selectedPrefectures.filter(
                                    (prefecture) => prefecture !== value,
                                  )
                              onChange(newValue)
                            }
                          }}
                          checked={selectedPrefectures.includes(value)}
                          disabled={
                            selectedPrefectures.includes('all') &&
                            value !== 'all'
                          }
                          inputRef={ref}
                          value={value}
                        />
                      )}
                    />
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>

      <Accordion
        elevation={0}
        disableGutters
        sx={{
          '&::before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ArrowForwardIosIcon sx={{ fontSize: '0.9rem' }} />}
          sx={{
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              transform: 'rotate(90deg)',
            },
            '& .MuiAccordionSummary-content': {
              marginLeft: 2,
            },
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
        >
          詳細に設定する
        </AccordionSummary>
        <AccordionDetails
          sx={{ gap: 4, display: 'flex', flexDirection: 'column' }}
        >
          <Box>
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '14px',
                  whiteSpace: 'nowrap',
                },
              }}
              control={
                <Controller
                  name="researchTarget.specialityPanel"
                  control={control}
                  render={({ field: { onChange, ref } }) => (
                    <Checkbox
                      inputRef={ref}
                      onChange={(e) => onChange(e.target.checked)}
                      checked={watch('researchTarget.specialityPanel')}
                    />
                  )}
                />
              }
              label="スペシャリティパネルを利用する"
            />
            <Typography variant="body2">
              別途担当よりご連絡いたします。
            </Typography>
          </Box>
          <FormControl
            sx={{
              width: '100%',
              '& .MuiFormControl-root': {
                width: '100%',
              },
            }}
          >
            <FormLabel>その他依頼</FormLabel>
            <Controller
              name="researchTarget.note"
              control={control}
              render={({ field }) => (
                <TextareaAutosize {...field} minRows={5} />
              )}
            />
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </FormControl>
  )
}
