import { z } from 'zod'
import {
  baseTypes,
  otherType,
  researchTargetSchema,
  userVoiceType,
} from '../NewAnalysisTheme/schema'

export const newKeywordSchema = z.object({
  keyword: z.object({
    name: z.preprocess((value: any) => {
      if (value) {
        return value.replace(/（/g, '(').replace(/）/g, ')')
      }
      return value
    }, z.string().nonempty('キーワードを入力してください')),
    selectedExcludedWords: z.array(z.string()),
    excludedWords: z.array(z.string()),
  }),
  researchTarget: researchTargetSchema,
})

export const v2NewKeywordSchema = z
  .object({
    keyword: z.object({
      name: z.preprocess((value: any) => {
        if (value) {
          return value.replace(/（/g, '(').replace(/）/g, ')')
        }
        return value
      }, z.string().nonempty('キーワードを入力してください')),
      selectedExcludedWords: z.array(z.string()),
      excludedWords: z.array(z.string()),
    }),
    researchTarget: researchTargetSchema,
    theme: z.optional(z.union([baseTypes, userVoiceType, otherType])),
    analysisThemeId: z.number().optional(),
  })
  .refine(
    (data) => {
      // analysisThemeIdが存在する場合、themeのチェックをスキップ
      if (data.analysisThemeId !== undefined) {
        return true
      }
      return data.theme !== undefined // themeが必須
    },
    {
      message: 'テーマを選択するか、analysisThemeIdを指定してください',
      path: ['theme'],
    },
  )
