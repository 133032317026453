import { Box, Typography } from '@mui/material'
import { useCurrentUser } from 'api/getCurrentUser'
import Loading from 'includes/Loading'
import usePaidSetting from 'pages/v2/User/PaidSetting/usePaidSetting'
import BaseSnackbar from 'pages/v2/components/BaseSnackbar'
import PaidRegisterMemberForm from 'pages/v2/sp/User/PaidRegisterMember/components/PaidRegisterMemberForm'

const PaidSetting = () => {
  const { currentUser } = useCurrentUser()
  const {
    requestingCheckPaidMemberStatus,
    isRegistered,
    onSubmit,
    requesting,
    message,
    isOpenBanner,
    hideBanner,
    severity,
    status,
  } = usePaidSetting()

  if (!currentUser) {
    return null
  }

  return (
    <>
      {(requestingCheckPaidMemberStatus || requesting) && <Loading />}
      <Typography component="dt">
        <strong>
          {isRegistered ? 'Paid（後払い）審査状況' : 'Paid（後払い）登録'}
        </strong>
      </Typography>
      <Box>
        {isRegistered ? (
          <Typography
            component="dd"
            sx={{
              color: 'green',
            }}
          >
            {status}
          </Typography>
        ) : (
          <>
            {currentUser && (
              <PaidRegisterMemberForm
                user={{
                  id: currentUser.id,
                  email: currentUser.email,
                  companyName: currentUser.company_name,
                  companyNameKana: currentUser.company_name_kana,
                  givenName: currentUser.given_name,
                  givenNameKana: currentUser.given_name_kana,
                  familyName: currentUser.family_name,
                  familyNameKana: currentUser.family_name_kana,
                  zipCode: currentUser.zip_code,
                  prefecture: currentUser.prefecture,
                  address1: currentUser.address1,
                  address2: currentUser.address2,
                  address3: currentUser.address3,
                  phoneNumber: currentUser.phone_number,
                  representative: currentUser.representative
                    ? {
                        id: currentUser.representative.id,
                        givenName: currentUser.representative.given_name,
                        givenNameKana:
                          currentUser.representative.given_name_kana,
                        familyName: currentUser.representative.family_name,
                        familyNameKana:
                          currentUser.representative.family_name_kana,
                      }
                    : null,
                }}
                onSubmit={onSubmit}
                requesting={requesting}
              />
            )}
          </>
        )}
      </Box>
      <BaseSnackbar
        message={message}
        open={isOpenBanner}
        handleClose={hideBanner}
        severity={severity}
        autoHideDuration={3000}
      />
    </>
  )
}

export default PaidSetting
