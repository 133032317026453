import { CheckoutInput, CheckoutPayload } from 'api/order/checkout'
import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

type CreateKeywordWithThemeInput = CheckoutInput

export const createKeywordWithTheme = async (
  input: CreateKeywordWithThemeInput,
) => {
  const {
    provider,
    taxRate,
    productId,
    quantity,
    cancelUrl,
    successUrl,
    params,
  } = input
  const response = await axios.post<CheckoutPayload>(`/api/keywords/checkout`, {
    provider,
    tax_rate: taxRate,
    product_id: productId,
    quantity,
    success_url: successUrl,
    cancel_url: cancelUrl,
    params,
  })
  return response.data
}

export const useCreateKeywordWithTheme = (
  option?: UseMutationOption<CheckoutPayload>,
) => useMutation(createKeywordWithTheme, option)
