import useSWR from 'swr'
import { castToV2TourStep } from 'types/v2TourHistory'

type GetTourHistoryResponse = {
  v2_tour_step: string | null
}

export const useLatestTourStep = () => {
  const { data, mutate } = useSWR<GetTourHistoryResponse>('/api/v2_tour_step', {
    suspense: true,
  })

  return {
    data: {
      v2TourStep: castToV2TourStep(data?.v2_tour_step ?? null),
    },
    mutate,
  }
}
