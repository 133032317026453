import { Box, CircularProgress } from '@mui/material'
import { memo, Suspense } from 'react'
import { AnalysisSummaryDetail } from '../KeywordDetail/components/AnalysisSummaryDetail'
import { BreadCrumb } from '../KeywordDetail/components/BreadCrumb'

export const SummaryDetailPage = memo(() => {
  return (
    <Box sx={{ p: 4 }}>
      <BreadCrumb />
      <Suspense
        fallback={
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        }
      >
        <AnalysisSummaryDetail />
      </Suspense>
    </Box>
  )
})

SummaryDetailPage.displayName = 'SummaryDetailPage'
