import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Topic } from 'types/topic'

export const AnalysisRareWords = ({
  rareWords,
}: {
  rareWords: Topic['rareWords']
}) => {
  const mostFrequestWords = rareWords
    .sort((a, b) => b.score - a.score)
    .slice(0, 30)

  return (
    <div>
      <Typography
        variant="body1"
        fontSize="16px"
        fontWeight="bold"
        sx={{ my: 1 }}
      >
        注目ワード
      </Typography>

      <div>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {mostFrequestWords &&
            mostFrequestWords.map((frequestWord, index) => (
              <Box key={index} sx={{ mr: 1, my: 0.5 }}>
                <Typography
                  fontSize={'12px'}
                  sx={{
                    px: 1,
                    py: 0.5,
                    bgcolor: '#449ba7',
                    color: '#FFF',
                    borderRadius: '5px',
                    display: 'inline-block',
                  }}
                >
                  {frequestWord.word}
                </Typography>
              </Box>
            ))}
        </Box>
      </div>
    </div>
  )
}
