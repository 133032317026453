import { Button } from '@mui/material'
import formatYen, { withTax } from 'libs/formatYen'
import React from 'react'

type PaymentButtonProps = {
  price: number
  disabled: boolean
  label: string
}

const PaymentButton: React.VFC<PaymentButtonProps> = ({
  price,
  disabled,
  label,
}) => {
  return (
    <Button
      variant="contained"
      type="submit"
      sx={{ px: 7, fontSize: '20px', color: 'white' }}
      disabled={disabled}
    >
      {label} / {formatYen(withTax(price))}
      （税込）
    </Button>
  )
}

export default PaymentButton
