import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Alert, Box, Typography } from '@mui/material'
import { CreateQuestionnaireV2Input } from 'api/createQuestionnaire'
import { useCurrentUser } from 'api/getCurrentUser'
import { useGetOrder } from 'api/order/getOrder'
import A8SalesScript from 'includes/A8SalesScript'
import Loading from 'includes/Loading'
import { withTax } from 'libs/formatYen'
import getValueFromOrderProcessingData from 'libs/getValueFromOrderProcessingData'
import HtmlTooltip from 'pages/v2/components/HtmlTooltip'
import { CreateQuestionnaireForm } from 'pages/v2/KeywordDetail/components/Questionnaire/CreateQuestionnaireForm'
import { useLocation } from 'react-router-dom'
import { AnalysisSummaryIdea } from 'types/analysisSummaryIdea'
import { Survey } from 'types/survey'

type Props = {
  summaryIdea: AnalysisSummaryIdea
  importInfo: Survey['importInfo']
  isPublic: boolean
  topicId: string | undefined
  isFirstVisit: boolean
  isDialogOpen: boolean
  orderId: string | null
}

export const QuestionnaireMake = ({
  summaryIdea,
  importInfo,
  isPublic,
  topicId,
  isFirstVisit,
  isDialogOpen,
  orderId,
}: Props) => {
  const location = useLocation<{
    questionnaireFormData?: CreateQuestionnaireV2Input
  }>()
  const { data: order, isLoading } = useGetOrder({ orderId: orderId })
  const { currentUser } = useCurrentUser()

  const formValue = getValueFromOrderProcessingData({
    type: 'checkout.questionnaire',
    orderProcessingData: order?.orderProcessingData ?? null,
  })

  const defaultValues = order
    ? formValue
    : location.state?.questionnaireFormData

  return (
    <>
      {isLoading && <Loading />}
      <Box
        sx={{
          overflow: 'auto',
          height: 'calc(100vh - 70px)',
          minHeight: 530,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            padding: '30px',
          }}
        >
          <Box
            sx={{
              mb: 4,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h2"
              fontSize="16px"
              fontWeight="bold"
              sx={{
                mr: 1,
              }}
            >
              簡易アンケート実施
            </Typography>
            <HtmlTooltip
              maxWidth={800}
              title={
                <div>
                  <p>
                    【アンケートの配信・回収タイミングについて】
                    <br />
                    ・平日14時までにアンケート配信依頼をされたものは、当日中に配信、
                    <br />
                    ・翌営業日には回収・結果が表示されます。（回収・反映は回答状況により前後）
                    <br />
                    ・平日14時以降の配信依頼は、翌日中に配信、翌々営業日に回収・結果が表示されます。
                    <br />
                    ・土日祝日の配信依頼は、翌営業日中に配信、翌々営業中に回収・結果が表示されます。
                    <br />
                  </p>
                  <img src="/assets/images/delivery_time2.png" />
                </div>
              }
            >
              <HelpOutlineIcon sx={{ fontSize: 20, cursor: 'pointer' }} />
            </HtmlTooltip>
          </Box>
          {isDialogOpen && (
            <Alert severity="success" sx={{ fontSize: '16px', mb: 2 }}>
              購入ありがとうございました。以下の内容で簡易アンケートを依頼します。
            </Alert>
          )}
          {isFirstVisit && order && (
            <A8SalesScript
              isFirstVisit={isFirstVisit}
              userId={currentUser?.id.toString()}
              totalPrice={order.total}
              items={order.orderItems.map((item) => ({
                code: 'registration',
                price: withTax(item.productPrice),
                quantity: item.quantity,
              }))}
            />
          )}
          <CreateQuestionnaireForm
            key={defaultValues ? 'default' : 'new'}
            summaryIdea={summaryIdea}
            importInfo={importInfo}
            isPublic={isPublic}
            topicId={topicId}
            defaultValues={defaultValues}
          />
        </Box>
      </Box>
    </>
  )
}
