import { Box, Link, Typography } from '@mui/material'
import React from 'react'

type NoRemainingCountMessageProps = {
  remainingCount: number
  isCampaign: boolean
}
const NoRemainingCountMessage: React.VFC<NoRemainingCountMessageProps> = ({
  remainingCount,
  isCampaign,
}) => {
  if (remainingCount > 0) {
    return null
  }

  return (
    <Box
      sx={{
        textAlign: 'center',
        mt: 2,
      }}
    >
      <Typography
        variant="body1"
        component="p"
        sx={{
          color: '#f00',
        }}
      >
        {isCampaign && (
          <>
            ★キャンペーン実施中★
            <br />
          </>
        )}
        残回数がありません。購入が必要です。
      </Typography>
      <Typography
        variant="body1"
        component="p"
        sx={{
          pt: 1,
        }}
      >
        購入に関する詳細は
        <Link
          href="https://viewpers.com/plan/"
          target="_blank"
          rel="noreferrer noreferrer"
          sx={{
            display: 'inline',
            fontWeight: 'bolder',
          }}
        >
          こちら
        </Link>
      </Typography>
    </Box>
  )
}

export default NoRemainingCountMessage
