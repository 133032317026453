import { useEffect } from 'react'

const useScroll = ({ target }: { target: string }) => {
  useEffect(() => {
    const scrollWrap = document.querySelectorAll(target)
    const setMaxHeight = () => {
      scrollWrap.forEach((wrap) => {
        const element = wrap as HTMLElement
        const top = element.getBoundingClientRect().top
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop
        element.style.maxHeight = `calc(100vh - ${top + scrollTop + 40 + 35}px)`
      })
    }
    setMaxHeight()
    window.addEventListener('resize', setMaxHeight)
    return () => window.removeEventListener('resize', setMaxHeight)
  }, [target])
}

export default useScroll
