import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
} from '@mui/material'
import Divider from '@mui/material/Divider'
import AnalysisEmpathyAndSympathizers from 'pages/v2/sp/KeywordDetail/components/AnalysisEmpathyAndSympathizers'
import AnalysisSummaryIdeaTitle from 'pages/v2/sp/KeywordDetail/components/AnalysisSummaryIdeaTitle'
import { AnalysisSurveyActionButtons } from 'pages/v2/sp/KeywordDetail/components/AnalysisSurveyActionButtons'
import SummaryIdeaDetail from 'pages/v2/sp/KeywordDetail/components/SummaryIdeaDetail'
import { DisplayRelatedTweetsButton } from 'pages/v2/sp/KeywordDetail/components/TweetDialog/TopicTweetDialog/DisplayRelatedTweetsButton'
import { Survey } from 'types/survey'
import { Topic } from 'types/topic'

type Props = {
  survey: Survey
  isPublic: boolean
  isFirstSurvey: boolean
}

export const AnalysisSurveyCard = ({
  survey,
  isPublic,
  isFirstSurvey,
}: Props) => {
  const { analysisSummaryIdea } = survey

  if (analysisSummaryIdea === undefined) {
    if (survey.topic === undefined) return <></>
    return <SurveyCardBeforeGeneratedIdea topic={survey.topic} />
  }

  return (
    <Card
      sx={{
        border: '10px',
        p: 3,
        display: 'flex',
        flexDirection: 'column',
      }}
      defaultExpanded={isFirstSurvey}
      component={Accordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{ p: 0 }}
      >
        <AnalysisSummaryIdeaTitle
          title={analysisSummaryIdea.title}
          isPublic={isPublic}
        />
      </AccordionSummary>

      <AccordionDetails
        sx={{
          p: 0,
        }}
      >
        <Divider
          sx={{
            mb: 2,
          }}
        />
        <AnalysisEmpathyAndSympathizers
          totalEmpathy={survey.importInfo?.totalEmpathy}
          totalSurvey={survey.importInfo?.totalSurvey}
          isPublic={isPublic}
          importInfo={survey.importInfo}
        />
        <Divider
          sx={{
            mb: 2,
            mt: 2,
          }}
        />

        <Box
          sx={{
            mb: 2,
          }}
        >
          <SummaryIdeaDetail
            detail={analysisSummaryIdea.detail}
            isPublic={isPublic}
          />
        </Box>

        {isPublic && (
          <AnalysisSurveyActionButtons
            summaryIdea={analysisSummaryIdea}
            topic={survey.topic}
          />
        )}
      </AccordionDetails>
    </Card>
  )
}

const SurveyCardBeforeGeneratedIdea = ({ topic }: { topic: Topic }) => {
  return (
    <Card
      sx={{
        border: '10px',
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
      }}
    >
      <AnalysisSummaryIdeaTitle isPublic={false} />
      <DisplayRelatedTweetsButton topic={topic} title="" />
    </Card>
  )
}
