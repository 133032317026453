import { Box, Card, CardContent, Typography } from '@mui/material'
import { useCurrentUser } from 'api/getCurrentUser'
import { Link } from 'react-router-dom'

const ContactInfoCard = () => {
  const { currentUser } = useCurrentUser()

  if (!currentUser) {
    return null
  }

  return (
    <Card sx={{ backgroundColor: 'white', padding: '12px' }}>
      <CardContent>
        <Box sx={{ marginBottom: 1 }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: '18px',
              color: '#3D3E3F',
              mb: 2,
              fontWeight: 'bold',
            }}
          >
            ご契約プラン内容
          </Typography>
          <Typography
            sx={{
              color: '#F39422',
              fontSize: '14px',
              pl: 2,
            }}
          >
            {currentUser.plan?.name}
          </Typography>
        </Box>
        <Box
          sx={{
            pl: 2,
          }}
        >
          <Box
            sx={{
              marginBottom: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              pl: 2,
            }}
          >
            <Typography sx={{ fontSize: '14px' }}>ご契約期間</Typography>
            <Typography sx={{ fontSize: '14px' }}>
              {currentUser.contract_start_at
                ? (Date as any)
                    .create(currentUser.contract_start_at)
                    .format('{year}年{M}月{dd}日')
                : null}
              〜
              {currentUser.contract_end_at
                ? (Date as any)
                    .create(currentUser.contract_end_at)
                    .format('{year}年{M}月{dd}日')
                : null}
            </Typography>
          </Box>
          <Box
            sx={{
              marginBottom: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              pl: 2,
            }}
          >
            <Typography sx={{ fontSize: '14px' }}>分析残回数</Typography>
            <Typography sx={{ fontSize: '14px' }}>
              {currentUser.remaining_analysis_count}
            </Typography>
          </Box>
          <Box sx={{ marginTop: 2 }}>
            <Typography sx={{ fontSize: '14px' }}>
              更新・その他の問い合わせは
              <Link
                to="/v2/themes/settings/contact"
                style={{
                  color: '#F39422',
                  display: 'inline',
                }}
              >
                こちら
              </Link>
              からお願いいたします。
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default ContactInfoCard
