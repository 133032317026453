import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

export type ConfirmPaymentsByPaidInput = {
  orderId: number
}

export type ConfirmPaymentsByPaidPayload = {
  message: string
}

export const confirmPaymentsByPaid = async (
  input: ConfirmPaymentsByPaidInput,
) => {
  const response = await axios.post<ConfirmPaymentsByPaidPayload>(
    '/api/paid/confirm_payments',
    {
      order_id: input.orderId,
    },
  )

  return response.data
}

export const useConfirmPaymentsByPaid = (
  option?: UseMutationOption<ConfirmPaymentsByPaidPayload>,
) => useMutation(confirmPaymentsByPaid, option)
