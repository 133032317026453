//ページ遷移するたびにGTMのイベントを送信するためのカスタムフック
import { Location } from 'history'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// dataLayerの型修正
declare global {
  interface Window {
    dataLayer: any[]
  }
}

const useGTM = () => {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const handleRouteChange = (location: Location<unknown>) => {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'pageview',
          page: location.pathname + location.search,
        })
      }
    }

    handleRouteChange(location)

    const unlisten = history.listen((location) => {
      handleRouteChange(location)
    })

    return () => {
      unlisten()
    }
  }, [location, history])
}

export default useGTM
