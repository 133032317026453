import phoneRegex from 'constants/regex/phoneRegex'
import zipCodeRegex from 'constants/regex/zipCodeRegex'
import { z } from 'zod'

export const paidRegisterMemberScheme = z
  .object({
    companyName: z.preprocess(
      (val) => (val === null ? '' : val),
      z.string().nonempty('御社名を入力してください'),
    ),
    companyNameKana: z.preprocess(
      (val) => (val === null ? '' : val),
      z.string().nonempty('御社名（カナ）を入力してください'),
    ),
    zipCode: z.preprocess(
      (val) => (val === null ? '' : val),
      z
        .string()
        .nonempty('郵便番号を入力してください')
        .regex(
          zipCodeRegex,
          '郵便番号はハイフンを含む7桁の数字（例: 123-4567）で入力してください',
        ),
    ),
    prefecture: z.preprocess(
      (val) => (val === null ? '' : val),
      z.string().nonempty('都道府県を入力してください'),
    ),
    address1: z.preprocess(
      (val) => (val === null ? '' : val),
      z.string().nonempty('住所1を入力してください'),
    ),
    address2: z.preprocess(
      (val) => (val === null ? '' : val),
      z.string().nonempty('住所2を入力してください'),
    ),
    address3: z.preprocess(
      (val) => (val === null ? '' : val),
      z.string().optional(),
    ),
    tel: z.preprocess((val: any) => {
      if (typeof val !== 'string') {
        return ''
      }
      return val
    }, z.string().regex(phoneRegex, '電話番号はハイフンを含む形式で、10桁または11桁の数字で入力してください').nonempty('電話番号を入力してください')),
    representativeSei: z.preprocess(
      (val) => (val === null ? '' : val),
      z.string().nonempty('代表者姓を入力してください'),
    ),
    representativeMei: z.preprocess(
      (val) => (val === null ? '' : val),
      z.string().nonempty('代表者名を入力してください'),
    ),
    representativeSeiKana: z.preprocess(
      (val) => (val === null ? '' : val),
      z.string().nonempty('代表者姓（カナ）を入力してください'),
    ),
    representativeMeiKana: z.preprocess(
      (val) => (val === null ? '' : val),
      z.string().nonempty('代表者名（カナ）を入力してください'),
    ),
    clerkSei: z.preprocess(
      (val) => (val === null ? '' : val),
      z.string().nonempty('担当者姓を入力してください'),
    ),
    clerkMei: z.preprocess(
      (val) => (val === null ? '' : val),
      z.string().nonempty('担当者名を入力してください'),
    ),
    clerkSeiKana: z.preprocess(
      (val) => (val === null ? '' : val),
      z.string().nonempty('担当者姓（カナ）を入力してください'),
    ),
    clerkMeiKana: z.preprocess(
      (val) => (val === null ? '' : val),
      z.string().nonempty('担当者名（カナ）を入力してください'),
    ),
    clerkTel: z.preprocess(
      (val) => (val === null ? '' : val),
      z
        .string()
        .optional()
        .refine(
          (val) => val === '' || phoneRegex.test(val as string),
          '電話番号はハイフンを含む形式で、10桁または11桁の数字で入力してください',
        ),
    ),
    clerkMobileTel: z.preprocess(
      (val) => (val === null ? '' : val),
      z
        .string()
        .optional()
        .refine(
          (val) => val === '' || phoneRegex.test(val as string),
          '電話番号はハイフンを含む形式で、10桁または11桁の数字で入力してください',
        ),
    ),
    email: z.preprocess(
      (val) => (val === null ? '' : val),
      z
        .string()
        .email('メールアドレスを入力してください')
        .nonempty('メールアドレスを入力してください'),
    ),
    isSameBillingInfo: z.boolean(),
    agreeTerms: z
      .boolean()
      .refine((val) => val === true, '利用規約に同意してください'),
    billingSei: z.string().optional(),
    billingMei: z.string().optional(),
    billingSeiKana: z.string().optional(),
    billingMeiKana: z.string().optional(),
    billingZipCode: z.string().optional(),
    billingPrefecture: z.string().optional(),
    billingAddress1: z.string().optional(),
    billingAddress2: z.string().optional(),
    billingAddress3: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (!data.isSameBillingInfo) {
      const requiredFieldMap = {
        billingSei: '請求先姓',
        billingMei: '請求先名',
        billingSeiKana: '請求先姓（カナ）',
        billingMeiKana: '請求先名（カナ）',
        billingZipCode: '請求先郵便番号',
        billingPrefecture: '請求先都道府県',
        billingAddress1: '請求先住所1',
        billingAddress2: '請求先住所2',
      } as const
      const requiredFields = Object.keys(
        requiredFieldMap,
      ) as (keyof typeof requiredFieldMap)[]
      requiredFields.forEach((field) => {
        if (!data[field]) {
          ctx.addIssue({
            path: [field],
            message: `${requiredFieldMap[field]}を入力してください`,
            code: z.ZodIssueCode.custom,
          })
          return
        }

        if (
          field === 'billingZipCode' &&
          data['billingZipCode'] &&
          !zipCodeRegex.test(data['billingZipCode'])
        ) {
          ctx.addIssue({
            path: [field],
            message: `${requiredFieldMap[field]}はハイフンを含む7桁の数字（例: 123-4567）で入力してください`,
            code: z.ZodIssueCode.custom,
          })
          return
        }
      })
    }
  })
