import { Button } from '@mui/material'
import { VFC } from 'react'

type QuestionnaireDownloadLinkProps = {
  status: {
    id: number
  } | null
  fileUrl: string | null
}

const QuestionnaireDownloadLink: VFC<QuestionnaireDownloadLinkProps> = ({
  status,
  fileUrl,
}) => {
  if (status?.id !== 10 || !fileUrl) {
    return null
  }

  return (
    <Button
      disabled={fileUrl === null}
      component="a"
      href={fileUrl}
      download="Result.xlsx"
      variant="contained"
      sx={{
        lineHeight: 1,
        textAlign: 'center',
        fontSize: 14,
        borderRadius: '5em',
        textDecoration: 'none',
        minWidth: '165px',
        color: 'white',
        px: 2,
        py: 1,
      }}
    >
      アンケート結果をダウンロードする
    </Button>
  )
}

export default QuestionnaireDownloadLink
