import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

type SendPasswordResetEmailInput = {
  email: string
}

type SendPasswordResetEmailPayload = {
  email: string
}

export const sendPasswordResetEmail = async (
  input: SendPasswordResetEmailInput,
) => {
  const { email } = input
  const response = await axios.post<SendPasswordResetEmailPayload>(
    '/api/auth/user/password_reset/email',
    {
      email,
    },
  )

  return response.data
}

type ChangePasswordInput = {
  password: string
  id: number
  expires: number
  hash: string
  signature: string
}

type ChangePasswordPayload = {
  email: string
}

export const changePassword = async (input: ChangePasswordInput) => {
  const { password } = input
  const response = await axios.post<ChangePasswordPayload>(
    `/api/auth/user/password_reset/reset?expires=${input.expires}&hash=${input.hash}&id=${input.id}&signature=${input.signature}`,
    {
      password,
    },
  )

  return response.data
}

export const useSendPasswordResetEmail = (
  option?: UseMutationOption<SendPasswordResetEmailPayload>,
) => useMutation(sendPasswordResetEmail, option)

export const useChangePassword = (
  option?: UseMutationOption<ChangePasswordPayload>,
) => useMutation(changePassword, option)
