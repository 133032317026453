import { useCurrentUser } from 'api/getCurrentUser'
import useProductInfo from 'hooks/useProductInfo'

export const useNeedKeywordCheckout = (checkoutMode: boolean) => {
  const { currentUser } = useCurrentUser()
  const { productInfo } = useProductInfo('keyword')

  if (!productInfo) {
    return false
  }

  return checkoutMode
    ? true
    : (currentUser?.remaining_adjustment_request_count ?? 0) <= 0 ||
        (currentUser?.remaining_analysis_count ?? 0) <= 0
}

export const useNeedQuestionnaireCheckout = (checkoutMode: boolean) => {
  const { currentUser } = useCurrentUser()
  const { productInfo } = useProductInfo('questionnaire')

  if (!productInfo) {
    return false
  }

  return checkoutMode
    ? true
    : (currentUser?.remaining_questionnaire_count ?? 0) <= 0
}

export const useNeedWeightedCheckout = (checkoutMode: boolean) => {
  const { currentUser } = useCurrentUser()
  const { productInfo } = useProductInfo('survey')

  if (!productInfo) {
    return false
  }

  return checkoutMode ? true : (currentUser?.remaining_weighted_count ?? 0) <= 0
}

export const useNeedTopicRequestCheckout = (checkoutMode: boolean) => {
  const { currentUser } = useCurrentUser()
  const { productInfo } = useProductInfo('summary')

  if (!productInfo) {
    return false
  }

  return checkoutMode
    ? true
    : (currentUser?.remaining_adjustment_request_count ?? 0) <= 0 ||
        (currentUser?.remaining_analysis_count ?? 0) <= 0
}
