import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React from 'react'

type AnalysisWordScoreTableProps = {
  rows: {
    word: string
    score: number
  }[]
  height?: number
}

const AnalysisWordScoreTable: React.FC<AnalysisWordScoreTableProps> = (
  props,
) => {
  const { rows, height } = props

  return (
    <Paper sx={{ width: '100%', height: height ?? '100%' }}>
      <TableContainer
        sx={{
          overflow: 'auto',
          height: height,
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Word</TableCell>
              <TableCell>Score</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.word}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white',
                }}
              >
                <TableCell component="th" scope="row">
                  {row.word}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.score.toFixed(4)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default AnalysisWordScoreTable
