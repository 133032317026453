import { Box, Card, CardContent } from '@mui/material'
import { useGetSampleAnalysis } from 'api/getAnalysis'
import { useV2Analysis } from 'hooks/useV2Analysis'
import Loading from 'includes/Loading'
import AnalysisSummaryTable from 'pages/v2/sp/V2Home/components/AnalysisSummaryTable'
import { FC, useEffect, useRef } from 'react'

const AnalysisResultList: FC = () => {
  const { data: analyses, loading, hasMore, loadMore } = useV2Analysis()
  const { data: sampleAnalyses } = useGetSampleAnalysis()

  const loadMoreRef = useRef(null)

  useEffect(() => {
    if (!hasMore || loading) return

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMore()
      }
    })

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current)
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current)
      }
    }
  }, [hasMore, loading, loadMore])

  return (
    <>
      {loading && <Loading />}
      {[...sampleAnalyses, ...analyses].map((analysis) => (
        <Card
          key={`site-item-${analysis.analysis_theme_id}-${analysis.id}`}
          sx={{
            mb: 2,
          }}
        >
          <CardContent
            sx={{
              p: 0,

              '&:last-child': {
                pb: 0,
              },
            }}
          >
            <AnalysisSummaryTable analysis={analysis} />
          </CardContent>
        </Card>
      ))}
      <Box ref={loadMoreRef} />
    </>
  )
}

export default AnalysisResultList
