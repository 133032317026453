import { Box } from '@mui/material'
import React from 'react'

type AuthPageLayoutProps = {
  children: React.ReactNode
}

const AuthPageLayout: React.VFC<AuthPageLayoutProps> = ({ children }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          overflow: 'hidden',
          width: '100%',
          minWidth: '100%',
          height: '100%',

          '&:after': {
            position: 'absolute',
            display: 'block',
            width: '100%',
            height: '100%',
            content: "''",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '0 0',
            backgroundSize: '100% auto',
            left: '0',
            top: '0',
            zIndex: '1',
            backgroundColor: 'rgba(218, 228, 248, 0.5)',
          },
        }}
      >
        <img
          src="/assets/images/login_bg01.jpg"
          style={{ minHeight: '100%' }}
          alt=""
        />
      </Box>

      <Box
        sx={{
          zIndex: '10',
          display: 'flex',
          boxSizing: 'border-box',
          width: '100vw',
          height: '100vh',
          padding: '20px 20px',
          justifyContent: 'center',
          alignItems: 'center',
          visibility: 'visible',
          pointerEvents: 'auto',
          opacity: '1',
        }}
      >
        <Box
          sx={{
            zIndex: '1',
            width: '100%',
            maxWidth: '450px',
          }}
        >
          <Box
            sx={{
              overflow: 'auto',
              padding: {
                lg: '40px 50px',
                xs: '20px 25px',
              },
              borderRadius: '15px',
              backgroundColor: '#fff',
              boxShadow: '0 0 10px rgba(160, 181, 200, 0.24)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                mb: 5,
              }}
            >
              <img
                src="/assets/images/vpfull-logo.png"
                alt="ViewPoint"
                style={{
                  width: '190px',
                  verticalAlign: 'middle',
                }}
              />
            </Box>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AuthPageLayout
