const choiceColor = (index: number) => {
  var colorcode = ''
  switch (index) {
    case 0:
      colorcode = '#2882AA'
      break
    case 1:
      colorcode = '#4DADD8'
      break
    case 2:
      colorcode = '#A9D7EB'
      break
    case 3:
      colorcode = '#ccdae5'
      break
    default:
      colorcode = '#ccdae5'
      break
  }
  return colorcode
}

export { choiceColor }
