import { Box, Link, Typography } from '@mui/material'

export const Dentifrice = () => {
  return (
    <>
      <Typography>
        プロモーションを行う際やアイデアを企画化するとき、以下のような課題がよくあります：
      </Typography>
      <Typography sx={{ px: 2, fontSize: 'xs' }}>
        <Typography component="li">
          新しい企画を検討して提案する際に、そのアイデアの妥当性を説明することが難しい
        </Typography>
        <Typography component="li">
          提案された新しい企画が正しそうか判断することが難しい
        </Typography>
      </Typography>
      <Typography sx={{ py: 2 }}>
        この事例ではこれらの課題を解決するために、どのような手順でViewPersを利用していったかご紹介します。
        <br />
        <br />
        「歯ブラシ」の新しいキャッチコピーを考えるのに利用してみた事例です。
      </Typography>
      <Box component="section" sx={{ my: 2 }}>
        <Typography component="h2" sx={{ my: 1, fontWeight: 'bold' }}>
          ステップ1: 「歯磨き」の調査
        </Typography>
        <p>
          まず、キーワード「歯磨き」に関して現在どのようなことが話題になっているかを調査しました。全国全性年代に聴取した共感数を参考に、日常生活の中での歯磨き習慣に関するトピックが多くの人々の共感を集めるということがわかりました。
        </p>
      </Box>
      <Box component="section" sx={{ my: 2 }}>
        <Typography component="h2" sx={{ my: 1, fontWeight: 'bold' }}>
          ステップ2: 「リフレッシュ」という視点を追加
        </Typography>
        <p>
          トピック要約をみると、リフレッシュに歯磨きをしていると読み取れたので、実際にどのように話題になっているか、キーワードを追加して深掘りしてみました。結果として、リセット効果、職場で手軽にという使えそうなキーワードが発掘できました。仕事の合間にリフレッシュするための方法として注目されていることも客観データとしてわかりました。
        </p>
      </Box>
      <Box component="section" sx={{ my: 2 }}>
        <Typography component="h2" sx={{ my: 1, fontWeight: 'bold' }}>
          ステップ3: リフレッシュとしての歯磨きの需要確認
        </Typography>
        <p>
          キャッチコピーの方向性は定まりそうです。次に、実際にリフレッシュとして歯磨きを使うことに需要があるかどうかをアンケートで確認しました。アンケートでは、職業、日中の過ごし方、リフレッシュしたい時間・方法、そして歯磨きがリフレッシュにつながるかを聞きました。その結果、多くの人が日中にリフレッシュしたいと考えていることが分かり、歯磨きはその手段の一つとして有効であることが確認できました。
          <br />
          <Link
            component="a"
            href="/v2/themes/-1/keywords/-2/summaries/-2/questionnaires/-1"
            sx={{ fontWeight: 'bold' }}
            target="_blank"
          >
            {'>> アンケート結果'}
          </Link>
        </p>
      </Box>
      <Box component="section" sx={{ my: 2 }}>
        <Typography component="h2" sx={{ my: 1, fontWeight: 'bold' }}>
          ステップ4: プロモーションアイデアの考案
        </Typography>
        <p>
          これらの調査とアンケート結果を基に、リフレッシュをテーマにした歯磨きの新しいプロモーションアイデアを考案しました。仕事の合間や日常生活の中でリフレッシュするために歯磨きを推奨するキャッチコピーを作成することができました。
        </p>
      </Box>
      <Typography>
        このようにして、アイデアを昇華させていくことができます。普段の業務にぜひご活用ください！
      </Typography>
    </>
  )
}
