import useSWR from 'swr'
import {
  AnnouncementDetailResponse,
  parseAnnounceDetail,
} from 'types/announcements'

type GetAnnouncementDetailInput = {
  announcementId?: number | null
}

export const useGetAnnouncementDetail = ({
  input,
}: {
  input: GetAnnouncementDetailInput
}) => {
  const { data, ...rest } = useSWR<{ data: AnnouncementDetailResponse }>(
    input.announcementId == undefined
      ? null
      : `/api/announcements/${input.announcementId}`,
  )

  return {
    data: data !== undefined ? parseAnnounceDetail(data.data) : undefined,
    ...rest,
  }
}
