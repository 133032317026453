import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Fab } from '@mui/material'
import React, { useState } from 'react'
import { Link as Scroll } from 'react-scroll'

type ScrollTopButtonProps = {
  bottom: number
  elementId: string
}

const ScrollTopButton: React.FC<ScrollTopButtonProps> = ({
  bottom,
  elementId,
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  window.addEventListener('scroll', toggleVisibility)

  if (!isVisible) {
    return null
  }

  return (
    <Fab
      sx={{
        position: 'fixed',
        // bottom: 120,
        bottom,
        right: 32,
        zIndex: 1000,
        backgroundColor: '#3D3E3F',
        borderRadius: '50%',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#3D3E3F',
          opacity: 0.8,
        },
      }}
    >
      <Scroll
        to={elementId}
        smooth={true}
        duration={1000}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <KeyboardArrowUpIcon
          width="24px"
          height="24px"
          sx={{ color: '#fff' }}
        />
      </Scroll>
    </Fab>
  )
}

export default ScrollTopButton
