import {
  AnalysisResult,
  AnalysisResultResponse,
  parseAnalysisResult,
} from './analysisResult'

export type AnalysisThemeResponse = {
  id: number
  name: string
  analysis_results: AnalysisResultResponse[]
  isSample?: boolean
}

export type AnalysisThemeKind =
  | 'newBusiness'
  | 'newProduct'
  | 'competitiveResearch'
  | 'existingProduct'
  | 'userVoice'
  | 'other'

export type AnalysisTheme = {
  id: number
  name: string
  analysisResults: AnalysisResult[]
  isSample: boolean
}

export const parseAnalysisTheme = (
  input: AnalysisThemeResponse,
): AnalysisTheme => {
  return {
    id: input.id,
    name: input.name,
    analysisResults: input.analysis_results.map(parseAnalysisResult),
    isSample: input.isSample ?? false,
  }
}
