import React, { useEffect, useRef } from 'react'

type A8SalesScriptProps = {
  userId: string | undefined
  totalPrice: number
  items: { code: string; price: number; quantity: number }[]
  isFirstVisit: boolean
}

declare global {
  interface Window {
    a8sales: (data: {
      pid: string
      order_number: string
      currency: string
      items: { code: string; price: number; quantity: number }[]
      total_price: number
    }) => void
  }
}

const A8SalesScript: React.VFC<A8SalesScriptProps> = ({
  userId,
  totalPrice,
  items,
  isFirstVisit,
}) => {
  const hasSentNotification = useRef(false)

  useEffect(() => {
    if (!userId) {
      return
    }

    if (!isFirstVisit) {
      return
    }

    if (!hasSentNotification.current) {
      if (process.env.REACT_APP_PUBLIC_ENV === 'local') {
        console.log('a8sales', {
          pid: 's00000026003001',
          order_number: userId,
          currency: 'JPY',
          items: items,
          total_price: totalPrice,
        })
      } else {
        const script = document.createElement('script')
        script.src = '//statics.a8.net/a8sales/a8sales.js'
        script.onload = () => {
          if (!window.a8sales) {
            return
          }

          window.a8sales({
            pid: 's00000026003001',
            order_number: userId,
            currency: 'JPY',
            items: items,
            total_price: totalPrice,
          })
        }
        document.body.appendChild(script)
      }
      hasSentNotification.current = true
    }
  }, [userId, totalPrice, items])

  return <span id="a8sales"></span>
}

export default A8SalesScript
