import { PrefectureOptions } from 'api/createKeyword/index'
import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

export type CreateAnalysisThemeInput = {
  name: string
  kind:
    | 'newBusiness'
    | 'newProduct'
    | 'competitiveResearch'
    | 'existingProduct'
    | 'userVoice'
    | 'other'
  userVoiceType?: 'positive' | 'negative' | 'indifferent' | null
  otherMemo?: string | null
  tags: number[]
  prefectures: PrefectureOptions[]
  speciality_panel: boolean
  note?: string | undefined
}

type CreateAnalysisThemePayload = {
  id: number
}

export const createAnalysisTheme = async (input: CreateAnalysisThemeInput) => {
  const {
    userVoiceType: user_voice_type,
    otherMemo: other_memo,
    ...rest
  } = input
  const response = await axios.post<CreateAnalysisThemePayload>(
    '/api/analysis_themes',
    {
      user_voice_type,
      other_memo,
      ...rest,
    },
  )

  return response.data
}

export const useCreateanalysisTheme = (
  option?: UseMutationOption<CreateAnalysisThemePayload>,
) => useMutation(createAnalysisTheme, option)
