import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'
import { OrderProcessingData } from 'types/order'

type CheckOrdersByPaidInput = {
  contents: string
  price: number
  frdKey: string
  provider: 'stripe' | 'paid'
  taxRate: number
  productId: number
  topicId?: string
  quantity: number
  params: OrderProcessingData['parameters']
}

type CheckOrdersByPaidPayload = {
  order_id: string
}

export const checkOrdersByPaid = async (input: CheckOrdersByPaidInput) => {
  const response = await axios.post<CheckOrdersByPaidPayload>(
    '/api/paid/check_orders',
    {
      contents: input.contents,
      price: input.price,
      frd_key: input.frdKey,
      provider: input.provider,
      tax_rate: input.taxRate,
      product_id: input.productId,
      topic_id: input.topicId,
      quantity: input.quantity,
      params: input.params,
    },
  )

  return response.data
}

export const useCheckOrdersByPaid = (
  option?: UseMutationOption<CheckOrdersByPaidPayload>,
) => useMutation(checkOrdersByPaid, option)
