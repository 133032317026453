import { Box, Link as MuiLink, Typography } from '@mui/material'

export const Lunch = () => {
  return (
    <>
      <Typography>
        新商品の行う際やアイデアを企画化するとき、以下のような課題がよくあります：
      </Typography>
      <Typography sx={{ px: 2, fontSize: 'xs' }}>
        <Typography component="li">
          新しい企画を検討して提案する際に、そのアイデアの妥当性（どれだけ市場が反応してくれるかの可能性）を説明することが難しい
        </Typography>
        <Typography component="li">
          チームで出るアイデアのレパートリーが少ない
        </Typography>
      </Typography>
      <Typography sx={{ py: 2 }}>
        この事例ではこれらの課題を解決するために、どのような手順で冷凍食品のインサイトを活用していったかご紹介します。
        食品メーカーが新商品開発を考えるのに利用した事例です。
      </Typography>
      <Box component="section" sx={{ my: 2 }}>
        <Typography component="h2" sx={{ my: 1, fontWeight: 'bold' }}>
          ステップ1: 「冷凍食品 お弁当」の調査
        </Typography>
        <p>
          まず、キーワード「冷凍食品
          お弁当」に関して現在どのようなことが話題になっているかを調査しました。投稿から得られたタイトル・サマリーから、忙しい現代社会において冷凍食品が効率的なソリューションとして評価されていることがわかりました。特に、上から2番目の「冷凍食品の広がる可能性と消費者の多様なニーズへの対応」というトピックから、健康志向の消費者向けの低カロリーや高タンパク質の冷凍食品が、お弁当向けの有望なビジネスチャンスとなり得ることが確認できました。
        </p>
      </Box>
      <Box component="section" sx={{ my: 2 }}>
        <Typography component="h2" sx={{ my: 1, fontWeight: 'bold' }}>
          ステップ2: 「健康志向」という視点を追加
        </Typography>
        <p>
          ステップ1での調査結果をもとに、こんどは冷凍食品に限らず健康志向のお弁当づくりの様子を把握すべく、代表的な栄養素であるたんぱく質を例に、キーワード「弁当
          (タンパク質 OR
          たんぱく質)」で深掘り調査を実施しました。結果からは、ヘルシーでバランスの取れた食事が求められている様子が読み取れます。とりわけ上から5番目の「高たんぱく質・低塩・低添加物の新製品弁当の提案」というトピックから、忙しい毎日を送る人々（例：ビジネスマン）をターゲットとした健康志向のお弁当の商品展開の可能性を発見することができました。
        </p>
      </Box>
      <Box component="section" sx={{ my: 2 }}>
        <Typography component="h2" sx={{ my: 1, fontWeight: 'bold' }}>
          ステップ3: 新商品の考案
        </Typography>
        <p>
          そこで、これらの調査をもとに、健康志向をテーマにした新しい冷凍食品の商品アイデアを考案し、ビジネスマンを対象としたアンケートで簡単な検証を実施することでターゲットに求められている商品像の解像度の向上を図りました。
          （実際の設問と調査結果は上から5番目の「高たんぱく質・低塩・低添加物の新製品弁当の提案」というトピックの「深掘り調査結果を見る」ボタンからご覧いただけます）
        </p>
        <br />
        <p>
          その結果、以下のような新商品案を生み出すことができました：
          <br />
          低カロリー冷凍食品：ダイエット中でも安心して食べられる、低カロリーで栄養バランスの良い冷凍食品。アンケート結果より、野菜を入れて訴求ポイントとするのを1つの案として試してみることに。
        </p>
        <p>
          高タンパク質冷凍食品：筋肉増強や健康維持に役立つ、高タンパク質の冷凍食品。こちらもアンケート結果より、豆類や卵を材料とすることを検討。
        </p>
      </Box>
      <Box component="section" sx={{ my: 2 }}>
        <Typography component="h2" sx={{ my: 1, fontWeight: 'bold' }}>
          まとめ
        </Typography>
        <Typography sx={{ px: 2, fontSize: 'xs' }}>
          このように、新商品の開発を検討する際の仮説づくりでもViewPersが活躍します。
          <br />
          ぜひ試してみてください！
        </Typography>
      </Box>
    </>
  )
}
