import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useGetAnalysisThemes } from 'api/getAnalysisThemes'
import { useCurrentUser } from 'api/getCurrentUser'
import { useUpdateUser } from 'api/updateUser'
import { useBanner } from 'hooks/useBanner'
import { additionalUserInputSchema } from 'pages/v2/checkout/components/additionalUserInput/scheme'
import BaseSnackbar from 'pages/v2/components/BaseSnackbar'
import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { dateFormatter } from 'util/dateFormatter'
import { z } from 'zod'

type FormData = z.infer<typeof additionalUserInputSchema>

type Props = {}
const UserInfoForm: React.VFC<Props> = () => {
  const { currentUser } = useCurrentUser()
  const { mutateSample } = useGetAnalysisThemes({})
  const { message, severity, isOpen, showBanner, hideBanner } = useBanner()
  const method = useForm({
    resolver: zodResolver(additionalUserInputSchema),
    defaultValues: {
      name: currentUser?.name ?? '',
      companyName: currentUser?.company_name ?? '',
      phoneNumber: currentUser?.phone_number ?? '',
      isDisplayV2Sample: currentUser?.is_display_v2_sample ?? 0,
    },
  })

  const { request } = useUpdateUser({
    onSuccess: () => {
      showBanner({
        message: '更新しました。',
        severity: 'success',
      })
      mutateSample()
    },
    onError: (error) => {
      console.error(error)
      showBanner({
        message: 'エラーが発生しました。',
        severity: 'error',
      })
    },
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = method

  const onSubmit = async (data: FormData) => {
    await request({
      userId: String(currentUser?.id ?? 0),
      name: data.name,
      companyName: data.companyName,
      phoneNumber: data.phoneNumber,
      isDisplayV2Sample: data.isDisplayV2Sample === 1 ? true : false,
    })
  }

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <FormControl
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                width: '100%',
                borderTop: '1px solid #ccdae5',
                padding: '20px 0',
                gap: 2,
              }}
            >
              <FormLabel
                sx={{ textAlign: 'start', whiteSpace: 'pre', color: '#000' }}
              >
                ID
              </FormLabel>
              <Box sx={{ fontWeight: 'bolder' }}>{currentUser?.id}</Box>
            </FormControl>
            <FormControl
              error={!!errors?.name}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                borderTop: '1px solid #ccdae5',
                padding: '20px 0',
                gap: 2,
              }}
            >
              <FormLabel
                required
                sx={{ textAlign: 'start', whiteSpace: 'pre', color: '#000' }}
              >
                ご担当者様お名前
              </FormLabel>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="text"
                    name="name"
                    placeholder="ご担当者様お名前"
                    variant="outlined"
                    error={!!errors?.name}
                    helperText={errors?.name?.message ?? errors?.name?.message}
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        height: 'auto',
                        padding: '10px 14px',
                      },
                    }}
                    InputProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl
              error={!!errors?.companyName}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                borderTop: '1px solid #ccdae5',
                padding: '20px 0',
                gap: 2,
              }}
            >
              <FormLabel
                required
                sx={{ textAlign: 'start', whiteSpace: 'pre', color: '#000' }}
              >
                御社名
              </FormLabel>
              <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="text"
                    name="companyName"
                    placeholder="御社名"
                    variant="outlined"
                    error={!!errors?.companyName}
                    helperText={
                      errors?.companyName?.message ??
                      errors?.companyName?.message
                    }
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        height: 'auto',
                        padding: '10px 14px',
                      },
                    }}
                    InputProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                width: '100%',
                borderTop: '1px solid #ccdae5',
                padding: '20px 0',
                gap: 2,
              }}
            >
              <FormLabel
                sx={{ textAlign: 'start', whiteSpace: 'pre', color: '#000' }}
              >
                メールアドレス
              </FormLabel>
              <Box sx={{ fontWeight: 'bolder' }}>{currentUser?.email}</Box>
            </FormControl>
            <FormControl
              error={!!errors?.phoneNumber}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                borderTop: '1px solid #ccdae5',
                padding: '20px 0',
                gap: 2,
              }}
            >
              <FormLabel
                required
                sx={{ textAlign: 'start', whiteSpace: 'pre', color: '#000' }}
              >
                電話番号
              </FormLabel>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="text"
                    name="phoneNumber"
                    placeholder="090-1234-5678"
                    variant="outlined"
                    error={!!errors?.phoneNumber}
                    helperText={
                      errors?.phoneNumber?.message ??
                      errors?.phoneNumber?.message
                    }
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        height: 'auto',
                        padding: '10px 14px',
                      },
                    }}
                    InputProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                width: '100%',
                borderTop: '1px solid #ccdae5',
                padding: '20px 0',
                gap: 2,
              }}
            >
              <FormLabel
                sx={{ textAlign: 'start', whiteSpace: 'pre', color: '#000' }}
              >
                登録日時
              </FormLabel>
              <Box sx={{ fontWeight: 'bolder' }}>
                {currentUser?.created_at
                  ? dateFormatter(currentUser.created_at)
                  : null}
              </Box>
            </FormControl>
            <FormControl
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                width: '100%',
                borderTop: '1px solid #ccdae5',
                padding: '20px 0',
                gap: 2,
              }}
            >
              <FormLabel
                sx={{ textAlign: 'start', whiteSpace: 'pre', color: '#000' }}
              >
                利用プラン
              </FormLabel>
              <Box sx={{ fontWeight: 'bolder' }}>{currentUser?.plan?.name}</Box>
            </FormControl>
            <FormControl
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                width: '100%',
                borderTop: '1px solid #ccdae5',
                padding: '20px 0',
                gap: 2,
              }}
            >
              <FormLabel
                sx={{ textAlign: 'start', whiteSpace: 'pre', color: '#000' }}
              >
                分析回数残り
              </FormLabel>
              <Box sx={{ fontWeight: 'bolder' }}>
                {currentUser?.remaining_analysis_count ?? 0} 回
              </Box>
            </FormControl>
            <FormControl
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                width: '100%',
                borderTop: '1px solid #ccdae5',
                padding: '20px 0',
                gap: 2,
              }}
            >
              <FormLabel
                sx={{ textAlign: 'start', whiteSpace: 'pre', color: '#000' }}
              >
                重み付け残り
              </FormLabel>
              <Box sx={{ fontWeight: 'bolder' }}>
                {currentUser?.remaining_weighted_count ?? 0} 回
              </Box>
            </FormControl>
            <FormControl
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                width: '100%',
                borderTop: '1px solid #ccdae5',
                padding: '20px 0',
                gap: 2,
              }}
            >
              <FormLabel
                sx={{ textAlign: 'start', whiteSpace: 'pre', color: '#000' }}
              >
                簡易アンケート回数残り
              </FormLabel>
              <Box sx={{ fontWeight: 'bolder' }}>
                {currentUser?.remaining_questionnaire_count ?? 0} 回
              </Box>
            </FormControl>
            <FormControl
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                width: '100%',
                borderTop: '1px solid #ccdae5',
                borderBottom: '1px solid #ccdae5',
                padding: '20px 0',
                gap: 2,
              }}
            >
              <FormLabel
                sx={{ textAlign: 'start', whiteSpace: 'pre', color: '#000' }}
              >
                共感調整依頼回数残り
              </FormLabel>
              <Box sx={{ fontWeight: 'bolder' }}>
                {currentUser?.remaining_adjustment_request_count ?? 0} 回
              </Box>
            </FormControl>
            <FormControl
              error={!!errors?.phoneNumber}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                borderTop: '1px solid #ccdae5',
                padding: '20px 0',
                gap: 2,
              }}
            >
              <FormLabel sx={{ textAlign: 'start', color: '#000' }}>
                サンプルデータ表示
              </FormLabel>
              <Controller
                name="isDisplayV2Sample"
                control={control}
                render={({ field }) => (
                  <Select
                    required
                    {...field}
                    fullWidth
                    placeholder=""
                    variant="outlined"
                    error={!!errors?.phoneNumber}
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        height: 'auto',
                        padding: '10px 14px',
                      },
                    }}
                  >
                    <MenuItem value={1} sx={{ fontSize: 'medium' }}>
                      表示
                    </MenuItem>
                    <MenuItem value={0} sx={{ fontSize: 'medium' }}>
                      非表示
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Box>
          <Button
            variant="contained"
            type="submit"
            sx={{ px: 7, fontSize: '14px', color: 'white' }}
          >
            更新する
          </Button>
        </Box>
        <BaseSnackbar
          message={message}
          open={isOpen}
          handleClose={hideBanner}
          severity={severity}
          autoHideDuration={60000}
        />
      </form>
    </FormProvider>
  )
}

export default UserInfoForm
