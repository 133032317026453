import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { stepsInfo } from 'pages/v2/Tour/components/TourGuideStepDialog'
import { VFC } from 'react'

type Props = { open: boolean; disabled: boolean; onNext: () => void }
export const StartTourGuideDialog: VFC<Props> = ({
  open,
  disabled,
  onNext,
}) => {
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      fullWidth
      maxWidth="lg"
      open={open}
    >
      <DialogTitle
        sx={{
          m: 0,
          py: 8,
          px: 2,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 4,
          maxWidth: '650px',
          width: '100%',
          margin: '0 auto',
        }}
      >
        <Box
          sx={{
            alignItems: 'end',
            display: 'flex',
            gap: 2,
          }}
        >
          <img
            src="/assets/images/vpfull-logo.png"
            alt="ViewPoint"
            width="200px"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            mt: 4,
            width: '100%',
          }}
        >
          {stepsInfo.map((info, index) => (
            <Box
              key={index}
              sx={{
                aspectRatio: '1/1',
                width: '20%',
                borderRadius: '50%',
                border: `2px solid #FF8800`,
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              STEP {index + 1} <br />
              {info.title}
            </Box>
          ))}
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          fontWeight: 'bold',
          maxWidth: '650px',
          width: '100%',
          margin: '0 auto',
          overflow: 'visible',
        }}
      >
        登録ありがとうございます！
        <br />
        <br />
        ViewPersはターゲットリサーチを簡単に実施でき、SNS分析の結果に更なる価値を与えます。
        <br />
        <br />
        ご利用方法について4 STEPでご案内いたします。
      </DialogContent>
      <DialogActions sx={{ display: 'flex', py: 8, justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ color: 'white' }}
          onClick={onNext}
          disabled={disabled}
        >
          次へ
        </Button>
      </DialogActions>
    </Dialog>
  )
}
