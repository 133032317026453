import { parsePaginate } from 'api/util'
import { parse } from 'json2csv'

type Props = {
  keywordId: number | undefined
}

const fields = ['tweet_id', 'url', 'text', 'tweet_at']
export const downloadDemoTweetCsv = async ({ keywordId }: Props) => {
  const jsonData = await fetch(
    `/assets/v2-example/tweets/${keywordId}/all.json`,
  ).then((res) => res.json())
  try {
    const csv = parse(jsonData.data, { fields })
    const blob = new Blob([csv], { type: 'text/csv:charset=utf-8' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'data.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (err) {
    console.error(err)
  }
}
