import { Card, CardContent, Typography } from '@mui/material'
import AnalysisResultsTable from 'pages/v2/V2Home/components/AnalysisResultTable'
import { FC } from 'react'

const AnalysisResultList: FC = () => {
  return (
    <Card sx={{ backgroundColor: 'white', padding: '12px' }}>
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            fontSize: '18px',
            color: '#3D3E3F',
            mb: 2,
            fontWeight: 'bold',
          }}
        >
          実行中の分析キーワード
        </Typography>
        <AnalysisResultsTable />
      </CardContent>
    </Card>
  )
}

export default AnalysisResultList
