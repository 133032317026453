import { useCurrentUser } from 'api/getCurrentUser'
import UserInfoForm from 'pages/v2/sp/User/UserSetting/components/UserInfoForm'

const UserSetting = () => {
  const { currentUser } = useCurrentUser()

  if (!currentUser) {
    return null
  }

  return <UserInfoForm />
}

export default UserSetting
