import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { VFC } from 'react'

type Props = { open: boolean; disabled: boolean; onSubmit: () => void }
export const StartTourGuideDialog: VFC<Props> = ({
  open,
  disabled,
  onSubmit,
}) => {
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      fullWidth
      maxWidth="sm"
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          margin: 0,
          width: '100%',
          maxWidth: '100%',
        },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          py: 8,
          px: 2,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <Box
          sx={{
            alignItems: 'end',
            display: 'flex',
            gap: 2,
          }}
        >
          <img
            src="/assets/images/vpfull-logo.png"
            alt="ViewPoint"
            width="160px"
          />
          <Typography sx={{ fontWeight: 'bold' }}>へようこそ</Typography>
        </Box>
        <Typography sx={{ fontWeight: 'bold' }}>
          ご登録ありがとうございます
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ fontWeight: 'bold' }}>
        <Typography
          component="span"
          sx={{ color: '#FF8800', fontWeight: 'bold' }}
        >
          ViewPersであなたの業務を効率化していきましょう。
        </Typography>
        <br />
        <br />
        1. 収集したいキーワードを設定
        <br />
        2. 共感度を目安にして、面白いトピックや投稿を見つける
        <br />
        <br />
        レポートが出た時点で裏付けとなる情報が付加されているので、このプロセスを繰り返すだけで、新たな気づきが生まれます。
        <br />
        <br />
        スマホからでもご利用可能ですが、PCからの利用で最大限の機能がご利用いただけますので、併せてご活用ください。
      </DialogContent>
      <DialogActions sx={{ display: 'flex', py: 8, justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ color: 'white' }}
          onClick={onSubmit}
          disabled={disabled}
        >
          利用を開始する
        </Button>
      </DialogActions>
    </Dialog>
  )
}
