import { Box, Card } from '@mui/material'
import AnalysisEmpathyAndSummaryContent from 'pages/v2/KeywordDetail/components/AnalysisEmpathyAndSummaryContent'
import AnalysisSummaryIdeaTitle from 'pages/v2/KeywordDetail/components/AnalysisSummaryIdeaTitle'
import { AnalysisSurveyContent } from 'pages/v2/KeywordDetail/components/AnalysisSurveyContent'
import { AnalysisStatus } from 'types/analysisResult'
import { Survey } from 'types/survey'
import { AnalysisSurveyActionButtons } from './AnalysisSurveyActionButtons'
import { Topic } from 'types/topic'
import { DisplayRelatedTweetsButton } from './TweetDialog'

type Props = {
  survey: Survey
  isPublic: boolean
  analysisStatus: AnalysisStatus | null
}
const CONTENT_HEIGHT = 250

export const AnalysisSurveyCard = ({
  survey,
  isPublic,
  analysisStatus,
}: Props) => {
  const { analysisSummaryIdea } = survey

  if (analysisSummaryIdea === undefined) {
    if (survey.topic === undefined) return <></>
    return <SurveyCardBeforeGeneratedIdea topic={survey.topic} />
  }

  return (
    <Card
      sx={{
        border: '10px',
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
      }}
    >
      <AnalysisSummaryIdeaTitle
        title={analysisSummaryIdea.title}
        rating={analysisSummaryIdea.rating}
        isPublic={isPublic}
      />

      <AnalysisEmpathyAndSummaryContent
        totalEmpathy={survey.importInfo?.totalEmpathy}
        totalSurvey={survey.importInfo?.totalSurvey}
        isPublic={isPublic}
        detail={analysisSummaryIdea.detail}
        height={CONTENT_HEIGHT}
      />

      <AnalysisSurveyContent
        surveyId={survey.id}
        topic={survey.topic}
        height={CONTENT_HEIGHT}
        importInfo={survey.importInfo}
      />

      {isPublic && (
        <AnalysisSurveyActionButtons
          summaryIdea={analysisSummaryIdea}
          topic={survey.topic}
          importInfo={survey.importInfo}
          empathySurveys={survey.empathySurveys}
          isPublic={isPublic}
          topicId={survey.topic?.labelId}
          analysisStatus={analysisStatus}
        />
      )}
    </Card>
  )
}

const SurveyCardBeforeGeneratedIdea = ({ topic }: { topic: Topic }) => {
  return (
    <Card
      sx={{
        border: '10px',
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
      }}
    >
      <AnalysisSummaryIdeaTitle isPublic={false} />
      <DisplayRelatedTweetsButton topic={topic} title="" />
    </Card>
  )
}
