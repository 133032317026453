import { useQuestionnaireByTopic } from 'api/getQuestionnaire'
import {
  genderLabel,
  generatePrefectureLabel,
} from 'pages/v2/components/ResearchTargetInputForm'
import { useMemo } from 'react'

const useQuestionnaireByTopicData = (id: string | undefined) => {
  const { data: questionnaire } = useQuestionnaireByTopic({
    input: { topicId: id ? Number(id) : undefined },
  })

  const targetText = useMemo(() => {
    if (!questionnaire) {
      return ''
    }
    const ages = questionnaire.requestContent.target.age.map((age) => {
      return (
        age +
        (Number(age) === 10 ? '代以下' : Number(age) === 60 ? '代以上' : '代')
      )
    })
    const genders = questionnaire.requestContent.target.gender.map(genderLabel)
    const prefectures = questionnaire.requestContent.target.prefecture.map(
      generatePrefectureLabel,
    )

    return [...genders, ...ages, ...prefectures].join('、')
  }, [questionnaire])

  const answerCount = useMemo(() => {
    return questionnaire?.resultContent?.target[0]?.totalCount || 0
  }, [questionnaire])

  const specialityPannelText = useMemo(() => {
    if (!questionnaire) {
      return ''
    }
    const useText = questionnaire.requestContent.specialityPannel.use
      ? '利用'
      : '未利用'
    const memo = questionnaire.requestContent.specialityPannel.memo

    return memo ? `${useText}、（${memo}）` : useText
  }, [questionnaire])

  return { questionnaire, targetText, answerCount, specialityPannelText }
}

export default useQuestionnaireByTopicData
