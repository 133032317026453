import { loadStripe } from '@stripe/stripe-js'
import { checkOrdersByPaid } from 'api/checkoutByPaid/checkOrdersByPaid'
import { useRegisterOrdersByPaid } from 'api/checkoutByPaid/registerOrdersByPaid'
import { useCreateKeywordWithTheme } from 'api/checkoutKeywordWithTheme'
import { useConfirmKeywordWithThemePaymentsByPaid } from 'api/confirmKeywordWithThemePaymentsByPaid'
import { CreateKeywordInput } from 'api/createKeyword'
import { useCurrentUser } from 'api/getCurrentUser'
import { useUpdateUser } from 'api/updateUser'
import { useBanner } from 'hooks/useBanner'
import useFrdKey from 'hooks/useFrdKey'
import useNeedUserAdditionalInput from 'hooks/useNeedUserAdditionalInput'
import useProductInfo from 'hooks/useProductInfo'
import { V2Paths } from 'pages/v2/paths'
import { FormData as SpKeywordFormData } from 'pages/v2/sp/NewKeyword/components/NewKeywordForm'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY as string
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

const useKeywordWithThemeCheckoutDialog = ({
  createKeywordParams,
  keywordFormData,
}: {
  createKeywordParams: CreateKeywordInput
  keywordFormData: SpKeywordFormData
}) => {
  const history = useHistory()
  const location = useLocation()
  const { currentUser } = useCurrentUser()
  const { productInfo } = useProductInfo('keyword')
  const [isCheckoutInput, setIsCheckoutInputInput] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const needUserAdditionalInput = useNeedUserAdditionalInput()
  const [additionalUserInput, setAdditionalUserInput] = useState<{
    name: string
    companyName: string
    phoneNumber: string
  } | null>(null)
  const {
    message,
    severity,
    isOpen: isOpenBanner,
    showBanner,
    hideBanner,
  } = useBanner()
  const { request: requestUpdateUser } = useUpdateUser({
    onSuccess: () => {},
    onError: () => {
      showBanner({ message: 'エラーが発生しました', severity: 'error' })
    },
  })
  const frdKey = useFrdKey()
  const { request: requestRegisterOrdersByPaid } = useRegisterOrdersByPaid({
    onSuccess: ({ message }) => {
      showBanner({ message, severity: 'success' })
    },
    onError: (error) => {
      if (error.response) {
        showBanner({
          message: error.response.data.errors,
          severity: 'error',
        })
      }
      return setIsProcessing(false)
    },
  })
  const { request: requestConfirmPaymentsByPaid } =
    useConfirmKeywordWithThemePaymentsByPaid({
      onSuccess: ({ message }) => {
        console.log({ message })
      },
      onError: (error) => {
        if (error.response) {
          showBanner({
            message: error.response.data.errors,
            severity: 'error',
          })
        }
        return setIsProcessing(false)
      },
    })

  const { request, requesting } = useCreateKeywordWithTheme({
    onSuccess: async ({ id: sessionId }) => {
      const stripe = await stripePromise
      if (!stripe) {
        showBanner({
          message: 'エラーが発生しました。もう一度お試しください。',
          severity: 'error',
        })
        return
      }
      if (!sessionId) {
        showBanner({
          message: 'セッションIDが取得できませんでした。',
          severity: 'error',
        })
        return
      }
      const { error } = await stripe.redirectToCheckout({ sessionId })

      if (error) {
        showBanner({
          message: 'エラーが発生しました。もう一度お試しください。',
          severity: 'error',
        })
        return
      }
      showBanner({ message: '購入が完了しました。', severity: 'success' })
    },
    onError: () => {
      showBanner({ message: '購入に失敗しました。', severity: 'error' })
      return
    },
  })

  const taxRate = 10

  const onNextCheckout = useCallback(
    (data: { name: string; companyName: string; phoneNumber: string }) => {
      setIsCheckoutInputInput(true)
      setAdditionalUserInput({
        name: data.name,
        companyName: data.companyName,
        phoneNumber: data.phoneNumber,
      })
    },
    [],
  )

  const onPurchaseViaStripe = useCallback(async () => {
    if (!currentUser) {
      return
    }

    if (additionalUserInput) {
      await requestUpdateUser({
        userId: currentUser?.id ? String(currentUser.id) : null,
        name: additionalUserInput.name,
        companyName: additionalUserInput.companyName,
        phoneNumber: additionalUserInput.phoneNumber,
        isDisplayV2Sample: currentUser.is_display_v2_sample === 1,
      })
    }
    if (productInfo == null) {
      showBanner({
        message: '商品情報が取得できませんでした。',
        severity: 'error',
      })
      return
    }
    const path = `${window.location.origin}${window.location.pathname}`
    await request({
      provider: 'stripe',
      taxRate,
      productId: productInfo.id,
      quantity: 1,
      successUrl: path,
      cancelUrl: path,
      params: {
        type: 'checkout.keyword',
        params: keywordFormData,
      },
      ...createKeywordParams,
    })
  }, [
    additionalUserInput,
    currentUser,
    productInfo,
    request,
    createKeywordParams,
    showBanner,
  ])

  const onPurchaseViaPaid = useCallback(async () => {
    if (isProcessing) {
      return
    }

    if (!currentUser?.paid_id) {
      showBanner({
        message: 'Paid（後払い）会員登録が必要です。',
        severity: 'error',
      })
      return
    }
    if (productInfo == null) {
      showBanner({
        message: '商品情報が取得できませんでした。',
        severity: 'error',
      })
      return
    }
    if (!frdKey) {
      showBanner({
        message:
          '決済のキーの読み込みに失敗しました。再度リロードするか、時間をおいて再度お試しください。',
        severity: 'error',
      })
    }

    const checkOrdersByPaidParams = {
      contents: productInfo.title,
      price: Number(productInfo.priceWithTax),
      provider: 'paid' as const,
      taxRate,
      productId: productInfo.id,
      quantity: 1,
    }

    setIsProcessing(true)

    showBanner({
      message: '決済処理中です。しばらくお待ちください。',
      severity: 'info',
    })

    window.onbeforeunload = () => {
      return '決済処理中です。このページを離れると処理が中断されます。'
    }

    let orderId: string | null = null
    try {
      const response = await checkOrdersByPaid({
        ...checkOrdersByPaidParams,
        frdKey,
        params: {
          type: 'checkout.keyword',
          params: keywordFormData,
        },
      })

      orderId = response.order_id
    } catch (error: any) {
      if (error.response) {
        showBanner({
          message: error.response.data.errors,
          severity: 'error',
        })
      }
      return setIsProcessing(false)
    }

    if (!orderId) {
      showBanner({
        message: '受注処理に失敗しました。もう一度お試しください。',
        severity: 'error',
      })
      return setIsProcessing(false)
    }

    await requestRegisterOrdersByPaid({
      ...checkOrdersByPaidParams,
      orderId: Number(orderId),
      frdKey,
    })

    await requestConfirmPaymentsByPaid({
      analysisThemeId: createKeywordParams.analysisThemeId,
      orderId: Number(orderId),
    })

    window.onbeforeunload = null
    setIsProcessing(false)

    setTimeout(() => {
      history.push({
        pathname: window.location.pathname,
        search: `?order_id=${orderId}`,
      })
      // ページの上部にスクロール
      window.scrollTo(0, 0)
    }, 2000)
  }, [
    requestRegisterOrdersByPaid,
    frdKey,
    productInfo,
    currentUser,
    createKeywordParams,
  ])

  useEffect(() => {
    if (needUserAdditionalInput) {
      setIsCheckoutInputInput(false)
    } else {
      setIsCheckoutInputInput(true)
    }
  }, [needUserAdditionalInput])

  const onMoveToPaidSetting = () => {
    history.push({
      pathname: V2Paths.PaidSetting,
      state: { keywordFormData, from: location.pathname },
    })
  }

  return {
    isCheckoutInput,
    onNextCheckout,
    onPurchaseViaStripe,
    onPurchaseViaPaid,
    message,
    severity,
    isOpenBanner,
    hideBanner,
    productInfo,
    currentUser,
    requesting,
    needUserAdditionalInput,
    isProcessing,
    onMoveToPaidSetting,
  }
}

export default useKeywordWithThemeCheckoutDialog
