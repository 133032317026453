import { useGetProductBySlug } from 'api/getProductBySlug'
import formatYen from 'libs/formatYen'
import { Product as ProductFromDB } from 'types/product'
import { v3DateFormatter } from 'util/dateFormatter'

type Description = { text: string; sub?: string[] }

type CommonProduct = {
  title: string
  precautions: string[]
  campaign?: string
  formatCampaign: ({
    price,
    amount,
    date,
  }: {
    date: string
    amount: number
    price: string
  }) => string
}

type QuestionnaireThanks = {
  description: (date: string | null) => string
  subDescription: () => string
}

type QuestionnaireDescriptions = (
  questionnaireUsageCount: number,
) => Description[]

type KeywordDescriptions = (questionnaireUsageCount: number) => Description[]

type SummaryDescriptions = (questionnaireUsageCount: number) => Description[]

type KeywordThanks = {
  description: (date: string | null) => string
  subDescription: () => string
}

type TopicThanks = {
  description: (title: string | null) => string
  subDescription: (date: string | null) => string
}

type SummaryThanks = {
  description: (date: string | null) => string
  subDescription: () => string
}

type QuestionnaireProduct = CommonProduct & {
  slug: 'questionnaire'
  thanks: QuestionnaireThanks
  formatDescriptions: QuestionnaireDescriptions
}

type KeywordProduct = CommonProduct & {
  slug: 'keyword'
  thanks: KeywordThanks
  formatDescriptions: KeywordDescriptions
}

type TopicProduct = CommonProduct & {
  slug: 'survey'
  thanks: TopicThanks
  descriptions: Description[]
}

type SummaryProduct = CommonProduct & {
  slug: 'summary'
  thanks: SummaryThanks
  formatDescriptions: SummaryDescriptions
}

type Product =
  | QuestionnaireProduct
  | KeywordProduct
  | TopicProduct
  | SummaryProduct

const questionnaireDescription = (amount: number) =>
  `100人へ最大5問のアンケートを${amount}回実施できます。`
const keywordDescription =
  '直近1ヶ月の間にXで投稿された中からキーワードを含んだ投稿を最大1,000件収集します。'
const summaryDescription =
  '同一のキーワード群で別属性で共感度をつけ直すことができます。'
const surveyDescription = 'トピック内の投稿全てに100名の共感度を付与できます。'
const questionnairePrecaution =
  '公序良俗に反する投稿はトピックが付与されないことがあります。'
const questionnairePrecaution2 =
  '内容によってアンケートの実施をお断りすることがあります。'
const questionnairePrecaution3 =
  'トピック共感度・簡易アンケートについては100人回収できるように努めますが、アンケートの回収数は保証されません。'
const questionnaireProduct: QuestionnaireProduct = {
  slug: 'questionnaire',
  title: '簡易アンケート実施',
  formatDescriptions: (questionnaireUsageCount: number) => [
    { text: questionnaireDescription(questionnaireUsageCount) },
    { text: '以下の内容が提供されます。', sub: ['アンケートの作成'] },
  ],
  formatCampaign: ({ price, amount, date }) =>
    `★キャンペーン実施中★\n先着20名様、${date}までの期間限定\n通常${price}で1回実施のところ、期間中は追加でもう${
      amount - 1
    }回実施できます!\nこの機会にぜひご利用ください!\n※追加分は8月中まで利用可能です。`,
  precautions: [
    questionnairePrecaution,
    questionnairePrecaution2,
    questionnairePrecaution3,
  ],
  thanks: {
    description: (date: string | null) =>
      `アンケートの実施を受け付けました。\n ${
        date ? `アンケートの回答は${date}ごろに\n 確認いただける予定です。` : ''
      }`,
    subDescription: () =>
      'ご確認いただけるようになりましたらメールでお知らせしますので、お待ちください。',
  },
}

const keywordProduct: KeywordProduct = {
  slug: 'keyword',
  title: '新規キーワード分析',
  formatDescriptions: (questionnaireUsageCount: number) => [
    { text: keywordDescription },
    {
      text: '1収集した投稿を最大10個のトピックに分割し、要約・抽出したアイデアへ共感度を付与します。',
    },
    { text: questionnaireDescription(questionnaireUsageCount) },
  ],
  formatCampaign: ({ price, amount, date }) =>
    `★キャンペーン実施中★\n先着20名様、${date}までの期間限定\n通常${price}で分析一回が、期間中は追加でもう${
      amount - 1
    }回実施できます!\nこの機会にぜひご利用ください!\n※追加分は8月中まで利用可能です。`,
  precautions: [
    questionnairePrecaution,
    questionnairePrecaution2,
    questionnairePrecaution3,
  ],
  thanks: {
    description: (date: string | null) =>
      `分析を開始しました。\n ${
        date
          ? `レポート・共感付与は${date}ごろに\n 確認いただける予定です。`
          : ''
      }`,
    subDescription: () =>
      'ご確認いただけるようになりましたらメールでお知らせしますので、お待ちください。',
  },
}

const summaryProduct: SummaryProduct = {
  slug: 'summary',
  title: '共感度の調整',
  formatDescriptions: (questionnaireUsageCount: number) => [
    { text: summaryDescription },
    { text: questionnaireDescription(questionnaireUsageCount) },
  ],
  formatCampaign: ({ price, amount, date }) =>
    `★キャンペーン実施中★\n先着20名様、${date}までの期間限定\n通常${price}で1回実施のところ、期間中は追加でもう${
      amount - 1
    }回実施できます!\nこの機会にぜひご利用ください!\n※追加分は8月中まで利用可能です。`,
  precautions: [
    questionnairePrecaution,
    questionnairePrecaution2,
    questionnairePrecaution3,
  ],
  thanks: {
    description: (date: string | null) =>
      `共感度の調整を受け付けました。\n ${
        date ? `レポートは${date}ごろに\n 確認いただける予定です。` : ''
      }`,
    subDescription: () =>
      'ご確認いただけるようになりましたらメールでお知らせしますので、お待ちください。',
  },
}

const surveyProduct: TopicProduct = {
  slug: 'survey',
  title: '投稿への共感度付与オプション購入',
  descriptions: [{ text: surveyDescription }],
  precautions: [questionnairePrecaution, '回収数は保証されません。'],
  formatCampaign: ({ price, amount, date }) =>
    `★キャンペーン実施中★\n先着20名様、${date}までの期間限定\n通常${price}で1回実施のところ、期間中は追加でもう${
      amount - 1
    }回実施できます!\nこの機会にぜひご利用ください!\n※追加分は8月中まで利用可能です。`,
  thanks: {
    description: (title: string | null) =>
      `${
        title
          ? `「${title}」\n のトピックの投稿に共感度付与を受け付けました。`
          : 'トピックの投稿に共感度付与を受け付けました。'
      }`,
    subDescription: (date: string | null) =>
      `${date ? `レポートの作成は${date}ごろを予定しています。` : ''}
        ご確認いただけるようになりましたらメールでお知らせしますので、お待ちください。`,
  },
}

const PRODUCTS_DATA: Product[] = [
  questionnaireProduct,
  keywordProduct,
  surveyProduct,
  summaryProduct,
]

export type ProductInfo = ProductFromDB &
  Product & { priceWithTax: number; descriptions: Description[] }

export const thanksArgsData = {
  keyword: {
    description: '１営業日後の15時',
    subDescription: null,
  },
  questionnaire: {
    description: '１営業日後の15時',
    subDescription: null,
  },
  survey: {
    description: null,
    subDescription: '１営業日後の15時',
  },
  summary: {
    description: '１営業日後の15時',
    subDescription: null,
  },
}

// 商品データの型定義
function isProductSlug(slug: any): slug is Product['slug'] {
  return PRODUCTS_DATA.some((product) => product.slug === slug)
}

function getProductBySlug<T extends Product['slug']>(
  slug: T,
): Extract<Product, { slug: T }> | undefined {
  if (isProductSlug(slug)) {
    return PRODUCTS_DATA.find((product) => product.slug === slug) as Extract<
      Product,
      { slug: T }
    >
  }
  return undefined
}

const useProductInfo = (
  slug: Product['slug'] | undefined,
): { productInfo: ProductInfo | undefined } => {
  if (!slug) {
    return { productInfo: undefined }
  }

  const productInfo = getProductBySlug(slug)
  if (productInfo == null) {
    return { productInfo: undefined }
  }

  const { data: product } = useGetProductBySlug({ slug })
  if (!product) {
    return { productInfo: undefined }
  }

  const priceWithTax = Math.floor(Number(product.price) * 1.1)

  let descriptions: Description[] = []
  let campaign: string | undefined = undefined
  if (productInfo.slug === 'questionnaire') {
    descriptions = productInfo.formatDescriptions(
      product.questionnaireUsageCount,
    )
    if (product.isCampaign) {
      campaign = productInfo.formatCampaign({
        price: formatYen(priceWithTax),
        amount: product.questionnaireUsageCount,
        date: v3DateFormatter(product.displayUntil as string, 'SlashDate'),
      })
    }
  } else if (productInfo.slug === 'keyword') {
    descriptions = productInfo.formatDescriptions(
      product.questionnaireUsageCount,
    )
    if (product.isCampaign) {
      campaign = productInfo.formatCampaign({
        price: formatYen(priceWithTax),
        amount: product.analysisUsageCount,
        date: v3DateFormatter(product.displayUntil as string, 'SlashDate'),
      })
    }
  } else if (productInfo.slug === 'summary') {
    descriptions = productInfo.formatDescriptions(
      product.questionnaireUsageCount,
    )
    if (product.isCampaign) {
      campaign = productInfo.formatCampaign({
        price: formatYen(priceWithTax),
        amount: product.adjustmentRequestUsageCount,
        date: v3DateFormatter(product.displayUntil as string, 'SlashDate'),
      })
    }
  } else if (productInfo.slug === 'survey') {
    descriptions = productInfo.descriptions
    if (product.isCampaign) {
      campaign = productInfo.formatCampaign({
        price: formatYen(priceWithTax),
        amount: product.weightedUsageCount,
        date: v3DateFormatter(product.displayUntil as string, 'SlashDate'),
      })
    }
  }

  return {
    productInfo: {
      ...product,
      ...productInfo,
      campaign,
      descriptions,
      priceWithTax: priceWithTax,
    },
  }
}

export const useQuestionnaireThanksDescription = ({
  date,
  isFailed,
}: {
  date: string | null
  isFailed: boolean
}): string => {
  const { productInfo } = useProductInfo('questionnaire')
  if (!productInfo || productInfo.slug !== 'questionnaire') {
    return ''
  }
  if (isFailed) {
    return ''
  }
  return productInfo.thanks.description(date)
}

export const useQuestionnaireThanksSubDescription = ({
  isFailed,
  amount,
}: {
  isFailed: boolean
  amount: number
}): string => {
  const { productInfo } = useProductInfo('questionnaire')
  if (!productInfo || productInfo.slug !== 'questionnaire') {
    return ''
  }
  if (isFailed) {
    return `簡易アンケート${amount}回分を付与したので、再度お試しください。`
  }
  return productInfo.thanks.subDescription()
}

export const useKeywordThanksDescription = ({
  date,
  isFailed,
}: {
  date: string | null
  isFailed: boolean
}): string => {
  const { productInfo } = useProductInfo('keyword')
  if (!productInfo || productInfo.slug !== 'keyword') {
    return ''
  }
  if (isFailed) {
    return ''
  }
  return productInfo.thanks.description(date)
}

export const useKeywordThanksSubDescription = ({
  isFailed,
  amount,
}: {
  isFailed: boolean
  amount: number
}): string => {
  const { productInfo } = useProductInfo('keyword')
  if (!productInfo || productInfo.slug !== 'keyword') {
    return ''
  }
  if (isFailed) {
    return `新規キーワード分析${amount}回分を付与したので、再度お試しください。`
  }
  return productInfo.thanks.subDescription()
}

export const useTopicThanksDescription = ({
  title,
  isFailed,
}: {
  title: string | null
  isFailed: boolean
}): string => {
  const { productInfo } = useProductInfo('survey')
  if (!productInfo || productInfo.slug !== 'survey') {
    return ''
  }
  if (isFailed) {
    return ''
  }
  return productInfo.thanks.description(title)
}

export const useTopicThanksSubDescription = ({
  date,
  isFailed,
  amount,
}: {
  date: string | null
  isFailed: boolean
  amount: number
}): string => {
  const { productInfo } = useProductInfo('survey')
  if (!productInfo || productInfo.slug !== 'survey') {
    return ''
  }
  if (isFailed) {
    return `投稿への共感度付与${amount}回分を付与したので、再度お試しください。`
  }
  return productInfo.thanks.subDescription(date)
}

export const useSummaryThanksDescription = ({
  date,
  isFailed,
}: {
  date: string | null
  isFailed: boolean
}): string => {
  const { productInfo } = useProductInfo('summary')
  if (!productInfo || productInfo.slug !== 'summary') {
    return ''
  }
  if (isFailed) {
    return ''
  }
  return productInfo.thanks.description(date)
}

export const useSummaryThanksSubDescription = ({
  isFailed,
  amount,
}: {
  isFailed: boolean
  amount: number
}): string => {
  const { productInfo } = useProductInfo('summary')
  if (!productInfo || productInfo.slug !== 'summary') {
    return ''
  }
  if (isFailed) {
    return `共感度調整${amount}回分を付与したので、再度お試しください。`
  }
  return productInfo.thanks.subDescription()
}

export default useProductInfo
