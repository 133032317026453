import { Button } from '@mui/material'
import { useDisclosure } from 'hooks/useDisclosure'
import { VFC } from 'react'
import { Topic } from 'types/topic'
import { WeightedFilterResultDialog } from './WeightedFilterResultDialog'

type Props = {
  isWeightedFilterCompleted: boolean
  topic: Topic
}
export const WeightedFilterResultButton: VFC<Props> = ({
  isWeightedFilterCompleted,
  topic,
}) => {
  const { onClose, isOpen, onOpen } = useDisclosure()
  return (
    <>
      <Button
        variant="outlined"
        onClick={onOpen}
        disabled={!isWeightedFilterCompleted}
      >
        {isWeightedFilterCompleted
          ? '投稿ごとの共感度を確認する'
          : 'トピックの投稿への共感度付与依頼中'}
      </Button>
      <WeightedFilterResultDialog
        open={isOpen}
        onClose={onClose}
        topic={topic}
      />
    </>
  )
}
