import { Box, Grid, Typography } from '@mui/material'
import { RatioCircle } from 'pages/v2/components/common/RatioCircle'
import React from 'react'
import { ImportInfo } from 'types/importInfo'

type Props = {
  totalEmpathy: number | undefined
  totalSurvey: number | undefined
  isPublic: boolean
  importInfo: ImportInfo | undefined
}

const AnalysisEmpathyAndSympathizers: React.FC<Props> = ({
  totalEmpathy,
  totalSurvey,
  isPublic,
  importInfo,
}) => {
  return (
    <Grid container>
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ fontSize: 12, fontWeight: 'bold' }}>共感した人数</Box>
        <RatioCircle
          top={isPublic ? totalEmpathy : undefined}
          bottom={isPublic ? totalSurvey : undefined}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            fontSize: 12,
            fontWeight: 'bold',
          }}
        >
          ボリューム属性
        </Box>
        {importInfo?.mainGender !== undefined &&
          importInfo.mainGeneration !== undefined && (
            <Sympathizers
              items={[
                {
                  imgSrc:
                    importInfo.mainGender === '男性'
                      ? '/assets/images/v2/30_male.svg'
                      : '/assets/images/v2/30_female.svg',
                  age: importInfo.mainGeneration,
                  gender: importInfo.mainGender,
                },
              ]}
            />
          )}
      </Grid>
    </Grid>
  )
}

const Sympathizers = ({
  items,
}: {
  items: { gender: string; age: string; imgSrc: string }[]
}) => (
  <Box sx={{ mt: 2 }}>
    <Grid container rowSpacing={1} columnSpacing={1}>
      {items.map((item) => (
        <Grid item xs={6} key={`${item.age}-${item.gender}`}>
          <Box
            sx={{
              backgroundColor: '#fcd4bc',
              borderRadius: '50%',
              width: {
                xs: 100,
                md: 120,
              },
              height: {
                xs: 100,
                md: 120,
              },
              position: 'relative',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                lineHeight: '1',
                position: 'absolute',
                justifyContent: 'center',
                top: '50%',
                transform: 'translate(0%, -50%)',
              }}
            >
              <img
                src={item.imgSrc}
                alt=""
                style={{ paddingBottom: '8px', width: '50%' }}
              />
              <Typography variant="body2">{`${item.age}・${item.gender}`}</Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  </Box>
)

export default AnalysisEmpathyAndSympathizers
