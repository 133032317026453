import { useCheckSession } from 'api/order/checkSession'
import usePreventSuccessPageNavigation from 'hooks/usePreventSunccessPageNavigation'
import {
  thanksArgsData,
  useKeywordThanksDescription,
  useKeywordThanksSubDescription,
  useQuestionnaireThanksDescription,
  useQuestionnaireThanksSubDescription,
  useSummaryThanksDescription,
  useSummaryThanksSubDescription,
  useTopicThanksDescription,
  useTopicThanksSubDescription,
} from 'hooks/useProductInfo'
import useQuery from 'hooks/useQuery'
import Loading from 'includes/Loading'
import ThanksDialog from 'pages/v2/checkout/components/ThanksDialog'
import { useHistory } from 'react-router-dom'

const SuccessPage = () => {
  const query = useQuery()
  const history = useHistory()
  const sessionId = query.get('session_id')
  const { data: order, isLoading } = useCheckSession({
    sessionId,
    options: { refreshInterval: 2000 },
  })
  const product = order?.orderItems[0]?.product
  const slug = product?.slug
  const webhookAPIStatus = order?.webhookAPIStatuses[0]
  const isWaitingWebhook = webhookAPIStatus?.status === 'pending'
  const isFailedWebhook = webhookAPIStatus?.status === 'failed'

  const questionnaireDescription = useQuestionnaireThanksDescription({
    date: thanksArgsData.questionnaire.description,
    isFailed: isFailedWebhook,
  })
  const keywordDescription = useKeywordThanksDescription({
    date: thanksArgsData.keyword.description,
    isFailed: isFailedWebhook,
  })
  const surveyDescription = useTopicThanksDescription({
    title: thanksArgsData.survey.description,
    isFailed: isFailedWebhook,
  })
  const summaryDescription = useSummaryThanksDescription({
    date: thanksArgsData.summary.description,
    isFailed: isFailedWebhook,
  })

  const questionnaireSubDescription = useQuestionnaireThanksSubDescription({
    isFailed: isFailedWebhook,
    amount: product?.questionnaireUsageCount ?? 0,
  })
  const keywordSubDescription = useKeywordThanksSubDescription({
    isFailed: isFailedWebhook,
    amount: product?.analysisUsageCount ?? 0,
  })
  const surveySubDescription = useTopicThanksSubDescription({
    date: thanksArgsData.survey.subDescription,
    isFailed: isFailedWebhook,
    amount: product?.weightedUsageCount ?? 0,
  })
  const summarySubDescription = useSummaryThanksSubDescription({
    isFailed: isFailedWebhook,
    amount: product?.adjustmentRequestUsageCount ?? 0,
  })

  const description =
    slug === 'questionnaire'
      ? questionnaireDescription
      : slug === 'keyword'
        ? keywordDescription
        : slug === 'survey'
          ? surveyDescription
          : slug === 'summary'
            ? summaryDescription
            : null

  const subDescription =
    slug === 'questionnaire'
      ? questionnaireSubDescription
      : slug === 'keyword'
        ? keywordSubDescription
        : slug === 'survey'
          ? surveySubDescription
          : slug === 'summary'
            ? summarySubDescription
            : null

  usePreventSuccessPageNavigation({
    isLoading,
    orderId: order?.id.toString(),
    isWaitingWebhook,
  })

  return (
    <>
      {(isLoading || isWaitingWebhook) && <Loading />}
      {product && (
        <ThanksDialog
          isOpen={!!order}
          order={order}
          onClose={() => history.push('/v2/themes')}
          description={description}
          subDescription={subDescription}
          productName={product.name}
          productPrice={Number(product.price)}
          productSlug={product.slug}
        />
      )}
    </>
  )
}

export default SuccessPage
