import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

export type SingupVerificationInput = {
  id: string | null
  hash: string | null
}

type SingupVerificationPayload = {
  message: string
  success: boolean
}

export const singupVerification = async (input: SingupVerificationInput) => {
  const { id, hash } = input
  const response = await axios.post<SingupVerificationPayload>(
    `/api/auth/user/email/verify`,
    {
      id,
      hash,
    },
  )

  console.log({ response })

  return response.data
}

// /api/auth/user/email/verify/resend
export type ResendEmailVerificationInput = {
  email: string
}

export type ResendEmailVerificationPayload = {
  message: string
}

export const resendEmailVerification = async (
  input: ResendEmailVerificationInput,
) => {
  const response = await axios.post<ResendEmailVerificationPayload>(
    `/api/auth/user/email/verify/resend`,
    {
      email: input.email,
    },
  )

  return response.data
}

export const useSingupVerification = (
  option?: UseMutationOption<SingupVerificationPayload>,
) => useMutation(singupVerification, option)

export const useResendEmailVerification = (
  option?: UseMutationOption<ResendEmailVerificationPayload>,
) => useMutation(resendEmailVerification, option)
