import {
  ConfirmPaymentsByPaidInput,
  ConfirmPaymentsByPaidPayload,
} from 'api/checkoutByPaid/confirmPaymentsByPaid'
import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

type ConfirmSurveyPaymentsByPaidInput = ConfirmPaymentsByPaidInput & {
  analysisResultId: string
}

export const confirmSurveyPaymentsByPaid = async ({
  orderId,
  analysisResultId,
}: ConfirmSurveyPaymentsByPaidInput) => {
  const response = await axios.post<ConfirmPaymentsByPaidPayload>(
    `/api/surveys/confirm_payments`,
    {
      order_id: orderId,
      analysis_result_id: analysisResultId,
    },
  )
  return response.data
}

export const useConfirmSurveyPaymentsByPaid = (
  option?: UseMutationOption<ConfirmPaymentsByPaidPayload>,
) => useMutation(confirmSurveyPaymentsByPaid, option)
