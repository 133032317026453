import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, FormControl, FormLabel, TextField } from '@mui/material'
import { additionalUserInputSchema } from 'pages/v2/checkout/components/additionalUserInput/scheme'
import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

type FormData = z.infer<typeof additionalUserInputSchema>

type AdditionalUserInputFormProps = {
  user: {
    id: number | undefined
    name: string
    companyName: string
    phoneNumber: string
    isDisplayV2Sample: 1 | 0
  }
  onSubmit: (data: FormData) => void
  label: string
}

const AdditionalUserInputForm: React.VFC<AdditionalUserInputFormProps> = ({
  user,
  onSubmit,
  label,
}) => {
  const method = useForm({
    resolver: zodResolver(additionalUserInputSchema),
    defaultValues: {
      name: user.name,
      companyName: user.companyName,
      phoneNumber: user.phoneNumber,
      isDisplayV2Sample: user.isDisplayV2Sample,
    },
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = method

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            py: 4,
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              flexDirection: 'column',
            }}
          >
            <FormControl
              error={!!errors?.name}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 2,
              }}
            >
              <FormLabel
                required
                sx={{ textAlign: 'start', whiteSpace: 'pre' }}
              >
                ご担当者様お名前
              </FormLabel>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    type="text"
                    name="name"
                    placeholder="ご担当者様お名前"
                    variant="outlined"
                    error={!!errors?.name}
                    helperText={errors?.name?.message ?? errors?.name?.message}
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        height: 'auto',
                        padding: '10px 14px',
                      },
                    }}
                    InputProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl
              error={!!errors?.companyName}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 2,
              }}
            >
              <FormLabel
                required
                sx={{ textAlign: 'start', whiteSpace: 'pre' }}
              >
                御社名
              </FormLabel>
              <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    type="text"
                    name="companyName"
                    placeholder="御社名"
                    variant="outlined"
                    error={!!errors?.companyName}
                    helperText={
                      errors?.companyName?.message ??
                      errors?.companyName?.message
                    }
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        height: 'auto',
                        padding: '10px 14px',
                      },
                    }}
                    InputProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl
              error={!!errors?.phoneNumber}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 2,
              }}
            >
              <FormLabel
                required
                sx={{ textAlign: 'start', whiteSpace: 'pre' }}
              >
                電話番号
              </FormLabel>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    type="text"
                    name="phoneNumber"
                    placeholder="090-1234-5678"
                    variant="outlined"
                    error={!!errors?.phoneNumber}
                    helperText={
                      errors?.phoneNumber?.message ??
                      errors?.phoneNumber?.message
                    }
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-input': {
                        height: 'auto',
                        padding: '10px 14px',
                      },
                    }}
                    InputProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Button
            variant="contained"
            type="submit"
            sx={{ px: 7, fontSize: '14px', color: 'white' }}
          >
            {label}
          </Button>
        </Box>
      </form>
    </FormProvider>
  )
}

export default AdditionalUserInputForm
