import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { useCurrentUser } from 'api/getCurrentUser'
import { useGetSuggestions } from 'api/getKeywordSuggestions'
import { useRecordLog } from 'api/recordLog'
import { useDisclosure } from 'hooks/useDisclosure'
import { useNeedKeywordCheckout } from 'hooks/useNeedCheckout'
import useProductInfo from 'hooks/useProductInfo'
import { useScrollHelper } from 'hooks/useScrollHelper'
import useSelectedTheme from 'hooks/useSelectedTheme'
import Loading from 'includes/Loading'
import KeywordCheckoutDialog from 'pages/v2/checkout/components/KeywordCheckoutDialog'
import NoRemainingCountMessage from 'pages/v2/components/NoRemainingCountMessage'
import {
  AgeRecord,
  GenderRecord,
  ResearchTargetInputForm,
} from 'pages/v2/components/ResearchTargetInputForm'
import PaymentButton from 'pages/v2/components/common/PaymentButton'
import React, { MutableRefObject, useEffect, useMemo } from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  FormProvider,
  useForm,
} from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { z } from 'zod'
import { newKeywordSchema } from '../schema'

export type FormData = z.infer<typeof newKeywordSchema>

type Props = {
  onSubmit: (data: FormData) => void
  requesting?: boolean
  checkoutMode: boolean
  defaultValues?: FormData
}

export const NewKeywordForm = ({
  onSubmit,
  requesting,
  checkoutMode,
  defaultValues,
}: Props) => {
  const baseExcludedWordsSuggestOptions = [
    { label: '広告投稿(“ad”が含まれるポストを除外します)', value: ['ad'] },
    { label: '広告投稿(“広告”が含まれるポストを除外します)', value: ['広告'] },
    { label: '広告投稿(“PR”が含まれるポストを除外します)', value: ['PR'] },
    {
      label: '広告投稿(“Amazon” “Rakuten” “楽天”が含まれるポストを除外します)',
      value: ['Amazon', 'Rakuten', 'amazon', 'rakuten', '楽天'],
    },
  ]

  const { ref: keywordRef, handleScroll: handleScrollToKeywordName } =
    useScrollHelper()

  const { request } = useRecordLog({})
  const { currentUser } = useCurrentUser()
  const { selectedTheme } = useSelectedTheme()
  const { data, isLoading } = useGetSuggestions({
    query: { keyword: selectedTheme?.name },
    options: { revalidateOnFocus: false },
  })
  const [otherExcludeWord, setOtherExcludeWord] = React.useState('')
  const location = useLocation<{ afterCreateTheme: boolean }>()
  const afterCreateTheme = location.state?.afterCreateTheme
  const { productInfo } = useProductInfo('keyword')
  const {
    isOpen: isOpenKeyword,
    onClose: onCloseKeyword,
    onOpen: onOpenKeyword,
  } = useDisclosure()
  const suggestions = data?.data ? data.data.map((s) => s.string) : []
  const method = useForm<FormData>({
    resolver: zodResolver(newKeywordSchema),
    defaultValues: {
      researchTarget: {
        gender: defaultValues?.researchTarget?.gender ?? 'both',
        generations: defaultValues?.researchTarget?.generations ?? [],
        prefectures: defaultValues?.researchTarget?.prefectures ?? [],
        specialityPanel:
          defaultValues?.researchTarget?.specialityPanel ?? false,
        note: defaultValues?.researchTarget?.note ?? '',
      },
      keyword: {
        name: defaultValues?.keyword?.name ?? '',
        selectedExcludedWords: defaultValues?.keyword
          ?.selectedExcludedWords ?? [
          ...(afterCreateTheme
            ? baseExcludedWordsSuggestOptions.flatMap((option) => option.value)
            : []),
        ],
        excludedWords: defaultValues?.keyword?.excludedWords ?? [],
      },
    },
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = method

  const researchTarget = watch('researchTarget')
  const keyword = watch('keyword')
  const currentKeywordName = keyword.name
  const currentExcludedWords = keyword.excludedWords ?? []
  const currentSelectedExcludedWords = keyword.selectedExcludedWords ?? []

  const excludedWordsSuggestOptions = useMemo(() => {
    for (const word of defaultValues?.keyword?.selectedExcludedWords ?? []) {
      const isExist = baseExcludedWordsSuggestOptions.some((option) =>
        option.value.includes(word),
      )
      if (!isExist) {
        baseExcludedWordsSuggestOptions.push({
          label: word,
          value: [word],
        })
      }
    }

    return baseExcludedWordsSuggestOptions
  }, [defaultValues?.keyword?.selectedExcludedWords])

  const keywordOptions = useMemo(() => {
    const result = [
      selectedTheme?.name,
      defaultValues?.keyword?.name,
      ...suggestions,
    ].filter((keyword): keyword is string => keyword != null)

    return Array.from(new Set(result))
  }, [selectedTheme, suggestions, defaultValues?.keyword?.name])

  const needCheckout = useNeedKeywordCheckout(checkoutMode)

  useEffect(() => {
    if (errors.keyword !== undefined) {
      handleScrollToKeywordName()
    }
  }, [errors.keyword])

  return (
    <FormProvider {...method}>
      <form
        onSubmit={handleSubmit(
          needCheckout
            ? async () => {
                onOpenKeyword()
                await request({
                  event: 'keyword_checkout_dialog_open',
                  userId: currentUser?.id ?? 0,
                  parameters: {
                    checkout_dialog_open_at: new Date().toISOString(),
                  },
                })
              }
            : onSubmit,
        )}
      >
        <Box
          sx={{
            display: 'flex',
            py: 5,
            flexDirection: 'column',
            alignItems: 'center',
            gap: 5,
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 5,
              flexDirection: 'column',
            }}
          >
            <KeywordFormSection
              title="キーワード"
              info={
                <>
                  分析情報をピックアップしました。気にあるものがあれば選択して分析を開始してください。
                  <br />
                  任意のものを追加することもできます。
                </>
              }
              ref={keywordRef}
            >
              {isLoading ? (
                <Loading />
              ) : (
                <KeywordNameField
                  errors={errors}
                  suggestions={keywordOptions}
                  control={control}
                  currentKeywordName={currentKeywordName}
                />
              )}
            </KeywordFormSection>
            <KeywordFormSection title="除外キーワード設定">
              <FormControl
                error={!!errors.keyword?.excludedWords}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  gap: {
                    sm: 2,
                    md: 8,
                    lg: 10,
                  },
                }}
              >
                <FormGroup defaultValue={watch('keyword.excludedWords')}>
                  {excludedWordsSuggestOptions.map(({ label, value }) => (
                    <FormControlLabel
                      key={value.join(',')}
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontSize: 'small',
                        },
                      }}
                      label={label}
                      control={
                        <Controller
                          name="keyword.selectedExcludedWords"
                          control={control}
                          render={({ field: { onChange, onBlur, ref } }) => (
                            <Checkbox
                              sx={{
                                '& .MuiSvgIcon-root': { fontSize: 24 },
                              }}
                              inputProps={{
                                style: { position: 'absolute' },
                              }}
                              onBlur={onBlur}
                              onChange={(e) => {
                                const newValue = e.target.checked
                                  ? [...currentSelectedExcludedWords, ...value]
                                  : currentSelectedExcludedWords.filter(
                                      (word) => !value.includes(word),
                                    )
                                onChange(newValue)
                              }}
                              checked={value.every((word) =>
                                currentSelectedExcludedWords.includes(word),
                              )}
                              inputRef={ref}
                              value={value}
                            />
                          )}
                        />
                      }
                    />
                  ))}
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        size="small"
                        value={otherExcludeWord}
                        onChange={(e) => setOtherExcludeWord(e.target.value)}
                        variant="outlined"
                        label="除外ワードを任意で設定"
                        placeholder="除外するキーワードを入力してください"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        variant="outlined"
                        sx={{ fontSize: '14px' }}
                        disabled={!otherExcludeWord}
                        onClick={() => {
                          if (
                            otherExcludeWord &&
                            !excludedWordsSuggestOptions.some((option) =>
                              option.value.includes(otherExcludeWord),
                            )
                          ) {
                            excludedWordsSuggestOptions.push({
                              label: otherExcludeWord,
                              value: [otherExcludeWord],
                            })
                            setOtherExcludeWord('')
                          }
                        }}
                      >
                        追加する
                      </Button>
                    </Grid>
                  </Grid>
                </FormGroup>
              </FormControl>
            </KeywordFormSection>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 5,
              flexDirection: 'column',
            }}
          >
            <KeywordFormSection title="ターゲットを変更する">
              <ResearchTargetInputForm />
            </KeywordFormSection>
          </Box>
          {needCheckout ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <PaymentButton
                price={Number(productInfo?.price ?? '0')}
                disabled={!!requesting}
                label="分析を開始する"
              />
              <NoRemainingCountMessage
                remainingCount={currentUser?.remaining_analysis_count ?? 0}
                isCampaign={productInfo?.isCampaign ?? false}
              />
              <KeywordCheckoutDialog
                onClose={onCloseKeyword}
                isOpen={isOpenKeyword}
                keywordFormData={{
                  researchTarget,
                  keyword,
                }}
                createKeywordParams={{
                  analysisThemeId: selectedTheme?.id,
                  name: currentKeywordName,
                  excludedWords: [
                    ...currentExcludedWords,
                    ...currentSelectedExcludedWords,
                  ],
                  tags: [
                    ...GenderRecord[researchTarget.gender],
                    ...researchTarget.generations.map(
                      (generation) => AgeRecord[generation],
                    ),
                  ],
                  prefectures: researchTarget.prefectures,
                  speciality_panel: researchTarget.specialityPanel,
                  note: researchTarget.note,
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{ px: 7, fontSize: '20px', color: 'white' }}
                disabled={
                  requesting ||
                  (currentUser?.remaining_analysis_count ?? 0) <= 0
                }
              >
                分析を開始
              </Button>
              <Typography sx={{ mt: 2 }}>
                ※キーワード分析チケットを1消費します
              </Typography>
            </Box>
          )}
        </Box>
      </form>
    </FormProvider>
  )
}

type KeywordFormSectionProps = {
  title: string
  info?: React.ReactNode
  children: React.ReactNode
  ref?: MutableRefObject<HTMLDivElement | null>
}

const KeywordFormSection: React.VFC<KeywordFormSectionProps> = ({
  title,
  info,
  children,
  ref,
}) => {
  return (
    <Box
      component="dl"
      sx={{
        width: '100%',
      }}
      ref={ref}
    >
      <Box
        component="dt"
        sx={{
          py: 2,
          px: 3,
          backgroundColor: '#f5f5f5',
          display: 'grid',
          gridTemplateColumns: '1fr 3fr',
          gap: 1,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            lineHeight: '1.4',
            fontSize: '16px',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            lineHeight: '1.4',
            fontSize: '13px',
          }}
        >
          {info}
        </Typography>
      </Box>
      <Typography
        component="dd"
        variant="subtitle1"
        sx={{
          fontSize: '14px',
          my: 2,
          px: 3,
        }}
      >
        {children}
      </Typography>
    </Box>
  )
}

type KeywordNameFieldProps = {
  currentKeywordName: string
  suggestions: string[]
  errors: FieldErrors<FormData>
  control: Control<FormData>
}

const KeywordNameField: React.VFC<KeywordNameFieldProps> = (props) => {
  const { errors, suggestions, currentKeywordName, control } = props
  const [suggestionList, setSuggestionList] =
    React.useState<string[]>(suggestions)
  const [otherSuggestion, setOtherSuggestion] = React.useState<string>('')

  return (
    <FormControl
      error={!!errors.keyword?.name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 2,
      }}
    >
      <FormLabel
        sx={{
          fontSize: 'small',
        }}
        required
      >
        キーワードを選択してください
      </FormLabel>

      <RadioGroup
        value={currentKeywordName}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
        }}
      >
        {suggestionList.map((keyword) => (
          <FormControlLabel
            key={keyword}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: 'small',
                color: '#000 !important',
              },
              '& .MuiTypography-root': {
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
              },
            }}
            label={keyword}
            control={
              <Controller
                name="keyword.name"
                control={control}
                render={({ field: { onChange, ref } }) => (
                  <Radio
                    inputRef={ref}
                    onChange={() => onChange(keyword)}
                    value={keyword}
                  />
                )}
              />
            }
          />
        ))}
      </RadioGroup>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            fullWidth
            size="small"
            value={otherSuggestion}
            onChange={(e) => setOtherSuggestion(e.target.value)}
            variant="outlined"
            label="任意でキーワードを設定"
            placeholder="キーワードを入力してください"
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            sx={{ fontSize: '14px' }}
            disabled={!otherSuggestion}
            onClick={() => {
              if (otherSuggestion) {
                setSuggestionList([...suggestionList, otherSuggestion])
                setOtherSuggestion('')
              }
            }}
          >
            追加する
          </Button>
        </Grid>
      </Grid>
    </FormControl>
  )
}
