import { useState } from 'react'

export type BannerSeverity = 'success' | 'error' | 'info' | 'warning'

export const useBanner = () => {
  const [message, setMessage] = useState<string>('')
  const [severity, setSeverity] = useState<BannerSeverity>('success')
  const [isOpen, setOpen] = useState(false)

  const showBanner = ({
    message,
    severity,
  }: {
    message: string
    severity: BannerSeverity
  }) => {
    setMessage(message)
    setSeverity(severity)
    setOpen(true)
  }

  const hideBanner = () => {
    setOpen(false)
  }

  return { message, severity, isOpen, showBanner, hideBanner }
}
