import { Box } from '@mui/material'
import QuestionItem from 'pages/v2/KeywordDetail/components/Questionnaire/Result/QuestionItem'
import { VFC } from 'react'

type QuestionListProps = {
  questions: {
    question: string
    answerType: 2 | 1
    answers: {
      count: number
      rate: number
      selection: string
    }[]
  }[]
}

const QuestionList: VFC<QuestionListProps> = ({ questions }) => {
  if (!questions) {
    return <>ファイルが選択されていないか、まだ結果が登録されていません</>
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
      }}
    >
      {questions.map((question, index) => (
        <QuestionItem key={index} question={question} index={index} />
      ))}
    </Box>
  )
}

export default QuestionList
