import { Box, Typography } from '@mui/material'
import React from 'react'

type Props =
  | {
      type:
        | 'collecting'
        | 'analysing'
        // 下記2については、loadingではないため別のコンポーネントにする
        | 'summaryDoesNotExist'
        | 'createSummaryFailed'
        | 'creatingSummaryIdeas'
    }
  | { type: 'createReport'; finishAt: string }

export const Loading: React.FC<Props> = (props) => {
  const { type } = props

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        pt: '140px',
      }}
    >
      {type === 'collecting' && (
        <BasicLoading
          imgSrc="/assets/images/v2/loading/collection.svg"
          title="分析を開始しました。"
          message="投稿収集中"
        />
      )}
      {type === 'createReport' && (
        <BasicLoading
          imgSrc="/assets/images/v2/loading/createReport.svg"
          title="レポートを作成しています。"
          message={`${props.finishAt}完了予定です。`}
        />
      )}
      {type === 'analysing' && (
        <BasicLoading
          imgSrc="/assets/images/v2/loading/createReport.svg"
          title="分析を開始しました。"
          message="分析中"
        />
      )}
      {type === 'summaryDoesNotExist' && (
        <BasicLoading
          imgSrc="/assets/images/v2/loading/createReport.svg"
          title="サマリーがありません。"
        />
      )}
      {type === 'createSummaryFailed' && (
        <BasicLoading
          imgSrc="/assets/images/v2/loading/createReport.svg"
          title="エラーが発生しました。"
          message="再度キーワードの作成を行なってください。"
        />
      )}
      {type === 'creatingSummaryIdeas' && (
        <BasicLoading
          imgSrc="/assets/images/v2/loading/createReport.svg"
          title="サマリーのデータを作成しています。"
        />
      )}
    </Box>
  )
}

const BasicLoading = (props: {
  imgSrc: string
  title: string
  message?: string
}) => {
  const { imgSrc, title, message } = props

  return (
    <>
      <Box
        sx={{
          width: '300px',
          mb: '48px',
        }}
      >
        <img src={imgSrc} alt={title} style={{ width: '100%' }} />
      </Box>
      <Typography
        variant="h6"
        sx={{ textAlign: 'center', fontWeight: 'bold', mb: '16px' }}
      >
        {title}
      </Typography>
      {message && (
        <Typography
          variant="body1"
          sx={{ color: '#2e2e2e', fontWeight: 'bold' }}
        >
          {message}
        </Typography>
      )}
    </>
  )
}
