import CloseIcon from '@mui/icons-material/Close'
import { Button, DialogActions } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import * as React from 'react'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

type Props = {
  children: React.ReactNode
  title?: string
  onClose: () => void
  disabledBackdrop?: boolean
  disabledClose?: boolean
  open?: boolean
  breakPoints?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  actions?: {
    text: string
    onClick: () => void
    color?:
      | 'primary'
      | 'secondary'
      | 'inherit'
      | 'success'
      | 'error'
      | 'info'
      | 'warning'
    disabled?: boolean
  }[]
}

const BaseDialog: React.FC<Props> = (props) => {
  const {
    children,
    title,
    onClose,
    open = false,
    breakPoints = 'sm',
    disabledBackdrop = false,
    disabledClose = false,
    actions,
  } = props

  const handleClose = () => {
    if (disabledClose || disabledBackdrop) {
      return
    }

    onClose()
  }

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={breakPoints}
      fullWidth
      sx={{
        ...(breakPoints === 'sm'
          ? {
              '& .MuiDialog-paper': {
                margin: 0,
                width: '100%',
                maxWidth: '100%',
              },
            }
          : {}),
      }}
    >
      {title && (
        <DialogTitle
          sx={{ m: 0, p: 2, textAlign: 'center', fontSize: 18 }}
          id="customized-dialog-title"
        >
          {title}
        </DialogTitle>
      )}
      {!disabledClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 8,
            zIndex: 1,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent>{children}</DialogContent>
      {actions && (
        <DialogActions
          sx={{
            justifyContent: 'center',
            gap: 1,
          }}
        >
          {actions.map((action, index) => (
            <Button
              key={index}
              onClick={action.onClick}
              color={action.color}
              variant="contained"
              disabled={action.disabled}
              sx={{
                padding: '5px 20px',
                borderRadius: 8,
              }}
            >
              {action.text}
            </Button>
          ))}
        </DialogActions>
      )}
    </BootstrapDialog>
  )
}

export default BaseDialog
