import { Box, Tab, Tabs } from '@mui/material'
import { V2Paths } from 'pages/v2/paths'
import Contact from 'pages/v2/sp/User/Contact'
import PaidSetting from 'pages/v2/sp/User/PaidSetting'
import PasswordSetting from 'pages/v2/sp/User/PasswordSetting'
import UserSetting from 'pages/v2/sp/User/UserSetting'
import React from 'react'
import { Link, Route, Switch, useLocation } from 'react-router-dom'

type SettingPagePath = string

const SPSettings = () => {
  const location = useLocation()
  const [currentTab, setCurrentTab] = React.useState<SettingPagePath>(
    location.pathname,
  )

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: SettingPagePath,
  ) => {
    setCurrentTab(newValue)
  }

  return (
    <Box
      sx={{
        overflow: 'auto',
        boxSizing: 'border-box',
        height: '100%',
        minHeight: '530px',
        backgroundColor: '#fbfbfb',
        fontFamily:
          "'游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic',\n    'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo',\n    Osaka, sans-serif",
        display: 'flex',
      }}
    >
      <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          aria-label="scrollable auto tabs example"
        >
          <Tab
            component={Link}
            to={V2Paths.UserSetting}
            label="ユーザー情報"
            value={V2Paths.UserSetting}
          />
          <Tab
            component={Link}
            to={V2Paths.PasswordSetting}
            label="パスワード変更"
            value={V2Paths.PasswordSetting}
          />
          <Tab
            component={Link}
            to={V2Paths.Contact}
            label="お問い合わせ"
            value={V2Paths.Contact}
          />
          <Tab
            component={Link}
            to={V2Paths.PaidSetting}
            label="Paid（後払い）設定"
            value={V2Paths.PaidSetting}
          />
        </Tabs>
        <Box
          sx={{
            p: 3,
          }}
        >
          <Switch>
            <Route
              exact
              path={V2Paths.UserSetting}
              component={() => <UserSetting />}
            />
            <Route
              exact
              path={V2Paths.PasswordSetting}
              component={() => <PasswordSetting />}
            />
            <Route exact path={V2Paths.Contact} component={() => <Contact />} />
            <Route
              exact
              path={V2Paths.PaidSetting}
              component={() => <PaidSetting />}
            />
          </Switch>
        </Box>
      </Box>
    </Box>
  )
}
export default SPSettings
