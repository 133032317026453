import { useCallback, useState } from 'react'

type Props = {
  defaultOpen?: boolean
}
export const useDisclosure = (props?: Props) => {
  const [isOpen, setIsOpen] = useState(props?.defaultOpen || false)

  const onOpen = useCallback(() => setIsOpen(true), [setIsOpen])
  const onClose = useCallback(() => setIsOpen(false), [setIsOpen])
  const onToggle = useCallback(() => setIsOpen((prev) => !prev), [setIsOpen])

  return {
    isOpen,
    setIsOpen,
    onOpen,
    onClose,
    onToggle,
  }
}
