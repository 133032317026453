import { Box, CircularProgress } from '@mui/material'
import {
  V2TweetListProps,
  useV2TweetsList,
} from 'pages/v2/KeywordDetail/components/TweetDialog/useV2TweetsList'
import { TweetCard } from 'pages/v2/sp/KeywordDetail/components/TweetDialog/TopicTweetDialog/TweetCard'
import InfiniteScroll from 'react-infinite-scroller'

type Props = {
  height?: number | string
  shouldDisplayEmpathy?: boolean
} & V2TweetListProps

export const V2TweetList = ({
  shouldDisplayEmpathy = false,
  height = 400,
  ...rest
}: Props) => {
  const { tweets, loadMore, hasMore } = useV2TweetsList({
    ...rest,
  })
  return (
    <Box>
      {tweets.length === 0 && (
        <Box
          height={height}
          fontWeight="bold"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          ツイートがありません
        </Box>
      )}
      <Box
        sx={{
          mt: 2.5,
          overflowY: 'scroll',
          maxHeight: height,
          pr: 2,
        }}
        className="js-scrollWrap"
      >
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={hasMore}
          useWindow={false}
          loader={
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          }
        >
          {tweets.map((tweet) => (
            <TweetCard
              key={`all-${tweet.id}`}
              tweet={tweet}
              shouldDisplayEmpathy={shouldDisplayEmpathy}
            />
          ))}
        </InfiniteScroll>
      </Box>
    </Box>
  )
}
