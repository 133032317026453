import { useLatestTourStep } from 'api/getTourHistory'
import { useUpdateV2TourStep } from 'api/updateV2TourStep'
import Loading from 'includes/Loading'
import { createContext, ReactNode, useContext, VFC } from 'react'
import { V2TourStep } from 'types/v2TourHistory'
import { V2TourSteps } from './tourDefinition'

type ProviderProps = {
  tourStep: V2TourStep
  onNext: () => void
  onPrev: () => void
  loading: boolean
}

const V2TourDriverContext = createContext<ProviderProps>({
  tourStep: 'START',
  onNext: () => {},
  onPrev: () => {},
  loading: false,
})

export const useV2Tour = () => {
  return useContext(V2TourDriverContext)
}

type Props = { children: ReactNode }
export const V2TourDriverProvider: VFC<Props> = ({ children }) => {
  const {
    data: { v2TourStep },
    mutate,
  } = useLatestTourStep()

  const { request, requesting } = useUpdateV2TourStep({
    onSuccess: async () => {
      await mutate()
    },
  })

  const handleNext = () => {
    const nextStep = V2TourSteps[v2TourStep].next
    if (nextStep !== null) {
      request({ v2TourStep: nextStep })
    }
  }

  const handlePrev = () => {
    const prevStep = V2TourSteps[v2TourStep].prev
    if (prevStep !== null) {
      request({ v2TourStep: prevStep })
    }
  }

  return (
    <V2TourDriverContext.Provider
      value={{
        tourStep: v2TourStep,
        onNext: () => handleNext(),
        onPrev: () => handlePrev(),
        loading: requesting,
      }}
    >
      {requesting && <Loading />}
      {children}
    </V2TourDriverContext.Provider>
  )
}
