import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

export type UpdateUserInput = {
  userId: string | null
  companyName: string
  phoneNumber: string
  name: string
  isDisplayV2Sample: boolean
}

const updateUser = async (input: UpdateUserInput) => {
  if (!input.userId) {
    return
  }

  await axios.put('/api/users/' + input.userId, {
    company_name: input.companyName,
    phone_number: input.phoneNumber,
    name: input.name,
    is_display_v2_sample: input.isDisplayV2Sample,
  })
}

export const useUpdateUser = (option?: UseMutationOption<void>) =>
  useMutation(updateUser, option)
