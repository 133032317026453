import {
  QuestionnaireForTopic,
  QuestionnaireResponseForTopic,
  parseQuestionnaireForTopic,
} from 'types/questionnaire'
import { CoOccurrence } from './coOccurrence'

// topicは構造化されていないため全てnullableにする。
export type TopicResponse = {
  label_id?: string
  name?: string
  count?: number
  labeling_comment?: string
  labeling_source_tweet?: any[]
  rareWords?: {
    word: string
    score: number
  }[]
  coOccurrences?: CoOccurrence[]
  questionnaire: QuestionnaireResponseForTopic | null
}

export type Topic = {
  labelId: string
  name: string
  count: number
  labelingComment: string
  labelingSourceTweet: any[]
  rareWords: {
    word: string
    score: number
  }[]
  questionnaire: QuestionnaireForTopic | null
  coOccurrences: CoOccurrence[]
}

export const parseTopic = (input: TopicResponse): Topic => {
  return {
    labelId: input.label_id ?? '',
    name: input.name ?? '',
    count: input.count ?? -1,
    labelingComment: input.labeling_comment ?? '',
    labelingSourceTweet: input.labeling_source_tweet ?? [],
    rareWords: input.rareWords ?? [],
    coOccurrences: input.coOccurrences ?? [],
    questionnaire: input.questionnaire
      ? parseQuestionnaireForTopic(input.questionnaire)
      : null,
  }
}
