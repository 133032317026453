import { z } from 'zod'

export const prefectureKeys = [
  'all',
  'hokkaido',
  'aomori',
  'iwate',
  'miyagi',
  'akita',
  'yamagata',
  'fukushima',
  'ibaraki',
  'tochigi',
  'gunma',
  'saitama',
  'chiba',
  'tokyo',
  'kanagawa',
  'niigata',
  'toyama',
  'ishikawa',
  'fukui',
  'yamanashi',
  'nagano',
  'gifu',
  'shizuoka',
  'aichi',
  'mie',
  'shiga',
  'kyoto',
  'osaka',
  'hyogo',
  'nara',
  'wakayama',
  'tottori',
  'shimane',
  'okayama',
  'hiroshima',
  'yamaguchi',
  'tokushima',
  'kagawa',
  'ehime',
  'kochi',
  'fukuoka',
  'saga',
  'nagasaki',
  'kumamoto',
  'oita',
  'miyazaki',
  'kagoshima',
  'okinawa',
] as const

const prefectureEnum = z.enum(prefectureKeys)

export const ageArray = ['10', '20', '30', '40', '50', '60', 'All'] as const
export type AgeTypes = (typeof ageArray)[number]

export const genderArray = ['female', 'male', 'both'] as const
export type GenderTypes = (typeof genderArray)[number]

const baseThemeSchema = z.object({
  name: z.preprocess((value: any) => {
    if (value) {
      return value.replace(/（/g, '(').replace(/）/g, ')')
    }
    return value
  }, z.string().nonempty('分析テーマを入力してください')),
})

export const otherType = baseThemeSchema.extend({
  type: z.literal('other'),
  memo: z.string().nonempty('その他のテーマ種別を入力してください。'),
})

export const userVoiceType = baseThemeSchema.extend({
  type: z.literal('userVoice'),
  userVoiceType: z.enum(['positive', 'negative', 'indifferent']),
})

export const baseTypes = baseThemeSchema.extend({
  type: z.enum([
    'newBusiness',
    'newProduct',
    'competitiveResearch',
    'existingProduct',
  ]),
})

export const researchTargetSchema = z.object({
  gender: z.enum(['female', 'male', 'both']),
  generations: z
    .array(z.enum(['All', '10', '20', '30', '40', '50', '60']))
    .min(1, '世代を選択してください'),
  prefectures: z.array(prefectureEnum).min(1, '都道府県を選択してください'),
  specialityPanel: z.boolean(),
  note: z.string().optional(),
})

export const newAnalysisThemeSchema = z.object({
  theme: z.union([baseTypes, userVoiceType, otherType]),
  researchTarget: researchTargetSchema,
})
