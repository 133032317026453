import { Box, Typography } from '@mui/material'
import QuestionList from 'pages/v2/KeywordDetail/components/Questionnaire/Result/QuestionList'
import React, { VFC } from 'react'

type QuestionnaireInfoProps = {
  targetText: string
  answerCount: number
  questions: {
    question: string
    answerType: 2 | 1
    answers: {
      count: number
      rate: number
      selection: string
    }[]
  }[]
  specialityPannelText: string
}

const QuestionnaireInfo: VFC<QuestionnaireInfoProps> = ({
  targetText,
  answerCount,
  questions,
  specialityPannelText,
}) => {
  return (
    <>
      <QuestionnaireInfoSection title="アンケート聴取者情報">
        アンケート対象：{targetText}
        <br />
        スペシャリティパネル利用: {specialityPannelText}
        <br />
        有効回答数：{answerCount}
      </QuestionnaireInfoSection>
      {questions.length > 0 && (
        <QuestionnaireInfoSection title="アンケート結果">
          <QuestionList questions={questions} />
        </QuestionnaireInfoSection>
      )}
    </>
  )
}

type QuestionnaireInfoSectionProps = {
  title: string
  children: React.ReactNode
}

const QuestionnaireInfoSection: React.VFC<QuestionnaireInfoSectionProps> = ({
  title,
  children,
}) => {
  return (
    <Box component="dl">
      <Typography
        component="dt"
        variant="h6"
        sx={{
          fontWeight: 'bold',
          lineHeight: '1.4',
          fontSize: '16px',
          py: 2,
          px: 3,
          backgroundColor: '#f5f5f5',
        }}
      >
        {title}
      </Typography>
      <Typography
        component="dd"
        variant="subtitle1"
        sx={{
          fontSize: '14px',
          my: 2,
          px: 3,
        }}
      >
        {children}
      </Typography>
    </Box>
  )
}

export default QuestionnaireInfo
