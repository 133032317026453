import { researchTargetSchema } from 'pages/v2/NewAnalysisTheme/schema'
import { z } from 'zod'

const questionSchema = z
  .object({
    answerType: z.union([z.literal(''), z.literal(1), z.literal(2)]).optional(),
    question: z
      .string()
      .max(200, '設問文は200文字以内にしてください。')
      .optional(),
    selections: z.array(z.string()).optional(),
  })
  .superRefine((data, ctx) => {
    const isInvalidAnswerType =
      data.answerType === undefined || data.answerType === ''
    const isInvalidQuestion =
      data.question === undefined || data.question === ''
    const isInvalidSelection =
      data.selections === undefined ||
      data.selections.length === 0 ||
      data.selections.every((item) => item.trim() === '')
    // 全てが空または選択されていない場合は何もしない
    if (isInvalidAnswerType && isInvalidQuestion && isInvalidSelection) {
      return
    }

    if (isInvalidAnswerType) {
      ctx.addIssue({
        path: ['answerType'],
        message: '回答タイプを選択してください',
        code: z.ZodIssueCode.custom,
      })
    }

    if (isInvalidQuestion) {
      ctx.addIssue({
        path: ['question'],
        message: '設問文を入力してください',
        code: z.ZodIssueCode.custom,
      })
    }

    if (isInvalidSelection) {
      ctx.addIssue({
        path: ['selections'],
        message: '選択肢を1つ以上入力してください',
        code: z.ZodIssueCode.custom,
      })
    }
  })

export const questionnaireSchema = z.object({
  title: z
    .string()
    .nonempty('タイトルを入力してください')
    .max(127, 'タイトルは127文字以内にしてください。'),
  researchTarget: researchTargetSchema,
  questions: z.array(questionSchema).refine(
    (questions) => {
      const allInvalid = questions.every(
        (question) => !isValidQuestionnaire(question),
      )

      return !allInvalid
    },
    {
      message: '設問を一つ以上入力してください。',
      path: [0],
    },
  ),
})

export const isValidQuestionnaire = (data: {
  answerType?: 2 | 1 | '' | undefined
  question?: string | undefined
  selections?: string[] | undefined
}) => {
  const isInvalidAnswerType =
    data.answerType === undefined || data.answerType === ''
  const isInvalidQuestion = data.question === undefined || data.question === ''
  const isInvalidSelection =
    data.selections === undefined ||
    data.selections.length === 0 ||
    data.selections.every((item) => item.trim() === '')

  if (isInvalidAnswerType && isInvalidQuestion && isInvalidSelection) {
    return false
  }

  return true
}
