import {
  CreateQuestionnaireInput,
  CreateQuestionnaireV2Input,
} from 'api/createQuestionnaire'
import BaseCheckoutDialog from 'pages/v2/checkout/components/BaseCheckoutDialog'
import useQuestionnaireCheckoutDialog from 'pages/v2/checkout/components/useQuestionnaireCheckoutDialog'
import React from 'react'

type QuestionnaireCheckoutDialogProps = {
  onClose: () => void
  isOpen: boolean
  createQuestionnaireParams: CreateQuestionnaireInput
  questionnaireFormData: CreateQuestionnaireV2Input
}

const QuestionnaireCheckoutDialog: React.VFC<
  QuestionnaireCheckoutDialogProps
> = ({ onClose, isOpen, questionnaireFormData, createQuestionnaireParams }) => {
  const {
    isCheckoutInput,
    onNextCheckout,
    onPurchaseViaStripe,
    onPurchaseViaPaid,
    message,
    severity,
    isOpenBanner,
    hideBanner,
    productInfo,
    requesting,
    needUserAdditionalInput,
    isProcessing,
    onMoveToPaidSetting,
  } = useQuestionnaireCheckoutDialog({
    questionnaireFormData,
    createQuestionnaireParams,
  })

  if (productInfo == null) {
    return null
  }

  return (
    <BaseCheckoutDialog
      isProcessing={isProcessing}
      isOpen={isOpen}
      onClose={onClose}
      productInfo={productInfo}
      isCheckoutInput={isCheckoutInput}
      onPurchaseViaStripe={onPurchaseViaStripe}
      onPurchaseViaPaid={onPurchaseViaPaid}
      requesting={requesting}
      message={message}
      isOpenBanner={isOpenBanner}
      hideBanner={hideBanner}
      severity={severity}
      onNextCheckout={onNextCheckout}
      needUserAdditionalInput={needUserAdditionalInput}
      onMoveToPaidSetting={onMoveToPaidSetting}
    />
  )
}

export default QuestionnaireCheckoutDialog
