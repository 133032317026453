import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Box, Button, Typography } from '@mui/material'
import { useCurrentUser } from 'api/getCurrentUser'
import useFirstVisitAfterCheckout from 'hooks/useFirstVisitAfterCheckout'
import A8SalesScript from 'includes/A8SalesScript'
import BaseDialog from 'includes/BaseDialog'
import formatYen, { withTax } from 'libs/formatYen'
import React from 'react'
import { Order } from 'types/order'
import { Slug } from 'types/product'

type ThanksDialogProps = {
  onClose: () => void
  isOpen: boolean
  description: string | null
  subDescription: string | null
  productName: string
  productPrice: number
  productSlug: Slug
  order: Order | undefined
}

const ThanksDialog: React.VFC<ThanksDialogProps> = ({
  description,
  subDescription,
  onClose,
  isOpen,
  productName,
  productPrice,
  productSlug,
  order,
}) => {
  const { currentUser } = useCurrentUser()
  const isFirstVisit = useFirstVisitAfterCheckout({
    orderId: order?.id?.toString(),
  })

  return (
    <BaseDialog
      open={isOpen}
      onClose={onClose}
      title="ご購入ありがとうございます"
      breakPoints="md"
    >
      <Box sx={{ px: 4, py: 2 }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6" sx={{ color: '#000', whiteSpace: 'pre' }}>
            {productName} {formatYen(withTax(Number(productPrice)))}（税込）{' '}
            <br />
            決済が完了しました。
          </Typography>

          <CheckCircleOutlineIcon
            sx={{ fontSize: 60, color: '#4CAF50', my: 5 }}
          />

          <Typography
            variant="h6"
            sx={{ color: '#000', mb: 5, whiteSpace: 'pre' }}
          >
            {description}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: '#000', mb: 5, whiteSpace: 'pre' }}
          >
            {subDescription}
          </Typography>

          <Box>
            <Button
              variant="contained"
              onClick={onClose}
              sx={{
                textTransform: 'none',
                fontSize: 16,
                color: 'white',
                borderRadius: '20px',
              }}
            >
              閉じる
            </Button>
          </Box>
        </Box>
      </Box>
      {order && (
        <A8SalesScript
          isFirstVisit={isFirstVisit}
          userId={currentUser?.id.toString()}
          totalPrice={order.total}
          items={order.orderItems.map((item) => ({
            code: 'registration',
            price: withTax(item.productPrice),
            quantity: item.quantity,
          }))}
        />
      )}
    </BaseDialog>
  )
}

export default ThanksDialog
