import { useApiContext } from 'App'
import axios from 'axios'
import MarkdownIt from 'markdown-it'
import { useEffect, useRef, useState } from 'react'
const md = new MarkdownIt({
  breaks: true,
})

const defaultRender =
  md.renderer.rules.link_open ||
  function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options)
  }
md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
  var aIndex = tokens[idx].attrIndex('target')

  if (aIndex < 0) {
    tokens[idx].attrPush(['target', '_blank'])
  } else {
    tokens[idx].attrs[aIndex][1] = '_blank'
  }

  return defaultRender(tokens, idx, options, env, self)
}

const MemoCard = (props) => {
  const api = useApiContext()
  const url = api.host + api.storage_list_api

  const [showMenu, setShowMenu] = useState(false)
  const [isEdit, setIsEdit] = useState(props.isEdit)
  const popupRef = useRef()
  const documentClickHandler = useRef()
  const onClick = (e) => {
    if (showMenu) return

    setShowMenu(true)
    document.addEventListener('click', documentClickHandler.current)
  }
  const onEdit = () => {
    props.onEdit(props.item)
  }
  const onDelete = () => {
    props.onDelete(props.item)
  }

  useEffect(() => {
    documentClickHandler.current = (e) => {
      if (popupRef.current.contains(e.target)) return

      setShowMenu(false)
      document.removeEventListener('click', documentClickHandler.current)
    }
  }, [])
  useEffect(() => {
    setIsEdit(props.isEdit)
  })

  const onSelectColor = async (color) => {
    if (color == 'ffffff') {
      props.item.hashtags = props.item.hashtags.filter(
        (hashtag) => !/^!color:/.test(hashtag),
      )
    } else {
      let removeColorTag = null
      for (const hashtag of props.item.hashtags) {
        if (/^!color:/.test(hashtag)) {
          removeColorTag = hashtag
          break
        }
      }
      if (removeColorTag) {
        props.item.hashtags.splice(
          props.item.hashtags.indexOf(removeColorTag),
          1,
        )
      }
      props.item.hashtags.push(`!color:${color}`)
    }
    const res = await axios.put(`${url}/${props.item.id}/hashtags`, {
      hashtags: props.item.hashtags,
    })
  }

  const onBookmark = async () => {
    const pinnedIndex = props.item.hashtags.indexOf('!pin:ON')
    if (pinnedIndex >= 0) {
      props.item.hashtags.splice(pinnedIndex, 1)
    } else {
      props.item.hashtags.push('!pin:ON')
    }
    const res = await axios.put(`${url}/${props.item.id}/hashtags`, {
      hashtags: props.item.hashtags,
    })
    props.onUpdatedPinned(props.item)
  }

  const toColor = (hashtags) => {
    for (const hashtag of hashtags) {
      if (/^!color:/.test(hashtag)) {
        const match = [...hashtag.matchAll(/^!color:(.*)/g)]
        return `#${match[0][1]}`
      }
    }
    return '#fff'
  }

  return (
    <>
      <div
        className={`c-memoCard ${isEdit ? 'is-selected' : ''}`}
        style={{ backgroundColor: toColor(props.item.hashtags) }}
      >
        <div className="c-memoCard__area">
          <div className="c-memoCard__head">
            <div className="c-memoCard__head__title">{props.item.name}</div>

            <div className="c-memoCard__head__right">
              <div
                className={`c-memoCard__head__right__pin ${
                  props.item.hashtags.indexOf('!pin:ON') >= 0
                    ? 'u-dib'
                    : 'u-dnone'
                }`}
              >
                <img src="/assets/images/pushpin_icon_3.svg" alt="" />
              </div>
              <div className="c-memoCard__head__right__btn">
                <img
                  ref={popupRef}
                  style={{ cursor: 'pointer' }}
                  src="/assets/images/icon_menu.svg"
                  alt=""
                  onClick={onClick}
                />

                <div className={'c-memoCard__menu' + (showMenu ? ' u-db' : '')}>
                  <div className="c-memoCard__menu__area">
                    <div
                      className={`c-memoCard__menu__item ${
                        isEdit ? 'c-memoCard__menu__fix' : ''
                      }`}
                      onClick={onEdit}
                    >
                      {isEdit ? '編集中' : '編集'}
                    </div>
                    <div
                      className={`c-memoCard__menu__item ${
                        props.item.hashtags.indexOf('!pin:ON') >= 0
                          ? 'c-memoCard__menu__fix'
                          : ''
                      }`}
                      onClick={onBookmark}
                    >
                      {props.item.hashtags.indexOf('!pin:ON') >= 0
                        ? 'メモ固定を解除'
                        : 'メモを固定'}
                    </div>
                    <div className="c-memoCard__menu__list">
                      <div
                        className="c-memoCard__menu__circle c01"
                        onClick={() => onSelectColor('ffffff')}
                      />
                      <div
                        className="c-memoCard__menu__circle c02"
                        onClick={() => onSelectColor('0000ff')}
                      />
                      <div
                        className="c-memoCard__menu__circle c03"
                        onClick={() => onSelectColor('ffa500')}
                      />
                      <div
                        className="c-memoCard__menu__circle c04"
                        onClick={() => onSelectColor('00ff00')}
                      />
                      <div
                        className="c-memoCard__menu__circle c05"
                        onClick={() => onSelectColor('ff0000')}
                      />
                      <div
                        className="c-memoCard__menu__circle c06"
                        onClick={() => onSelectColor('ffff00')}
                      />
                      <div
                        className="c-memoCard__menu__circle c07"
                        onClick={() => onSelectColor('87ceeb')}
                      />
                      <div
                        className="c-memoCard__menu__circle c08"
                        onClick={() => onSelectColor('ffd700')}
                      />
                      <div
                        className="c-memoCard__menu__circle c09"
                        onClick={() => onSelectColor('adff2f')}
                      />
                      <div
                        className="c-memoCard__menu__circle c10"
                        onClick={() => onSelectColor('ffc0cb')}
                      />
                    </div>
                    <div
                      className="c-memoCard__menu__delete c-memoCard__menu__item"
                      onClick={onDelete}
                    >
                      削除
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="c-memoCard__cont u-colorGray"
            dangerouslySetInnerHTML={{
              __html: md.render(props.item.text != null ? props.item.text : ''),
            }}
          />
        </div>
      </div>
    </>

    /*
          <>
      <label className="c-memoCard__area c-memoCard__area--header u-mt10 u-maxh100per" style={{ height: "auto" }}>
        <div className="p-topics u-pt10 u-pr20 u-pl20">
          <div className="p-topics__head">
            {props.item.name}
          </div>
          <div className="p-topics__headBtn">
            <img
              width="16"
              height="16"
              src="/assets/images/icon_menu.svg"
              alt=""
            />
          </div>
          <div className="p-topics__cont u-colorGray" dangerouslySetInnerHTML={{__html: md.render(props.item.text != null ? props.item.text : "")}} />
        </div>
      </label>
    </>

      */
  )
}

export default MemoCard
