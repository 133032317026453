import { Alert, Box, Typography } from '@mui/material'
import { useCreateSurvey } from 'api/createSurvey'
import { useCurrentUser } from 'api/getCurrentUser'
import { useGetOrder } from 'api/order/getOrder'
import useFirstVisitAfterCheckout from 'hooks/useFirstVisitAfterCheckout'
import useOpenWeightedDialog from 'hooks/useOpenWeightedDialog'
import useQuery from 'hooks/useQuery'
import useSelectedKeyword from 'hooks/useSelectedKeyword'
import A8SalesScript from 'includes/A8SalesScript'
import BaseDialog from 'includes/BaseDialog'
import Loading from 'includes/Loading'
import { withTax } from 'libs/formatYen'
import getValueFromOrderProcessingData from 'libs/getValueFromOrderProcessingData'
import {
  AgeRecord,
  GenderRecord,
} from 'pages/v2/components/ResearchTargetInputForm'
import WeightedFilterForm, {
  FormData,
} from 'pages/v2/KeywordDetail/components/WeightedFilter/WeightedFilterForm'
import { VFC, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

type Props = {
  title: string
  open: boolean
  onClose: () => void
  onOpen: () => void
  topicId: string
}

export const WeightedFilterDialog: VFC<Props> = ({
  title,
  open,
  onClose,
  onOpen,
  topicId,
}) => {
  const { selectedKeywordId } = useSelectedKeyword()
  const [formResetKey, setFormResetKey] = useState(0)
  const query = useQuery()
  const checkoutMode = query.get('checkout') ? query.get('checkout') : null
  const orderId = query.get('order_id') ? query.get('order_id') : null
  const { data: order, isLoading } = useGetOrder({ orderId: orderId })
  const location = useLocation<{ surveyFormData?: FormData }>()
  const { currentUser } = useCurrentUser()
  const isFirstVisit = useFirstVisitAfterCheckout({
    orderId: order?.id?.toString(),
  })
  const { isDialogOpen, closeDialog } = useOpenWeightedDialog({
    orderId: orderId?.toString(),
    orderProductSlug: order ? order.orderItems[0].product.slug : undefined,
    topicId,
    orderTopicId: order ? order.topicId : undefined,
  })

  const { request, requesting } = useCreateSurvey({
    onSuccess: () => {
      setFormResetKey((prev) => prev + 1)
      onClose()
    },
    onError: (e) => {
      console.error(e)
    },
  })

  const formValue = getValueFromOrderProcessingData({
    type: 'checkout.survey',
    orderProcessingData: order?.orderProcessingData ?? null,
  })

  const defaultValues = order ? formValue : location.state?.surveyFormData

  useEffect(() => {
    if (isDialogOpen) {
      onOpen()
    } else {
      onClose()
    }
  }, [isDialogOpen])

  return (
    <>
      {isLoading && <Loading />}
      <BaseDialog
        open={open}
        onClose={isDialogOpen ? closeDialog : onClose}
        breakPoints="lg"
      >
        <Box
          sx={{
            p: 3,
          }}
        >
          <Box
            sx={{
              mb: 1,
              textAlign: 'center',
            }}
          >
            <Typography
              fontSize="16px"
              fontWeight="bold"
              sx={{
                mb: 1,
              }}
            >
              {title}
            </Typography>
            <Typography
              fontSize="16px"
              color="text.secondary"
              fontWeight="bold"
            >
              のトピックの投稿に共感度を付与します
            </Typography>
          </Box>
          {isDialogOpen && (
            <Alert severity="success" sx={{ fontSize: '16px', mt: 2 }}>
              購入ありがとうございました。以下の内容で共感度の付与を開始します。
            </Alert>
          )}
          {isFirstVisit && order && (
            <A8SalesScript
              isFirstVisit={isFirstVisit}
              userId={currentUser?.id.toString()}
              totalPrice={order.total}
              items={order.orderItems.map((item) => ({
                code: 'registration',
                price: withTax(item.productPrice),
                quantity: item.quantity,
              }))}
            />
          )}
          <Box
            sx={{
              py: 3,
              width: '80%',
              margin: '0 auto',
            }}
          >
            投稿ごとに共感度をつけることでよりアイデアを見つけやすくなるかもしれません。
            <br />
            {/* TODO: リンク先を変更する */}
            この機能では、<Link to="/">サンプル</Link>
            のようなレポートが出力できます。
            <br />
            {/* TODO: 完了予定時間を入れる */}
            現在依頼されますとレポートの作成完了は、YYYY/MM/DD
            HH時頃を予定しております。
            <br />
            完了次第メールが飛びますのでお待ちください。
          </Box>

          <WeightedFilterForm
            onSubmit={({ researchTarget }) => {
              if (requesting || selectedKeywordId === undefined) {
                return
              }
              request({
                analysisResultId: selectedKeywordId.toString(),
                labelId: topicId,
                tags: [
                  ...GenderRecord[researchTarget.gender],
                  ...researchTarget.generations.map(
                    (generation) => AgeRecord[generation],
                  ),
                ],
                prefectures: researchTarget.prefectures,
                specialityPanel: researchTarget.specialityPanel,
                note: researchTarget.note ?? '',
              })
            }}
            topicId={topicId}
            key={
              defaultValues ? `default-${formResetKey}` : `new-${formResetKey}`
            }
            requesting={requesting}
            checkoutMode={!!checkoutMode}
            defaultValues={defaultValues}
          />
        </Box>
      </BaseDialog>
    </>
  )
}
