import { Box, Typography } from '@mui/material'
import { useCurrentUser } from 'api/getCurrentUser'
import { useUpdateUser } from 'api/updateUser'
import { useBanner } from 'hooks/useBanner'
import useDailyDialog from 'hooks/useDailyDialog'
import useNeedUserAdditionalInput from 'hooks/useNeedUserAdditionalInput'
import BaseDialog from 'includes/BaseDialog'
import AdditionalUserInputForm from 'pages/v2/checkout/components/additionalUserInput/AdditionalUserInputForm'
import { additionalUserInputSchema } from 'pages/v2/checkout/components/additionalUserInput/scheme'
import BaseSnackbar from 'pages/v2/components/BaseSnackbar'
import { z } from 'zod'

type FormData = z.infer<typeof additionalUserInputSchema>
const AdditionalUserInputDialog = () => {
  const {
    message,
    severity,
    isOpen: isOpenBanner,
    showBanner,
    hideBanner,
  } = useBanner()
  const { currentUser } = useCurrentUser()
  const needAdditionInput = useNeedUserAdditionalInput()
  const { isDialogOpen, setIsDialogOpen } = useDailyDialog(
    'lastAdditionalUserInputDialogDisplayTimestamp',
    needAdditionInput,
  )
  const { request } = useUpdateUser({
    onSuccess: () => {
      showBanner({ message: '登録が完了しました', severity: 'success' })
      setTimeout(() => {
        handleClose()
      }, 1000)
    },
    onError: () => {
      showBanner({ message: 'エラーが発生しました', severity: 'error' })
    },
  })

  const handleClose = () => {
    setIsDialogOpen(false)
  }

  const onSubmit = async (data: FormData) => {
    if (!currentUser) {
      return
    }

    await request({
      userId: String(currentUser.id),
      name: data.name,
      companyName: data.companyName,
      phoneNumber: data.phoneNumber,
      isDisplayV2Sample: data.isDisplayV2Sample === 1,
    })
  }

  return (
    <>
      <BaseDialog
        open={isDialogOpen}
        onClose={handleClose}
        title="追加情報の登録"
        disabledClose
        breakPoints="md"
      >
        <Box sx={{ px: 4, py: 2 }}>
          <Typography
            variant="h6"
            sx={{ fontSize: '18px', fontWeight: 'bold' }}
          >
            ご利用にあたって情報の登録が必要です
          </Typography>
          {currentUser && (
            <AdditionalUserInputForm
              user={{
                id: currentUser.id,
                name: currentUser.name,
                companyName: currentUser.company_name,
                phoneNumber: currentUser.phone_number,
                isDisplayV2Sample: currentUser.is_display_v2_sample,
              }}
              onSubmit={onSubmit}
              label="登録する"
            />
          )}
        </Box>
      </BaseDialog>
      <BaseSnackbar
        message={message}
        open={isOpenBanner}
        handleClose={hideBanner}
        severity={severity}
        autoHideDuration={3000}
      />
    </>
  )
}

export default AdditionalUserInputDialog
