import AuthPageLayout from 'pages/components/AuthPageLayout'
import PasswordResetEmailForm from 'pages/passwordResetEmail/components/PasswordResetEmailForm'

const PasswordResetEmail = () => {
  return (
    <AuthPageLayout>
      <PasswordResetEmailForm />
    </AuthPageLayout>
  )
}

export default PasswordResetEmail
