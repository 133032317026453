import { useCurrentUser } from 'api/getCurrentUser'
import useIsSpScreen from 'hooks/useIsSpScreen'
import { DemoPage } from 'pages/v2/Demo/Demo2'
import QuestionnaireResultPage from 'pages/v2/KeywordDetail/result'
import CancelPage from 'pages/v2/checkout/Cancel'
import SuccessPage from 'pages/v2/checkout/Success'
import SuccessPaidPage from 'pages/v2/checkout/SuccessPaid'
import { SPKeywordDetailPage } from 'pages/v2/sp/KeywordDetail/components'
import { SPNewKeywordPage } from 'pages/v2/sp/NewKeyword'
import { SPSummaryDetailPage } from 'pages/v2/sp/SummaryDetail'
import SPSettings from 'pages/v2/sp/User/Setting'
import { V2SPHomePage } from 'pages/v2/sp/V2Home'
import { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { KeywordDetailPage } from './KeywordDetail'
import { NewAnalysisThemePage } from './NewAnalysisTheme'
import { NewKeywordPage } from './NewKeyword'
import { SummaryDetailPage } from './SummaryDetail'
import { V2Tour } from './Tour'
import Settings from './User/Setting'
import { V2HomePage } from './V2Home'
import { Layout } from './layout'
import { V2Paths } from './paths'

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return null
}

export const V2Routes = () => {
  const { currentUser } = useCurrentUser()
  const isSpScreen = useIsSpScreen()

  if (!currentUser?.v2_enabled) return <Redirect from="/v2/*" to="/" />

  return (
    <V2Tour>
      <Layout>
        <ScrollToTop />
        <Switch>
          <Route
            exact
            path={V2Paths.NewTheme}
            component={() => <NewAnalysisThemePage />}
          />
          <Route exact path={V2Paths.Demo} component={() => <DemoPage />} />
          <Route
            path={V2Paths.Settings}
            component={() => (isSpScreen ? <SPSettings /> : <Settings />)}
          />
          <Route
            exact
            path={V2Paths.Theme}
            component={() => <>キーワードが存在しません。</>}
          />
          <Route
            exact
            path={isSpScreen ? V2Paths.SPNewKeyword : V2Paths.NewKeyword}
            component={() =>
              isSpScreen ? <SPNewKeywordPage /> : <NewKeywordPage />
            }
          />
          <Route
            exact
            path={V2Paths.Keyword}
            component={() =>
              isSpScreen ? <SPKeywordDetailPage /> : <KeywordDetailPage />
            }
          />
          <Route
            exact
            path={V2Paths.Summary}
            component={() =>
              isSpScreen ? <SPSummaryDetailPage /> : <SummaryDetailPage />
            }
          />
          <Route
            exact
            path={V2Paths.QuestionnaireResult}
            component={() => <QuestionnaireResultPage />}
          />
          <Route
            exact
            path={V2Paths.Success}
            component={() => <SuccessPage />}
          />
          <Route
            exact
            path={V2Paths.SuccessPaid}
            component={() => <SuccessPaidPage />}
          />
          <Route exact path={V2Paths.Cancel} component={() => <CancelPage />} />
          <Route
            exact
            path={V2Paths.Home}
            component={() => (isSpScreen ? <V2SPHomePage /> : <V2HomePage />)}
          />
          <Redirect exact path="/v2" to={V2Paths.Home} />
          <Redirect from="/v2/*" to={V2Paths.Home} />
        </Switch>
      </Layout>
    </V2Tour>
  )
}
