import { Alert, Box, CircularProgress } from '@mui/material'
import {
  useCreateKeyword,
  useCreateKeywordWithAnalysisTheme,
} from 'api/createKeyword'
import { useGetAnalysisThemes } from 'api/getAnalysisThemes'
import { useCurrentUser } from 'api/getCurrentUser'
import { useGetOrder } from 'api/order/getOrder'
import useFirstVisitAfterCheckout from 'hooks/useFirstVisitAfterCheckout'
import useQuery from 'hooks/useQuery'
import A8SalesScript from 'includes/A8SalesScript'
import Loading from 'includes/Loading'
import { withTax } from 'libs/formatYen'
import { getValueFromOrderProcessingDataForSP } from 'libs/getValueFromOrderProcessingData'
import {
  AgeRecord,
  GenderRecord,
} from 'pages/v2/components/ResearchTargetInputForm'
import { v2NewKeywordSchema } from 'pages/v2/NewKeyword/schema'
import { V2Paths } from 'pages/v2/paths'
import BackButton from 'pages/v2/sp/components/BackButton'
import { NewKeywordForm } from 'pages/v2/sp/NewKeyword/components/NewKeywordForm'
import { FC, Suspense } from 'react'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { z } from 'zod'

type FormData = z.infer<typeof v2NewKeywordSchema>

export const SPNewKeywordPage: FC = () => {
  const history = useHistory()
  const location = useLocation<{ keywordFormData?: FormData }>()
  const query = useQuery()
  const checkoutMode = query.get('checkout') ? query.get('checkout') : null
  const orderId = query.get('order_id') ? query.get('order_id') : null
  const { data: order, isLoading } = useGetOrder({ orderId: orderId })

  const { mutate } = useGetAnalysisThemes({})
  const { currentUser } = useCurrentUser()
  const isFirstVisit = useFirstVisitAfterCheckout({
    orderId: order?.id?.toString(),
  })

  const { request, requesting } = useCreateKeywordWithAnalysisTheme({
    async onSuccess({ id, analysis_theme_id: themeId }) {
      await mutate()
      history.push(
        generatePath(V2Paths.Keyword, {
          themeId,
          keywordId: id,
        }),
      )
    },
    onError(error) {
      console.error('error', error)
    },
  })

  const { request: requestCreateKeyword, requesting: requestingCreateKeyword } =
    useCreateKeyword({
      async onSuccess({ id, analysis_theme_id: themeId }) {
        await mutate()
        history.push(
          generatePath(V2Paths.Keyword, {
            themeId,
            keywordId: id,
          }),
        )
      },
      onError(error) {
        console.error('error', error)
      },
    })

  const formValue = getValueFromOrderProcessingDataForSP({
    type: 'checkout.keyword',
    orderProcessingData: order?.orderProcessingData ?? null,
  })

  const defaultValues = order ? formValue : location.state?.keywordFormData

  const onSubmit = async (data: FormData) => {
    if (!data.analysisThemeId && !data.theme) {
      return
    }

    const baseBody = {
      name: data.keyword.name,
      excludedWords: [
        ...data.keyword.excludedWords,
        ...data.keyword.selectedExcludedWords,
      ],
      tags: [
        ...GenderRecord[data.researchTarget.gender],
        ...data.researchTarget.generations.map(
          (generation) => AgeRecord[generation],
        ),
      ],
      prefectures: data.researchTarget.prefectures,
      speciality_panel: data.researchTarget.specialityPanel,
      note: data.researchTarget.note,
    }

    if (data.analysisThemeId) {
      await requestCreateKeyword({
        ...baseBody,
        analysisThemeId: data.analysisThemeId,
      })
    } else if (data.theme) {
      await request({
        ...baseBody,
        theme: {
          name: data.theme.name,
          kind: data.theme.type,
          userVoiceType:
            data.theme.type === 'userVoice' ? data.theme.userVoiceType : null,
          otherMemo: data.theme.type === 'other' ? data.theme.memo : null,
          tags: [
            ...GenderRecord[data.researchTarget.gender],
            ...data.researchTarget.generations.map(
              (generation) => AgeRecord[generation],
            ),
          ],
          prefectures: data.researchTarget.prefectures,
          speciality_panel: data.researchTarget.specialityPanel,
          note: data.researchTarget.note,
        },
      })
    }
  }

  return (
    <>
      {isLoading && <Loading />}
      <Box sx={{ py: 3 }}>
        <BackButton>新規分析</BackButton>
        <Suspense
          fallback={
            <Box
              sx={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          }
        >
          {order && (
            <Alert severity="success" sx={{ fontSize: '16px', mt: 2 }}>
              購入ありがとうございました。以下の内容で分析を開始します。
            </Alert>
          )}
          {isFirstVisit && order && (
            <A8SalesScript
              isFirstVisit={isFirstVisit}
              userId={currentUser?.id.toString()}
              totalPrice={order.total}
              items={order.orderItems.map((item) => ({
                code: 'registration',
                price: withTax(item.productPrice),
                quantity: item.quantity,
              }))}
            />
          )}
          <NewKeywordForm
            key={defaultValues ? 'default' : 'new'}
            onSubmit={onSubmit}
            requesting={requesting}
            checkoutMode={!!checkoutMode}
            defaultValues={defaultValues}
          />
        </Suspense>
      </Box>
    </>
  )
}
