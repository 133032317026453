import {
  ConfirmPaymentsByPaidInput,
  ConfirmPaymentsByPaidPayload,
} from 'api/checkoutByPaid/confirmPaymentsByPaid'
import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

type ConfirmKeywordPaymentsByPaidInput = ConfirmPaymentsByPaidInput & {
  analysisThemeId: number | undefined
}

export const confirmKeywordPaymentsByPaid = async (
  input: ConfirmKeywordPaymentsByPaidInput,
) => {
  const { analysisThemeId, orderId } = input
  const response = await axios.post<ConfirmPaymentsByPaidPayload>(
    `/api/analysis_themes/${analysisThemeId}/keywords/confirm_payments`,
    {
      order_id: orderId,
    },
  )
  return response.data
}

export const useConfirmKeywordPaymentsByPaid = (
  option?: UseMutationOption<ConfirmPaymentsByPaidPayload>,
) => useMutation(confirmKeywordPaymentsByPaid, option)
