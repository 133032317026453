import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import { Link as Scroll } from 'react-scroll'

type TableOfContentsProps = {
  sections: {
    elementId: string
    title: string
  }[]
}

const TableOfContents: React.FC<TableOfContentsProps> = (props) => {
  const SHOW_LIMIT = 5
  const { sections } = props
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  if (sections.length === 0) return null

  return (
    <List component={Card} sx={{ padding: 4 }}>
      <Typography variant="h6" gutterBottom>
        目次
      </Typography>
      {sections.slice(0, SHOW_LIMIT).map((section) => (
        <ListItem
          key={section.elementId}
          disablePadding
          sx={{
            marginBottom: 1,
            '&:hover': {
              backgroundColor: '#e0e0e0',
            },
          }}
        >
          <Scroll
            to={section.elementId}
            smooth={true}
            duration={1000}
            offset={-10}
            style={{
              color: '#3D3E3F',
              letterSpacing: '0.5px',
              fontSize: '14px',
              fontWeight: 'bolder',
              cursor: 'pointer',
            }}
          >
            {section.title}
          </Scroll>
        </ListItem>
      ))}
      {sections.length > SHOW_LIMIT && !isOpen && (
        <Button
          onClick={handleToggle}
          sx={{
            fontWeight: 'bolder',
            cursor: 'pointer',
          }}
        >
          もっと見る
        </Button>
      )}
      {isOpen &&
        sections.slice(SHOW_LIMIT).map((section) => (
          <ListItem
            key={section.elementId}
            disablePadding
            sx={{
              marginBottom: 1,
              '&:hover': {
                backgroundColor: '#e0e0e0',
              },
            }}
          >
            <Scroll
              to={section.elementId}
              smooth={true}
              duration={1000}
              offset={-10}
              style={{
                color: '#3D3E3F',
                letterSpacing: '0.5px',
                fontSize: '14px',
                fontWeight: 'bolder',
                cursor: 'pointer',
              }}
            >
              {section.title}
            </Scroll>
          </ListItem>
        ))}
    </List>
  )
}

export default TableOfContents
