import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  tableCellClasses,
} from '@mui/material'
import LineChart from 'pages/v2/KeywordDetail/components/Questionnaire/Result/LineChart'
import { choiceColor } from 'pages/v2/KeywordDetail/components/Questionnaire/Result/choiceColor'
import { VFC } from 'react'
import { Pie } from 'react-chartjs-2'

const pieOptions = {
  legend: {
    display: false,
  },
}

type QuestionItemProps = {
  question: {
    question: string
    answerType: 2 | 1
    answers: {
      count: number
      rate: number
      selection: string
    }[]
  }
  index: number
}

const QuestionItem: VFC<QuestionItemProps> = ({ question, index }) => {
  const createPieData = (answers: QuestionItemProps['question']['answers']) => {
    answers.sort((a, b) => {
      if (a.rate > b.rate) return -1
      if (a.rate < b.rate) return 1
      return 0
    })
    const labels = answers.map((answer) => {
      return answer.selection
    })
    const rates = answers.map((answer) => {
      return answer.rate
    })
    const colorCodes = answers.map((answer, index) => {
      return choiceColor(index)
    })
    const sampleData = {
      labels: labels,
      datasets: [
        {
          data: rates,
          backgroundColor: colorCodes,
          borderWidth: 0,
        },
      ],
    }
    return sampleData
  }

  const createPieDataDetail = (
    answers: QuestionItemProps['question']['answers'],
  ) => {
    answers.sort((a, b) => {
      if (a.rate > b.rate) return -1
      if (a.rate < b.rate) return 1
      return 0
    })

    return answers.map((answer, index) => ({
      label: answer.selection,
      rate: `${answer.rate}%`,
      color: choiceColor(index),
    }))
  }

  const pieData = createPieData(question.answers)
  const pieDataDetail = createPieDataDetail(question.answers)

  return (
    <Box>
      <Typography
        variant="subtitle1"
        sx={{
          marginTop: '25px',
          paddingBottom: '25px',
        }}
      >
        設問{index + 1}：
        <br />
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: 16,
          }}
        >
          {question.question}
        </Typography>
      </Typography>
      {question.answerType === 1 ? (
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Pie data={pieData} options={pieOptions} />
          </Grid>
          <Grid item xs={6}>
            <Table
              size="small"
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                },
              }}
            >
              <TableBody>
                {pieDataDetail.map((item, idx) => (
                  <TableRow key={idx}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        fontWeight: 'normal',
                        paddingRight: '30px',
                        paddingBottom: '10px',
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        component="span"
                        sx={{
                          display: 'inline-block',
                          width: '1.5em',
                          height: '1.5em',
                          marginTop: '-0.1em',
                          marginRight: '0.5em',
                          verticalAlign: 'middle',
                          borderRadius: '50%',
                          bgcolor: item.color,
                        }}
                      />
                      {item.label}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: 'bold',
                      }}
                    >
                      {item.rate}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      ) : (
        <LineChart answers={question.answers} />
      )}
    </Box>
  )
}

export default QuestionItem
