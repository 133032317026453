// Layouts
import { useApiContext } from 'App'
import PlanType from '../enums/PlanType'
import FloatingBanner from './FloatingBanner'
import Header from './Header'
import Side from './Side'
import V2UpgradeSuggestionDialog from './V2UpgradeSuggestionDialog'
import PopupProvider from './popup/PopupProvider'

const Layout = (props) => {
  const { me } = useApiContext()

  return (
    <div id="Site">
      <Header />
      <PopupProvider>
        <div className="l-contents">
          <Side />
          {props.children}
        </div>
      </PopupProvider>

      <V2UpgradeSuggestionDialog />

      {me?.plan?.id === PlanType.FREEMIUM && <FloatingBanner />}
    </div>
  )
}
export default Layout
