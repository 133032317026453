import { Box, IconButton, TextField, Typography } from '@mui/material'
import { useDisclosure } from 'hooks/useDisclosure'
import { FormEvent, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'

type Props = {
  onSubmit: (text: string) => void
  defaultText: string
  label: string
}

export const ToggleInputForm = ({ onSubmit, defaultText, label }: Props) => {
  const { isOpen, onToggle } = useDisclosure()

  const [resetCounter, setResetCounter] = useState(0)
  const [text, setText] = useState<string>(defaultText)

  const onSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit(text)
    setResetCounter((prev) => prev + 1)
    onToggle()
  }

  return (
    <>
      {isOpen ? (
        <form key={resetCounter} onBlur={onSave} onSubmit={onSave}>
          <TextField
            id="standard-basic"
            defaultValue={defaultText}
            label={label}
            color="secondary"
            variant="standard"
            onChange={(e) => setText(e.target.value)}
          />
        </form>
      ) : (
        <Box
          sx={{
            py: '3.5px',
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #707070',
          }}
        >
          <Typography
            sx={{ color: '#2E2E2E', fontWeight: 'bold', fontSize: '18px' }}
          >
            {text}
          </Typography>
          <IconButton onClick={onToggle}>
            <EditIcon />
          </IconButton>
        </Box>
      )}
    </>
  )
}
