import { Box, Card, Grid } from '@mui/material'
import { RatioCircle } from 'pages/v2/components/common/RatioCircle'
import React from 'react'

type Props = {
  totalEmpathy: number | undefined
  totalSurvey: number | undefined
  isPublic: boolean
  detail: string
  height?: number
}

const AnalysisEmpathyAndSummaryContent: React.FC<Props> = ({
  height,
  totalEmpathy,
  totalSurvey,
  isPublic,
  detail,
}) => {
  return (
    <Grid
      container
      sx={{
        height,
      }}
    >
      <Grid
        item
        xs={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ fontSize: 12, fontWeight: 'bold' }}>共感した人数</Box>
        <RatioCircle
          top={isPublic ? totalEmpathy : undefined}
          bottom={isPublic ? totalSurvey : undefined}
        />
      </Grid>
      <Grid item xs={9}>
        <Card
          sx={{
            backgroundColor: '#f5f5f5',
            p: 2,
            height: '100%',
          }}
        >
          {isPublic
            ? detail
            : '解析中です、しばらくお待ち下さい。約1〜2営業日で表示されます。'}
        </Card>
      </Grid>
    </Grid>
  )
}

export default AnalysisEmpathyAndSummaryContent
