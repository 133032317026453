import { useEffect, useState } from 'react'

const useIsSpScreen = () => {
  const [isSpScreen, setIsSpScreen] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)')

    const handleMediaQueryChange = (event: MediaQueryListEvent) => {
      if (event.matches) {
      }
      setIsSpScreen(event.matches)
    }

    setIsSpScreen(mediaQuery.matches)

    mediaQuery.addEventListener('change', handleMediaQueryChange)

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange)
    }
  }, [])

  if (Boolean(process.env.REACT_APP_SP_RELEASE) !== true) {
    return false
  }

  return isSpScreen
}

export default useIsSpScreen
