import useSWRInfinite from 'swr/infinite'

const getKey = ({
  pageIndex,
  previousPageData,
  sort,
}: {
  pageIndex: number
  previousPageData: any
  sort: string | null
}) => {
  if (
    previousPageData &&
    previousPageData.data &&
    !previousPageData.data.length
  )
    return null
  return `/api/results?page=${pageIndex + 1}${
    sort === null ? '' : '&sort=' + sort
  }&v2=true`
}

export type AnalysisResult = {
  id: number
  target_words: string
  questionnaire: {
    id: number
    status: {
      id: number
      name: string
    }
    created_at: string
  } | null
  created_at: string
  analysis_theme_id: number
  analysis_theme: {
    id: number
    name: string
  } | null
  summaries: {
    id: number
    title: string
    created_at: string
    is_public: boolean
  }[]
}

type GetAnalysisResponse = {
  data: AnalysisResult[]
  links: {
    first: string
    last: string
    prev: string | null
    next: string | null
  }
  meta: {
    current_page: number
    from: number
    last_page: number
    per_page: number
    total: number
  }
}

type Props = {
  params: {
    sort: string | null
  }
}

export const useGetV2Analysis = ({ params }: Props) =>
  useSWRInfinite<GetAnalysisResponse>((pageIndex, previousPageData) =>
    getKey({
      pageIndex,
      previousPageData,
      sort: params.sort,
    }),
  )
