import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'
import { AgeType } from 'types/age'
import { GenderType } from 'types/gender'
import { Prefecture } from 'types/prefecture'

export type CreateQuestionnaireInput = {
  analysisResultId: string
  topicId?: string
  title: string
  specialityPannel: {
    use: boolean
    memo: string
  }
  target: {
    gender: GenderType[]
    age: AgeType[]
    prefecture?: Prefecture[]
  }
  questions: QuestionType[]
}

export type CreateQuestionnaireV2Input = {
  analysisResultId: string
  topicId: string | undefined
  title: string
  researchTarget: {
    gender: GenderType | 'both'
    generations: (AgeType | 'All')[]
    prefectures: Prefecture[]
    specialityPanel: boolean
    note: string
  }
  questions: QuestionType[]
}

type QuestionType = {
  answerType: 1 | 2
  question: string
  selections: string[]
}

export const createQuestionnaire = async ({
  analysisResultId,
  title,
  specialityPannel,
  target,
  questions,
  topicId,
}: CreateQuestionnaireInput) => {
  await axios.post('/api/questionnaires', {
    analysis_result_id: analysisResultId,
    topic_id: topicId,
    title,
    request_content: {
      speciality_pannel: specialityPannel,
      target,
      questions: questions.map(({ answerType, question, selections }) => ({
        answer_type: answerType,
        question,
        selections,
      })),
    },
  })
}

export const useCreateQuestionnaire = (option?: UseMutationOption<void>) =>
  useMutation(createQuestionnaire, option)
