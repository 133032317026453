import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Link, Typography } from '@mui/material'
import { paidRegisterMemberScheme } from 'pages/v2/PaidRegisterMember/scheme'
import FormCheckbox from 'pages/v2/sp/User/PaidRegisterMember/components/FormCheckbox'
import FormInput from 'pages/v2/sp/User/PaidRegisterMember/components/FormInput'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

type FormData = z.infer<typeof paidRegisterMemberScheme>

type PaidRegisterMemberFormProps = {
  user: {
    id: number | undefined
    email: string
    companyName: string
    companyNameKana: string
    givenName: string
    givenNameKana: string
    familyName: string
    familyNameKana: string
    zipCode: string
    prefecture: string
    address1: string
    address2: string
    address3: string
    phoneNumber: string
    representative: {
      id: number
      givenName: string
      familyName: string
      givenNameKana: string
      familyNameKana: string
    } | null
  }
  onSubmit: (data: FormData) => void
  requesting: boolean
}

const PaidRegisterMemberForm: React.VFC<PaidRegisterMemberFormProps> = ({
  user,
  onSubmit,
  requesting,
}) => {
  const method = useForm<FormData>({
    resolver: zodResolver(paidRegisterMemberScheme),
    defaultValues: {
      companyName: user.companyName,
      companyNameKana: user.companyNameKana,
      clerkMei: user.givenName,
      clerkMeiKana: user.givenNameKana,
      clerkSei: user.familyName,
      clerkSeiKana: user.familyNameKana,
      clerkTel: user.phoneNumber,
      clerkMobileTel: user.phoneNumber,
      email: user.email,
      zipCode: user.zipCode,
      prefecture: user.prefecture,
      address1: user.address1,
      address2: user.address2,
      address3: user.address3,
      tel: user.phoneNumber,
      representativeSei: user.representative?.familyName ?? '',
      representativeMei: user.representative?.givenName ?? '',
      representativeSeiKana: user.representative?.familyNameKana ?? '',
      representativeMeiKana: user.representative?.givenNameKana ?? '',
      isSameBillingInfo: true,
      agreeTerms: false,
    },
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = method

  console.log({ errors })

  const isSameBillingInfo = watch('isSameBillingInfo')
  const agreeTerms = watch('agreeTerms')

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            py: 4,
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              flexDirection: 'column',
            }}
          >
            <FormInput<FormData>
              name="companyName"
              control={control}
              label="御社名"
              required
              placeholder="御社名"
              errorObj={errors}
            />
            <FormInput<FormData>
              name="companyNameKana"
              control={control}
              label="御社名（カナ）"
              required
              placeholder="オンシャメイ"
              errorObj={errors}
            />
            <FormInput<FormData>
              name="tel"
              control={control}
              label="電話番号"
              required
              placeholder="090-1234-5678"
              errorObj={errors}
            />
            <FormInput<FormData>
              name="representativeSei"
              control={control}
              label="代表者姓"
              required
              placeholder="代表者姓"
              errorObj={errors}
            />
            <FormInput<FormData>
              name="representativeMei"
              control={control}
              label="代表者名"
              required
              placeholder="代表者名"
              errorObj={errors}
            />
            <FormInput<FormData>
              name="representativeSeiKana"
              control={control}
              label="代表者姓（カナ）"
              required
              placeholder="ダイヒョウシャセイ"
              errorObj={errors}
            />
            <FormInput<FormData>
              name="representativeMeiKana"
              control={control}
              label="代表者名（カナ）"
              required
              placeholder="ダイヒョウシャメイ"
              errorObj={errors}
            />
            <FormInput<FormData>
              name="clerkSei"
              control={control}
              label="担当者姓"
              required
              placeholder="担当者姓"
              errorObj={errors}
            />
            <FormInput<FormData>
              name="clerkMei"
              control={control}
              label="担当者名"
              required
              placeholder="担当者名"
              errorObj={errors}
            />
            <FormInput<FormData>
              name="clerkSeiKana"
              control={control}
              label="担当者姓（カナ）"
              required
              placeholder="タントウシャセイ"
              errorObj={errors}
            />
            <FormInput<FormData>
              name="clerkMeiKana"
              control={control}
              label="担当者名（カナ）"
              required
              placeholder="タントウシャメイ"
              errorObj={errors}
            />
            <FormInput<FormData>
              name="clerkTel"
              control={control}
              label="担当者電話番号"
              placeholder="090-1234-5678"
              errorObj={errors}
            />
            <FormInput<FormData>
              name="clerkMobileTel"
              control={control}
              label="担当者携帯電話番号"
              placeholder="090-1234-5678"
              errorObj={errors}
            />
            <FormInput<FormData>
              name="email"
              control={control}
              label="メールアドレス"
              required
              placeholder="メールアドレス"
              errorObj={errors}
            />
            <FormInput<FormData>
              name="zipCode"
              control={control}
              label="郵便番号"
              required
              placeholder="123-4567"
              errorObj={errors}
            />
            <FormInput<FormData>
              name="prefecture"
              control={control}
              label="都道府県"
              required
              placeholder="東京都"
              errorObj={errors}
            />
            <FormInput<FormData>
              name="address1"
              control={control}
              label="住所1"
              required
              placeholder="中央区"
              errorObj={errors}
            />
            <FormInput<FormData>
              name="address2"
              control={control}
              label="住所2"
              required
              placeholder="日本橋蛎殻町1-14-14"
              errorObj={errors}
            />
            <FormInput<FormData>
              name="address3"
              control={control}
              label="住所3"
              placeholder="xxxビルxxx号室"
              errorObj={errors}
            />
            <Box
              sx={{
                width: '100%',
                padding: 1,
                marginBottom: 1,
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                ▼請求先情報
              </Typography>
              <FormCheckbox<FormData>
                name="isSameBillingInfo"
                control={control}
                label="上記と同じ"
                errorObj={errors}
              />
            </Box>

            {!isSameBillingInfo && (
              <>
                <FormInput<FormData>
                  name="billingSei"
                  control={control}
                  label="請求先担当者姓"
                  required
                  placeholder="請求先担当者姓"
                  errorObj={errors}
                />
                <FormInput<FormData>
                  name="billingMei"
                  control={control}
                  label="請求先担当者名"
                  required
                  placeholder="請求先担当者名"
                  errorObj={errors}
                />
                <FormInput<FormData>
                  name="billingSeiKana"
                  control={control}
                  label="請求先担当者姓（カナ）"
                  required
                  placeholder="セイメイ"
                  errorObj={errors}
                />
                <FormInput<FormData>
                  name="billingMeiKana"
                  control={control}
                  label="請求先担当者名（カナ）"
                  required
                  placeholder="セイメイ"
                  errorObj={errors}
                />
                <FormInput<FormData>
                  name="billingZipCode"
                  control={control}
                  label="請求先郵便番号"
                  required
                  placeholder="123-4567"
                  errorObj={errors}
                />
                <FormInput<FormData>
                  name="billingPrefecture"
                  control={control}
                  label="請求先都道府県"
                  required
                  placeholder="東京都"
                  errorObj={errors}
                />
                <FormInput<FormData>
                  name="billingAddress1"
                  control={control}
                  label="請求先住所1"
                  required
                  placeholder="中央区"
                  errorObj={errors}
                />
                <FormInput<FormData>
                  name="billingAddress2"
                  control={control}
                  label="請求先住所2"
                  required
                  placeholder="日本橋蛎殻町1-14-14"
                  errorObj={errors}
                />
                <FormInput<FormData>
                  name="billingAddress3"
                  control={control}
                  label="請求先住所3"
                  placeholder="xxxビルxxx号室"
                  errorObj={errors}
                />
              </>
            )}
            <FormCheckbox<FormData>
              name="agreeTerms"
              control={control}
              label={
                <>
                  <Link
                    href="https://paid.jp/v/contents/pre/guide/rules.jsp#member"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      fontSize: '16px',
                      textDecoration: 'none',
                      display: 'inline',
                    }}
                  >
                    利用規約
                  </Link>
                  に同意してPaid（後払い）に申し込む
                </>
              }
              errorObj={errors}
            />
          </Box>
          <Button
            variant="contained"
            type="submit"
            sx={{ px: 7, fontSize: '14px', color: 'white' }}
            disabled={!agreeTerms || requesting}
          >
            登録する
          </Button>
        </Box>
      </form>
    </FormProvider>
  )
}

export default PaidRegisterMemberForm
