import CloseIcon from '@mui/icons-material/Close'
import { Box, Dialog, DialogTitle, Grid, IconButton } from '@mui/material'
import useSelectedKeyword from 'hooks/useSelectedKeyword'
import { VFC } from 'react'
import { Topic } from 'types/topic'
import { TopicTweetListSection } from '../TweetDialog'
import { AnalysisCoOccurrences } from '../TweetDialog/TopicTweetDialog/AnalysisCoOccurrences'
import { AnalysisRareWords } from '../TweetDialog/TopicTweetDialog/AnalysisRareWords'

type Props = {
  open: boolean
  onClose: () => void
  topic: Topic
}

export const WeightedFilterResultDialog: VFC<Props> = ({
  open,
  onClose,
  topic,
}) => {
  const { selectedKeywordId } = useSelectedKeyword()

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      sx={{ padding: '32px' }}
      open={open}
      onClose={onClose}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 24,
          top: 8,
          zIndex: 1,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>投稿共感度確認</DialogTitle>
      <Box sx={{ padding: '32px' }}>
        <Grid container>
          {topic?.coOccurrences && topic?.coOccurrences.length > 0 && (
            <Grid item xs={12} md={6}>
              <AnalysisCoOccurrences coOccurrences={topic.coOccurrences} />
            </Grid>
          )}
          {topic?.rareWords && topic?.rareWords.length > 0 && (
            <Grid item xs={12} md={6}>
              <AnalysisRareWords rareWords={topic?.rareWords ?? []} />
            </Grid>
          )}
        </Grid>

        {topic?.labelId && (
          <TopicTweetListSection
            shouldDisplayEmpathy={true}
            keywordId={selectedKeywordId}
            topicId={topic.labelId}
          />
        )}
      </Box>
    </Dialog>
  )
}
