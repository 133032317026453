import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { useEffect, useState } from 'react'

export const PCRecommendDialog = () => {
  const [isSpScreen, setIsSpScreen] = useState<boolean>(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)')

    const handleMediaQueryChange = (event: MediaQueryListEvent) => {
      if (event.matches) {
      }
      setIsSpScreen(event.matches)
    }

    setIsSpScreen(mediaQuery.matches)

    mediaQuery.addEventListener('change', handleMediaQueryChange)

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange)
    }
  }, [])

  const handleClose = () => {
    setIsSpScreen(false)
  }

  if (Boolean(process.env.REACT_APP_SP_RELEASE) === true) {
    return null
  }

  return (
    <Dialog open={isSpScreen} onClose={handleClose}>
      <DialogTitle>PCでの利用を推奨しています</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>続ける</Button>
      </DialogActions>
    </Dialog>
  )
}
