import { useMemo } from 'react'

function extractTopicId(path: string): number | undefined {
  const pattern =
    /^\/v2\/themes\/\d+\/keywords\/\d+\/summaries\/\d+\/questionnaires\/(\d+)$/

  const match = path.match(pattern)

  if (match) {
    const res = parseInt(match[1], 10)
    if (isNaN(res)) {
      return undefined
    }
    return res
  }
  return undefined
}

const useTopicIdInQuestionnaires = () => {
  const selectedTopicId = useMemo(
    () => extractTopicId(location.pathname),
    [location.pathname],
  )

  return { selectedTopicId }
}

export default useTopicIdInQuestionnaires
