import { Box, Link as MuiLink, Typography } from '@mui/material'

export const Lotion = () => {
  return (
    <>
      <Typography>このサンプルは「WEBメディア運営者」向けです。</Typography>
      <Typography sx={{ px: 2, fontSize: 'xs' }}>
        <Typography component="li">
          新しい記事を作成するためのネタ探しが大変
        </Typography>
        <Typography component="li">検索順位があがらない</Typography>
        <Typography component="li">予算・時間に余裕がない</Typography>
      </Typography>
      <Typography sx={{ py: 2 }}>
        ViewPersを使えばこれらの課題を簡単に解決できます。
        SEO記事の作成に活用したサンプルをご紹介します。
        <br />
        <br />
        ケーススタディ : 化粧水についての記事作成
      </Typography>
      <Box component="section" sx={{ my: 2 }}>
        <Typography component="h2" sx={{ my: 1, fontWeight: 'bold' }}>
          ステップ1: 簡単にネタ探し
        </Typography>
        <p>
          まずはテーマに「肌 悩み」を入力して、記事のアイデアを探します。
          5分ほどで投稿が集まり、1営業日たつと、属性情報が共感度という形であらわれるので、
          どの層にどれくらいささるテーマなのかが、大体わかります。
          今回は１トピック目の「
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '14px' }}
            component="span"
          >
            アラサーのエイジング悩み解消への期待
          </Typography>
          」が共感度が高く、メディアの狙っている層ともあっていそうなので、こちらのテーマで記事を作成してみます。
        </p>
      </Box>
      <Box component="section" sx={{ my: 2 }}>
        <Typography component="h2" sx={{ my: 1, fontWeight: 'bold' }}>
          ステップ2: 記事用の集計データの取得
        </Typography>
        <p>
          SEO対策としてコンテンツの質を上げることが重要です。独自調査した
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '14px' }}
            component="span"
          >
            定量データ
          </Typography>
          を記事に組み込むことで、信頼性をあげましょう。１分析につき１回、
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '14px' }}
            component="span"
          >
            簡易調査が無料で利用でき
          </Typography>
          、結果はそのまま記事にご利用いただくことが可能です。
          今回はアンチエイジングのお肌の手入れについて聞いてみました。
          アンケート結果は
          <MuiLink
            component="a"
            href="/v2/themes/-2/keywords/-3/summaries/-3/questionnaires/-2"
            sx={{ fontWeight: 'bold' }}
            target="_blank"
          >
            こちら
          </MuiLink>
          <br />
          さらに
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '14px' }}
            component="span"
          >
            化粧水
          </Typography>
          といったようなキーワードで検索して、人気の品や話題を探してみたりしてみるとさらにいい記事が書けそうです。
          サンプル記事はこちらからご覧いただけます。
          <MuiLink
            component="a"
            href="https://viewpers.com/seo-wp"
            sx={{ fontWeight: 'bold' }}
            target="_blank"
          >
            【テスト記事】20代におすすめ！毎日使いたくなるコスパ最強化粧水ランキングTOP10
          </MuiLink>
          <br />
          <br />
          1度の分析は
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '14px' }}
            component="span"
          >
            9,000円
          </Typography>
          で実施でき、たった２つのステップで良質な記事を生み出すことが可能です。
          設定したら待つだけで、さらに最短１営業日で結果が確認できるので、高速に多くの記事を書くことができるのではないでしょうか？
        </p>
        <p>ぜひViewPersをご活用ください。</p>
      </Box>
    </>
  )
}
