import { FormControl, FormLabel, TextField } from '@mui/material'
import React from 'react'
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
} from 'react-hook-form'

interface FormInputProps<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>
  control: Control<TFieldValues>
  label: string
  required?: boolean
  placeholder?: string | undefined
  errorObj: Partial<Record<keyof TFieldValues, FieldError>>
  type?: React.InputHTMLAttributes<HTMLInputElement>['type']
}

const FormInput = <TFieldValues extends FieldValues>({
  name,
  control,
  label,
  required = false,
  placeholder,
  errorObj,
  type = 'text',
}: FormInputProps<TFieldValues>) => {
  return (
    <FormControl
      error={!!errorObj[name]}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 2,
      }}
    >
      <FormLabel
        required={required}
        sx={{ textAlign: 'start', whiteSpace: 'pre' }}
      >
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            size="small"
            type={type}
            placeholder={placeholder}
            variant="outlined"
            error={!!errorObj[name]}
            helperText={errorObj[name]?.message}
            sx={{
              width: '300px',
              '& .MuiInputBase-input': { height: 'auto', padding: '10px 14px' },
            }}
            InputProps={{
              style: {
                fontSize: 16,
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: 16,
              },
            }}
          />
        )}
      />
    </FormControl>
  )
}

export default FormInput
