import { useLatestTourStep } from 'api/getTourHistory'
import { useUpdateV2TourStep } from 'api/updateV2TourStep'
import useIsSpScreen from 'hooks/useIsSpScreen'
import { StartTourGuideDialog as SPStartTourGuideDialog } from 'pages/v2/sp/Tour/components/StartTourGuideDialog'
import { CompleteTourGuideDialog } from 'pages/v2/Tour/components/CompleteTourGuideDialog'
import { StartTourGuideDialog } from 'pages/v2/Tour/components/StartTourGuideDialog'
import { TourGuideStepDialog } from 'pages/v2/Tour/components/TourGuideStepDialog'
import { ReactNode, VFC } from 'react'
import { useV2Tour, V2TourDriverProvider } from './V2TourProvider'

type Props = {
  children: ReactNode
}
export const V2Tour: VFC<Props> = ({ children }) => {
  const isSpScreen = useIsSpScreen()

  if (isSpScreen) return <V2SPTour>{children}</V2SPTour>

  return (
    <V2TourDriverProvider>
      <V2TourInner>{children}</V2TourInner>
    </V2TourDriverProvider>
  )
}

const V2SPTour: VFC<Props> = ({ children }) => {
  const {
    data: { v2TourStep },
    mutate,
  } = useLatestTourStep()

  const { request, requesting } = useUpdateV2TourStep({
    onSuccess: () => {
      mutate()
    },
  })

  const onSubmit = async () => {
    await request({ v2TourStep: 'COMPLETE' })
  }

  return (
    <>
      <SPStartTourGuideDialog
        open={v2TourStep === 'START'}
        disabled={requesting}
        onSubmit={onSubmit}
      />
      {children}
    </>
  )
}

const V2TourInner: VFC<Props> = ({ children }) => {
  const { tourStep, onNext, onPrev, loading } = useV2Tour()

  return (
    <>
      <StartTourGuideDialog
        open={tourStep === 'START'}
        disabled={loading}
        onNext={onNext}
      />
      {['INTRO', 'COLLECTING_WORDS', 'REPORT', 'TOPIC_ANALYSIS'].map(
        (step, index) => (
          <TourGuideStepDialog
            key={step}
            open={tourStep === step}
            disabled={loading}
            onNext={onNext}
            onPrev={onPrev}
            step={index + 1}
          />
        ),
      )}
      <CompleteTourGuideDialog
        open={tourStep === 'POST_ANALYSIS'}
        disabled={loading}
        onNext={onNext}
      />
      {children}
    </>
  )
}
