import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button } from '@mui/material'
import { useChangePassword } from 'api/changePassword'
import { useBanner } from 'hooks/useBanner'
import BaseSnackbar from 'pages/v2/components/BaseSnackbar'
import PasswordField from 'pages/v2/sp/User/PasswordSetting/components/PasswordField'
import { passwordChangeSchema } from 'pages/v2/User/PasswordSetting/scheme'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

type FormData = z.infer<typeof passwordChangeSchema>

type PasswordChangeFormProps = {}

const PasswordChangeForm: React.VFC<PasswordChangeFormProps> = () => {
  const { message, severity, isOpen, showBanner, hideBanner } = useBanner()
  const method = useForm({
    resolver: zodResolver(passwordChangeSchema),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
  })

  const { request } = useChangePassword({
    onSuccess: () => {
      showBanner({
        message: '更新しました。',
        severity: 'success',
      })
    },
    onError: (error) => {
      if (error.response) {
        showBanner({
          message: error.response.data?.errors || '更新に失敗しました。',
          severity: 'error',
        })
      }
    },
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = method

  const onSubmit = async (data: FormData) => {
    await request({
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
      newPasswordConfirmation: data.newPasswordConfirmation,
    })
  }

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <PasswordField
              control={control}
              errors={errors}
              label="現在のパスワード"
              fieldName="currentPassword"
            />
            <PasswordField
              control={control}
              errors={errors}
              label="新しいパスワード"
              fieldName="newPassword"
            />
            <PasswordField
              control={control}
              errors={errors}
              label="新しいパスワード（確認）"
              fieldName="newPasswordConfirmation"
            />
          </Box>
          <Button
            variant="contained"
            type="submit"
            sx={{ px: 7, fontSize: '14px', color: 'white' }}
          >
            更新する
          </Button>
        </Box>
        <BaseSnackbar
          message={message}
          open={isOpen}
          handleClose={hideBanner}
          severity={severity}
          autoHideDuration={60000}
        />
      </form>
    </FormProvider>
  )
}

export default PasswordChangeForm
