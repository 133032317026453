import { Box } from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { TagCloud } from 'react-tagcloud'

type WordCloudProps = {
  data: { value: string; count: number }[]
  height?: number
}

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export const WordCloud: React.FC<WordCloudProps> = (props) => {
  const { data, height } = props

  const query = useQuery()
  const wc_mode = query.get('wc_mode')
  const wc_limit = query.get('wc_limit')

  const luminosity: 'dark' | 'bright' = wc_mode === 'dark' ? 'bright' : 'dark'

  const options = {
    luminosity,
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: height ?? '100%',
        overflow: 'auto',
        background: wc_mode === 'dark' ? '#3e3833' : '#fff',
      }}
    >
      <TagCloud
        minSize={12}
        maxSize={48}
        tags={data.slice(0, Number(wc_limit ?? 50))}
        colorOptions={options}
        className="simple-cloud"
      />
    </Box>
  )
}

export default WordCloud
