import { Typography } from '@mui/material'
import { RatingLabel } from 'pages/v2/components/common/RatingLabel'
import { FC } from 'react'
import { AnalysisSummaryRating } from 'types/analysisSummaryIdea'

type Props = (
  | { isPublic: false }
  | {
      isPublic: true
      title: string
    }
) & {
  rating?: AnalysisSummaryRating
}

const AnalysisSummaryIdeaTitle: FC<Props> = ({ rating, ...rest }) => {
  return (
    <>
      <Typography fontWeight="bold">
        {rest.isPublic
          ? rest.title
          : '解析中です、しばらくお待ち下さい。約1〜2営業日で表示されます。'}
      </Typography>
      {rating && <RatingLabel rating={rating} />}
    </>
  )
}

export default AnalysisSummaryIdeaTitle
