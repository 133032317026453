import { Box, Link, Typography } from '@mui/material'
import { useCurrentUser } from 'api/getCurrentUser'
import { PAID_NOT_AVAILABLE, PAID_PENDING } from 'enums/PaidMemberStatus'
import { useDisclosure } from 'hooks/useDisclosure'
import PaidPaymentConfirmationDialog from 'pages/v2/sp/checkout/components/PaidPaymentConfirmationDialog'
import PurchaseButton from 'pages/v2/sp/checkout/components/PurchaseButton'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

type PurchaseButtonProps = {
  onPurchaseViaStripe: () => void
  onPurchaseViaPaid: () => void
  requesting: boolean
  paidMemberStatus: 1 | 2 | 3 | 4 | null
  isProcessing: boolean
  onMoveToPaidSetting: () => void
  product: {
    title: string
    priceWithTax: number
  }
}

const PaymentButtons: React.VFC<PurchaseButtonProps> = ({
  onPurchaseViaStripe,
  onPurchaseViaPaid,
  requesting,
  paidMemberStatus,
  isProcessing,
  onMoveToPaidSetting,
  product,
}) => {
  const { currentUser } = useCurrentUser()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const isPaymentProcessing = requesting || isProcessing

  return (
    <Box sx={{ width: '100%', margin: '50px auto 0' }}>
      <Typography
        variant="body2"
        sx={{ color: '#000', fontWeight: 'bold', fontSize: '15px', mb: 1 }}
      >
        購入に進まれる方は決済方法を選択してください。
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <PurchaseButton
          onPurchase={onPurchaseViaStripe}
          requesting={isPaymentProcessing}
          label="クレジットカードで支払いを行う"
        />
        <PurchaseButton
          onPurchase={onOpen}
          requesting={requesting}
          label="Paid（後払い）決済"
          disabled={
            !currentUser?.paid_id ||
            paidMemberStatus === PAID_PENDING ||
            paidMemberStatus === PAID_NOT_AVAILABLE ||
            isPaymentProcessing
          }
          subText="請求書払い・口座振替などはこちら"
        />

        <PaidPaymentConfirmationDialog
          open={isOpen}
          onClose={onClose}
          onConfirm={onPurchaseViaPaid}
          product={product}
          disabled={isPaymentProcessing}
        />

        <>
          {paidMemberStatus === PAID_PENDING ? (
            <Typography
              variant="body2"
              sx={{
                color: '#000',
                fontWeight: 'bold',
                fontSize: '16px',
                mt: 2,
              }}
            >
              Paid（後払い）審査中です。審査完了次第ご利用いただけます。 <br />
              最短15分~3日 お急ぎの場合
              <RouterLink
                to="/v2/themes/settings/contact"
                style={{
                  color: '#F39422',
                  display: 'inline',
                }}
              >
                こちら
              </RouterLink>
              までご連絡ください。
            </Typography>
          ) : paidMemberStatus === PAID_NOT_AVAILABLE ? (
            <Typography
              variant="body2"
              sx={{
                color: '#000',
                fontWeight: 'bold',
                fontSize: '16px',
                mt: 2,
              }}
            >
              Paid（後払い）決済は現在ご利用いただけません
            </Typography>
          ) : paidMemberStatus === null ? (
            <Typography
              variant="body2"
              sx={{
                color: '#000',
                fontWeight: 'bold',
                fontSize: '16px',
                mt: 2,
              }}
            >
              Paid（後払い）決済をご利用いただくには、Paid（後払い）アカウントの登録が必要です。
              <br />
              まだの方は
              <Link
                component="button"
                variant="body2"
                fontSize="16px"
                fontWeight="bold"
                onClick={onMoveToPaidSetting}
              >
                こちら
              </Link>
            </Typography>
          ) : null}
        </>
      </Box>
    </Box>
  )
}

export default PaymentButtons
