import { useCheckPaidMemberStatus } from 'api/checkPaidMemberStatus'
import { CreateQuestionnaireV2Input } from 'api/createQuestionnaire'
import { useCurrentUser } from 'api/getCurrentUser'
import { useRegisterPaidMember } from 'api/registerMember'
import PAID_MEMBER_STATUS from 'enums/PaidMemberStatus'
import { useBanner } from 'hooks/useBanner'
import useFrdKey from 'hooks/useFrdKey'
import { FormData as SummaryFormData } from 'pages/v2/KeywordDetail/components/Summary/CreateSummary/CreateSummaryDialog'
import { FormData as SurveyFormData } from 'pages/v2/KeywordDetail/components/WeightedFilter/WeightedFilterForm'
import { FormData as KeywordFormData } from 'pages/v2/NewKeyword/components/NewKeywordForm'
import { paidRegisterMemberScheme } from 'pages/v2/PaidRegisterMember/scheme'
import { V2Paths } from 'pages/v2/paths'
import { useEffect, useState } from 'react'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { z } from 'zod'

type FormData = z.infer<typeof paidRegisterMemberScheme>
const usePaidSetting = () => {
  const [status, setStatus] = useState<string>('')
  const {
    message,
    severity,
    isOpen: isOpenBanner,
    showBanner,
    hideBanner,
  } = useBanner()
  const { currentUser } = useCurrentUser()
  const location = useLocation<{
    from?: string
    keywordFormData?: KeywordFormData
    summaryFormData?: SummaryFormData
    questionnaireFormData?: CreateQuestionnaireV2Input
    surveyFormData?: SurveyFormData
  }>()
  const history = useHistory()
  const frdKey = useFrdKey()
  const { request, requesting } = useRegisterPaidMember({
    onSuccess: () => {
      showBanner({
        message: 'Paid（後払い）への申し込みが完了しました。',
        severity: 'success',
      })

      setTimeout(() => {
        if (location.state?.from) {
          history.push({
            pathname: location.state.from,
            state: {
              keywordFormData: location.state.keywordFormData,
              summaryFormData: location.state.summaryFormData,
              questionnaireFormData: location.state.questionnaireFormData,
              surveyFormData: location.state.surveyFormData,
            },
          })
        } else {
          history.push(generatePath(V2Paths.UserSetting))
        }
      }, 2000)
    },
    onError: (error) => {
      if (error.response?.data?.errors) {
        showBanner({ message: error.response?.data?.errors, severity: 'error' })
        return
      } else {
        showBanner({ message: 'エラーが発生しました', severity: 'error' })
      }
    },
  })

  const {
    request: requestCheckPaidMemberStatus,
    requesting: requestingCheckPaidMemberStatus,
  } = useCheckPaidMemberStatus({
    onSuccess: ({ status }) => {
      setStatus(PAID_MEMBER_STATUS[status])
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const isRegistered = Boolean(currentUser?.paid_id)

  const onSubmit = async (data: FormData) => {
    if (!currentUser) {
      return
    }

    if (!frdKey) {
      showBanner({
        message:
          'Paidのキーの読み込みに失敗しました。再度リロードするか、時間をおいて再度お試しください。',
        severity: 'error',
      })
    }

    await request({
      companyName: data.companyName,
      companyNameKana: data.companyNameKana,
      zipCode: data.zipCode,
      prefecture: data.prefecture,
      address1: data.address1,
      address2: data.address2,
      address3: data.address3,
      tel: data.tel,
      representativeSei: data.representativeSei,
      representativeMei: data.representativeMei,
      representativeSeiKana: data.representativeSeiKana,
      representativeMeiKana: data.representativeMeiKana,
      clerkSei: data.clerkSei,
      clerkMei: data.clerkMei,
      clerkSeiKana: data.clerkSeiKana,
      clerkMeiKana: data.clerkMeiKana,
      clerkTel: data.clerkTel,
      clerkMobileTel: data.clerkMobileTel,
      email: data.email,
      frdKey,
      billingSei: data.billingSei,
      billingMei: data.billingMei,
      billingSeiKana: data.billingSeiKana,
      billingMeiKana: data.billingMeiKana,
      billingZipCode: data.billingZipCode,
      billingPrefecture: data.billingPrefecture,
      billingAddress1: data.billingAddress1,
      billingAddress2: data.billingAddress2,
      billingAddress3: data.billingAddress3,
    })
  }

  useEffect(() => {
    requestCheckPaidMemberStatus()
  }, [])

  return {
    requestingCheckPaidMemberStatus,
    isRegistered,
    onSubmit,
    requesting,
    message,
    isOpenBanner,
    hideBanner,
    severity,
    status,
  }
}

export default usePaidSetting
