import LaunchIcon from '@mui/icons-material/Launch'
import { Box, Link } from '@mui/material'
import { V2Tweet } from 'types/tweet'
import { v2DateFormatter } from 'util/dateFormatter'
import { EmpathyFilterDetailButton } from './EmpathyFilterDetai'

type Props = {
  shouldDisplayEmpathy: boolean
  tweet: V2Tweet
}

export const TweetCard = ({ shouldDisplayEmpathy, tweet }: Props) => {
  return (
    <>
      <Box
        sx={{
          mt: 1.25,
          px: 2.5,
          py: 1.25,
          border: '1px solid #ccdae5',
          borderRadius: '10px',

          '&:first-child': {
            mt: '0px',
          },
        }}
      >
        <Box
          sx={{
            lineHeight: 1,
            display: 'flex',
            margin: '0 -5px',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Box
            sx={{
              color: '#9697af',
              fontSize: 12,
            }}
          >
            {tweet.tweetAt ? v2DateFormatter(tweet.tweetAt) : ''}
          </Box>
          {tweet.url && (
            <Link
              sx={{
                marginLeft: 'auto',
              }}
              underline="none"
              target="_blank"
              rel="noreferrer"
              href={tweet.url}
            >
              <LaunchIcon />
            </Link>
          )}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {shouldDisplayEmpathy && tweet.empathies.length > 0 && (
            <Box
              sx={{
                minWidth: '48px',
                width: '50px',
                display: 'flex',
                gap: 2,
                overflowX: 'scroll',
              }}
            >
              {tweet.empathies.map((empathy) => (
                <EmpathyFilterDetailButton
                  key={`${tweet.tweetId}-${empathy.id}`}
                  empathy={empathy}
                  tweetText={tweet.text}
                  empathies={tweet.empathies}
                />
              ))}
            </Box>
          )}

          <Box
            sx={{
              overflowWrap: 'break-word',
              wordBreak: 'break-word',
              mt: 1.25,
            }}
          >
            {tweet.text}
          </Box>
        </Box>
      </Box>
    </>
  )
}
