import { Grid } from '@mui/material'
import ContactInfoCard from 'pages/v2/V2Home/components/ContractInfoCard'
import { NotificationCard } from './components'
import AnalysisResultList from './components/AnalysisResultList'

export const V2HomePage = () => {
  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{
        backgroundColor: '#F5F5F5',
        px: '58px',
        py: '136px',
        gap: {
          xs: 2,
          md: 1,
        },
      }}
    >
      <Grid item xs={12} md={7}>
        <NotificationCard />
      </Grid>
      <Grid item xs={12} md={4.8}>
        <ContactInfoCard />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          mt: {
            xs: 0,
            md: 4,
          },
        }}
      >
        <AnalysisResultList />
      </Grid>
    </Grid>
  )
}
