import { Alert, Portal, Snackbar } from '@mui/material'
import React from 'react'

type BaseSnackbarProps = {
  message: string
  open: boolean
  handleClose: () => void
  severity?: 'success' | 'error' | 'warning' | 'info'
  autoHideDuration?: number
}

const BaseSnackbar: React.FC<BaseSnackbarProps> = ({
  message,
  open,
  handleClose,
  severity = 'success',
  autoHideDuration = 6000,
}) => {
  return (
    <Portal>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={autoHideDuration}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: '100%', fontSize: 15 }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Portal>
  )
}

export default BaseSnackbar
