import { CreateQuestionnaireV2Input } from 'api/createQuestionnaire'
import { FormData as SummaryFormData } from 'pages/v2/KeywordDetail/components/Summary/CreateSummary/CreateSummaryDialog'
import { FormData as SurveyFormData } from 'pages/v2/KeywordDetail/components/WeightedFilter/WeightedFilterForm'
import { FormData as KeywordFormData } from 'pages/v2/NewKeyword/components/NewKeywordForm'
import { FormData as SpKeywordFormData } from 'pages/v2/sp/NewKeyword/components/NewKeywordForm'
import { OrderProcessingData } from 'types/order'

type ParameterTypes = NonNullable<OrderProcessingData['parameters']>['type']
type ExtractParameterType<T extends ParameterTypes> =
  T extends 'checkout.questionnaire'
    ? CreateQuestionnaireV2Input
    : T extends 'checkout.keyword'
      ? KeywordFormData
      : T extends 'checkout.summary'
        ? SummaryFormData
        : T extends 'checkout.survey'
          ? SurveyFormData
          : never

const getValueFromOrderProcessingData = <K extends ParameterTypes>({
  orderProcessingData,
  type,
}: {
  orderProcessingData: OrderProcessingData | null
  type: K
}): ExtractParameterType<K> | undefined => {
  if (!orderProcessingData || !orderProcessingData.parameters) {
    return
  }

  const data = orderProcessingData.parameters

  if (data.type !== type) {
    return
  }

  return data.params as ExtractParameterType<K>
}

type SPExtractParameterType<T extends ParameterTypes> =
  T extends 'checkout.questionnaire'
    ? CreateQuestionnaireV2Input
    : T extends 'checkout.keyword'
      ? SpKeywordFormData
      : T extends 'checkout.summary'
        ? SummaryFormData
        : T extends 'checkout.survey'
          ? SurveyFormData
          : never

export const getValueFromOrderProcessingDataForSP = <K extends ParameterTypes>({
  orderProcessingData,
  type,
}: {
  orderProcessingData: OrderProcessingData | null
  type: K
}): SPExtractParameterType<K> | undefined => {
  if (!orderProcessingData || !orderProcessingData.parameters) {
    return
  }

  const data = orderProcessingData.parameters

  if (data.type !== type) {
    return
  }

  return data.params as SPExtractParameterType<K>
}

export default getValueFromOrderProcessingData
