import { createTheme } from '@mui/material'
import { color } from 'html2canvas/dist/types/css/types/color'

export const muiTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          input: {
            background: 'transparent',
            fontWeight: 'bold',
            border: 'none',
            padding: '8px',
            boxSizing: 'content-box',
          },
        },
      },
    },
    // Global styles
    MuiCssBaseline: {
      styleOverrides: {
        button: {
          cursor: 'pointer',
        },

        img: {
          maxWidth: '100%',
        },

        '.MuiBox-root': {
          fontSize: '14px',
        },
      },
    },
  },
  palette: {
    primary: {
      dark: '#FF6600',
      main: '#FF8800',
      light: '#FF9900',
      '50': 'FFFF00',
      contrastText: '#fff',
    },
    secondary: {
      main: '#6C00F8',
      light: '#F4EAFF',
    },
  },
  typography: {
    fontFamily: [
      'Noto Sans JP',
      '游ゴシック体',
      'YuGothic',
      '游ゴシック',
      'Yu Gothic',
      'ヒラギノ角ゴ Pro W3',
      'Hiragino Kaku Gothic Pro',
      'メイリオ',
      'Meiryo',
      'Osaka',
      'sans-serif',
    ].join(','),
  },
})
