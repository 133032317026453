import {
  ConfirmPaymentsByPaidInput,
  ConfirmPaymentsByPaidPayload,
} from 'api/checkoutByPaid/confirmPaymentsByPaid'
import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

type ConfirmKeywordWithThemePaymentsByPaidInput = ConfirmPaymentsByPaidInput & {
  analysisThemeId: number | undefined
}

export const confirmKeywordWithThemePaymentsByPaid = async (
  input: ConfirmKeywordWithThemePaymentsByPaidInput,
) => {
  const { orderId } = input
  const response = await axios.post<ConfirmPaymentsByPaidPayload>(
    `/api/keywords/confirm_payments`,
    {
      order_id: orderId,
    },
  )
  return response.data
}

export const useConfirmKeywordWithThemePaymentsByPaid = (
  option?: UseMutationOption<ConfirmPaymentsByPaidPayload>,
) => useMutation(confirmKeywordWithThemePaymentsByPaid, option)
