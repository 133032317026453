import { Box, Button, Grid, Typography } from '@mui/material'
import { V2Paths } from 'pages/v2/paths'
import { NotificationCard } from 'pages/v2/sp/V2Home/components'
import AnalysisResultList from 'pages/v2/sp/V2Home/components/AnalysisResultList'
import { generatePath, Link } from 'react-router-dom'

export const V2SPHomePage = () => {
  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{
        py: 3,
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          mb: 4,
        }}
      >
        <NotificationCard />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          mb: 4,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontSize: '14px',
            color: '#3D3E3F',
            mb: 3,
            fontWeight: 'bold',
          }}
        >
          分析
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Link to={generatePath(V2Paths.SPNewKeyword)}>
            <Button
              variant="contained"
              sx={{
                px: 7,
                fontSize: '14px',
                color: 'white',
                borderRadius: '20px',
                width: '250px',
              }}
            >
              新規分析
            </Button>
          </Link>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <AnalysisResultList />
      </Grid>
    </Grid>
  )
}
