import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete'
import '@webscopeio/react-textarea-autocomplete/style.css'
import { useApiContext } from 'App'
import axios from 'axios'
import { useEffect, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

const SideMemoTextarea = (props) => {
  const api = useApiContext()
  const url = api.host + api.hashtag_list_api

  const [memo, setMemo] = useState(props.memo)
  const [isEdit, setIsEdit] = useState(props.isEdit)
  useEffect(() => {
    setMemo(props.memo)
    setIsEdit(props.isEdit)
  })

  const Item = ({ entity: { name } }) => (
    <div>
      <small>#{name}</small>
    </div>
  )
  const Loading = () => (
    <div>
      <small>Loading</small>
    </div>
  )
  const keydown = (index, e) => {
    if (e.code === 'ArrowDown') {
      if (e.target.value.slice(currentPos).indexOf('\n') < 0) {
        for (let i = index + 1; i < memo.length; i++) {
          if (textareas[i] !== undefined) {
            textareas[i].textareaRef.focus()
            textareas[i].setCaretPosition(0)
            break
          }
        }
      }
    }
    if (e.code === 'ArrowUp') {
      if (
        e.target.value.slice(currentPos).split('\n').length ==
        e.target.value.split('\n').length
      ) {
        for (let i = index - 1; i >= 0; i--) {
          if (textareas[i] !== undefined) {
            textareas[i].textareaRef.focus()
            textareas[i].setCaretPosition(0)
            break
          }
        }
      }
    }
  }
  const onChangeText = (index, e) => {
    if (textareas[index] === undefined) return
    const newMemo = memo.concat()
    newMemo[index] = e.target.value
    setMemo(newMemo)
    props.onChange(newMemo)
  }

  let textareas = {}

  let currentPos = 0
  const change = (num) => {
    currentPos = num
  }
  // const onFocus = (index) => {
  //   console.log(index);
  //   setCurrentTextareaIndex(index);
  //   textareas[currentTextareaIndex].setCaretPosition(0);
  // };

  return (
    <>
      <label className={`c-globalMemoTextarea ${isEdit ? 'is-edit' : ''}`}>
        {memo &&
          memo.map((memoText, index) => {
            if (/^\[(.*)\]\((.*)\)$/.test(memoText)) {
              const matches = /^\[(.*)\]\((.*)\)$/.exec(memoText)
              return (
                <a
                  className="c-globalMemoTextarea__imageLink"
                  href={matches[2]}
                  target="_blank"
                  key={index}
                  rel="noreferrer"
                >
                  {matches[1]}
                </a>
              )
            } else {
              return (
                <ReactTextareaAutocomplete
                  key={index}
                  ref={(x) => {
                    textareas[index] = x
                  }}
                  value={memoText}
                  onKeyDown={keydown.bind(this, index)}
                  // onFocus={onFocus.bind(this, index)}
                  onCaretPositionChange={change}
                  onChange={onChangeText.bind(this, index)}
                  dropdownStyle={{
                    zIndex: 100,
                    borderRadius: '15px',
                  }}
                  listStyle={{
                    borderRadius: '5px',
                  }}
                  trigger={{
                    '#': {
                      dataProvider: async (token) => {
                        const res = await axios.get(url, {
                          params: {
                            tag: token,
                          },
                        })
                        return res.data.hashtags
                          .filter((x) => {
                            return !/^![^:]*:/.test(x)
                          })
                          .map((x) => {
                            return { name: x }
                          })
                      },
                      component: Item,
                      output: (item, trigger) => `#${item.name}`,
                    },
                  }}
                  minChar={0}
                  loadingComponent={Loading}
                  textAreaComponent={TextareaAutosize}
                />
              )
            }
          })}
        <div className="c-globalMemoTextarea__btn--save">
          <div
            onClick={props.onCancel}
            className={`c-btn__link c-btn__link--size-ss ${
              !isEdit ? 'u-dnone' : ''
            }`}
            style={{
              cursor: 'pointer',
            }}
          >
            キャンセル
          </div>
          <div
            onClick={props.onSave}
            className={`c-btn__link c-btn__link--size-ss ${
              !isEdit ? 'u-ml0' : 'u-ml20'
            }`}
            style={{
              cursor: 'pointer',
            }}
          >
            {isEdit ? '上書き保存' : '保存'}
          </div>
        </div>
      </label>
    </>
  )
}

export default SideMemoTextarea
