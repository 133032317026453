import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@mui/material'
import { useCurrentUser } from 'api/getCurrentUser'
import useDailyDialog from 'hooks/useDailyDialog'
import BaseDialog from 'includes/BaseDialog'

const V2UpgradeSuggestionDialog = () => {
  const { currentUser } = useCurrentUser()
  const { isDialogOpen, setIsDialogOpen } = useDailyDialog(
    'lastDialogDisplayTimestamp',
    Boolean(currentUser && !currentUser.v2_enabled),
  )

  const handleClose = () => {
    setIsDialogOpen(false)
  }

  const handleApply = () => {
    const applyPage = 'https://lp.viewpers.com/form01'
    window.open(applyPage, '_blank')
    handleClose()
  }

  return (
    <BaseDialog
      open={isDialogOpen}
      onClose={handleClose}
      title="バージョンアップのお知らせ"
    >
      <DialogContent>
        <DialogContentText>
          <Typography
            sx={{
              pb: 1.5,
              fontWeight: 'bold',
              fontSize: 14,
            }}
          >
            ご利用いただきありがとうございます。
          </Typography>
          <Typography
            sx={{
              pb: 1.5,
              fontWeight: 'bold',
              fontSize: 14,
            }}
          >
            AIによるサマリー機能やレポート機能などが追加された
            <br />
            新しいViewPersをご利用いただけます。
          </Typography>
          <Typography
            sx={{
              pb: 1.5,
              fontWeight: 'bold',
              fontSize: 14,
            }}
          >
            ご利用には切り替えが必要になりますので、
            <br />
            リンク先のページより申請ください 。
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Button
          onClick={handleApply}
          color="primary"
          variant="contained"
          sx={{
            color: 'white',
            fontSize: 14,
          }}
        >
          新バージョンを利用申請ページへ
        </Button>
        <Button
          onClick={handleClose}
          color="primary"
          sx={{
            fontSize: 14,
          }}
        >
          閉じる
        </Button>
      </DialogActions>
    </BaseDialog>
  )
}

export default V2UpgradeSuggestionDialog
