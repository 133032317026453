import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

type BaseRecordLogInput<T extends string, P extends {}> = {
  event: T
  parameters: P
}

type RecordLogInput = {
  userId: number
} & (
  | RecordCheckoutDialogOpenInput
  | RecordFirstThemeOpenInput
  | RecordSampleOpenInput
)

type RecordCheckoutDialogOpenInput = BaseRecordLogInput<
  | 'keyword_checkout_dialog_open'
  | 'questionnaire_checkout_dialog_open'
  | 'summary_checkout_dialog_open'
  | 'survey_checkout_dialog_open',
  {
    checkout_dialog_open_at: string
  }
>

// テーマ作成の初回表示日時
type RecordFirstThemeOpenInput = BaseRecordLogInput<
  'first_theme_open',
  {
    first_theme_open_at: string
  }
>

// サンプルの表示日時
type RecordSampleOpenInput = BaseRecordLogInput<
  'sample_open',
  {
    sample_open_at: string
    theme_name: string
  }
>

export const recordLog = async (input: RecordLogInput) => {
  await axios.post<void>('/api/record_logs', {
    event: input.event,
    parameters: input.parameters,
    user_id: input.userId,
  })
}

export const useRecordLog = (option?: UseMutationOption<void>) =>
  useMutation(recordLog, option)
