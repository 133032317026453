import { createPortal } from 'react-dom'

const PopupProvider = ({ children, ...props }) => {
  return (
    <>
      {children}
      <div id="popup-container"></div>
    </>
  )
}

export const PopupPortal = ({ children, ...props }) => {
  return createPortal(children, document.getElementById('popup-container'))
}

export default PopupProvider
