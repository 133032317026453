import { Box, List, ListItem, Typography } from '@mui/material'
import { choiceColor } from 'pages/v2/KeywordDetail/components/Questionnaire/Result/choiceColor'
import { VFC } from 'react'

type LineChartProps = {
  answers: {
    selection: string
    rate: number
    count: number
  }[]
}

const LineChart: VFC<LineChartProps> = ({ answers }) => {
  answers.sort((a, b) => {
    if (a.rate > b.rate) return -1
    if (a.rate < b.rate) return 1
    return 0
  })
  const labels = answers.map((answer, index) => {
    return {
      label: answer.selection,
      rate: answer.rate,
      count: answer.count + '件',
      color: choiceColor(index),
    }
  })
  return (
    <List
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: 1,
      }}
    >
      {labels.map((item, index) => {
        return (
          <ListItem
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxWidth: '100px',
              p: 0,
            }}
          >
            <Box
              sx={{
                margin: '0 auto',
                paddingTop: 3.5,
                width: '44px',
                height: '175px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
            >
              <Typography
                component="p"
                sx={{
                  fontSize: 12,
                  textAlign: 'center',
                  pb: 1,
                  ...(index === 0 && {
                    color: item.color,
                  }),
                }}
              >
                {item.count}
              </Typography>
              <Box
                sx={{
                  height: `${item.rate}%`,
                  backgroundColor: item.color,
                }}
              ></Box>
            </Box>
            <Typography
              component="p"
              sx={{
                mt: 2.5,
                textAlign: 'center',
                fontSize: 12,
              }}
            >
              {item.label}
            </Typography>
          </ListItem>
        )
      })}
    </List>
  )
}

export default LineChart
