import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

type CheckPaidMemberStatusPayload = {
  status: '1' | '2' | '3' | '4'
}

export const checkPaidMemberStatus = async () => {
  const response = await axios.post<CheckPaidMemberStatusPayload>(
    '/api/paid/check_member_statuses',
    {},
  )

  return response.data
}

export const useCheckPaidMemberStatus = (
  option?: UseMutationOption<CheckPaidMemberStatusPayload>,
) => useMutation(checkPaidMemberStatus, option)
