import useSelectedKeyword from 'hooks/useSelectedKeyword'
import { useMemo } from 'react'

function extractSummaryId(path: string): number | undefined {
  const pattern =
    /\/v2\/themes\/-?\d+\/keywords\/-?\d+\/summaries\/(-?\d+)(\/|$)/

  // 正規表現でpathをテスト
  const match = path.match(pattern)

  if (match) {
    const res = parseInt(match[1], 10)
    if (isNaN(res)) {
      return undefined
    }
    return res
  }
  return undefined
}

const useSelectedSummary = () => {
  const { selectedKeyword } = useSelectedKeyword()
  const selectedSummaryId = useMemo(
    () => extractSummaryId(location.pathname),
    [location.pathname],
  )

  const selectedSummary = useMemo(
    () =>
      selectedKeyword?.summaries?.find(
        (summary) => summary.id === selectedSummaryId,
      ),
    [selectedKeyword, selectedSummaryId],
  )

  const isDemo = useMemo(
    () => (selectedSummaryId === undefined ? false : selectedSummaryId < 0),
    [selectedSummaryId],
  )

  return { selectedSummary, selectedSummaryId, isDemo }
}

export default useSelectedSummary
