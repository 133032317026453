type PaginateLink = {
  first: string
  last: string
  next: string | null
  prev: string | null
}

type PaginateMetaResponse = {
  current_page: number
  from: number
  last_page: number
  links: {
    url: string | null
    label: string
    active: boolean
  }[]
  path: string
  per_page: number
  to: number
  total: number
}

type PaginateMeta = {
  currentPage: number
  from: number
  lastPage: number
  links: {
    url: string | null
    label: string
    active: boolean
  }[]
  path: string
  perPage: number
  to: number
  total: number
}

export type PaginateTypeResponse<T> = {
  data: T[]
  links: PaginateLink
  meta: PaginateMetaResponse
}

export type PaginateType<T> = {
  data: T[]
  links: PaginateLink
  meta: PaginateMeta
}

export const parsePaginate = <T, U>(
  input: PaginateTypeResponse<T>,
  parse: (input: T) => U,
): PaginateType<U> => {
  return {
    data: input.data.map(parse),
    links: input.links,
    meta: {
      ...input.meta,
      currentPage: input.meta.current_page,
      lastPage: input.meta.last_page,
      perPage: input.meta.per_page,
    },
  }
}
