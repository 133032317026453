import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, Breadcrumbs, Typography } from '@mui/material'
import { useGetAnalysisThemes } from 'api/getAnalysisThemes'
import { useUpdateSummary } from 'api/updateSummary'
import useSelectedKeyword from 'hooks/useSelectedKeyword'
import useSelectedSummary from 'hooks/useSelectedSummary'
import useSelectedTheme from 'hooks/useSelectedTheme'
import useTopicIdInQuestionnaires from 'hooks/useTopicIdInQuestionnaires'
import { ToggleInputForm } from 'includes/form/ToggleInputForm'
import { V2Paths } from 'pages/v2/paths'
import { Link, generatePath } from 'react-router-dom'

export const BreadCrumb = () => {
  const { mutate } = useGetAnalysisThemes({})
  const { selectedThemeId } = useSelectedTheme()
  const { selectedSummary } = useSelectedSummary()
  const { selectedKeywordId, selectedKeyword } = useSelectedKeyword()
  const { selectedTopicId } = useTopicIdInQuestionnaires()

  const { request } = useUpdateSummary({
    onSuccess() {
      mutate()
    },
  })

  return (
    <Box
      display="flex"
      alignItems="center"
      fontSize="24px"
      color="#2e2e2e"
      fontWeight="bold"
    >
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="large" />}
      >
        {selectedKeyword && (
          <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>
            {selectedKeyword.targetWords}
          </Typography>
        )}

        {selectedSummary &&
          (selectedTopicId ? (
            <Link
              to={generatePath(V2Paths.Summary, {
                themeId: selectedThemeId,
                keywordId: selectedKeywordId,
                summaryId: selectedSummary.id,
              })}
            >
              <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>
                {selectedSummary.title}
              </Typography>
            </Link>
          ) : (
            <ToggleInputForm
              key={selectedKeywordId}
              label=""
              defaultText={
                selectedSummary.title ??
                selectedSummary.createdAt.toISOString().split('T')[0] ??
                ''
              }
              onSubmit={(text) =>
                request({ id: selectedSummary.id, title: text })
              }
            />
          ))}

        {selectedTopicId && (
          <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>
            アンケートレポート
          </Typography>
        )}
      </Breadcrumbs>
    </Box>
  )
}
