import { Box, Typography } from '@mui/material'
import ContactForm from './components/ContactForm'

const Contact = () => {
  return (
    <Box
      sx={{
        width: '95%',
        display: 'flex',
      }}
    >
      <Typography
        component="dt"
        sx={{
          width: '15%',
        }}
      >
        <strong>お問い合わせ</strong>
      </Typography>
      <Box
        sx={{
          flex: 1,
        }}
      >
        <ContactForm />
      </Box>
    </Box>
  )
}

export default Contact
