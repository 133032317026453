import { Box, Typography } from '@mui/material'

export const Pod = () => {
  return (
    <>
      <Typography>
        プロモーションの行う際やアイデアを企画化するとき、以下のような課題がよくあります：
      </Typography>
      <Typography sx={{ px: 2, fontSize: 'xs' }}>
        <Typography component="li">アイデアを出し尽くした感じがする</Typography>
        <Typography component="li">
          思いつきのアイデアと妥当性があるアイデアの区別がつかないため、企画化するための決定打がない
        </Typography>
      </Typography>
      <Typography sx={{ py: 2 }}>
        この事例ではこれらの課題を解決するために、どのような手順で「鍋
        レシピ」のインサイトを活用していったかご紹介します。
        <br />
        <br />
        鍋つゆを製造販売している食品メーカーが、夏に鈍化しがちな売り上げをアップさせるために利用した事例です。
      </Typography>
      <Box component="section" sx={{ my: 2 }}>
        <Typography component="h2" sx={{ my: 1, fontWeight: 'bold' }}>
          ステップ1: 「鍋 レシピ」の調査
        </Typography>
        <p>
          まず、キーワード「鍋
          レシピ」に関して、普段消費者がどのような鍋の料理に関心があるかを調査しました。
        </p>
        <p>
          サマリーから、多くのユーザーが簡単に作れる調理方法やレシピに関心を持っていることがわかりました。特に圧力鍋や炊飯器を利用したレシピが人気であることが判明しました。
        </p>
      </Box>
      <Box component="section" sx={{ my: 2 }}>
        <Typography component="h2" sx={{ my: 1, fontWeight: 'bold' }}>
          ステップ2: 「鍋 夏」という視点を追加
        </Typography>
        <p>
          次に、元々の目的だった夏の鍋つゆの売り上げ減少への対策のヒントを調べるために、「鍋
          夏」を調査しました。
        </p>
        <p>
          その結果、暑いので 夏には鍋を食べないという声が多かった一方、
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '14px' }}
            component="span"
          >
            敢えて暑い夏に鍋などの熱い料理を食べる
          </Typography>
          トピックも一定数出てきました。
        </p>
      </Box>
      <Box component="section" sx={{ my: 2 }}>
        <Typography component="h2" sx={{ my: 1, fontWeight: 'bold' }}>
          ステップ3: 投稿への共感度付与
        </Typography>
        <p>
          詳細を調べるために、「真夏でも鍋料理が大人気！エアコンの効いた部屋で食べるキムチ鍋や鍋焼きうどんが絶品」の投稿に共感度を付与して、
        </p>
        <p>
          生活者が興味を持つどんな投稿かを確認することにしました。
          <br />
          その中で、下記の投稿が出てきました。
          <br />
          <br />
          「9日(火)8席空いてます🐍 夏でも “鍋もの”食べちゃおう😋
          スープを楽しむ豚肉鍋。 そのままでも美味しいけれど、
          キムチを足すも良し、 ごまダレをかけるも良し、
          自家製ポン酢をかけるも良し… 冷房で冷えて 胃腸が弱ってる方もいるので、
          今週は、鍋もので調子を整えてこ〜🍀」
          <br />
          ここから、冷房で
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '14px' }}
            component="span"
          >
            胃腸を冷やして弱らせてしまった人など
          </Typography>
          、
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '14px' }}
            component="span"
          >
            胃腸を温める目的で 夏に鍋を食べている層
          </Typography>
          がいることがわかりました！
          この検索結果をもとに、鍋料理のプロモーションとして、
          「冷えた体に、鍋つゆの温かさを。」
          というキャッチコピー案を作ることができました。
          <br />
        </p>
      </Box>
      <Box component="section" sx={{ my: 2 }}>
        <Typography component="h2" sx={{ my: 1, fontWeight: 'bold' }}>
          まとめ
        </Typography>
        <Typography sx={{ px: 2, fontSize: 'xs' }}>
          このように、プロモーションのキャッチコピーを作るための仮説づくりでもViewPersが活躍します。
          <br />
          ぜひ試してみてください！
        </Typography>
      </Box>
    </>
  )
}
