import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import { Box } from '@mui/material'
import { AnalysisSummaryRating } from 'types/analysisSummaryIdea'

export const RatingLabel = ({
  rating,
  goodLabel = 'ポジティブな話題',
  badLabel = 'ネガティブな話題',
}: {
  rating: AnalysisSummaryRating
  goodLabel?: string
  badLabel?: string
}) => {
  if (rating === 'good')
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          py: 1,
        }}
      >
        <ThumbUpAltIcon
          sx={{ width: '18px', height: '18px', color: '#37bd17' }}
        />
        <Box
          sx={{
            ml: 0.5,
            fontSize: 12,
            color: '#37bd17',
            fontWeight: 'bold',
          }}
        >
          {goodLabel}
        </Box>
      </Box>
    )
  if (rating === 'bad') {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          py: 1,
        }}
      >
        <ThumbDownAltIcon
          sx={{ width: '18px', height: '18px', color: 'red' }}
        />
        <Box
          sx={{
            ml: 0.5,
            fontSize: 12,
            color: 'red',
            fontWeight: 'bold',
          }}
        >
          {badLabel}
        </Box>
      </Box>
    )
  }
  return <Box sx={{ py: 1 }}></Box>
}
