import CloseIcon from '@mui/icons-material/Close'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import { Box, Button, Card, Fab, IconButton, Typography } from '@mui/material'
import { useDisclosure } from 'hooks/useDisclosure'
import { Dentifrice } from 'pages/v2/KeywordDetail/components/Summary/Detail/TipsForUse/Dentifrice'
import { Lotion } from 'pages/v2/KeywordDetail/components/Summary/Detail/TipsForUse/Lotion'
import { Lunch } from 'pages/v2/KeywordDetail/components/Summary/Detail/TipsForUse/Lunch'
import { Nisa } from 'pages/v2/KeywordDetail/components/Summary/Detail/TipsForUse/Nisa'
import { Pod } from 'pages/v2/KeywordDetail/components/Summary/Detail/TipsForUse/Pod'
import { V2Paths } from 'pages/v2/paths'
import { Link as InternalLink } from 'react-router-dom'

type Props = { themeId: -1 | -2 | -3 | -4 | -5 }
export const TipsForUseButton = ({ themeId }: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultOpen: true,
  })
  return (
    <>
      {isOpen ? (
        <Box
          sx={{
            background: 'rgba(0, 0, 0, 0.5)',
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
          onClick={onClose}
        >
          <Card
            sx={{
              position: 'fixed',
              top: 100,
              right: 15,
              left: 15,
              bottom: 50,
              height: 640,
              zIndex: 1000,
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: 60,
                paddingX: 2,
                background: '#FF6600',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              onClick={onClose}
            >
              <LightbulbIcon sx={{ color: 'white', fontSize: 40 }} />
              <Typography
                variant="h6"
                sx={{ textAlign: 'center', color: 'white' }}
              >
                活用のヒント
              </Typography>
              <IconButton onClick={onClose}>
                <CloseIcon sx={{ color: 'white', fontSize: 24 }} />
              </IconButton>
            </Box>
            <Box
              sx={{
                background: '#fff',
                width: 'full',
                height: '580px',
                p: 2,
                overflowY: 'scroll',
              }}
            >
              {themeId === -1 && <Dentifrice />}
              {themeId === -2 && <Lotion />}
              {themeId === -3 && <Nisa />}
              {themeId === -4 && <Lunch />}
              {themeId === -5 && <Pod />}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  pt: 2,
                }}
              >
                <Button
                  variant="contained"
                  component={InternalLink}
                  to={V2Paths.SPNewKeyword}
                >
                  実際に使ってみる
                </Button>
              </Box>
            </Box>
          </Card>
        </Box>
      ) : (
        <Fab
          sx={{
            position: 'fixed',
            right: 20,
            bottom: 20,
            width: 80,
            height: 80,
          }}
          color="primary"
          aria-label="info"
          onClick={onOpen}
        >
          <LightbulbIcon sx={{ color: 'white', fontSize: 40 }} />
        </Fab>
      )}
    </>
  )
}
