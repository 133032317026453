import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import {
  CreateQuestionnaireV2Input,
  useCreateQuestionnaire,
} from 'api/createQuestionnaire'
import { useCurrentUser } from 'api/getCurrentUser'
import { useRecordLog } from 'api/recordLog'
import { useBanner } from 'hooks/useBanner'
import { useDisclosure } from 'hooks/useDisclosure'
import { useNeedQuestionnaireCheckout } from 'hooks/useNeedCheckout'
import useQuery from 'hooks/useQuery'
import { useScrollHelper } from 'hooks/useScrollHelper'
import useSelectedKeyword from 'hooks/useSelectedKeyword'
import useSelectedSummary from 'hooks/useSelectedSummary'
import useSelectedTheme from 'hooks/useSelectedTheme'
import AnalysisEmpathyAndSummaryContent from 'pages/v2/KeywordDetail/components/AnalysisEmpathyAndSummaryContent'
import AnalysisSummaryIdeaTitle from 'pages/v2/KeywordDetail/components/AnalysisSummaryIdeaTitle'
import { CreateQuestionnairePreviewForm } from 'pages/v2/KeywordDetail/components/Questionnaire/CreateQuestionnaireForm/CreateQuestionnairePreviewForm'
import {
  isValidQuestionnaire,
  questionnaireSchema,
} from 'pages/v2/KeywordDetail/components/Questionnaire/schema'
import QuestionnaireCheckoutDialog from 'pages/v2/checkout/components/QuestionnaireCheckoutDialog'
import BaseSnackbar from 'pages/v2/components/BaseSnackbar'
import { ResearchTargetInputForm } from 'pages/v2/components/ResearchTargetInputForm'
import { V2Paths } from 'pages/v2/paths'
import { Fragment, useCallback, useEffect, useRef } from 'react'
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { generatePath, useHistory } from 'react-router-dom'
import { AgeType } from 'types/age'
import { AnalysisSummaryIdea } from 'types/analysisSummaryIdea'
import { GenderType } from 'types/gender'
import { Survey } from 'types/survey'

type CreateQuestionnaireFormInput = Omit<
  CreateQuestionnaireV2Input,
  'analysisResultId'
>

type Props = {
  summaryIdea: AnalysisSummaryIdea
  importInfo: Survey['importInfo']
  isPublic: boolean
  topicId: string | undefined
  defaultValues?: CreateQuestionnaireFormInput
}
export const CreateQuestionnaireForm = ({
  summaryIdea,
  importInfo,
  isPublic,
  topicId,
  defaultValues,
}: Props) => {
  const { ref: titleRef, handleScroll: handleScrollToTitle } = useScrollHelper()
  const { message, severity, isOpen, showBanner, hideBanner } = useBanner()
  const { currentUser } = useCurrentUser()
  const { request: requestRecordLog } = useRecordLog({})
  const query = useQuery()
  const checkoutMode = query.get('checkout') ? query.get('checkout') : null
  const { selectedThemeId } = useSelectedTheme()
  const { selectedKeywordId } = useSelectedKeyword()
  const { selectedSummaryId } = useSelectedSummary()
  const {
    isOpen: isPreview,
    onOpen: toPreview,
    onClose: toForm,
  } = useDisclosure()
  const {
    isOpen: isOpenQuestionnaire,
    onOpen: onOpenQuestionnaire,
    onClose: onCloseQuestionnaire,
  } = useDisclosure()
  const history = useHistory()
  const needCheckout = useNeedQuestionnaireCheckout(!!checkoutMode)

  const scrollRef = useRef<HTMLFormElement | null>(null)
  const scrollToTop = () => {
    if (scrollRef.current) scrollRef.current.scrollTop = 0
  }

  const methods = useForm<CreateQuestionnaireV2Input>({
    resolver: zodResolver(questionnaireSchema),
    defaultValues: {
      title: defaultValues?.title ?? '',
      researchTarget: {
        gender: defaultValues?.researchTarget?.gender ?? 'both',
        generations: defaultValues?.researchTarget?.generations ?? [],
        prefectures: defaultValues?.researchTarget?.prefectures ?? [],
        specialityPanel:
          defaultValues?.researchTarget?.specialityPanel ?? false,
        note: defaultValues?.researchTarget?.note ?? '',
      },
      questions: defaultValues?.questions
        ? defaultValues?.questions.map((question) => ({
            ...question,
            question: question.question === null ? '' : question.question,
            selections: question.selections.map((selection) =>
              selection === null ? '' : selection,
            ),
          }))
        : Array.from({ length: 5 }).map(() => ({
            question: '',
            selections: [''],
          })),
    },
  })
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = methods

  const { fields } = useFieldArray({
    control,
    name: 'questions',
  })

  const { request, requesting } = useCreateQuestionnaire({
    onSuccess: () => {
      showBanner({
        message: 'アンケートを送信しました',
        severity: 'success',
      })
      setTimeout(() => {
        history.push(
          generatePath(V2Paths.Summary, {
            themeId: selectedThemeId,
            keywordId: selectedKeywordId,
            summaryId: selectedSummaryId,
          }),
        )
      }, 2000)
    },
    onError: () => {
      showBanner({
        message: '分析が完了していないためアンケート調査依頼ができません。',
        severity: 'error',
      })
    },
  })

  const currentResearchTarget = watch('researchTarget')
  const currentQuestions = watch('questions')
  const currentTitle = watch('title')

  const getGender = (gender: GenderType | 'both'): GenderType[] => {
    return gender === 'both' ? ['male', 'female'] : [gender]
  }

  const getAge = (generations: (AgeType | 'All')[]): AgeType[] => {
    return generations.includes('All')
      ? ['10', '20', '30', '40', '50', '60']
      : (generations as AgeType[])
  }

  const onClickSubmit: SubmitHandler<CreateQuestionnaireFormInput> =
    useCallback((data) => {
      if (selectedKeywordId === undefined) return

      const gender = getGender(data.researchTarget.gender)
      const age = getAge(data.researchTarget.generations)

      request({
        ...data,
        target: {
          prefecture: data.researchTarget.prefectures,
          age,
          gender,
        },
        specialityPannel: {
          use: data.researchTarget.specialityPanel ?? false,
          memo: data.researchTarget.note ?? '',
        },
        analysisResultId: selectedKeywordId.toString(),
        topicId,
        questions: data.questions
          .filter(isValidQuestionnaire)
          .map((question) => ({
            ...question,
            selections: question.selections.filter(
              (selection) => selection !== '',
            ),
          })),
      })
    }, [])

  useEffect(() => {
    if (errors.title !== undefined) {
      handleScrollToTitle()
    }
  }, [errors.title])

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(
          needCheckout
            ? async () => {
                onOpenQuestionnaire()
                await requestRecordLog({
                  event: 'questionnaire_checkout_dialog_open',
                  userId: currentUser?.id ?? 0,
                  parameters: {
                    checkout_dialog_open_at: new Date().toISOString(),
                  },
                })
              }
            : onClickSubmit,
        )}
        // browserのdefault validateを抑制する
        noValidate
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '48px',
          overflowY: 'auto',
        }}
        ref={scrollRef}
      >
        {isPreview ? (
          <CreateQuestionnairePreviewForm
            onClickCancel={toForm}
            isLoading={requesting}
            checkoutMode={!!checkoutMode}
          />
        ) : (
          <>
            <SummaryDetailContent
              summaryIdea={summaryIdea}
              importInfo={importInfo}
              isPublic={isPublic}
            />

            <Box>
              <Box
                sx={{
                  display: 'flex',
                  px: 2.5,
                  py: 5,
                  borderWidth: '1px 0',
                }}
                ref={titleRef}
              >
                <FormLabel
                  sx={{ fontSize: 'medium', color: '#000', minWidth: '230px' }}
                >
                  タイトル
                </FormLabel>

                <TextField
                  required
                  size="small"
                  error={errors.title !== undefined}
                  {...register('title')}
                  label="タイトルを入力"
                  variant="outlined"
                  helperText={errors?.title?.message}
                  sx={{
                    flex: '1',
                    '& .MuiInputBase-root > .MuiOutlinedInput-notchedOutline': {
                      fontSize: 'medium',
                    },
                  }}
                  InputLabelProps={{
                    sx: { fontSize: 'medium' },
                  }}
                  inputProps={{
                    style: {
                      fontSize: 'medium',
                      padding: '8px 16px',
                      boxSizing: 'content-box',
                      border: 'none',
                    },
                  }}
                  FormHelperTextProps={{
                    sx: { fontSize: 'small' },
                  }}
                />
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 5,
                  flexDirection: 'column',
                  py: 5,
                }}
              >
                <ResearchTargetInputForm />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  padding: '24px 20px',
                  borderWidth: '1px 0',
                  width: 'full',
                  mt: 5,
                }}
              >
                <FormLabel
                  sx={{ fontSize: 'medium', color: '#000', minWidth: '230px' }}
                >
                  質問内容
                </FormLabel>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    gap: '16px',
                  }}
                >
                  {fields.map((field, index) => {
                    const questionError =
                      errors.questions !== undefined &&
                      index < (errors.questions.length ?? 0)
                        ? errors.questions[index]
                        : undefined
                    return (
                      <Fragment key={field.id}>
                        <FormControl
                          error={questionError !== undefined}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                          }}
                        >
                          <FormLabel
                            sx={{
                              fontSize: 'medium',
                              color: '#000',
                              minWidth: '100px',
                            }}
                          >{`設問${index + 1}`}</FormLabel>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%',
                              rowGap: '16px',
                            }}
                          >
                            <Controller
                              name={`questions.${index}.answerType`}
                              control={control}
                              render={({ field }) => (
                                <FormControl
                                  error={
                                    questionError?.answerType !== undefined
                                  }
                                >
                                  <InputLabel
                                    required
                                    id={`${index}-answer-type-label`}
                                    sx={{
                                      fontSize: 'medium',
                                      color: '#000',
                                      top: -2,
                                    }}
                                  >
                                    設問タイプ
                                  </InputLabel>
                                  <Select
                                    required
                                    {...field}
                                    // prevent using any in here.
                                    defaultValue={'' as any}
                                    label="設問タイプ"
                                    labelId={`${index}-answer-type-label`}
                                    size="medium"
                                    SelectDisplayProps={{
                                      style: {
                                        fontSize: '14px',
                                        minHeight: 'fit-content',
                                      },
                                    }}
                                    sx={{
                                      width: '150px',
                                      flex: '1',
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        fontSize: '14px',
                                      },
                                    }}
                                  >
                                    <MenuItem
                                      value={1}
                                      sx={{ fontSize: 'medium' }}
                                    >
                                      単一回答
                                    </MenuItem>
                                    <MenuItem
                                      value={2}
                                      sx={{ fontSize: 'medium' }}
                                    >
                                      複数回答
                                    </MenuItem>
                                  </Select>
                                  {questionError?.answerType && (
                                    <FormHelperText
                                      error
                                      sx={{ fontSize: 'small' }}
                                    >
                                      設問タイプを選択してください
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              )}
                            />
                            <Controller
                              name={`questions.${index}.question`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  error={questionError?.question !== undefined}
                                  required
                                  size="small"
                                  {...field}
                                  label="設問文を入力"
                                  variant="outlined"
                                  multiline
                                  rows={4}
                                  helperText={
                                    questionError?.question?.message ?? ''
                                  }
                                  sx={{
                                    width: '100%',
                                    '& .MuiInputBase-root > .MuiOutlinedInput-notchedOutline':
                                      {
                                        fontSize: 'medium',
                                      },
                                  }}
                                  InputLabelProps={{
                                    sx: {
                                      fontSize: 'medium',
                                    },
                                  }}
                                  inputProps={{
                                    sx: {
                                      fontSize: 'medium',
                                      boxSizing: 'content-box',
                                    },
                                  }}
                                  FormHelperTextProps={{
                                    sx: {
                                      fontSize: 'small',
                                    },
                                  }}
                                />
                              )}
                            />
                            <Controller
                              name={`questions.${index}.selections`}
                              control={control}
                              render={({ field: fld }) => (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '8px',
                                  }}
                                >
                                  {fld.value.map((selection, selIndex) => (
                                    <Box
                                      key={`${selection}-${selIndex}`}
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <TextField
                                        size="small"
                                        {...register(
                                          `questions.${index}.selections.${selIndex}`,
                                        )}
                                        error={
                                          questionError?.selections !==
                                          undefined
                                        }
                                        defaultValue={selection}
                                        helperText={
                                          selIndex === fld.value.length - 1
                                            ? questionError?.selections?.message
                                            : ''
                                        }
                                        label={`選択肢 ${selIndex + 1}`}
                                        sx={{
                                          width: '100%',
                                          '& .MuiInputBase-root > .MuiOutlinedInput-notchedOutline':
                                            {
                                              fontSize: 'medium',
                                            },
                                        }}
                                        InputLabelProps={{
                                          sx: { fontSize: 'medium' },
                                        }}
                                        inputProps={{
                                          style: {
                                            fontSize: 'medium',
                                            padding: '8px 16px',
                                            boxSizing: 'content-box',
                                            border: 'none',
                                          },
                                        }}
                                        FormHelperTextProps={{
                                          sx: { fontSize: 'small' },
                                        }}
                                      />
                                      {fld.value.length >= 2 && (
                                        <IconButton
                                          onClick={() => {
                                            const updated = [
                                              ...watch(
                                                `questions.${index}.selections`,
                                              ),
                                            ]
                                            updated.splice(selIndex, 1)
                                            fld.onChange(updated)
                                          }}
                                        >
                                          <img
                                            src="/assets/images/icon_trash01.svg"
                                            alt=""
                                            className="c-inlineIcon"
                                          />
                                        </IconButton>
                                      )}
                                    </Box>
                                  ))}
                                  <Button
                                    onClick={() => {
                                      fld.onChange([
                                        ...watch(
                                          `questions.${index}.selections`,
                                        ),
                                        '',
                                      ])
                                    }}
                                    disabled={fld.value.length >= 10}
                                    sx={{ fontSize: '14px' }}
                                  >
                                    選択肢を追加
                                  </Button>
                                </Box>
                              )}
                            />
                          </Box>
                        </FormControl>
                        <FormHelperText error sx={{ fontSize: 'small' }}>
                          {questionError?.message ?? ''}
                        </FormHelperText>
                        {index !== fields.length - 1 && <Divider />}
                      </Fragment>
                    )
                  })}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button
                variant="contained"
                sx={{ px: 2, fontSize: '14px', color: 'white' }}
                onClick={handleSubmit(() => {
                  scrollToTop()
                  toPreview()
                })}
              >
                入力内容を確認
              </Button>
            </Box>
          </>
        )}
        <QuestionnaireCheckoutDialog
          isOpen={isOpenQuestionnaire}
          onClose={onCloseQuestionnaire}
          createQuestionnaireParams={{
            target: {
              prefecture: currentResearchTarget.prefectures,
              gender: getGender(currentResearchTarget.gender),
              age: getAge(currentResearchTarget.generations),
            },
            specialityPannel: {
              use: currentResearchTarget.specialityPanel ?? false,
              memo: currentResearchTarget.note ?? '',
            },
            analysisResultId: selectedKeywordId
              ? selectedKeywordId.toString()
              : '',
            topicId,
            questions: currentQuestions
              .filter(isValidQuestionnaire)
              .map((question) => ({
                ...question,
                selections: question.selections.filter(
                  (selection) => selection !== '',
                ),
              })),
            title: currentTitle,
          }}
          questionnaireFormData={{
            title: currentTitle,
            analysisResultId: selectedKeywordId
              ? selectedKeywordId.toString()
              : '',
            topicId,
            researchTarget: currentResearchTarget,
            questions: currentQuestions,
          }}
        />
      </form>
      <BaseSnackbar
        message={message}
        open={isOpen}
        handleClose={hideBanner}
        severity={severity}
        autoHideDuration={60000}
      />
    </FormProvider>
  )
}

const SummaryDetailContent = ({
  summaryIdea,
  importInfo,
  isPublic,
}: {
  summaryIdea: AnalysisSummaryIdea
  importInfo: Survey['importInfo']
  isPublic: boolean
}) => (
  <Box
    sx={{
      border: '1px solid #707070',
      borderRadius: '10px',
      py: 2,
      px: 2,
      width: '100%',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        mb: 2,
        p: 3,
      }}
    >
      <Box>
        <AnalysisSummaryIdeaTitle
          title={summaryIdea.title}
          rating={summaryIdea.rating}
          isPublic={isPublic}
        />
      </Box>

      <AnalysisEmpathyAndSummaryContent
        totalEmpathy={importInfo?.totalEmpathy}
        totalSurvey={importInfo?.totalSurvey}
        isPublic={isPublic}
        detail={summaryIdea.detail}
        height={250}
      />
    </Box>
  </Box>
)
