import useSWR, { SWRConfiguration } from 'swr'
import { ProductResponseData, parseProduct } from 'types/product'

type ProductResponse = {
  data: ProductResponseData
}

export const useGetProductBySlug = ({
  slug,
  options,
}: {
  slug: string
  options?: SWRConfiguration<ProductResponse, Error>
}) => {
  const { data, ...rest } = useSWR<ProductResponse>(
    `/api/products/${slug}`,
    options,
  )
  const product = data?.data ? parseProduct(data.data) : undefined
  return { data: product, ...rest }
}
