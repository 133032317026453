import { Box, Grid, Typography } from '@mui/material'
import AnalysisWordScoreTable from 'pages/v2/KeywordDetail/components/AnalysisWordScoreTable'
import WordCloud from 'pages/v2/components/WordCloud'
import { useMemo } from 'react'
import { ImportInfo } from 'types/importInfo'
import { Topic } from 'types/topic'

type Props = {
  surveyId: number
  topic?: Topic | undefined
  height: number
  importInfo: ImportInfo | undefined
}
export const AnalysisSurveyContent = ({
  surveyId,
  topic,
  height,
  importInfo,
}: Props) => {
  const wordCloudInput = useMemo(
    () =>
      (topic?.rareWords ?? [])
        .map((rareWord) => ({
          value: rareWord.word,
          count: rareWord.score,
          key: rareWord.word,
        }))
        .sort((a, b) => b.count - a.count),
    [surveyId],
  )

  return (
    <Grid container sx={{ height }}>
      <Grid
        item
        xs={3}
        sx={{
          alignItems: 'center',
          justifyContent: 'start',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            fontSize: 12,
            fontWeight: 'bold',
            mb: 2,
          }}
        >
          共感した人の
          <br />
          ボリューム属性
        </Box>
        {importInfo?.mainGender !== undefined &&
          importInfo.mainGeneration !== undefined && (
            <Sympathizers
              items={[
                {
                  imgSrc:
                    importInfo.mainGender === '男性'
                      ? '/assets/images/v2/30_male.svg'
                      : '/assets/images/v2/30_female.svg',
                  age: importInfo.mainGeneration,
                  gender: importInfo.mainGender,
                },
              ]}
            />
          )}
      </Grid>
      <Grid
        item
        xs={9}
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 4,
        }}
      >
        <AnalysisWordScoreTable rows={topic?.rareWords ?? []} height={height} />
        {(topic?.rareWords ?? []).length !== 0 && (
          <WordCloud
            key={`wc-${surveyId}`}
            data={wordCloudInput}
            height={height}
          />
        )}
      </Grid>
    </Grid>
  )
}

const Sympathizers = ({
  items,
}: {
  items: { gender: string; age: string; imgSrc: string }[]
}) => (
  <Box sx={{ mt: 2 }}>
    <Grid container rowSpacing={1} columnSpacing={1}>
      {items.map((item) => (
        <Grid item xs={6} key={`${item.age}-${item.gender}`}>
          <Box
            sx={{
              backgroundColor: '#fcd4bc',
              borderRadius: '50%',
              width: {
                xs: 100,
                md: 120,
              },
              height: {
                xs: 100,
                md: 120,
              },
              position: 'relative',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                lineHeight: '1',
                position: 'absolute',
                justifyContent: 'center',
                top: '50%',
                transform: 'translate(0%, -50%)',
              }}
            >
              <img
                src={item.imgSrc}
                alt=""
                style={{ paddingBottom: '8px', width: '50%' }}
              />
              <Typography variant="body2">{`${item.age}・${item.gender}`}</Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  </Box>
)
