import {
  useResendEmailVerification,
  useSingupVerification,
} from 'api/signupVerification'
import { useBanner } from 'hooks/useBanner'
import useQuery from 'hooks/useQuery'
import Loading from 'includes/Loading'
import AuthPageLayout from 'pages/components/AuthPageLayout'
import ResendEmailVerificationForm from 'pages/signupVerification/components/ResendEmailVerificationForm'
import BaseSnackbar from 'pages/v2/components/BaseSnackbar'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const SignupVerification = () => {
  const query = useQuery()
  const history = useHistory()
  const [status, setStatus] = useState<300 | 400 | null>(null)
  const { message, severity, isOpen, showBanner, hideBanner } = useBanner()

  const { request, requesting } = useSingupVerification({
    onSuccess: ({ message }) => {
      showBanner({
        message:
          '設定した情報でログインください。ログイン画面に自動で遷移します。',
        severity: 'success',
      })
      setTimeout(() => {
        history.push('/login')
      }, 3000)
    },
    onError: (error) => {
      if (error.response) {
        showBanner({
          message: error.response.data?.errors || 'エラーが発生しました。',
          severity: 'error',
        })
      } else {
        showBanner({
          message: 'エラーが発生しました。',
          severity: 'error',
        })
      }
      setStatus(400)
    },
  })

  const { request: resendRequest, requesting: resendRequesting } =
    useResendEmailVerification({
      onSuccess: ({ message }) => {
        showBanner({
          message: message,
          severity: 'success',
        })

        setTimeout(() => {
          history.push('/login')
        }, 3000)
      },
      onError: (error) => {
        console.log({ error })
        if (error.response) {
          showBanner({
            message:
              error.response.data?.errors ||
              '通信エラーです。もう一度お試しください。',
            severity: 'error',
          })
        } else {
          showBanner({
            message: '通信エラーです。もう一度お試しください。',
            severity: 'error',
          })
        }

        setStatus(400)
      },
    })

  useEffect(() => {
    if (query.get('status') === 'resend') {
      setStatus(300)
      showBanner({
        message:
          '登録確認メールを再送信します。メールアドレスを入力してください',
        severity: 'info',
      })
      return
    }

    const querySignup = async () => {
      await request({
        id: query.get('id'),
        hash: query.get('hash'),
      })
    }

    querySignup()
  }, [query])

  return (
    <AuthPageLayout>
      {(requesting || resendRequesting) && <Loading />}

      {(status === 300 || status === 400) && (
        <ResendEmailVerificationForm
          requesting={resendRequesting}
          request={resendRequest}
        />
      )}
      <BaseSnackbar
        message={message}
        open={isOpen}
        handleClose={hideBanner}
        severity={severity}
        autoHideDuration={60000}
      />
    </AuthPageLayout>
  )
}

export default SignupVerification
