import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useSignup } from 'api/signup'
import { useBanner } from 'hooks/useBanner'
import Loading from 'includes/Loading'
import AuthPageLayout from 'pages/components/AuthPageLayout'
import SignupForm from 'pages/signup/components/SignupForm'
import { signupSchema } from 'pages/signup/schema'
import BaseSnackbar from 'pages/v2/components/BaseSnackbar'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link as RouteLink } from 'react-router-dom'

const Signup = () => {
  const { message, severity, isOpen, showBanner, hideBanner } = useBanner()
  const method = useForm({
    resolver: zodResolver(signupSchema),
    defaultValues: {
      email: '',
      password: '',
      passwordConfirmation: '',
    },
  })
  const { reset } = method
  const { request, requesting } = useSignup({
    onSuccess: () => {
      showBanner({
        message: `ご登録のメールアドレスにメールを送信しました。
          ご確認いただき、メールに記載されたURLをクリックして、ViewPersへの登録を完了してください。`,
        severity: 'success',
      })
      reset()
    },
    onError: (error) => {
      if (error.response) {
        showBanner({
          message:
            error.response.data?.errors ||
            'ご入力のメールアドレスは既に登録されています',
          severity: 'error',
        })
      } else {
        showBanner({
          message: 'ご入力のメールアドレスは既に登録されています',
          severity: 'error',
        })
      }
    },
  })

  // globalなcssの設定を無効にする
  useEffect(() => {
    document.body.style.minWidth = '100vw'
    document.documentElement.style.fontSize = '100%'
    return () => {
      document.body.style.minWidth = '1340px'
      document.documentElement.style.fontSize = '62.5%'
    }
  }, [])

  return (
    <AuthPageLayout>
      <>
        <Typography
          variant="h2"
          sx={{
            fontSize: '20px',
            my: 3,
            fontWeight: 'bold',
          }}
        >
          新規登録
        </Typography>

        <Typography
          variant="body2"
          sx={{
            fontSize: '14px',
            mb: 0.5,
          }}
        >
          既にアカウントをお持ちの方は
          <RouteLink
            to="/login"
            style={{
              fontSize: '14px',
              textDecoration: 'none',
              display: 'inline',
              color: '#FF8800',
            }}
          >
            こちら
          </RouteLink>
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: '14px',
          }}
        >
          確認メールが届かない方は
          <RouteLink
            to="/email-verification?status=resend"
            style={{
              fontSize: '14px',
              textDecoration: 'none',
              display: 'inline',
              color: '#FF8800',
            }}
          >
            こちら
          </RouteLink>
        </Typography>

        {requesting && <Loading />}

        <SignupForm request={request} requesting={requesting} method={method} />

        <BaseSnackbar
          message={message}
          open={isOpen}
          handleClose={hideBanner}
          severity={severity}
          autoHideDuration={60000}
        />
      </>
    </AuthPageLayout>
  )
}

export default Signup
