import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Typography,
} from '@mui/material'
import { useDebounce } from 'hooks/useDebounce'
import { useDisclosure } from 'hooks/useDisclosure'
import useSelectedKeyword from 'hooks/useSelectedKeyword'
import { AnalysisCoOccurrences } from 'pages/v2/KeywordDetail/components/TweetDialog/TopicTweetDialog/AnalysisCoOccurrences'
import { AnalysisRareWords } from 'pages/v2/KeywordDetail/components/TweetDialog/TopicTweetDialog/AnalysisRareWords'
import { Suspense } from 'react'
import { Topic } from 'types/topic'
import { V2TweetList } from '../V2TweetList'

type Props = {
  topic?: Topic
  tweetIds?: string[]
  title: string
}
export const DisplayRelatedTweetsButton = ({
  topic,
  title,
  tweetIds,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button variant="outlined" sx={{ px: 2 }} onClick={onOpen}>
        参考となった投稿を見る
      </Button>
      <AnalysisDetailTweetsDialog
        open={isOpen}
        onClose={onClose}
        topic={topic}
        title={title}
        tweetIds={tweetIds}
      />
    </>
  )
}

const AnalysisDetailTweetsDialog = ({
  onClose,
  open,
  topic,
  title,
  tweetIds,
}: {
  onClose: () => void
  open: boolean
  topic: Topic | undefined
  title: string
  tweetIds: string[] | undefined
}) => {
  const { selectedKeywordId } = useSelectedKeyword()
  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      sx={{ padding: '32px' }}
      open={open}
      onClose={onClose}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 24,
          top: 8,
          zIndex: 1,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ padding: '32px' }}>
        <Grid container>
          {topic?.coOccurrences && topic?.coOccurrences.length > 0 && (
            <Grid item xs={12} md={6}>
              <AnalysisCoOccurrences coOccurrences={topic.coOccurrences} />
            </Grid>
          )}
          {topic?.rareWords && topic?.rareWords.length > 0 && (
            <Grid item xs={12} md={6}>
              <AnalysisRareWords rareWords={topic?.rareWords ?? []} />
            </Grid>
          )}
        </Grid>

        {tweetIds && (
          <SelectedTweetSection
            title={title}
            keywordId={selectedKeywordId}
            tweetIds={tweetIds}
          />
        )}

        {topic?.labelId && (
          <TopicTweetListSection
            title="このトピックのすべての投稿を見る"
            keywordId={selectedKeywordId}
            topicId={topic.labelId}
          />
        )}
      </Box>
    </Dialog>
  )
}

const SelectedTweetSection = ({
  title,
  keywordId,
  tweetIds,
}: {
  title: string
  keywordId?: number
  tweetIds: string[]
}) => {
  const { value, debouncedValue, onChange } = useDebounce({ defaultValue: '' })

  return (
    <Box
      sx={{
        mt: 4,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="start" gap={8}>
        <Typography fontSize="16px" fontWeight="bold">
          {title}
        </Typography>
        <Input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="検索"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </Box>
      <Suspense
        fallback={
          <Box
            height={300}
            mt={2.5}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        }
      >
        <V2TweetList
          type="each"
          tweetIds={tweetIds}
          keywordId={keywordId}
          text={debouncedValue}
          height={300}
        />
      </Suspense>
    </Box>
  )
}

export const TopicTweetListSection = ({
  keywordId,
  topicId,
  title,
  shouldDisplayEmpathy = false,
}: {
  keywordId?: number
  topicId: string
  title?: string
  shouldDisplayEmpathy?: boolean
}) => {
  const { value, debouncedValue, onChange } = useDebounce({ defaultValue: '' })

  return (
    <Box
      sx={{
        mt: 4,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="start" gap={8}>
        {title && (
          <Typography fontSize="16px" fontWeight="bold">
            このトピックのすべての投稿を見る
          </Typography>
        )}
        <Input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="検索"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </Box>
      <Suspense
        fallback={
          <Box
            height={300}
            mt={2.5}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        }
      >
        <V2TweetList
          shouldDisplayEmpathy={shouldDisplayEmpathy}
          type="topic"
          keywordId={keywordId}
          topicId={topicId}
          text={debouncedValue}
          height={300}
        />
      </Suspense>
    </Box>
  )
}
