import { useEffect, useState } from 'react'

function useDailyDialog(name: string, shouldDisplayDialog: boolean) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  useEffect(() => {
    if (!shouldDisplayDialog) {
      return
    }
    const lastDisplayedTimestamp = localStorage.getItem(name)
    const lastDisplayed = lastDisplayedTimestamp
      ? Number(lastDisplayedTimestamp)
      : null
    const now = Date.now()
    console.log({
      lastDisplayedTimestamp,
      lastDisplayed,
      now,
      diff: now - (lastDisplayed || 0),
    })

    if (!lastDisplayed || now - lastDisplayed >= 24 * 60 * 60 * 1000) {
      console.log('open dialog')
      setIsDialogOpen(true)
      localStorage.setItem(name, now.toString())
    }
  }, [shouldDisplayDialog, name])

  return {
    isDialogOpen,
    setIsDialogOpen,
  }
}

export default useDailyDialog
