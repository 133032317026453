import { Box, Typography } from '@mui/material'
import { useCurrentUser } from 'api/getCurrentUser'
import UserInfoForm from 'pages/v2/User/UserSetting/components/UserInfoForm'

const UserSetting = () => {
  const { currentUser } = useCurrentUser()

  if (!currentUser) {
    return null
  }

  return (
    <Box
      sx={{
        width: '95%',
        display: 'flex',
      }}
    >
      <Typography
        component="dt"
        sx={{
          width: '15%',
        }}
      >
        <strong>ユーザー情報</strong>
      </Typography>
      <Box
        sx={{
          flex: 1,
        }}
      >
        <UserInfoForm />
      </Box>
    </Box>
  )
}

export default UserSetting
