import { Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import { ProductInfo } from 'hooks/useProductInfo'
import React from 'react'

type PrecautionsProps = {
  precautions: ProductInfo['precautions']
}

const Precautions: React.VFC<PrecautionsProps> = ({ precautions }) => {
  return (
    <Grid container>
      <Grid item xs={5}>
        <Typography variant="h6" sx={{ color: '#000' }}>
          注意事項
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <List sx={{ listStyleType: 'disc', pt: 0 }}>
          {precautions.map((precaution, index) => (
            <ListItem
              key={`precaution-${index}`}
              sx={{ display: 'list-item', pl: '2px', py: 0 }}
            >
              <ListItemText primary={precaution} sx={{ color: '#000' }} />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  )
}

export default Precautions
