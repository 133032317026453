import { Box, Typography } from '@mui/material'

export const Nisa = () => {
  return (
    <>
      <Typography>このサンプルは「WEBメディア運営者」向けです。</Typography>
      <Typography sx={{ px: 2, fontSize: 'xs' }}>
        <Typography component="li">
          新しい記事を作成するためのネタ探しが大変
        </Typography>
        <Typography component="li">検索順位があがらない</Typography>
        <Typography component="li">予算・時間に余裕がない</Typography>
      </Typography>
      <Typography sx={{ py: 2 }}>
        ViewPersを使えばこれらの課題を簡単に解決できます。
        SEO記事の作成に活用したサンプルをご紹介します。
        <br />
        <br />
        ケーススタディ: 新しい金融商品の企画・検討
      </Typography>
      <Box component="section" sx={{ my: 2 }}>
        <Typography component="h2" sx={{ my: 1, fontWeight: 'bold' }}>
          ステップ1: 簡単にネタ探し
        </Typography>
        <p>
          まずは、金融系のトレンドワード中心に探すということで、テーマに「NISA」を入力してアイデアを探します。
        </p>
        <p>
          5分ほどで投稿が集まり、1営業日たつと、属性情報が共感度という形であらわれるので、
          どの層にどれくらいささるテーマなのかが、大体わかります。
        </p>
        <p>
          今回は２トピック目の「
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '14px' }}
            component="span"
          >
            積立NISAの金額設定に関するお悩みと考え方
          </Typography>
          」が共感度が高く、50代男性が一番多く共感しているデータが出てきました。
          <br />
          これは、50代というキャリアの後半戦に立っている方の資産形成に関するお悩みがあることがわかります。
          資産形成のリスク許容度が低くなりがちな50代の実態が出ているかと考えられます。
        </p>
      </Box>
      <Box component="section" sx={{ my: 2 }}>
        <Typography component="h2" sx={{ my: 1, fontWeight: 'bold' }}>
          ステップ2: キーワードの深掘り
        </Typography>
        <p>
          次に、NISAに関するお悩みをより深掘りしていくために、「NISA
          悩み」と分析をかけました。
        </p>
        <p>
          結果を見てみると ２トピック目の「
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '14px' }}
            component="span"
          >
            新NISAで将来の大幅な資産増加：初心者でも毎月5,000円のほったらかし投資
            」で、ステップ1で出てきた悩みを解決できるようなサマリーが出てきました。
          </Typography>
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '14px' }}
            component="span"
          >
            従来の一方通行型のセミナーというよりワークショップ形式で双方向型のものが共感度が高いということがわかりました。
          </Typography>
          <br />
          <br />
          1度の分析は
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '14px' }}
            component="span"
          >
            9,000円
          </Typography>
          で実施でき、たった２つのステップで良質な記事を生み出すことが可能です。
          設定したら待つだけで、さらに最短１営業日で結果が確認できるので、高速に多くの記事を書くことができるのではないでしょうか？
        </p>
        <p>ぜひViewPersをご活用ください。</p>
      </Box>
    </>
  )
}
