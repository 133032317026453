export type AnalysisSummaryIdeaResponse = {
  id: number
  title: string
  detail?: string | null
  rating: string
  tweets?: any
  survey_id: number
}

export type AnalysisSummaryRating = 'good' | 'bad' | 'unrated'

export type AnalysisSummaryIdea = {
  id: number
  surveyId: number
  title: string
  detail: string
  rating: AnalysisSummaryRating
  tweetIds?: string[]
}

const parseAnalysisSummaryRating = (value: string): AnalysisSummaryRating => {
  switch (value) {
    case 'good':
    case 'bad':
    case 'unrated':
      return value
    default:
      throw new Error(`Invalid AnalysisSummaryRating value: ${value}`)
  }
}

const parseTweetIds = (input: any): string[] => {
  try {
    if (Array.isArray(input)) {
      return input as string[]
    }
    const result = JSON.parse(input)
    return result as string[]
  } catch (e) {
    console.error('Parsing error: ', e)
  }
  return []
}

export const parseAnalysisSummaryIdea = (
  input: AnalysisSummaryIdeaResponse,
): AnalysisSummaryIdea => {
  return {
    id: input.id,
    rating: parseAnalysisSummaryRating(input.rating),
    title: input.title,
    detail:
      input.detail ??
      '作成中です。しばらくお待ち下さい。約1〜2営業日で表示されます。',
    surveyId: input.survey_id,
    tweetIds: parseTweetIds(input.tweets),
  }
}
