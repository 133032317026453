import { Box } from '@mui/material'
import useSelectedKeyword from 'hooks/useSelectedKeyword'
import useSelectedTheme from 'hooks/useSelectedTheme'
import { V2Paths } from 'pages/v2/paths'
import BackButton from 'pages/v2/sp/components/BackButton'
import { Loading } from 'pages/v2/sp/KeywordDetail/components/Loading'
import React, { useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import { AnalysisStatus } from 'types/analysisResult'

export const SPKeywordDetailPage = () => {
  // pollingで状態を更新するような場合は、軽量なAPI Endpoingを作成する
  const { selectedThemeId } = useSelectedTheme()
  const { selectedKeywordId, selectedKeyword } = useSelectedKeyword()

  const status = useMemo(
    () => selectedKeyword?.analysisStatus,
    [selectedKeyword],
  )

  if (selectedKeyword === undefined) return <Redirect to="/v2/themes" />

  if (status === AnalysisStatus.Collecting)
    return (
      <LoadingLayout>
        <Loading type="collecting" />
      </LoadingLayout>
    )

  if (status === AnalysisStatus.TopicAnalysing)
    return (
      <LoadingLayout>
        <Loading type="analysing" />
      </LoadingLayout>
    )

  if (
    status === AnalysisStatus.TopicLabeling ||
    status === AnalysisStatus.SuggestWords
  )
    return (
      <LoadingLayout>
        <Loading type="createReport" finishAt="2024-01-24" />
      </LoadingLayout>
    )

  if (status === AnalysisStatus.Complete || status === AnalysisStatus.Close)
    return selectedKeyword.summaries.length === 0 ? (
      <LoadingLayout>
        <Loading type="summaryDoesNotExist" />
      </LoadingLayout>
    ) : (
      <Redirect
        to={`/v2/themes/${selectedThemeId}/keywords/${selectedKeywordId}/summary/${selectedKeyword.summaries[0].id}`}
      />
    )

  return (
    <LoadingLayout>
      <Loading type="createSummaryFailed" />
    </LoadingLayout>
  )
}

const LoadingLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box sx={{ py: 3 }}>
      <BackButton path={V2Paths.Home}>戻る</BackButton>
      {children}
    </Box>
  )
}
