import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

type ChangePasswordInput = {
  currentPassword: string
  newPassword: string
  newPasswordConfirmation: string
}

const changePassword = async (input: ChangePasswordInput) => {
  await axios.put('/api/me/password_change', {
    current_password: input.currentPassword,
    new_password: input.newPassword,
    new_password_confirmation: input.newPasswordConfirmation,
  })
}

export const useChangePassword = (option?: UseMutationOption<void>) =>
  useMutation(changePassword, option)
