const FloatingBanner = ({ ...props }) => {
  return (
    <div
      className={`u-dfl u-pt20 u-pb20 u-pr20 u-pl20`}
      style={{
        position: 'sticky',
        bottom: 10,
        width: '80%',
        margin: 'auto',
        backgroundColor: '#ffebcc',
        borderRadius: 15,
        zIndex: 11,
      }}
    >
      <a
        href="https://viewpers.com/plan_inquiry/"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/assets/images/banner/1.jpg" />
      </a>
      <a
        className="u-ml15"
        href="https://viewpers.com/tips.html"
        target="_blank"
        style={{ position: 'relative' }}
        rel="noreferrer"
      >
        <img
          src="/assets/images/banner/2.png"
          style={{ visibility: 'hidden' }}
        />
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
          }}
        >
          <span className="u-colorOrange u-fontB" style={{ fontSize: '2rem' }}>
            使い方に困ったら
          </span>
        </div>
      </a>
      <a
        className="u-ml15"
        href="https://viewpers.com/"
        target="_blank"
        style={{ position: 'relative' }}
        rel="noreferrer"
      >
        <img
          src="/assets/images/banner/3.png"
          style={{ visibility: 'hidden' }}
        />
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
          }}
        >
          <span
            className="u-colorOrange u-fontB"
            style={{ fontSize: '2rem', textAlign: 'center', lineHeight: 1.3 }}
          >
            サービスの詳細や
            <br />
            活用事例など
          </span>
        </div>
      </a>
      <a
        className="u-ml15"
        href="https://viewpers.com/lp/inquiry/"
        target="_blank"
        style={{ position: 'relative' }}
        rel="noreferrer"
      >
        <img
          src="/assets/images/banner/4.png"
          style={{ visibility: 'hidden' }}
        />
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
          }}
        >
          <span className="u-colorOrange u-fontB" style={{ fontSize: '2rem' }}>
            問い合わせ
          </span>
        </div>
      </a>
    </div>
  )
}

export default FloatingBanner
