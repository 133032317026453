import {
  AnalysisSummaryIdea,
  AnalysisSummaryIdeaResponse,
  parseAnalysisSummaryIdea,
} from './analysisSummaryIdea'
import {
  EmpathySurvey,
  EmpathySurveyResponse,
  parseEmpathySurvey,
} from './empathySurvey'
import { ImportInfo, ImportInfoResponse, parseImportInfo } from './importInfo'
import { Topic } from './topic'

export type SurveyResponse = {
  id: number
  name: string | null
  summary_ideas: AnalysisSummaryIdeaResponse[]
  latest_summary_idea: AnalysisSummaryIdeaResponse | null
  status_id: number
  weighted_status_id: number | null
  tags: { name: string }[]
  label_id: number
  import_infors: ImportInfoResponse[] | null
  empathy_surveys: EmpathySurveyResponse[] | null
}

export enum SurveyStatus {
  Request = 1,
  UnderSurvey = 2,
  Import = 3,
  Update = 4,
  Complete = 5,
}

export enum SurveyWeightedStatus {
  AssigneeNotSet = 1,
  Deliverable = 2,
  Reflected = 3,
}

export type Survey = {
  id: number
  status: SurveyStatus
  weightedStatus: SurveyWeightedStatus | undefined
  analysisSummaryIdea: AnalysisSummaryIdea | undefined
  topic?: Topic
  importInfo: ImportInfo | undefined
  empathySurveys: EmpathySurvey[] | undefined
}

const parseSurveyStatus = (value: number): SurveyStatus => {
  switch (value) {
    case 1:
      return SurveyStatus.Request
    case 2:
      return SurveyStatus.UnderSurvey
    case 3:
      return SurveyStatus.Import
    case 4:
      return SurveyStatus.Update
    case 5:
      return SurveyStatus.Complete
    default:
      throw new Error(`Invalid SurveyStatus value: ${value}`)
  }
}

const parseSurveyWeightedStatus = (value: number): SurveyWeightedStatus => {
  switch (value) {
    case 1:
      return SurveyWeightedStatus.AssigneeNotSet
    case 2:
      return SurveyWeightedStatus.Deliverable
    case 3:
      return SurveyWeightedStatus.Reflected
    default:
      throw new Error(`Invalid SurveyWeightedStatus value: ${value}`)
  }
}

export const parseSurvey = (input: SurveyResponse, topics: Topic[]): Survey => {
  const topic = topics.find(
    (topic) => topic.labelId.toString() === input.label_id.toString(),
  )
  const importInfos = input.import_infors ?? []

  return {
    id: input.id,
    status: parseSurveyStatus(input.status_id),
    weightedStatus:
      input.weighted_status_id !== null
        ? parseSurveyWeightedStatus(input.weighted_status_id)
        : undefined,
    analysisSummaryIdea:
      input.latest_summary_idea === null
        ? undefined
        : parseAnalysisSummaryIdea(input.latest_summary_idea),
    topic,
    importInfo:
      importInfos.length > 0 ? parseImportInfo(importInfos[0]) : undefined,
    empathySurveys: input.empathy_surveys?.map(parseEmpathySurvey) ?? [],
  }
}
