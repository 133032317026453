import { useCurrentUser } from 'api/getCurrentUser'
import phoneRegex from 'constants/regex/phoneRegex'

const useNeedUserAdditionalInput = () => {
  const { currentUser } = useCurrentUser()
  return (
    !currentUser?.name ||
    !currentUser?.company_name ||
    !currentUser?.phone_number ||
    !phoneRegex.test(currentUser.phone_number)
  )
}

export default useNeedUserAdditionalInput
