import { Checkbox, FormControl, FormControlLabel } from '@mui/material'
import React from 'react'
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
} from 'react-hook-form'

interface FormCheckboxProps<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>
  control: Control<TFieldValues>
  label: string | React.ReactNode
  errorObj: Partial<Record<keyof TFieldValues, FieldError>>
}

const FormCheckbox = <TFieldValues extends FieldValues>({
  name,
  control,
  label,
  errorObj,
}: FormCheckboxProps<TFieldValues>) => {
  return (
    <FormControl error={!!errorObj[name]} component="fieldset" sx={{ mt: 2 }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox {...field} color="primary" checked={field.value} />
            }
            label={label}
          />
        )}
      />
      {errorObj[name] && (
        <p style={{ color: 'red' }}>{errorObj[name]?.message}</p>
      )}
    </FormControl>
  )
}

export default FormCheckbox
