export type Slug = 'questionnaire' | 'keyword' | 'survey' | 'summary'

export type Product = {
  id: number
  name: string
  slug: Slug
  price: string
  weightedUsageCount: number
  questionnaireUsageCount: number
  analysisUsageCount: number
  adjustmentRequestUsageCount: number
  stripePriceId: string
  isPublic: boolean
  isCampaign: boolean
  displayUntil: string | null
  createdAt: string
  updatedAt: string
}

export type ProductResponseData = {
  id: number
  name: string
  slug: Slug
  price: string
  stripe_price_id: string
  weighted_usage_count: number
  questionnaire_usage_count: number
  analysis_usage_count: number
  adjustment_request_usage_count: number
  is_public: boolean
  is_campaign: boolean
  display_until: string | null
  created_at: string
  updated_at: string
}

export const parseProduct = (product: ProductResponseData): Product => ({
  id: product.id,
  name: product.name,
  slug: product.slug,
  price: Math.floor(Number(product.price)).toString(),
  stripePriceId: product.stripe_price_id,
  weightedUsageCount: product.weighted_usage_count,
  questionnaireUsageCount: product.questionnaire_usage_count,
  analysisUsageCount: product.analysis_usage_count,
  adjustmentRequestUsageCount: product.adjustment_request_usage_count,
  isPublic: product.is_public,
  isCampaign: product.is_campaign,
  displayUntil: product.display_until
    ? new Date(product.display_until).toLocaleString('ja-JP', {
        timeZone: 'Asia/Tokyo',
      })
    : null,
  createdAt: product.created_at,
  updatedAt: product.updated_at,
})
