import useSWR, { SWRConfiguration } from 'swr'
import { OrderResponseData, parseOrder } from 'types/order'

type OrderResponse = {
  data: OrderResponseData
}

export const useCheckSession = ({
  sessionId,
  options,
}: {
  sessionId: string | null
  options?: SWRConfiguration<OrderResponse, Error>
}) => {
  const { data, ...rest } = useSWR<OrderResponse>(
    sessionId ? `/api/orders/check_session/${sessionId}` : null,
    options,
  )
  const order = data?.data ? parseOrder(data.data) : undefined
  return { data: order, ...rest }
}
