import { useGetV2Tweets } from 'api/getV2Tweets'
import useSelectedKeyword from 'hooks/useSelectedKeyword'
import { useCallback, useMemo } from 'react'

export type V2TweetListProps = {
  keywordId?: number
  text?: string
} & (
  | {
      type: 'all'
    }
  | {
      type: 'each'
      tweetIds: string[]
    }
  | {
      type: 'topic'
      topicId: string
    }
)

export const useV2TweetsList = (props: V2TweetListProps) => {
  const { selectedKeywordId } = useSelectedKeyword()
  const { data, size, setSize } = useGetV2Tweets({
    keywordId: selectedKeywordId,
    text: props.text,
    ...props,
  })

  const loading = useMemo(
    () => data === undefined || data.length < size,
    [data, size],
  )

  const currentMeta = useMemo(() => {
    if (data === undefined || data.length === 0) return null
    return data[data.length - 1].meta
  }, [data])

  const hasMore = useMemo(
    () =>
      currentMeta !== null && currentMeta.currentPage < currentMeta.lastPage,
    [currentMeta],
  )

  const loadMore = useCallback(() => {
    if (loading || !hasMore) return
    setSize((prev) => prev + 1)
  }, [loading, setSize, size])

  const tweets = useMemo(() => {
    if (!data) return []
    return data.flatMap((dt) => dt.data)
  }, [data])

  return { loading, tweets, loadMore, hasMore }
}
