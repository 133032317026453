export type V2TourStep =
  | 'START'
  | 'INTRO'
  | 'COLLECTING_WORDS'
  | 'REPORT'
  | 'TOPIC_ANALYSIS'
  | 'POST_ANALYSIS'
  | 'COMPLETE'

export const castToV2TourStep = (value: string | null): V2TourStep => {
  const validSteps: V2TourStep[] = [
    'START',
    'INTRO',
    'COLLECTING_WORDS',
    'REPORT',
    'TOPIC_ANALYSIS',
    'POST_ANALYSIS',
    'COMPLETE',
  ]

  if (validSteps.includes(value as V2TourStep)) {
    return value as V2TourStep
  } else {
    return 'START'
  }
}

export type V2TourHistory = {
  step: V2TourStep
}
