import { useCallback, useState } from 'react'

import { notifyError } from '../util/errorTracking'

export type UseMutationOption<RequestReturn> = Partial<{
  onSuccess: (data: RequestReturn) => void
  onError: (error: any) => void
  onRequestStarted: () => void
  onRequestDone: () => void
}>

export const useMutation = <RequestArg extends any[], RequestReturn>(
  requestFn: (...arg: RequestArg) => Promise<RequestReturn>,
  options?: UseMutationOption<RequestReturn>,
) => {
  const [requesting, setRequesting] = useState<boolean>(false)

  const requestStart = useCallback(() => {
    setRequesting(true)
    options?.onRequestStarted?.()
  }, [options])

  const requestDone = useCallback(() => {
    setRequesting(false)
    options?.onRequestDone?.()
  }, [options])

  const request = useCallback(
    async (...args: RequestArg) => {
      try {
        requestStart()
        const data = await requestFn(...args)
        options?.onSuccess?.(data)
      } catch (error: any) {
        options?.onError?.(error)
        notifyError(error)
      } finally {
        requestDone()
      }
    },
    [options, requestFn, requestStart, requestDone],
  )

  return { request, requesting }
}
