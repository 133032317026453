import { Button } from '@mui/material'
import { useGetOrder } from 'api/order/getOrder'
import { useDisclosure } from 'hooks/useDisclosure'
import useQuery from 'hooks/useQuery'
import useSelectedSummary from 'hooks/useSelectedSummary'
import Loading from 'includes/Loading'
import getValueFromOrderProcessingData from 'libs/getValueFromOrderProcessingData'
import { FormData } from 'pages/v2/KeywordDetail/components/Summary/CreateSummary/CreateSummaryDialog'
import { useV2Tour } from 'pages/v2/Tour/V2TourProvider'
import { useLocation } from 'react-router-dom'
import { CreateSummaryDialog } from './CreateSummaryDialog'

export const CreateSummaryButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const query = useQuery()
  const checkoutMode = query.get('checkout') ? query.get('checkout') : null
  const orderId = query.get('order_id') ? query.get('order_id') : null
  const { data: order, isLoading } = useGetOrder({ orderId: orderId })
  const location = useLocation<{ summaryFormData: FormData }>()
  const formValue = getValueFromOrderProcessingData({
    type: 'checkout.summary',
    orderProcessingData: order?.orderProcessingData ?? null,
  })
  const { tourStep } = useV2Tour()
  const { isDemo } = useSelectedSummary()

  const defaultValues = order ? formValue : location.state?.summaryFormData

  return (
    <>
      {isLoading && <Loading />}
      <Button
        variant="contained"
        sx={{ px: 2, fontSize: '14px', color: 'white' }}
        onClick={onOpen}
        disabled={isDemo && tourStep === 'COMPLETE'}
      >
        共感度を調整する
      </Button>
      <CreateSummaryDialog
        key={defaultValues ? 'default' : 'new'}
        onClose={onClose}
        onOpen={onOpen}
        open={isOpen}
        checkoutMode={!!checkoutMode}
        defaultValues={defaultValues}
        order={order}
      />
    </>
  )
}
