import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

export type CreateSummaryInput = {
  keywordId: number
  tags: number[]
  prefectures: string[]
  specialityPanel: boolean
  note?: string
}

type CreateSummaryPayload = {
  id: number
}

export const createSummary = async (input: CreateSummaryInput) => {
  const res = await axios.post<CreateSummaryPayload>(
    `/api/results/${input.keywordId}/summaries`,
    {
      tags: input.tags,
      prefectures: input.prefectures,
      speciality_panel: input.specialityPanel,
      note: input.note ?? null,
    },
  )

  return res.data
}

export const useCreateSummary = (
  option?: UseMutationOption<CreateSummaryPayload>,
) => useMutation(createSummary, option)
