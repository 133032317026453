import { Link } from 'react-router-dom'

const Side = () => {
  return (
    <>
      <div className="l-contents__side">
        <aside className={'l-sidebar is-open'}>
          <div className="l-sidebar__inner">
            <ul className="l-sidebar__menu">
              <li className="c-menuBtn">
                <Link to="/" className="c-menuBtn__inner">
                  <span className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon">
                    <img
                      style={{ width: '60%' }}
                      src="/assets/images/icons/01_home.svg"
                      alt=""
                    />
                    <img
                      style={{ width: '60%' }}
                      src="/assets/images/icons/01_home_active.svg"
                      alt=""
                    />
                  </span>
                  <div className="c-menuBtn__title side__menuTitle">ホーム</div>
                </Link>
              </li>
              <li className="c-menuBtn">
                <Link to="/analysis" className="c-menuBtn__inner">
                  <span className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon">
                    <img
                      style={{ width: '60%' }}
                      src="/assets/images/icons/02_analysis.svg"
                      alt=""
                    />
                    <img
                      style={{ width: '60%' }}
                      src="/assets/images/icons/02_analysis_active.svg"
                      alt=""
                    />
                  </span>
                  {/* List ket qua phan tich */}
                  <div className="c-menuBtn__title side__menuTitle">
                    分析結果一覧
                  </div>
                </Link>
              </li>
              {/* TODO: 機能検証が終わるまで一時的に非表示*/}
              {/*<li className='c-menuBtn'>*/}
              {/*  <Link to='/scheduled' className='c-menuBtn__inner'>*/}
              {/*    <span className='c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon'>*/}
              {/*      <img*/}
              {/*        style={{width: '62%'}}*/}
              {/*        src='/assets/images/clock_black.png'*/}
              {/*        alt=''*/}
              {/*      />*/}
              {/*      <img*/}
              {/*        style={{width: '63%'}}*/}
              {/*        src='/assets/images/clock_orange.png'*/}
              {/*        alt=''*/}
              {/*      />*/}
              {/*    </span>*/}
              {/*    /!* List kết quả phân tích định kỳ *!/*/}
              {/*    <div className='c-menuBtn__title side__menuTitle'>*/}
              {/*      定期分析一覧*/}
              {/*    </div>*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li className="c-menuBtn">
                <Link to="/questionnaire" className="c-menuBtn__inner">
                  <span className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon">
                    <img
                      style={{ width: '60%' }}
                      src="/assets/images/icons/03_questionnaire.svg"
                      alt=""
                    />
                    <img
                      style={{ width: '60%' }}
                      src="/assets/images/icons/03_questionnaire_active.svg"
                      alt=""
                    />
                  </span>
                  {/* List survey */}
                  <div className="c-menuBtn__title side__menuTitle u-ml15">
                    アンケート一覧
                  </div>
                </Link>
              </li>
              {/* TODO: 機能検証が終わるまで一時的に非表示*/}
              {/*<li className='c-menuBtn'>*/}
              {/*  <Link to='/storage' className='c-menuBtn__inner'>*/}
              {/*    <span className='c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon u-ml5 u-w30 u-h30'>*/}
              {/*      <img*/}
              {/*        style={{width: '68%'}}*/}
              {/*        src='/assets/images/package_black.png'*/}
              {/*        alt=''*/}
              {/*      />*/}
              {/*      <img*/}
              {/*        style={{width: '68%'}}*/}
              {/*        src='/assets/images/package_orange.png'*/}
              {/*        alt=''*/}
              {/*      />*/}
              {/*    </span>*/}
              {/*    <div className='c-menuBtn__title side__menuTitle u-ml15'>*/}
              {/*      ストレージ*/}
              {/*    </div>*/}
              {/*  </Link>*/}
              {/*</li>*/}

              <li className="c-menuBtn">
                <Link to="/search" className="c-menuBtn__inner">
                  <span className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon">
                    <img
                      style={{ width: '60%' }}
                      src="/assets/images/icons/04_search.svg"
                      alt=""
                    />
                    <img
                      style={{ width: '60%' }}
                      src="/assets/images/icons/04_search_active.svg"
                      alt=""
                    />
                  </span>
                  <div className="c-menuBtn__title side__menuTitle u-ml15">
                    検索
                  </div>
                </Link>
              </li>
            </ul>

            <ul className="l-sidebar__menu">
              {/*<li className='c-menuBtn'>*/}
              {/*  <a href="" target="_blank" className='c-menuBtn__inner'>*/}
              {/*     <span className='c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon'>*/}
              {/*      <img style={{ width: "60%" }} src='/assets/images/icons/05_faq.svg' alt='' />*/}
              {/*      <img style={{ width: "60%" }} src='/assets/images/icons/05_faq_active.svg' alt='' />*/}
              {/*    </span>*/}
              {/*    <div className='c-menuBtn__title side__menuTitle'>FAQ</div>*/}
              {/*  </a>*/}
              {/*</li>*/}
              <li className="c-menuBtn">
                <a
                  href="https://viewpers.com/tips.html?utm_source=dc.viewpers.com&utm_medium=link&utm_campaign=webapp"
                  target="_blank"
                  className="c-menuBtn__inner"
                  rel="noreferrer"
                >
                  <span className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon">
                    <img
                      style={{ width: '60%' }}
                      src="/assets/images/icons/06_tips.svg"
                      alt=""
                    />
                    <img
                      style={{ width: '60%' }}
                      src="/assets/images/icons/06_tips_active.svg"
                      alt=""
                    />
                  </span>
                  <div className="c-menuBtn__title side__menuTitle">Tips</div>
                </a>
              </li>
              <li className="c-menuBtn">
                <a
                  href="https://viewpers.com/?utm_source=dc.viewpers.com&utm_medium=link&utm_campaign=webapp"
                  target="_blank"
                  className="c-menuBtn__inner"
                  rel="noreferrer"
                >
                  <span className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon">
                    <img
                      style={{ width: '50%' }}
                      src="/assets/images/icons/07_site.svg"
                      alt=""
                    />
                    <img
                      style={{ width: '50%' }}
                      src="/assets/images/icons/07_site_active.svg"
                      alt=""
                    />
                  </span>
                  <div className="c-menuBtn__title side__menuTitle">
                    サービスサイト
                  </div>
                </a>
              </li>
              <li className="c-menuBtn">
                <Link to="/settings/user" className="c-menuBtn__inner">
                  <span className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon">
                    <img src="/assets/images/icon_setting_black01.svg" alt="" />
                    <img src="/assets/images/icon_setting_blue01.svg" alt="" />
                  </span>
                  <div className="c-menuBtn__title side__menuTitle">設定</div>
                </Link>
              </li>
              <li className="c-menuBtn">
                <Link to="/help" className="c-menuBtn__inner">
                  <span className="c-menuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon">
                    <img src="/assets/images/icon_help_black01.svg" alt="" />
                    <img src="/assets/images/icon_help_blue01.svg" alt="" />
                  </span>
                  <div className="c-menuBtn__title side__menuTitle">ヘルプ</div>
                </Link>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    </>
  )
}

export default Side
