import {
  Button,
  Dialog,
  Box,
  Typography,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { VFC } from 'react'
import { Empathy } from 'types/empathy'
import { useDisclosure } from 'hooks/useDisclosure'
import { v2DateFormatter } from 'util/dateFormatter'

type Props = {
  tweetText: string
  empathy: Empathy
  empathies: Empathy[]
}
export const EmpathyFilterDetailButton: VFC<Props> = ({
  tweetText,
  empathy,
  empathies,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button
        sx={{
          p: 0,
          width: '32px',
          minWidth: '32px',
          height: '32px',
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          bgcolor: '#ff6600',
          borderRadius: '16px',
        }}
        variant="contained"
        onClick={onOpen}
      >
        <Typography color="white" fontWeight="bold">
          {empathy.empathyCount}
        </Typography>
      </Button>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>共感フィルター詳細</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 24,
            top: 8,
            zIndex: 1,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box
            sx={{
              my: 4,
              border: 'solid 1px #DDE7EE',
              borderRadius: '10px',
              display: 'flex',
              flexDirection: 'column',
              p: '10px 20px',
              gap: '10px',
              overflowWrap: 'break-word',
            }}
          >
            <Typography fontSize="14px" color="#00000">
              {tweetText}
            </Typography>
          </Box>
          <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ bg: '##F5F5F5' }} />
                  <TableCell
                    sx={{
                      bg: '#F5F5F5',
                      fontWeight: 'bold',
                      fontSize: '14px',
                    }}
                    align="center"
                  >
                    聴取属性
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                    align="center"
                  >
                    共感数
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {empathies.map((empathy) => (
                  <TableRow key={empathy.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        fontSize: '12px',
                      }}
                    >
                      {v2DateFormatter(empathy.createdAt)}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '12px',
                      }}
                      align="center"
                    >
                      {empathy.tags.join(' / ')}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: '12px',
                      }}
                    >
                      {empathy.empathyCount} / {empathy.totalCount}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  )
}
