import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useV2Analysis } from 'hooks/useV2Analysis'
import Loading from 'includes/Loading'
import * as React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'
import { dateFormatter } from 'util/dateFormatter'

type AnalysisResult = {
  id: number
  target_words: string
  // questionnaire: {
  //   id: number
  //   status: {
  //     id: number
  //     name: string
  //   }
  //   created_at: string
  // } | null
  created_at: string
  analysis_theme_id: number
}

const columns = [
  { id: 'target_words', label: '分析キーワード', minWidth: 170 },
  { id: 'created_at', label: '投稿収集', minWidth: 170 },
  // { id: 'questionnaire', label: '簡易アンケート', minWidth: 170 },
  { id: 'analysis_date', label: '分析実行日時', minWidth: 170 },
]

type AnalysisResultsTableProps = {}

const AnalysisResultsTable: React.FC<AnalysisResultsTableProps> = (props) => {
  const { data: analyses, loadMore, hasMore, loading } = useV2Analysis()

  const createData = (analysisResult: AnalysisResult) => ({
    ...analysisResult,
    // questionnaire_date: analysisResult.questionnaire
    //   ? analysisResult.questionnaire.created_at
    //   : null,
    analysis_date: analysisResult.created_at,
  })

  const rows = analyses.map(createData)

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            component={InfiniteScroll}
            loadMore={loadMore}
            hasMore={hasMore}
            useWindow={false}
          >
            {loading && <Loading />}
            {rows.map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                <TableCell>
                  <Link
                    key={`side-item-${row.analysis_theme_id}-${row.id}`}
                    to={`/v2/themes/${row.analysis_theme_id}/keywords/${row.id}`}
                  >
                    {row.target_words}
                  </Link>
                </TableCell>
                <TableCell>{dateFormatter(row.created_at)}</TableCell>
                {/* <TableCell>
                  {row.questionnaire_date
                    ? dateFormatter(row.questionnaire_date)
                    : null}
                </TableCell> */}
                <TableCell>{dateFormatter(row.analysis_date)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default AnalysisResultsTable
