import { Visibility, VisibilityOff } from '@mui/icons-material'
import { FormControl, FormLabel, IconButton, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'

type PasswordFieldProps = {
  control: Control<
    {
      currentPassword: string
      newPassword: string
      newPasswordConfirmation: string
    },
    any
  >
  errors: FieldErrors<{
    currentPassword: string
    newPassword: string
    newPasswordConfirmation: string
  }>
  label: string
  fieldName: 'currentPassword' | 'newPassword' | 'newPasswordConfirmation'
}

const PasswordField: React.VFC<PasswordFieldProps> = ({
  control,
  errors,
  label,
  fieldName,
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword(!showPassword)

  return (
    <FormControl
      error={errors[fieldName] ? true : false}
      sx={{
        borderTop: '1px solid #ccdae5',
        padding: '20px 0',
        gap: 2,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',

        '&:last-child': {
          borderBottom: '1px solid #ccdae5',
        },
      }}
    >
      <FormLabel
        required
        sx={{ textAlign: 'start', whiteSpace: 'pre', color: '#000' }}
      >
        {label}
      </FormLabel>
      <Controller
        name={fieldName}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type={showPassword ? 'text' : 'password'}
            fullWidth
            placeholder="8文字以上の半角英数字"
            name={fieldName}
            helperText={errors[fieldName]?.message}
            sx={{
              width: '100%',
              '& .MuiInputBase-input': {
                height: 'auto',
                padding: '10px 14px',
              },
            }}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
              style: {
                fontSize: 16,
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: 16,
              },
            }}
          />
        )}
      />
    </FormControl>
  )
}

export default PasswordField
