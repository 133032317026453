import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { VFC } from 'react'

type Props = {
  open: boolean
  disabled: boolean
  onNext: () => void
  onPrev: () => void
  step: number
}

export const stepsInfo = [
  {
    title: 'ワード収集',
    description:
      'トップページ左上の「新規キーワード作成」から今回の分析全体のテーマ、続いて今回収集したい投稿のキーワードを設定しましょう。',
    gifUrl: '/assets/images/tour/step/step1.gif',
  },
  {
    title: 'レポート',
    description:
      '収集した投稿をAIが話題ごとにトピックに分割し、分析目的に沿うように要約します。トピック内の投稿文もすべて確認できます。',
    gifUrl: '/assets/images/tour/step/step2.gif',
  },
  {
    title: 'トピック分析',
    description:
      'リサーチターゲットがどの話題に共感するかを数値で明らかにします。導き出されたトピックが、ターゲットにどの程度共感されているか確認してみましょう。',
    gifUrl: '/assets/images/tour/step/step3.gif',
  },
  {
    title: '投稿分析',
    description:
      'トピック内の投稿そのものに共感できるかを、リサーチターゲットに聴取することも可能です。匿名性の高いSNS投稿に属性情報（性別・年代・移住地）を付与して、分析の価値を高めましょう。',
    gifUrl: '/assets/images/tour/step/step4.gif',
  },
]

export const TourGuideStepDialog: VFC<Props> = ({
  open,
  disabled,
  onNext,
  onPrev,
  step,
}) => {
  const currentStepIndex = step - 1
  const stepInfo = stepsInfo[currentStepIndex]

  if (!stepInfo) {
    return null
  }

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      fullWidth
      maxWidth="xl"
      open={open}
      sx={{
        maxHeight: '100%',
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          py: 8,
          px: 2,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 4,
          position: 'relative',
        }}
      >
        <Typography sx={{ fontWeight: 'bold', fontSize: 22 }}>
          {`STEP ${step} ${stepInfo.title}`}
        </Typography>
        <Box
          sx={{
            position: 'absolute',
            top: '30px',
            right: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          {stepsInfo.map((_, index) => (
            <Box
              key={index}
              sx={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                border: `2px solid ${
                  index <= currentStepIndex ? '#FF8800' : 'black'
                }`,
                fontWeight: 'bold',
                color: index <= currentStepIndex ? '#FF8800' : 'black',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {index + 1}
            </Box>
          ))}
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          px: 16,
          fontWeight: 'bold',
          overflow: 'visible',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 4,
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            width: '100%',
            maxWidth: '600px',
          }}
        >
          {stepInfo.description}
        </Typography>
        <img
          src={stepInfo.gifUrl}
          alt="gif"
          style={{
            width: '100%',
            maxWidth: '850px',
            height: 'auto',
          }}
        />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', py: 4, justifyContent: 'center' }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={onPrev}
          disabled={disabled}
        >
          戻る
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ color: 'white' }}
          onClick={onNext}
          disabled={disabled}
        >
          次へ
        </Button>
      </DialogActions>
    </Dialog>
  )
}
