import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { AnalysisResult } from 'api/getV2Analysis'
import * as React from 'react'
import { Link } from 'react-router-dom'

type AnalysisSummaryTableProps = {
  analysis: AnalysisResult
}

const AnalysisSummaryTable: React.FC<AnalysisSummaryTableProps> = ({
  analysis,
}) => {
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: '240px' }}>
                {analysis.target_words}
              </TableCell>
              <TableCell style={{ minWidth: '100px' }}>
                {analysis.analysis_theme?.name}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {analysis.summaries.map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                <TableCell>
                  <Link
                    key={`side-item-${analysis.analysis_theme_id}-${analysis.id}-${row.id}`}
                    to={`/v2/themes/${analysis.analysis_theme_id}/keywords/${analysis.id}/summaries/${row.id}`}
                  >
                    {row.title}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default AnalysisSummaryTable
