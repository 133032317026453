import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

// Common
import { useApiContext } from 'App'
import Sugar from 'sugar'
import toastr from 'toastr'
import PlanType from '../enums/PlanType'
import useTour from '../hooks/useTour'
import { notifyError } from '../util/errorTracking'
import SideMemo from './SideMemo'
import PopupConfirm from './popup/PopupConfirm'
import PopupExecuteAnalysis from './popup/PopupExecuteAnalysis'
import PopupTourProgress from './popup/PopupTourProgress'
import PopupWelcome from './popup/PopupWelcome'

const Header = () => {
  const api = useApiContext()
  const url = api.host + api.users_api

  const [me, setMe] = useState({})
  const [memoModalOpen, setMemoModalOpen] = useState(false)

  const {
    tourStep,
    tourIndex,
    show: showAnalysisAdd,
    next,
    nextStep,
    moveToStep,
  } = useTour(0, 0)
  const { show: showAnalysisResultList } = useTour(1, 0)
  const { show: showAnalysisResult } = useTour(2, 0)

  useEffect(() => {
    if (tourStep === 3 && tourIndex === 0) {
      api.setHeaderOpenModalName('tourProgress')
    }
  }, [tourStep, tourIndex])

  const handleMemoModalOpen = () => {
    setMemoModalOpen(true)
  }

  const handleMemoModalClose = () => {
    setMemoModalOpen(false)
  }

  const addMarkdown = useRef(null)

  const video = useRef(null)
  const canvas = useRef(null)

  // popup
  // const [openname, setModalOpenName] = useState();
  const [flg, setFlg] = useState(true)
  const modalOpen = (modalname) => {
    if (flg) {
      setFlg(false)
    }
    api.setHeaderOpenModalName(modalname)
  }

  const modalClose = () => {
    if (flg) {
      return
    } else {
      setFlg(true)
    }
    api.setHeaderOpenModalName('')
  }

  // ログインユーザー情報
  useEffect(() => {
    axios
      .get(url)
      .then((result) => {
        setMe(result.data.data)
        api.setMe(result.data.data)
        // omitted(result.data.data)
        // console.log('result: ', result.data);
      })
      .catch((error) => {
        notifyError(error)
      })
  }, [url])

  const logout = (e) => {
    e.preventDefault()
    if (localStorage.getItem('jfc')) {
      localStorage.clear()
    }
    window.location.href = '/users/auth/logout'
  }

  const screenShot = async (e) => {
    e.preventDefault()

    const localMediaStream = await navigator.mediaDevices.getDisplayMedia({
      video: {
        cursor: 'never',
        displaySurface: 'browser',
      },
    })
    console.log(localMediaStream.getVideoTracks())
    video.current.srcObject = localMediaStream
    await new Promise((resolve) => setTimeout(resolve, 2000))
    canvas.current.width = document.body.offsetWidth
    canvas.current.height = document.body.offsetHeight
    await new Promise((resolve) => setTimeout(resolve, 1000))
    const ctx = canvas.current.getContext('2d')
    ctx.drawImage(video.current, 0, 0)
    // console.log(canvas.current.toDataURL('image/png'));
    localMediaStream.getTracks().forEach((x) => x.stop())
    video.current.srcObject = null

    toastr.info('画面メモを撮影中...')
    // const canvas = await html2canvas(document.querySelector("#root"));
    // const dataUrl = canvas.toDataURL("image/png");
    const dataUrl = canvas.current.toDataURL('image/png')
    const bin = atob(dataUrl.split(',')[1])
    const buffer = new Uint8Array(bin.length)
    for (let i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i)
    }
    const blob = new Blob([buffer.buffer], { type: 'image/png' })
    const timeString = Sugar.Date.format(new Date(), '%Y%m%d%H%M%S')
    const fileName = `${timeString}_画面メモ.png`
    const formData = new FormData()
    formData.append('file', blob, fileName)
    formData.append('file_type_id', 1)
    const url = api.host + api.storage_list_api
    const result = await axios.post(url, formData)
    console.log(result)
    toastr.success('画面メモを保存しました')

    const markdown = `[${result.data.data.name}](/api/storages/${result.data.data.id})`
    addMarkdown.current(markdown)
  }
  const handleChangeMemo = (func) => {
    addMarkdown.current = func
  }

  return (
    <>
      <header className="l-header">
        <video ref={video} className="u-dnone" autoPlay />
        <canvas ref={canvas} className="u-dnone" />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/" className="l-header__link">
            <h1 className="l-header__logo">
              <img src="/assets/images/vpfull-logo.png" alt="ViewPoint" />
            </h1>
          </Link>
          <div className="c-btn u-ml15">
            <div
              className="c-btn__link c-btn__link--size-s js-popOpen"
              data-pop="pop1"
              data-tour="analysis-add"
              onClick={() => {
                modalOpen('exec-analysis')
                if (tourStep === 0 && tourIndex === 0) next()
              }}
              style={{ cursor: 'pointer' }}
            >
              ＋ 分析を新規追加
            </div>
          </div>
          {api.me?.plan && (
            <div className="u-ml20">
              <div className="u-colorMain u-fontB" style={{ lineHeight: '1' }}>
                {api.me.plan?.id === PlanType.STANDARD
                  ? '基本プラン'
                  : '無料プラン'}
              </div>
              <div style={{ lineHeight: '1' }}>
                <span className="u-mr5">分析回数 あと</span>
                <span
                  className={`${
                    api.me.remaining_analysis_count <= 0
                      ? 'u-colorDanger'
                      : 'u-colorMain'
                  } "u-fontB"`}
                  style={{ fontSize: '22px' }}
                >
                  {api.me.remaining_analysis_count}
                </span>
                <span className="u-ml5">回</span>
              </div>
            </div>
          )}
        </div>
        <div
          className={
            api.headerOpenModalName === 'header'
              ? 'l-header__account c-accountMenuBtn is-open'
              : 'l-header__account c-accountMenuBtn'
          }
          onClick={() => modalClose()}
        >
          <div className="c-accountMenuBtn__btn">
            <div className="c-accountMenuBtn__btnInner js-accountMenuBtnOpen">
              <img
                height="30"
                width="75"
                style={{ opacity: 0.5 }}
                src="/assets/images/logo_discover-cloud.svg"
                alt="DiscoverCloud"
              />
              {/*<img height="30" width="150" src="/assets/images/circles.png" />*/}
              <a
                className="u-ml20"
                onClick={screenShot}
                style={{ cursor: 'pointer' }}
              >
                <span className="c-roundIcon c-roundIcon--hov-border c-roundIcon--hov-icon">
                  <img src="/assets/images/camera.svg" alt="" />
                  <img src="/assets/images/camera_blue.svg" alt="" />
                  <span
                    className="c-roundIcon__tooltip"
                    data-html2canvas-ignore
                  >
                    画面メモを保存する
                  </span>
                </span>
              </a>
              <input
                type="button"
                className="c-btn__link c-btn__link--light-gray c-btn__link--size-ss u-ml5"
                value="メモを開く"
                onClick={handleMemoModalOpen}
              />
              <div
                className={
                  memoModalOpen
                    ? 'c-memoForm c-memoForm--header is-open'
                    : 'c-memoForm c-memoForm--header'
                }
                style={{ pointerEvents: memoModalOpen ? undefined : 'none' }}
              >
                <SideMemo
                  handleMemoModalClose={handleMemoModalClose}
                  handleChangeMemo={handleChangeMemo}
                />
              </div>

              <span
                className="c-accountMenuBtn__icon c-roundIcon c-roundIcon--hov-bgGray c-roundIcon--hov-icon"
                onClick={() => modalOpen('header')}
              >
                <img src="/assets/images/icon_person_black01.svg" alt="" />
                <img src="/assets/images/icon_person_blue01.svg" alt="" />
              </span>
              <div
                className="c-accountMenuBtn__name"
                onClick={() => modalOpen('header')}
              >
                {me.email &&
                  me.email.substr(0, me.email.indexOf('@') + 1) + '...'}
              </div>
            </div>
          </div>
          <ul className="c-accountMenuBtn__list">
            <div className="u-dfl u-justifyBetween u-aiCenter u-ml20 u-mr20 u-mt5">
              <div>分析回数</div>
              <div className="u-dfl u-aiCenter">
                <div
                  className={`${
                    api.me.remaining_analysis_count <= 0
                      ? 'u-colorDanger'
                      : 'u-colorMain'
                  } u-fontB`}
                  style={{ fontSize: '14px' }}
                >
                  {api.me.remaining_analysis_count}
                </div>
                <div className="u-ml10">回</div>
              </div>
            </div>
            <div className="u-dfl u-justifyBetween u-aiCenter u-ml20 u-mr20">
              <div>重みつけ回数</div>
              <div className="u-dfl u-aiCenter">
                <div
                  className={`${
                    api.me.remaining_weighted_count <= 0
                      ? 'u-colorDanger'
                      : 'u-colorMain'
                  } u-fontB`}
                  style={{ fontSize: '14px' }}
                >
                  {api.me.remaining_weighted_count}
                </div>
                <div className="u-ml10">回</div>
              </div>
            </div>
            <div className="u-dfl u-justifyBetween u-aiCenter u-ml20 u-mr20 u-mb15">
              <div>簡易アンケート回数</div>
              <div className="u-dfl u-aiCenter">
                <div
                  className={`${
                    api.me.remaining_questionnaire_count <= 0
                      ? 'u-colorDanger'
                      : 'u-colorMain'
                  } u-fontB`}
                  style={{ fontSize: '14px' }}
                >
                  {api.me.remaining_questionnaire_count}
                </div>
                <div className="u-ml10">回</div>
              </div>
            </div>
            <div className="u-dfl u-justifyBetween u-aiCenter u-ml20 u-mr20 u-mb15">
              <div>共感調整依頼回数</div>
              <div className="u-dfl u-aiCenter">
                <div
                  className={`${
                    api.me.remaining_adjustment_request_count <= 0
                      ? 'u-colorDanger'
                      : 'u-colorMain'
                  } u-fontB`}
                  style={{ fontSize: '14px' }}
                >
                  {api.me.remaining_adjustment_request_count}
                </div>
                <div className="u-ml10">回</div>
              </div>
            </div>
            <Link to="/settings/user" style={{ cursor: 'pointer' }}>
              <div className="u-ml20 u-mt5">ユーザー情報</div>
            </Link>
            <Link to="/settings/contract" style={{ cursor: 'pointer' }}>
              <div className="u-ml20 u-mt5">ご契約内容</div>
            </Link>
            <Link to="/settings/contact" style={{ cursor: 'pointer' }}>
              <div className="u-ml20 u-mt5">お問い合わせ</div>
            </Link>
            <li className="u-mt15">
              <Link to="#" onClick={(e) => logout(e)}>
                ログアウト
              </Link>
            </li>
          </ul>
        </div>
      </header>

      <PopupExecuteAnalysis
        openname={api.headerOpenModalName}
        modalCloseCallback={modalClose}
      />

      <PopupTourProgress
        openname={api.headerOpenModalName}
        modalCloseCallback={() => {
          // modalClose();
          setFlg(false)
          api.setHeaderOpenModalName('confirm')
        }}
        okCallback={() => {
          // modalClose();
          setFlg(false)
          api.setHeaderOpenModalName('')
          showAnalysisAdd()
          showAnalysisResultList()
          showAnalysisResult()
          if (tourStep === 3 && tourIndex === 0) nextStep()
        }}
        skipCallback={() => {
          // modalClose();
          setFlg(false)
          api.setHeaderOpenModalName('confirm')
        }}
        progress={tourStep}
      />

      <PopupWelcome
        openname={api.headerOpenModalName}
        modalCloseCallback={() => api.setHeaderOpenModalName('confirm')}
        okCallback={() => api.setHeaderOpenModalName('tourProgress')}
      />

      <PopupConfirm
        openname={api.headerOpenModalName}
        title="プロダクトツアーを終了しますか？"
        message="終了すると、次回から表示されません"
        okLabel="終了する"
        modalCloseCallback={() => api.setHeaderOpenModalName('')}
        okCallback={() => {
          moveToStep(4)
          api.setHeaderOpenModalName('')
        }}
      />
    </>
  )
}

export default Header
