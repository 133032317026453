import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

type RegisterPaidMemberInput = {
  companyName: string
  companyNameKana: string
  zipCode: string
  prefecture: string
  address1: string
  address2: string
  address3?: string
  tel: string
  representativeSei: string
  representativeMei: string
  representativeSeiKana: string
  representativeMeiKana: string
  clerkSei: string
  clerkMei: string
  clerkSeiKana: string
  clerkMeiKana: string
  email: string
  clerkTel?: string
  clerkMobileTel?: string
  frdKey: string
  billingSei?: string
  billingMei?: string
  billingSeiKana?: string
  billingMeiKana?: string
  billingZipCode?: string
  billingPrefecture?: string
  billingAddress1?: string
  billingAddress2?: string
  billingAddress3?: string
}

type RegisterPaidMemberPayload = {
  message: string
  paid_id: string | undefined
}

export const registerPaidMember = async (input: RegisterPaidMemberInput) => {
  const response = await axios.post<RegisterPaidMemberPayload>(
    '/api/paid/register_members',
    {
      company_name: input.companyName,
      company_name_kana: input.companyNameKana,
      zip_code: input.zipCode,
      prefecture: input.prefecture,
      address1: input.address1,
      address2: input.address2,
      address3: input.address3,
      tel: input.tel,
      representative_sei: input.representativeSei,
      representative_mei: input.representativeMei,
      representative_sei_kana: input.representativeSeiKana,
      representative_mei_kana: input.representativeMeiKana,
      clerk_sei: input.clerkSei,
      clerk_mei: input.clerkMei,
      clerk_sei_kana: input.clerkSeiKana,
      clerk_mei_kana: input.clerkMeiKana,
      email: input.email,
      clerk_tel: input.clerkTel,
      clerk_mobile_tel: input.clerkMobileTel,
      frd_key: input.frdKey,
      billing_sei: input.billingSei,
      billing_mei: input.billingMei,
      billing_sei_kana: input.billingSeiKana,
      billing_mei_kana: input.billingMeiKana,
      billing_zip_code: input.billingZipCode,
      billing_prefecture: input.billingPrefecture,
      billing_address1: input.billingAddress1,
      billing_address2: input.billingAddress2,
      billing_address3: input.billingAddress3,
    },
  )

  return response.data
}

export const useRegisterPaidMember = (
  option?: UseMutationOption<RegisterPaidMemberPayload>,
) => useMutation(registerPaidMember, option)
