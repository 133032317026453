import axios from 'axios'
import { useEffect, useState } from 'react'
// @ts-ignore
import { useApiContext } from 'App'
// DatePicker
import ja from 'date-fns/locale/ja'
import { useAnalysis } from 'hooks/useAnalysis'
import { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import PlanType from '../../enums/PlanType.js'
import useTour from '../../hooks/useTour.js'
import { notifyError } from '../../util/errorTracking'
import AnaLing from '../Analing.js'

registerLocale('ja', ja)

const ExternalSiteIcon = () => {
  return (
    <span style={{ position: 'relative', marginLeft: 2 }}>
      <svg
        width="1.5em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ position: 'absolute' }}
      >
        <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
        <polyline points="15 3 21 3 21 9"></polyline>
        <line x1="10" y1="14" x2="21" y2="3"></line>
      </svg>
    </span>
  )
}

type Props = {
  openname: string
  modalCloseCallback: () => {}
}

type GenerateExceptWordArgs = {
  isExceptAd: boolean
  isExceptSpam: boolean
  exceptWord: string
}

// @ts-ignore
const PopupExecuteAnalysis = (props: Props) => {
  const api = useApiContext()
  // @ts-ignore
  const url = api.host + api.analysis_list_api
  const [isAnaling, setIsAnaling] = useState(false)
  const [targetWord, setTargetWord] = useState('')
  const [exceptWord, setExceptWord] = useState('')
  const [hashtagString, setHashtagString] = useState('')
  const [itemCount, setItemCount] = useState(1000)
  const [proposal, setProposal] = useState('')
  const [isSending, setIsSending] = useState(false)
  const [isExceptSpam, setIsExceptSpam] = useState(false)
  const [isExceptAd, setIsExceptAd] = useState(false)
  const { tourStep, tourIndex, show, nextStep } = useTour(0, 1)

  const { mutate: mutateAnalysisResult } = useAnalysis()
  const { mutate: mutateHolderedAnalysisResult } = useAnalysis(true)

  const sendData = async () => {
    if (isSending) return
    setIsAnaling(true)
    setIsSending(true)
    try {
      const res = await axios.post(url, {
        target_words: targetWord,
        except_words: generateExceptWord({
          isExceptAd,
          isExceptSpam,
          exceptWord,
        }),
        count: itemCount,
        proposal: proposal,
      })
      const id = res.data.data.id
      const hashtags = hashtagString
        .split(/\s/g)
        .map((x) => x.replace(/#/g, ''))

      if (hashtags.length > 0) {
        await axios.put(`${url}/${id}/hashtags`, {
          hashtags: hashtags,
        })
      }
      // @ts-ignore
      const newMe = await axios.get(api.host + api.users_api)
      console.log(newMe)
      // @ts-ignore
      api.setMe(newMe.data.data)
      setIsSending(false)
      setTimeout(() => {
        setIsAnaling(false)
        clearState()
        if (tourStep === 0 && tourIndex === 3) {
          nextStep()
        }
        props.modalCloseCallback()
        mutateAnalysisResult()
        mutateHolderedAnalysisResult()
      }, 3000)
    } catch (error: any) {
      notifyError(error)
      setIsSending(false)
      setTimeout(() => {
        setIsAnaling(false)
        clearState()
      }, 3000)
    }
  }

  const clearState = () => {
    setTargetWord('')
    setHashtagString('')
    setProposal('')
    setItemCount(1000)
  }

  // 分析キーワード
  const handleSearch = (e: any) => {
    const splitValue = e.target.value.trim().split(/\s+/)

    const val = splitValue.splice(0, 1)
    val.push(splitValue.join(' '))
    if (val[0]) {
      setTargetWord(val[0])
    } else {
      setTargetWord('')
    }
    if (val[1]) {
      setExceptWord(val[1])
    } else {
      setExceptWord('')
    }
  }

  const generateExceptWord = (args: GenerateExceptWordArgs): string => {
    const { isExceptAd, isExceptSpam, exceptWord } = args
    let result = exceptWord
    if (isExceptAd) {
      result += ' -ad -広告 -pr'
    }

    if (isExceptSpam) {
      result += ' -Rakuten -楽天 -Amazon -ランキング -送料'
    }
    return result
  }

  // @ts-ignore
  useEffect(() => {
    setItemCount(1000)
  }, [])

  useEffect(() => {
    console.log('tourIndex', tourIndex, 'tourStep', tourStep)
    if (props.openname === 'exec-analysis') show()
  }, [tourIndex])

  const onModalClose = () => {
    const VideoElement = document.getElementById('video')
    // @ts-ignore
    if (VideoElement) VideoElement.pause()
    props.modalCloseCallback()
  }

  return (
    <div
      className={props.openname === 'exec-analysis' ? 'p-pop is-open' : 'p-pop'}
      data-name="pop6"
    >
      <div
        className="p-pop__overlay js-popClose"
        onClick={() => {
          onModalClose()
          clearState()
        }}
      />
      <div className="p-pop__inner bOverright">
        <div
          className="p-pop__closeBtn js-popClose"
          onClick={() => onModalClose()}
        />

        <div className="p-pop__cont wrap-more-item">
          <div className="formb-content">
            <h2 className="c-popHeading">分析を新規作成</h2>
            {isAnaling && <AnaLing />}

            <form onSubmit={(e) => {}}>
              <div className="brow30">
                <div className="c-conditionList">
                  <div className="c-conditionList__inner">
                    <dl className="c-conditionList__list">
                      <dd
                        className="c-conditionList__cont"
                        data-tour="target_word"
                      >
                        <span className="c-inputForm">
                          <input
                            type="text"
                            name="target_word"
                            placeholder="分析したいキーワードを入力"
                            onChange={(e) => handleSearch(e)}
                          />
                        </span>
                      </dd>
                      <dt className="c-conditionList__title">ツイートの除外</dt>
                      <dd className="c-conditionList__cont">
                        <label
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            paddingBottom: 10,
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{
                              flexShrink: 0,
                            }}
                            checked={isExceptAd}
                            onChange={(e) => {
                              setIsExceptAd(e.target.checked)
                            }}
                          />
                          <p
                            style={{
                              fontSize: '13px',
                            }}
                          >
                            広告系ツイートを除外する。
                            <br />
                            以下のようなキーワードが含まれたツイートが除外されます:
                            ad 広告 pr
                          </p>
                        </label>

                        <label
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{
                              flexShrink: 0,
                            }}
                            checked={isExceptSpam}
                            onChange={(e) => {
                              setIsExceptSpam(e.target.checked)
                            }}
                          />
                          <p
                            style={{
                              fontSize: '13px',
                            }}
                          >
                            スパム系ツイートを除外する。
                            <br />
                            以下のようなキーワードが含まれたツイートが除外されます:
                            Rakuten 楽天 Amazon ランキング 送料
                          </p>
                        </label>
                      </dd>
                      <dt className="c-conditionList__title">
                        このキーワードの関連タグ
                      </dt>
                      <dd className="c-conditionList__cont">
                        <span className="c-inputForm">
                          <input
                            type="text"
                            name="hashtags"
                            value={hashtagString}
                            placeholder="#"
                            onChange={(e) => {
                              setHashtagString(e.target.value)
                            }}
                          />
                        </span>
                      </dd>
                      <dt className="c-conditionList__title">抽出する件数</dt>
                      <dd className="c-conditionList__cont">
                        <div
                          // @ts-ignore
                          disabled={true}
                          className="u-w100  b-select"
                          value={itemCount}
                          // onChange={(e) => {
                          //   setItemCount(parseInt(e.target.value, 10));
                          // }}
                        >
                          <span>1000</span>
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>

                <div
                  className="u-mt40 u-w65per u-borderMain"
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    borderWidth: 2,
                    borderStyle: 'dashed',
                    borderRadius: 5,
                    position: 'relative',
                    height: '100%',
                  }}
                >
                  <h2
                    className="c-popHeading"
                    style={{
                      textAlign: 'center',
                      position: 'absolute',
                      right: 0,
                      left: 0,
                      top: '-1.5em',
                    }}
                  >
                    <span
                      className="u-colorOrange"
                      style={{
                        padding: '0 .5em',
                        backgroundColor: 'white',
                        fontSize: '1.5em',
                      }}
                    >
                      分析キーワードに悩んだときは？
                    </span>
                  </h2>
                  <div style={{ padding: '1.2em 1em 1em' }}>
                    <div
                      className="u-dfl"
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p className="u-colorBk">
                        <ul className="u-mt10">
                          <li>
                            <a
                              className="u-mr25"
                              // @ts-ignore
                              href={`https://vp-keyword-tool.vercel.app/?user_id=${api.me.id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              ViewPersキーワードツール
                              <ExternalSiteIcon />
                            </a>
                            活用ください。
                          </li>
                        </ul>
                      </p>
                    </div>
                    <p className="u-mt20 u-colorBk">
                      ご自身のプロジェクトや商品・サービスに関するキーワードを入力して、
                      <br />
                      関連キーワードを発見しましょう
                    </p>
                  </div>
                </div>
              </div>
              <div className="c-conditionList__btn c-btn">
                {/* @ts-ignore */}
                {api.me?.remaining_analysis_count <= 0 ||
                targetWord.length === 0 ||
                isSending ? (
                  <input
                    type="button"
                    data-tour="execute-analysis"
                    className={`c-btn__link c-btn__link--size-l js-popClose u-bgLightGray`}
                    value="このキーワードで分析開始"
                    disabled
                    style={{ cursor: 'default', marginTop: '65px' }}
                  />
                ) : (
                  <input
                    type="button"
                    data-tour="execute-analysis"
                    className={'c-btn__link c-btn__link--size-l js-popClose'}
                    value="このキーワードで分析開始"
                    onClick={() => sendData()}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </div>
              {/* @ts-ignore */}
              {api.me?.remaining_analysis_count <= 0 && (
                <div className="u-mt20 u-tc">
                  {/* @ts-ignore */}
                  {api.me.plan?.id === PlanType.FREEMIUM ? (
                    <p className="u-colorRed">
                      分析可能回数を超えました。プランの変更を是非ご検討ください！
                    </p>
                  ) : (
                    <p className="u-colorRed">分析可能回数を超えました。</p>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupExecuteAnalysis
