import { PaginateTypeResponse, parsePaginate } from 'api/util'
import { useMemo } from 'react'
import useSWRInfinite from 'swr/infinite'
import { V2TweetResponse, parseV2Tweet } from 'types/tweet'

const getMockPath = ({ keywordId, ...rest }: Props) => {
  if (keywordId === undefined) return null
  const common = `/assets/v2-example/tweets/${keywordId}/${rest.type}`
  if (rest.type === 'all') return common + '.json'
  if (rest.type === 'each') return common + '/' + rest.tweetIds[0] + '.json'
  if (rest.type === 'topic') return common + '/' + rest.topicId + '.json'
  return null
}

const getKey = ({
  pageIndex,
  previousPageData,
  keywordId,
  text,
  ...rest
}: {
  pageIndex: number
  previousPageData: GetV2TweetsResponse | null
} & Props): string | null => {
  if (keywordId === undefined) return null

  if (
    previousPageData &&
    previousPageData.data &&
    previousPageData.data.length === 0
  )
    return null

  let requestUrl = `/api/results/${keywordId}/tweets?type=${rest.type}&page=${
    pageIndex + 1
  }`

  if (text !== undefined && text !== '') requestUrl += `&text=${text}`

  if (rest.type === 'all') {
    return requestUrl
  }

  if (rest.type === 'topic') {
    if (rest.topicId === undefined) return null
    return requestUrl + `&topic_id=${rest.topicId}`
  }

  if (rest.tweetIds.length === 0) return null

  let res = requestUrl
  for (const tweetId of rest.tweetIds) res += `&tweet_ids[]=${tweetId}`
  return res
}

type GetV2TweetsResponse = PaginateTypeResponse<V2TweetResponse>

type Props = {
  keywordId?: number
  text?: string
} & (
  | {
      type: 'all'
    }
  | {
      type: 'each'
      tweetIds: string[]
    }
  | {
      type: 'topic'
      topicId: string
    }
)

export const useGetV2Tweets = (props: Props) => {
  const isMock = (props.keywordId ?? 0) < 0

  const { data, ...rest } = useSWRInfinite<
    PaginateTypeResponse<V2TweetResponse>
  >(
    (pageIndex, previousPageData) =>
      isMock
        ? getMockPath(props)
        : getKey({
            pageIndex,
            previousPageData,
            ...props,
          }),
    isMock ? (url) => fetch(url).then((res) => res.json()) : null,
    {
      suspense: true,
      ...(isMock
        ? {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            revalidateOnMount: false,
            revalidanteOnStale: false,
          }
        : {}),
    },
  )

  const result = useMemo(() => {
    if (data === undefined) return []

    if (!isMock) {
      return data.flatMap((dt) => parsePaginate(dt, parseV2Tweet))
    }
    return data.flatMap((dt) => {
      const re = parsePaginate(dt, parseV2Tweet)
      return {
        ...re,
        data: re.data.filter(({ text }) => text.includes(props.text ?? '')),
      }
    })
  }, [props, data])

  return {
    data: result,
    ...rest,
  }
}
