import { Typography } from '@mui/material'
import AuthPageLayout from 'pages/components/AuthPageLayout'
import PasswordResetForm from 'pages/passwordReset/components/PasswordResetForm'
import { Link as RouteLink } from 'react-router-dom'
const PasswordReset = () => {
  return (
    <AuthPageLayout>
      <Typography
        variant="body2"
        sx={{
          fontSize: '14px',
        }}
      >
        パスワードを再設定メールを再送信する場合は
        <RouteLink
          to="/password-reset-email"
          style={{
            fontSize: '14px',
            textDecoration: 'none',
            display: 'inline',
            color: '#FF8800',
          }}
        >
          こちら
        </RouteLink>
      </Typography>
      <PasswordResetForm />
    </AuthPageLayout>
  )
}

export default PasswordReset
