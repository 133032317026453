import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, FormControl, TextField } from '@mui/material'
import { resendEmailVerificationSchema } from 'pages/signupVerification/scheme'
import React, { useCallback } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

type ResendEmailVerificationFormProps = {
  requesting: boolean
  request: (data: FormData) => Promise<void>
}

type FormData = z.infer<typeof resendEmailVerificationSchema>

const ResendEmailVerificationForm: React.VFC<
  ResendEmailVerificationFormProps
> = ({ request, requesting }) => {
  const method = useForm({
    resolver: zodResolver(resendEmailVerificationSchema),
    defaultValues: {
      email: '',
    },
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = method

  const onSubmit = useCallback(
    (data: FormData) => {
      request(data)
    },
    [request],
  )

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            py: 4,
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              flexDirection: 'column',
            }}
          >
            <FormControl
              error={!!errors?.email}
              sx={{
                width: '100%',
              }}
            >
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    type="email"
                    label="メールアドレス"
                    placeholder="viewpers@example.com"
                    variant="outlined"
                    error={!!errors?.email}
                    helperText={
                      errors?.email?.message ?? errors?.email?.message
                    }
                    sx={{
                      maxWidth: '360px',
                      '& .MuiInputBase-input': {
                        height: 'auto',
                        padding: '10px 14px',
                      },
                    }}
                    InputProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Button
            variant="contained"
            type="submit"
            sx={{ px: 7, fontSize: '16px', color: 'white' }}
            disabled={requesting}
          >
            メール再送信
          </Button>
        </Box>
      </form>
    </FormProvider>
  )
}

export default ResendEmailVerificationForm
