import { SWRConfiguration } from 'swr'
import useSWRInfinite from 'swr/infinite'
import {
  GetAnnouncementsResponse,
  parseAnnouncements,
} from 'types/announcements'

const getKey = ({
  pageIndex,
  status,
  keyword,
  sort,
}: {
  pageIndex: number
  status: string | null
  keyword: string | null
  sort: string | null
}): string | null => {
  let requestUrl = `/api/announcements?page=${pageIndex + 1}`
  if (status !== null) requestUrl += `&status=${status}`
  if (keyword !== null) requestUrl += `&keyword=${keyword}`
  if (sort !== null) requestUrl += `&sort=${sort}`
  return requestUrl
}

type Props = {
  query: {
    status: string | null
    keyword: string | null
    sort: string | null
  }
  options: SWRConfiguration<any, Error>
}
export const useGetAnnouncements = ({ query, options }: Props) => {
  const { data, ...rest } = useSWRInfinite<GetAnnouncementsResponse>(
    (pageIndex) =>
      getKey({
        pageIndex,
        status: query.status,
        keyword: query.keyword,
        sort: query.sort,
      }),
    {
      ...options,
    },
  )

  return {
    data:
      data !== undefined ? data.flatMap((d) => parseAnnouncements(d.data)) : [],
    ...rest,
  }
}
