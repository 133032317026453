import { Typography } from '@mui/material'
import { VFC } from 'react'

type Props =
  | { isPublic: false }
  | {
      isPublic: true
      title: string
    }

const AnalysisSummaryIdeaTitle: VFC<Props> = ({ ...rest }) => {
  return (
    <Typography fontWeight="bold">
      {rest.isPublic
        ? rest.title
        : '解析中です、しばらくお待ち下さい。約1〜2営業日で表示されます。'}
    </Typography>
  )
}

export default AnalysisSummaryIdeaTitle
