import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography,
} from '@mui/material'
import { useScrollHelper } from 'hooks/useScrollHelper'
import {
  genderOptions,
  generationOptions,
  prefectureOptions,
} from 'pages/v2/components/ResearchTargetInputForm'
import { FormData } from 'pages/v2/NewKeyword/components/NewKeywordForm'
import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export const ResearchTargetInputForm = () => {
  const { ref: researchTargetRef, handleScroll: handleScrollToResearchTarget } =
    useScrollHelper()

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<FormData>()

  const selectedPrefectures = watch('researchTarget.prefectures') ?? []
  const selectedGenerations = watch('researchTarget.generations') ?? []

  useEffect(() => {
    if (errors.researchTarget !== undefined) {
      handleScrollToResearchTarget()
    }
  }, [errors.researchTarget])

  return (
    <FormControl
      error={!!errors?.researchTarget}
      sx={{
        display: 'flex',
        gap: 3,
        width: '100%',
      }}
    >
      <FormLabel required sx={{ textAlign: 'start' }}>
        リサーチターゲットを指定してください。
      </FormLabel>
      <Grid
        container
        sx={{
          width: 'full',
          rowGap: 4,
        }}
        ref={researchTargetRef}
      >
        <Grid item xs={12} md={6} xl={2}>
          <FormControl
            error={!!errors.researchTarget?.gender}
            sx={{ width: '100%' }}
          >
            <FormLabel required sx={{ textAlign: 'start' }}>
              性別
            </FormLabel>
            <RadioGroup
              value={watch('researchTarget.gender')}
              sx={{
                maxHeight: { xs: '80px', md: '100px', xl: '200px' },
              }}
            >
              {genderOptions.map(({ value, label }) => (
                <FormControlLabel
                  key={value}
                  label={label}
                  value={value}
                  control={
                    <Controller
                      name="researchTarget.gender"
                      control={control}
                      render={({ field: { onChange, ref } }) => (
                        <Radio
                          inputRef={ref}
                          onChange={() => onChange(value)}
                          value={value}
                        />
                      )}
                    />
                  }
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                      whiteSpace: 'nowrap',
                    },
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} xl={2}>
          <FormControl
            error={!!errors.researchTarget?.generations}
            sx={{ width: '100%' }}
          >
            <FormLabel required sx={{ textAlign: 'start' }}>
              年代
            </FormLabel>
            {errors.researchTarget?.generations && (
              <FormLabel color="error" sx={{ pt: 2 }}>
                {errors.researchTarget.generations.message}
              </FormLabel>
            )}
            <FormGroup
              sx={{
                maxHeight: { xs: '150px', md: '200px', xl: '400px' },
                overflow: 'hidden',
              }}
            >
              {generationOptions.map(({ value, label }) => (
                <FormControlLabel
                  key={value}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                      whiteSpace: 'nowrap',
                    },
                  }}
                  label={label}
                  control={
                    <Controller
                      name="researchTarget.generations"
                      control={control}
                      render={({ field: { onChange, onBlur, ref } }) => (
                        <Checkbox
                          sx={{
                            '& .MuiSvgIcon-root': { fontSize: 24 },
                          }}
                          inputProps={{
                            style: { position: 'absolute' },
                          }}
                          onBlur={onBlur}
                          onChange={(e) => {
                            if (value === 'All' && e.target.checked) {
                              onChange(['All'])
                            } else {
                              const newValue = e.target.checked
                                ? [...selectedGenerations, value]
                                : selectedGenerations.filter(
                                    (generation) => generation !== value,
                                  )
                              onChange(newValue)
                            }
                          }}
                          checked={selectedGenerations.includes(value)}
                          inputRef={ref}
                          value={value}
                          disabled={
                            selectedGenerations.includes('All') &&
                            value !== 'All'
                          }
                        />
                      )}
                    />
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} xl={8}>
          <FormControl
            error={!!errors.researchTarget?.prefectures}
            sx={{ width: '100%' }}
          >
            <FormLabel required sx={{ textAlign: 'start' }}>
              居住地
            </FormLabel>
            {errors.researchTarget?.prefectures && (
              <FormLabel color="error" sx={{ pt: 2 }}>
                {errors.researchTarget.prefectures.message}
              </FormLabel>
            )}
            <FormGroup
              sx={{
                maxHeight: { xs: '520px', md: '420px', xl: '300px' },
                display: 'flex',
              }}
            >
              {prefectureOptions.map(({ value, label }) => (
                <FormControlLabel
                  key={value}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                      whiteSpace: 'nowrap',
                    },
                  }}
                  label={label}
                  control={
                    <Controller
                      name="researchTarget.prefectures"
                      control={control}
                      render={({ field: { onChange, onBlur, ref } }) => (
                        <Checkbox
                          sx={{
                            '& .MuiSvgIcon-root': { fontSize: 24 },
                          }}
                          inputProps={{
                            style: { position: 'absolute' },
                          }}
                          onBlur={onBlur}
                          onChange={(e) => {
                            if (value === 'all' && e.target.checked) {
                              onChange(['all'])
                            } else {
                              const newValue = e.target.checked
                                ? [...selectedPrefectures, value]
                                : selectedPrefectures.filter(
                                    (prefecture) => prefecture !== value,
                                  )
                              onChange(newValue)
                            }
                          }}
                          checked={selectedPrefectures.includes(value)}
                          disabled={
                            selectedPrefectures.includes('all') &&
                            value !== 'all'
                          }
                          inputRef={ref}
                          value={value}
                        />
                      )}
                    />
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>

      <Accordion
        elevation={0}
        disableGutters
        sx={{
          backgroundColor: 'transparent',
          '&::before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ArrowForwardIosIcon sx={{ fontSize: '0.9rem' }} />}
          sx={{
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              transform: 'rotate(90deg)',
            },
            '& .MuiAccordionSummary-content': {
              marginLeft: 2,
            },
            fontWeight: 'bold',
            textDecoration: 'underline',
            px: 0,
          }}
        >
          詳細に設定する
        </AccordionSummary>
        <AccordionDetails
          sx={{ gap: 4, display: 'flex', flexDirection: 'column', px: 0 }}
        >
          <Box>
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '14px',
                  whiteSpace: 'nowrap',
                },
              }}
              control={
                <Controller
                  name="researchTarget.specialityPanel"
                  control={control}
                  render={({ field: { onChange, ref } }) => (
                    <Checkbox
                      inputRef={ref}
                      onChange={(e) => onChange(e.target.checked)}
                      checked={watch('researchTarget.specialityPanel')}
                    />
                  )}
                />
              }
              label="スペシャリティパネルを利用する"
            />
            <Typography variant="body2">
              別途担当よりご連絡いたします。
            </Typography>
          </Box>
          <FormControl
            sx={{
              width: '100%',
              '& .MuiFormControl-root': {
                width: '100%',
              },
            }}
          >
            <FormLabel>その他依頼</FormLabel>
            <Controller
              name="researchTarget.note"
              control={control}
              render={({ field }) => (
                <TextareaAutosize {...field} minRows={5} />
              )}
            />
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </FormControl>
  )
}
