import { zodResolver } from '@hookform/resolvers/zod'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useGetAnalysisThemes } from 'api/getAnalysisThemes'
import { useCurrentUser } from 'api/getCurrentUser'
import { useRecordLog } from 'api/recordLog'
import { useDisclosure } from 'hooks/useDisclosure'
import { useNeedKeywordCheckout } from 'hooks/useNeedCheckout'
import useProductInfo from 'hooks/useProductInfo'
import { useScrollHelper } from 'hooks/useScrollHelper'
import useSelectedTheme from 'hooks/useSelectedTheme'
import {
  AgeRecord,
  GenderRecord,
} from 'pages/v2/components/ResearchTargetInputForm'
import {
  themeTypeOptions,
  userVoiceTypeOptions,
} from 'pages/v2/NewAnalysisTheme/components/NewAnalysisThemeForm'
import { v2NewKeywordSchema } from 'pages/v2/NewKeyword/schema'
import KeywordCheckoutDialog from 'pages/v2/sp/checkout/components/KeywordCheckoutDialog'
import PaymentButton from 'pages/v2/sp/components/common/PaymentButton'
import NoRemainingCountMessage from 'pages/v2/sp/components/NoRemainingCountMessage'
import { ResearchTargetInputForm } from 'pages/v2/sp/components/ResearchTargetInputForm'
import React, { MutableRefObject, useEffect, useMemo } from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { AnalysisTheme } from 'types/analysisTheme'
import { z } from 'zod'

export type FormData = z.infer<typeof v2NewKeywordSchema>

type Props = {
  onSubmit: (data: FormData) => void
  requesting?: boolean
  checkoutMode: boolean
  defaultValues?: FormData
}

export const NewKeywordForm = ({
  onSubmit,
  requesting,
  checkoutMode,
  defaultValues,
}: Props) => {
  const baseExcludedWordsSuggestOptions = [
    { label: '広告投稿(“ad”が含まれるポストを除外します)', value: ['ad'] },
    { label: '広告投稿(“広告”が含まれるポストを除外します)', value: ['広告'] },
    { label: '広告投稿(“PR”が含まれるポストを除外します)', value: ['PR'] },
    {
      label: '広告投稿(“Amazon” “Rakuten” “楽天”が含まれるポストを除外します)',
      value: ['Amazon', 'Rakuten', 'amazon', 'rakuten', '楽天'],
    },
  ]

  const { ref: themeRef, handleScroll: handleScrollToTheme } = useScrollHelper()
  const { ref: keywordRef, handleScroll: handleScrollToKeywordName } =
    useScrollHelper()

  const { request } = useRecordLog({})
  const { currentUser } = useCurrentUser()
  const { data: analysisThemes } = useGetAnalysisThemes({})
  const { selectedTheme } = useSelectedTheme()
  const [otherExcludeWord, setOtherExcludeWord] = React.useState('')
  const location = useLocation<{ afterCreateTheme: boolean }>()
  const afterCreateTheme = location.state?.afterCreateTheme
  const { productInfo } = useProductInfo('keyword')
  const {
    isOpen: isOpenKeyword,
    onClose: onCloseKeyword,
    onOpen: onOpenKeyword,
  } = useDisclosure()
  const method = useForm<FormData>({
    resolver: zodResolver(v2NewKeywordSchema),
    defaultValues: {
      researchTarget: {
        gender: defaultValues?.researchTarget?.gender ?? 'both',
        generations: defaultValues?.researchTarget?.generations ?? [],
        prefectures: defaultValues?.researchTarget?.prefectures ?? [],
        specialityPanel:
          defaultValues?.researchTarget?.specialityPanel ?? false,
        note: defaultValues?.researchTarget?.note ?? '',
      },
      keyword: {
        name: defaultValues?.keyword?.name ?? '',
        selectedExcludedWords: defaultValues?.keyword
          ?.selectedExcludedWords ?? [
          ...(afterCreateTheme
            ? baseExcludedWordsSuggestOptions.flatMap((option) => option.value)
            : []),
        ],
        excludedWords: defaultValues?.keyword?.excludedWords ?? [],
      },
      analysisThemeId: defaultValues?.analysisThemeId ?? undefined,
      theme: {
        name: defaultValues?.theme?.name ?? '',
        type: defaultValues?.theme?.type ?? 'newBusiness',
      },
    },
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = method

  const researchTarget = watch('researchTarget')
  const keyword = watch('keyword')
  const currentKeywordName = keyword.name
  const currentExcludedWords = keyword.excludedWords ?? []
  const currentSelectedExcludedWords = keyword.selectedExcludedWords ?? []
  const currentSelectedAnalysisThemeId = watch('analysisThemeId')
  const currentTheme = watch('theme')

  const excludedWordsSuggestOptions = useMemo(() => {
    for (const word of defaultValues?.keyword?.selectedExcludedWords ?? []) {
      const isExist = baseExcludedWordsSuggestOptions.some((option) =>
        option.value.includes(word),
      )
      if (!isExist) {
        baseExcludedWordsSuggestOptions.push({
          label: word,
          value: [word],
        })
      }
    }

    return baseExcludedWordsSuggestOptions
  }, [defaultValues?.keyword?.selectedExcludedWords])

  const needCheckout = useNeedKeywordCheckout(checkoutMode)

  useEffect(() => {
    if (errors.keyword !== undefined) {
      handleScrollToKeywordName()
    }
  }, [errors.keyword])

  useEffect(() => {
    if (!currentSelectedAnalysisThemeId && errors.theme !== undefined) {
      handleScrollToTheme()
    }
  }, [errors.theme, currentSelectedAnalysisThemeId])

  return (
    <FormProvider {...method}>
      <form
        onSubmit={handleSubmit(
          needCheckout
            ? async () => {
                onOpenKeyword()
                await request({
                  event: 'keyword_checkout_dialog_open',
                  userId: currentUser?.id ?? 0,
                  parameters: {
                    checkout_dialog_open_at: new Date().toISOString(),
                  },
                })
              }
            : onSubmit,
        )}
      >
        <Box
          sx={{
            display: 'flex',
            py: 4,
            flexDirection: 'column',
            alignItems: 'center',
            gap: 5,
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 5,
              flexDirection: 'column',
            }}
          >
            <Box
              ref={themeRef}
              sx={{
                width: '100%',
              }}
            >
              <ThemeField
                analysisThemes={(analysisThemes ?? []).filter(
                  (theme) => !theme.isSample,
                )}
                isCreate={defaultValues?.analysisThemeId === undefined}
              />
            </Box>
            <KeywordFormSection
              title="リサーチターゲットを選択してください"
              info="共感度を聞く対象が変わります"
            >
              <ResearchTargetInputForm />
            </KeywordFormSection>
            <KeywordFormSection
              title="キーワード"
              info="一致するキーワードを検索します"
              ref={keywordRef}
            >
              <KeywordNameField errors={errors} control={control} />
            </KeywordFormSection>
            <KeywordFormSection
              title="除外キーワード設定"
              info="一致したキーワードを除外して検索します"
            >
              <FormControl
                error={!!errors.keyword?.excludedWords}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                <FormGroup defaultValue={watch('keyword.excludedWords')}>
                  {excludedWordsSuggestOptions.map(({ label, value }) => (
                    <FormControlLabel
                      key={value.join(',')}
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontSize: 'small',
                        },
                      }}
                      label={label}
                      control={
                        <Controller
                          name="keyword.selectedExcludedWords"
                          control={control}
                          render={({ field: { onChange, onBlur, ref } }) => (
                            <Checkbox
                              sx={{
                                '& .MuiSvgIcon-root': { fontSize: 24 },
                                // checkboxの背景を白くする
                                '& .MuiCheckbox-root': {
                                  color: 'white',
                                },
                              }}
                              inputProps={{
                                style: {
                                  position: 'absolute',
                                },
                              }}
                              onBlur={onBlur}
                              onChange={(e) => {
                                const newValue = e.target.checked
                                  ? [...currentSelectedExcludedWords, ...value]
                                  : currentSelectedExcludedWords.filter(
                                      (word) => !value.includes(word),
                                    )
                                onChange(newValue)
                              }}
                              checked={value.every((word) =>
                                currentSelectedExcludedWords.includes(word),
                              )}
                              inputRef={ref}
                              value={value}
                            />
                          )}
                        />
                      }
                    />
                  ))}
                  <Grid
                    container
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        size="small"
                        value={otherExcludeWord}
                        onChange={(e) => setOtherExcludeWord(e.target.value)}
                        variant="outlined"
                        sx={{
                          backgroundColor: 'white',
                        }}
                        label="除外ワードを任意で設定"
                        placeholder="除外するキーワードを入力してください"
                      />
                    </Grid>
                    <Grid item xs={4} spacing={1}>
                      <Button
                        variant="contained"
                        sx={{ fontSize: '14px', color: 'white' }}
                        disabled={!otherExcludeWord}
                        onClick={() => {
                          if (
                            otherExcludeWord &&
                            !excludedWordsSuggestOptions.some((option) =>
                              option.value.includes(otherExcludeWord),
                            )
                          ) {
                            excludedWordsSuggestOptions.push({
                              label: otherExcludeWord,
                              value: [otherExcludeWord],
                            })
                            setOtherExcludeWord('')
                          }
                        }}
                      >
                        追加する
                      </Button>
                    </Grid>
                  </Grid>
                </FormGroup>
              </FormControl>
            </KeywordFormSection>
          </Box>
          {needCheckout ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <PaymentButton
                price={Number(productInfo?.price ?? '0')}
                disabled={!!requesting}
                label="分析を開始する"
              />
              <NoRemainingCountMessage
                remainingCount={currentUser?.remaining_analysis_count ?? 0}
                isCampaign={productInfo?.isCampaign ?? false}
              />
              <KeywordCheckoutDialog
                onClose={onCloseKeyword}
                isOpen={isOpenKeyword}
                keywordFormData={{
                  researchTarget,
                  keyword,
                  analysisThemeId: currentSelectedAnalysisThemeId,
                  theme: currentTheme,
                }}
                createKeywordParams={{
                  analysisThemeId: selectedTheme?.id,
                  name: currentKeywordName,
                  excludedWords: [
                    ...currentExcludedWords,
                    ...currentSelectedExcludedWords,
                  ],
                  tags: [
                    ...GenderRecord[researchTarget.gender],
                    ...researchTarget.generations.map(
                      (generation) => AgeRecord[generation],
                    ),
                  ],
                  prefectures: researchTarget.prefectures,
                  speciality_panel: researchTarget.specialityPanel,
                  note: researchTarget.note,
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{ px: 7, fontSize: '20px', color: 'white' }}
                disabled={
                  requesting ||
                  (currentUser?.remaining_analysis_count ?? 0) <= 0
                }
              >
                分析を開始
              </Button>
              <Typography sx={{ mt: 2 }}>
                ※キーワード分析チケットを1消費します
              </Typography>
            </Box>
          )}
        </Box>
      </form>
    </FormProvider>
  )
}

type KeywordFormSectionProps = {
  title: string
  info?: React.ReactNode
  children: React.ReactNode
  ref?: MutableRefObject<HTMLDivElement | null>
}

const KeywordFormSection: React.VFC<KeywordFormSectionProps> = ({
  title,
  info,
  children,
  ref,
}) => {
  return (
    <Box
      component="dl"
      sx={{
        width: '100%',
      }}
      ref={ref}
    >
      <Box
        component="dt"
        sx={{
          py: 2,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            lineHeight: '1.4',
            fontSize: '18px',
            pb: 1,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            lineHeight: '1.4',
            fontSize: '13px',
          }}
        >
          {info}
        </Typography>
      </Box>
      <Typography
        component="dd"
        variant="subtitle1"
        sx={{
          fontSize: '14px',
          my: 1,
        }}
      >
        {children}
      </Typography>
    </Box>
  )
}

type KeywordNameFieldProps = {
  errors: FieldErrors<FormData>
  control: Control<FormData>
}

const KeywordNameField: React.VFC<KeywordNameFieldProps> = (props) => {
  const { errors, control } = props

  return (
    <FormControl
      error={!!errors.keyword?.name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 2,
      }}
    >
      <Controller
        name="keyword.name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            size="small"
            type="text"
            name="keyword.name"
            placeholder="キーワードを入力してください"
            variant="outlined"
            error={!!errors?.keyword?.name}
            helperText={errors?.keyword?.name?.message}
            sx={{
              width: '100%',
              '& .MuiInputBase-input': {
                height: 'auto',
                padding: '10px 14px',
              },
              backgroundColor: 'white',
            }}
            InputProps={{
              style: {
                fontSize: 16,
              },
            }}
            InputLabelProps={{
              style: {
                fontSize: 16,
              },
            }}
          />
        )}
      />
    </FormControl>
  )
}

type ThemeFieldProps = {
  analysisThemes: AnalysisTheme[]
  isCreate: boolean
}

const ThemeField: React.VFC<ThemeFieldProps> = ({
  analysisThemes,
  isCreate,
}) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<FormData>()
  const [isCreatingNew, setIsCreatingNew] = React.useState(isCreate)

  React.useEffect(() => {
    if (isCreatingNew) {
      setValue('analysisThemeId', undefined)
    } else {
      setValue('theme', undefined)
    }
  }, [isCreatingNew, setValue])

  return (
    <>
      <KeywordFormSection title="テーマ" info="選択または新規作成してください">
        <FormControl fullWidth>
          <Controller
            name="analysisThemeId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                value={isCreatingNew ? 'new' : watch('analysisThemeId')}
                sx={{
                  backgroundColor: 'white',
                }}
                onChange={(e) => {
                  const value = e.target.value
                  if (value === 'new') {
                    setIsCreatingNew(true)
                    field.onChange(undefined)
                  } else {
                    setIsCreatingNew(false)
                    field.onChange(value)
                  }
                }}
                displayEmpty
              >
                {analysisThemes.map((theme) => (
                  <MenuItem key={theme.id} value={theme.id}>
                    {theme.name}
                  </MenuItem>
                ))}
                <MenuItem value="new">新規作成</MenuItem>
              </Select>
            )}
          />
        </FormControl>
        {isCreatingNew && (
          <FormControl
            error={!!errors.theme?.name}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: 2,
            }}
          >
            <Controller
              name="theme.name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  type="text"
                  placeholder="テーマ名を入力してください"
                  variant="outlined"
                  error={isCreatingNew && !!errors?.theme?.name}
                  helperText={isCreatingNew ? errors?.theme?.name?.message : ''}
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      height: 'auto',
                      padding: '10px 14px',
                    },
                    backgroundColor: 'white',
                  }}
                  InputProps={{
                    style: {
                      fontSize: 16,
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: 16,
                    },
                  }}
                />
              )}
            />
          </FormControl>
        )}
      </KeywordFormSection>
      {isCreatingNew && (
        <KeywordFormSection
          title="テーマ種別を選んでください"
          info="分析の目線が変わります"
        >
          <FormControl
            error={!!errors.theme?.type}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
            }}
          >
            {!!errors.theme?.type && (
              <FormLabel required sx={{ textAlign: 'start' }}>
                テーマ種別を選んでください
              </FormLabel>
            )}
            <RadioGroup defaultValue={watch('theme.type')}>
              {themeTypeOptions.map(({ value, label }) => (
                <Box
                  key={value}
                  sx={{
                    display: value === 'other' ? 'flex' : '',
                  }}
                >
                  <FormControlLabel
                    value={value}
                    control={
                      <Controller
                        name="theme.type"
                        control={control}
                        render={({ field: { onChange, ref } }) => (
                          <Radio
                            inputRef={ref}
                            onChange={() => onChange(value)}
                            value={value}
                          />
                        )}
                      />
                    }
                    label={label}
                  />
                  {value === 'userVoice' && (
                    <RadioGroup
                      sx={{ pl: 4, display: 'flex', flexDirection: 'row' }}
                    >
                      {userVoiceTypeOptions.map(({ value, label }) => (
                        <FormControlLabel
                          key={value}
                          value={value}
                          disabled={watch('theme.type') !== 'userVoice'}
                          control={
                            <Controller
                              name="theme.userVoiceType"
                              control={control}
                              render={({ field: { onChange, ref } }) => (
                                <Radio
                                  disabled={watch('theme.type') !== 'userVoice'}
                                  inputRef={ref}
                                  onChange={() => onChange(value)}
                                  value={value}
                                />
                              )}
                            />
                          }
                          label={label}
                        />
                      ))}
                    </RadioGroup>
                  )}
                  {value === 'other' && (
                    <Controller
                      name="theme.memo"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          disabled={watch('theme.type') !== 'other'}
                          fullWidth
                          size="small"
                          variant="outlined"
                          error={
                            watch('theme.type') === 'other' &&
                            !!(errors?.theme as any)?.memo
                          }
                          helperText={
                            watch('theme.type') === 'other' &&
                            (errors?.theme as any)?.memo
                              ? (errors?.theme as any)?.memo?.message
                              : ''
                          }
                          sx={{ maxWidth: '180px' }}
                        />
                      )}
                    />
                  )}
                </Box>
              ))}
            </RadioGroup>
          </FormControl>
        </KeywordFormSection>
      )}
    </>
  )
}
