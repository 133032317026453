import useSWR, { SWRConfiguration } from 'swr'
import { QuestionnaireResponse, parseQuestionnaire } from 'types/questionnaire'

const getRequestUrl = (input: GetQuestionnaireByTopicInput) => {
  console.log(input)
  if (input.topicId === undefined) return null

  if (input.topicId < 0)
    return `/assets/v2-example/questionnaires/${input.topicId}.json`

  return `/api/questionnaires/by_topic/${input.topicId}`
}

type GetQuestionnaireByTopicInput = {
  topicId?: number
}
type GetQuestionnaireResponse = QuestionnaireResponse

export const useQuestionnaireByTopic = ({
  input,
}: {
  input: GetQuestionnaireByTopicInput
  options?: SWRConfiguration<GetQuestionnaireResponse, Error>
}) => {
  const isMock = (input.topicId ?? 0) < 0

  const { data, ...rest } = useSWR<{
    data: QuestionnaireResponse
  }>(
    getRequestUrl(input),
    isMock ? (url: string) => fetch(url).then((res) => res.json()) : null,
    isMock
      ? {
          revalidateIfStale: false,
          revalidateOnReconnect: false,
          revalidateOnFocus: false,
        }
      : {},
  )

  return {
    data: data !== undefined ? parseQuestionnaire(data.data) : undefined,
    ...rest,
  }
}
