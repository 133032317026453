import { CheckoutInput, CheckoutPayload } from 'api/order/checkout'
import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

type CheckoutQuestionnaireInput = CheckoutInput & {
  analysisResultId: string
}

export const checkoutQuestionnaire = async ({
  provider,
  taxRate,
  productId,
  quantity,
  successUrl,
  cancelUrl,
  params,
  topicId,
  analysisResultId,
}: CheckoutQuestionnaireInput) => {
  const response = await axios.post<CheckoutPayload>(
    '/api/questionnaires/checkout',
    {
      provider,
      tax_rate: taxRate,
      product_id: productId,
      quantity,
      success_url: successUrl,
      cancel_url: cancelUrl,
      params,
      topic_id: topicId,
      analysis_result_id: analysisResultId,
    },
  )

  return response.data
}

export const useCheckoutQuestionnaire = (
  option?: UseMutationOption<CheckoutPayload>,
) => useMutation(checkoutQuestionnaire, option)
