/** @jsxRuntime classic */
import 'driver.js/dist/driver.css'
import 'font-awesome/css/font-awesome.min.css'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import App from './App'

import React from 'react'
import ReactDOM from 'react-dom'
// import './index.css';
import BugsnagPerformance from '@bugsnag/browser-performance'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import reportWebVitals from './reportWebVitals'

const REACT_APP_BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY || ''

let ErrorBoundary = ({ children }: { children: any }) => children

if (process.env.REACT_APP_PUBLIC_ENV !== 'local') {
  Bugsnag.start({
    apiKey: REACT_APP_BUGSNAG_API_KEY,
    releaseStage: process.env.REACT_APP_PUBLIC_ENV,
    plugins: [new BugsnagPluginReact()],
  })
  BugsnagPerformance.start({
    apiKey: REACT_APP_BUGSNAG_API_KEY,
    releaseStage: process.env.REACT_APP_PUBLIC_ENV,
  })

  ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) as any
}

if (process.env.REACT_APP_PUBLIC_ENV !== 'local') {
  console.log = () => {}
}

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
