export type EmpathySurveyResponse = {
  id: number
  label_id: number
  prefectures: string[]
  tags: { name: string }[]
  analysis_result_tweets: {
    pivot: {
      empathy_count: number
      total_count: number
      tweet_id: number
    }
  }[]
}

export type EmpathySurvey = {
  id: number
  labelId: number
  prefectures: string[]
  tags: { name: string }[]
  empathies: { totalCount: number; empathyCount: number; tweetId: number }[]
}

export const parseEmpathySurvey = (
  input: EmpathySurveyResponse,
): EmpathySurvey => {
  return {
    id: input.id,
    labelId: input.label_id,
    prefectures: input.prefectures,
    tags: input.tags,
    empathies: input.analysis_result_tweets.map(({ pivot }) => ({
      totalCount: pivot.total_count,
      empathyCount: pivot.empathy_count,
      tweetId: pivot.tweet_id,
    })),
  }
}
