import { useGetAnalysis } from 'api/getAnalysis'
import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { unstable_serialize } from 'swr/infinite'
import { useAnalysisResultSearchQuery } from './useAnalysisResultSearchQuery'

type sortKey =
  | 'is_important'
  | 'periodic_analysis_slot_id'
  | 'target_word'
  | 'analysis_status_id'
  | 'questionnaire_status_id'
  | 'analyze_start_at'

export const useAnalysis = (isFolderStructure = false) => {
  const history = useHistory()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)

  const { keyWord, hashTags } = useAnalysisResultSearchQuery()

  const sortFlag = useMemo(() => {
    const sort = queryParams.get('sort')
    if (sort === null || sort.length === 0) return ''
    if (sort[0] === '-') return sort.slice(1)
    return sort
  }, [queryParams])

  const sortOrder = useMemo(() => {
    const sort = queryParams.get('sort')
    if (sort === null || sort.length === 0) return null
    return sort[0] === '-' ? 'desc' : 'asc'
  }, [queryParams])

  const { data, size, setSize, mutate } = useGetAnalysis({
    isFolderStructure,
    params: {
      sort: queryParams.get('sort'),
      keyword: isFolderStructure ? keyWord : null,
      tags: isFolderStructure ? hashTags : null,
    },
  })

  const analysisResults = useMemo(() => {
    if (!data) return []
    return data.flatMap((dt) => dt.data)
  }, [data])

  const loading = useMemo(
    () => data === undefined || data.length < size,
    [data, size],
  )

  const currentMeta = useMemo(() => {
    if (data === undefined || data.length === 0) return null
    return data[data.length - 1].meta as {
      current_page: number
      last_page: number
    }
  }, [data])

  const hasMore = useMemo(
    () =>
      currentMeta !== null && currentMeta.current_page < currentMeta.last_page,
    [currentMeta],
  )

  const loadMore = useCallback(() => {
    if (loading || !hasMore) return
    setSize((prev) => prev + 1)
  }, [loading, setSize, size])

  const handleSortChange = (sortValue: sortKey) => {
    if (sortValue !== sortFlag || sortOrder === null) {
      queryParams.set('sort', sortValue)
    } else if (sortOrder === 'asc') {
      queryParams.set('sort', '-' + sortValue)
    } else if (sortOrder === 'desc') {
      queryParams.delete('sort')
    }

    history.push({
      pathname: location.pathname,
      search: queryParams.toString(),
    })
  }

  const onMutate = useCallback(() => {
    queryParams.delete('sort')
    history.push({
      pathname: location.pathname,
      search: queryParams.toString(),
    })
    mutate()
  }, [setSize, mutate, location, unstable_serialize, queryParams, history])

  return {
    data: analysisResults,
    loadMore,
    handleSortChange,
    hasMore,
    sortFlag,
    sortOrder,
    mutate: onMutate,
    loading,
  }
}
