type AnnouncementsResponseData = Array<{
  id: number
  close_at: string | null
  created_at: string | null
  publish_at: string | null
  title: string | null
  status: {
    id: number
    name: string
  }
}>

type GetAnnouncementsResponse = {
  data: AnnouncementsResponseData
  links: {
    first: string
    last: string
    prev: string | null
    next: string | null
  }
  meta: {
    current_page: number
    from: number
    last_page: number
    per_page: number
    total: number
  }
}

type AnnouncementDetailResponse = {
  id: number
  close_at: string | null
  created_at: string | null
  publish_at: string | null
  title: string | null
  status: {
    id: number
    name: string
  }
  body: string
}

type Announcement = {
  id: number
  closeAt: string | null
  createdAt: string | null
  publishAt: string | null
  title: string | null
  status: {
    id: number
    name: string
  } | null
  body: string
}

type Announcements = {
  id: number
  closeAt: string | null
  createdAt: string | null
  publishAt: string | null
  title: string | null
  status: {
    id: number
    name: string
  } | null
}[]

const parseAnnounceDetail = (
  input: AnnouncementDetailResponse,
): Announcement => {
  return {
    id: input.id,
    closeAt: input.close_at ?? null,
    createdAt: input.created_at ?? null,
    publishAt: input.publish_at ?? null,
    title: input.title ?? null,
    status: input.status
      ? {
          id: input.status.id,
          name: input.status.name,
        }
      : null,
    body: input.body,
  }
}

const parseAnnouncements = (
  input: AnnouncementsResponseData,
): Announcements => {
  return input.map((announce) => ({
    id: announce.id,
    closeAt: announce.close_at ?? null,
    createdAt: announce.created_at ?? null,
    publishAt: announce.publish_at ?? null,
    title: announce.title ?? null,
    status: announce.status
      ? {
          id: announce.status.id,
          name: announce.status.name,
        }
      : null,
  }))
}

export { parseAnnounceDetail, parseAnnouncements }
export type {
  Announcement,
  AnnouncementDetailResponse,
  Announcements,
  GetAnnouncementsResponse,
}
