import { Box, Button, Card, Fab, IconButton, Typography } from '@mui/material'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import { useDisclosure } from 'hooks/useDisclosure'
import CloseIcon from '@mui/icons-material/Close'
import { Dentifrice } from './Dentifrice'
import { Lotion } from './Lotion'
import { Link as InternalLink } from 'react-router-dom'
import { Nisa } from './Nisa'
import { Pod } from './Pod'
import { Lunch } from './Lunch'

type Props = { themeId: -1 | -2 | -3 | -4 | -5 }
export const TipsForUseButton = ({ themeId }: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultOpen: true,
  })
  return (
    <>
      {isOpen ? (
        <Card
          sx={{
            position: 'fixed',
            right: 10,
            bottom: 0,
            width: 500,
            height: 640,
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: 60,
              paddingX: 2,
              background: '#FF6600',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            onClick={onClose}
          >
            <LightbulbIcon sx={{ color: 'white', fontSize: 40 }} />
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', color: 'white' }}
            >
              活用のヒント
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon sx={{ color: 'white', fontSize: 24 }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              background: '#fff',
              width: 'full',
              height: '580px',
              p: 2,
              overflowY: 'scroll',
            }}
          >
            {themeId === -1 && <Dentifrice />}
            {themeId === -2 && <Lotion />}
            {themeId === -3 && <Nisa />}
            {themeId === -4 && <Lunch />}
            {themeId === -5 && <Pod />}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                pt: 2,
              }}
            >
              <Button
                variant="contained"
                component={InternalLink}
                to={'/v2/themes/new'}
              >
                実際に使ってみる
              </Button>
            </Box>
          </Box>
        </Card>
      ) : (
        <Fab
          sx={{
            position: 'fixed',
            right: 20,
            bottom: 20,
            width: 80,
            height: 80,
          }}
          color="primary"
          aria-label="info"
          onClick={onOpen}
        >
          <LightbulbIcon sx={{ color: 'white', fontSize: 40 }} />
        </Fab>
      )}
    </>
  )
}
