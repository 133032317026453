import { Card, Typography } from '@mui/material'
import { useCreateanalysisTheme } from 'api/createAnalysisTheme'
import { useGetAnalysisThemes } from 'api/getAnalysisThemes'
import { FC } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import toastr from 'toastr'
import { z } from 'zod'
import { AgeRecord, GenderRecord } from '../components/ResearchTargetInputForm'
import { V2Paths } from '../paths'
import { NewAnalysisThemeForm } from './components/NewAnalysisThemeForm'
import { newAnalysisThemeSchema } from './schema'

type FormData = z.infer<typeof newAnalysisThemeSchema>

export const NewAnalysisThemePage: FC = () => {
  const history = useHistory()

  const { mutate } = useGetAnalysisThemes({})

  const { request, requesting } = useCreateanalysisTheme({
    async onSuccess({ id }) {
      await mutate()
      toastr.info('テーマを作成しました。')
      history.push({
        pathname: generatePath(V2Paths.NewKeyword, {
          themeId: id,
        }),
        state: { afterCreateTheme: true },
      })
    },
    onError(error) {
      console.error('error', error)
    },
  })

  const onSubmit = async (data: FormData) =>
    request({
      name: data.theme.name,
      kind: data.theme.type,
      userVoiceType:
        data.theme.type === 'userVoice' ? data.theme.userVoiceType : null,
      otherMemo: data.theme.type === 'other' ? data.theme.memo : null,
      tags: [
        ...GenderRecord[data.researchTarget.gender],
        ...data.researchTarget.generations.map(
          (generation) => AgeRecord[generation],
        ),
      ],
      prefectures: data.researchTarget.prefectures,
      speciality_panel: data.researchTarget.specialityPanel,
      note: data.researchTarget.note,
    })

  return (
    <Card sx={{ mx: 4, my: 8, py: 4, px: { xs: 4, md: 8, lg: 16 } }}>
      <Typography
        style={{ textAlign: 'center', color: '#707070', fontSize: '24px' }}
      >
        新規テーマ作成
      </Typography>
      <NewAnalysisThemeForm onSubmit={onSubmit} requesting={requesting} />
    </Card>
  )
}
