import { CreateQuestionnaireV2Input } from 'api/createQuestionnaire'
import { FormData as SummaryFormData } from 'pages/v2/KeywordDetail/components/Summary/CreateSummary/CreateSummaryDialog'
import { FormData as SurveyFormData } from 'pages/v2/KeywordDetail/components/WeightedFilter/WeightedFilterForm'
import { FormData as KeywordFormData } from 'pages/v2/NewKeyword/components/NewKeywordForm'
import { FormData as SpKeywordFormData } from 'pages/v2/sp/NewKeyword/components/NewKeywordForm'
import { Product, ProductResponseData, parseProduct } from 'types/product'

type Provider = 'stripe' | 'paid'

type OrderItemResponseData = {
  id: number
  product_name: string
  product_price: string
  product_tax_amount: string
  quantity: number
  order_id: number
  product_id: number
  product: ProductResponseData
  created_at: string
  updated_at: string
}

type OrderProcessingDataResponseData = {
  id: number
  parameters:
    | {
        type: 'checkout.questionnaire'
        params: CreateQuestionnaireV2Input
      }
    | {
        type: 'checkout.keyword'
        params: KeywordFormData
      }
    | {
        type: 'checkout.summary'
        params: SummaryFormData
      }
    | {
        type: 'checkout.survey'
        params: SurveyFormData
      }
    | null
  created_at: string
  updated_at: string
}

export type WebhookAPIStatusResponseData = {
  id: number
  api_name: string
  status: string
  analysis_result: {
    id: number
    analyze_complete_at: string
  } | null
  analysis_result_id: number
  questionnaire: {
    id: number
    complete_at: string
  } | null
  questionnaire_id: number
  summary: {
    id: number
    analysis_result: {
      id: number
      analyze_complete_at: string
    } | null
  } | null
  summary_id: number
  survey: {
    id: number
    weighted_expiration_date: string
  } | null
  survey_id: number
  orderId: number
  createdAt: string
  updatedAt: string
}

export type OrderResponseData = {
  id: number
  provider: Provider
  session_id: string
  payment_intent_id: string
  total: string
  tax_amount: string
  status: string
  tax_rate: number
  error_message: string
  user: string
  order_items: OrderItemResponseData[]
  webhook_api_statuses: WebhookAPIStatusResponseData[]
  order_processing_data: OrderProcessingDataResponseData | null
  user_id: number
  topic_id?: string
  created_at: string
  updated_at: string
}

export type Order = {
  id: number
  provider: Provider
  sessionId: string
  paymentIntentId: string
  total: number
  taxAmount: number
  status: string
  taxRate: number
  errorMessage: string
  user: string
  orderItems: OrderItem[]
  webhookAPIStatuses: WebhookAPIStatus[]
  orderProcessingData: OrderProcessingData | null
  userId: number
  topicId?: string
  createdAt: string
  updatedAt: string
}

export type WebhookAPIStatus = {
  id: number
  apiName: string
  status: string
  analysisResult: {
    id: number
    analyzeCompleteAt: string
  } | null
  analysisResultId: number
  questionnaire: {
    id: number
    completeAt: string
  } | null
  questionnaireId: number
  summary: {
    id: number
    analysisResult: {
      id: number
      analyzeCompleteAt: string
    } | null
  } | null
  summaryId: number
  survey: {
    id: number
    weightedExpirationDate: string
  } | null
  surveyId: number
  orderId: number
  createdAt: string
  updatedAt: string
}

export type OrderItem = {
  id: number
  productName: string
  productPrice: number
  productTaxAmount: number
  quantity: number
  orderId: number
  productId: number
  product: Product
  createdAt: string
  updatedAt: string
}

export type OrderProcessingData = {
  id: number
  parameters:
    | {
        type: 'checkout.questionnaire'
        params: CreateQuestionnaireV2Input
      }
    | {
        type: 'checkout.keyword'
        params: KeywordFormData | SpKeywordFormData
      }
    | {
        type: 'checkout.summary'
        params: SummaryFormData
      }
    | {
        type: 'checkout.survey'
        params: SurveyFormData
      }
    | null
  createdAt: string
  updatedAt: string
}

export const parseOrderItem = (
  orderItem: OrderItemResponseData,
): OrderItem => ({
  id: orderItem.id,
  productName: orderItem.product_name,
  productPrice: Math.floor(Number(orderItem.product_price)),
  productTaxAmount: Math.floor(Number(orderItem.product_tax_amount)),
  quantity: orderItem.quantity,
  orderId: orderItem.order_id,
  productId: orderItem.product_id,
  product: parseProduct(orderItem.product),
  createdAt: orderItem.created_at,
  updatedAt: orderItem.updated_at,
})

const parseWebhookAPIStatus = (
  webhookAPIStatus: WebhookAPIStatusResponseData,
): WebhookAPIStatus => ({
  id: webhookAPIStatus.id,
  apiName: webhookAPIStatus.api_name,
  status: webhookAPIStatus.status,
  analysisResult: webhookAPIStatus.analysis_result
    ? {
        id: webhookAPIStatus.analysis_result.id,
        analyzeCompleteAt: webhookAPIStatus.analysis_result.analyze_complete_at,
      }
    : null,
  analysisResultId: webhookAPIStatus.analysis_result_id,
  questionnaire: webhookAPIStatus.questionnaire
    ? {
        id: webhookAPIStatus.questionnaire.id,
        completeAt: webhookAPIStatus.questionnaire.complete_at,
      }
    : null,
  questionnaireId: webhookAPIStatus.questionnaire_id,
  summary: webhookAPIStatus.summary
    ? {
        id: webhookAPIStatus.summary.id,
        analysisResult: webhookAPIStatus.summary.analysis_result
          ? {
              id: webhookAPIStatus.summary.analysis_result.id,
              analyzeCompleteAt:
                webhookAPIStatus.summary.analysis_result.analyze_complete_at,
            }
          : null,
      }
    : null,
  summaryId: webhookAPIStatus.summary_id,
  survey: webhookAPIStatus.survey
    ? {
        id: webhookAPIStatus.survey.id,
        weightedExpirationDate:
          webhookAPIStatus.survey.weighted_expiration_date,
      }
    : null,
  surveyId: webhookAPIStatus.survey_id,
  orderId: webhookAPIStatus.orderId,
  createdAt: webhookAPIStatus.createdAt,
  updatedAt: webhookAPIStatus.updatedAt,
})

const parseOrderProcessingData = (
  orderProcessingData: OrderProcessingDataResponseData,
): OrderProcessingData => ({
  id: orderProcessingData.id,
  parameters: orderProcessingData.parameters,
  createdAt: orderProcessingData.created_at,
  updatedAt: orderProcessingData.updated_at,
})

export const parseOrder = (order: OrderResponseData): Order => ({
  id: order.id,
  provider: order.provider,
  sessionId: order.session_id,
  paymentIntentId: order.payment_intent_id,
  total: Math.floor(Number(order.total)),
  taxAmount: Math.floor(Number(order.tax_amount)),
  taxRate: Math.floor(Number(order.tax_rate)),
  status: order.status,
  errorMessage: order.error_message,
  user: order.user,
  orderItems: order.order_items.map(parseOrderItem),
  webhookAPIStatuses: order.webhook_api_statuses
    ? order.webhook_api_statuses.map(parseWebhookAPIStatus)
    : [],
  orderProcessingData: order.order_processing_data
    ? parseOrderProcessingData(order.order_processing_data)
    : null,
  userId: order.user_id,
  topicId: order.topic_id,
  createdAt: order.created_at,
  updatedAt: order.updated_at,
})
