import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, FormControl, TextField } from '@mui/material'
import { useSendPasswordResetEmail } from 'api/resetPassword'
import { useBanner } from 'hooks/useBanner'
import { passwordResetEmailSchema } from 'pages/passwordResetEmail/schema'
import BaseSnackbar from 'pages/v2/components/BaseSnackbar'
import React, { useCallback } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { z } from 'zod'

type PasswordResetEmailFormProps = {}

type FormData = z.infer<typeof passwordResetEmailSchema>

const PasswordResetEmailForm: React.VFC<PasswordResetEmailFormProps> = () => {
  const history = useHistory()
  const { message, severity, isOpen, showBanner, hideBanner } = useBanner()

  const { request, requesting } = useSendPasswordResetEmail({
    onSuccess: () => {
      showBanner({
        message: 'パスワード再設定メールを送信しました',
        severity: 'success',
      })

      setTimeout(() => {
        history.push('/login')
      }, 3000)
    },
    onError: (error) => {
      if (error.response) {
        showBanner({
          message:
            error.response.data?.errors ||
            'パスワード再設定メールの送信に失敗しました',
          severity: 'error',
        })
      } else {
        showBanner({
          message: 'パスワード再設定メールの送信に失敗しました',
          severity: 'error',
        })
      }
    },
  })

  const method = useForm({
    resolver: zodResolver(passwordResetEmailSchema),
    defaultValues: {
      email: '',
    },
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = method

  const onSubmit = useCallback(
    (data: FormData) => {
      request(data)
    },
    [request],
  )

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            py: 4,
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              flexDirection: 'column',
            }}
          >
            <FormControl
              error={!!errors?.email}
              sx={{
                width: '100%',
              }}
            >
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    type="email"
                    label="メールアドレス"
                    placeholder="viewpers@example.com"
                    variant="outlined"
                    error={!!errors?.email}
                    helperText={
                      errors?.email?.message ?? errors?.email?.message
                    }
                    sx={{
                      maxWidth: '360px',
                      '& .MuiInputBase-input': {
                        height: 'auto',
                        padding: '10px 14px',
                      },
                    }}
                    InputProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Button
            variant="contained"
            type="submit"
            sx={{ px: 7, fontSize: '16px', color: 'white' }}
            disabled={requesting}
          >
            パスワード再設定メールを送信
          </Button>
        </Box>
      </form>
      <BaseSnackbar
        message={message}
        open={isOpen}
        handleClose={hideBanner}
        severity={severity}
      />
    </FormProvider>
  )
}

export default PasswordResetEmailForm
