import { useEffect, useState } from 'react'

declare global {
  interface Window {
    _paid:
      | {
          getFRDKey: (callback: (key: string) => void) => void
        }
      | undefined
  }
}

const useFrdKey = () => {
  const [frdKey, setFrdKey] = useState<string>('')

  useEffect(() => {
    console.log('useFrdKey')

    const loadFrdScript = () => {
      const script = document.createElement('script')
      script.src = 'https://api.paid.jp/js/company/frd_script.js'
      script.async = true
      script.onload = () => {
        console.log('FRD Script Loaded')
        if (window._paid) {
          window._paid.getFRDKey(function (key) {
            console.log('FRD Key:', key)
            setFrdKey(key)
          })
        } else {
          console.error('window._paid is not defined')
        }
      }
      script.onerror = () => {
        console.error('Failed to load frd_script.js')
      }
      document.body.appendChild(script)
    }

    loadFrdScript()

    return () => {
      const script = document.querySelector(
        'script[src="https://api.paid.jp/js/company/frd_script.js"]',
      )
      if (script) {
        document.body.removeChild(script)
      }
    }
  }, [])

  return frdKey
}

export default useFrdKey
