import {
  ConfirmPaymentsByPaidInput,
  ConfirmPaymentsByPaidPayload,
} from 'api/checkoutByPaid/confirmPaymentsByPaid'
import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

type ConfirmQuestionnairePaymentsByPaidInput = ConfirmPaymentsByPaidInput

export const confirmQuestionnairePaymentsByPaid = async (
  input: ConfirmQuestionnairePaymentsByPaidInput,
) => {
  const { orderId } = input
  const response = await axios.post<ConfirmPaymentsByPaidPayload>(
    '/api/questionnaires/confirm_payments',
    {
      order_id: orderId,
    },
  )
  return response.data
}

export const useConfirmQuestionnairePaymentsByPaid = (
  option?: UseMutationOption<ConfirmPaymentsByPaidPayload>,
) => useMutation(confirmQuestionnairePaymentsByPaid, option)
