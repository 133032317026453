import { CreateSurveyInput } from 'api/createSurvey'
import BaseCheckoutDialog from 'pages/v2/checkout/components/BaseCheckoutDialog'
import useWeightedCheckoutDialog from 'pages/v2/checkout/components/useWeightedCheckoutDialog'
import { FormData } from 'pages/v2/KeywordDetail/components/WeightedFilter/WeightedFilterForm'
import React from 'react'

type WeightedCheckoutDialogProps = {
  onClose: () => void
  isOpen: boolean
  createSurveyParams: CreateSurveyInput
  surveyFormData: FormData
  topicId?: string
}

const WeightedCheckoutDialog: React.VFC<WeightedCheckoutDialogProps> = ({
  onClose,
  isOpen,
  surveyFormData,
  createSurveyParams,
  topicId,
}) => {
  const {
    isCheckoutInput,
    onNextCheckout,
    onPurchaseViaStripe,
    onPurchaseViaPaid,
    message,
    severity,
    isOpenBanner,
    hideBanner,
    productInfo,
    requesting,
    needUserAdditionalInput,
    isProcessing,
    onMoveToPaidSetting,
  } = useWeightedCheckoutDialog({
    surveyFormData,
    createSurveyParams,
    topicId,
  })

  if (productInfo == null) {
    return null
  }

  return (
    <BaseCheckoutDialog
      isProcessing={isProcessing}
      isOpen={isOpen}
      onClose={onClose}
      productInfo={productInfo}
      isCheckoutInput={isCheckoutInput}
      onPurchaseViaStripe={onPurchaseViaStripe}
      onPurchaseViaPaid={onPurchaseViaPaid}
      requesting={requesting}
      message={message}
      isOpenBanner={isOpenBanner}
      hideBanner={hideBanner}
      severity={severity}
      onNextCheckout={onNextCheckout}
      needUserAdditionalInput={needUserAdditionalInput}
      onMoveToPaidSetting={onMoveToPaidSetting}
    />
  )
}

export default WeightedCheckoutDialog
