import { useGetAnalysisThemes } from 'api/getAnalysisThemes'
import { useMemo } from 'react'

// routerより上記コンポーネントであるため正規表現でpathを取得する。
function extractThemeId(path: string) {
  const pattern = /\/v2\/themes\/(-?\d+)(\/|$)/

  const match = path.match(pattern)

  if (match && match.length > 1) {
    const res = parseInt(match[1], 10)
    if (isNaN(res)) {
      return undefined
    }
    return res
  }

  return undefined
}

const useSelectedTheme = () => {
  const { data: analysisThemes } = useGetAnalysisThemes({})
  const selectedThemeId = useMemo(
    () => extractThemeId(location.pathname),
    [location.pathname],
  )

  const selectedTheme = useMemo(
    () => analysisThemes?.find((theme) => theme.id === selectedThemeId),
    [analysisThemes, selectedThemeId],
  )

  return { selectedTheme, selectedThemeId }
}

export default useSelectedTheme
