import { useCheckPaidMemberStatus } from 'api/checkPaidMemberStatus'
import { useCheckOrdersByPaid } from 'api/checkoutByPaid/checkOrdersByPaid'
import { useConfirmPaymentsByPaid } from 'api/checkoutByPaid/confirmPaymentsByPaid'
import { useRegisterOrdersByPaid } from 'api/checkoutByPaid/registerOrdersByPaid'
import { useRegisterPaidMember } from 'api/registerMember'
import PAID_MEMBER_STATUS from 'enums/PaidMemberStatus'
import { useBanner } from 'hooks/useBanner'
import useFrdKey from 'hooks/useFrdKey'
import { CompleteTourGuideDialog } from 'pages/v2/Tour/components/CompleteTourGuideDialog'
import { StartTourGuideDialog } from 'pages/v2/Tour/components/StartTourGuideDialog'
import { TourGuideStepDialog } from 'pages/v2/Tour/components/TourGuideStepDialog'
import BaseSnackbar from 'pages/v2/components/BaseSnackbar'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

const registerMemberParams = {
  companyName: '株式会社ラクーンホールディングス',
  companyNameKana: 'カブシキガイシャラクーンホールディングス',
  zipCode: '103-0014',
  prefecture: '東京都',
  address1: '中央区',
  address2: '日本橋蛎殻町1-14-14',
  tel: '03-5652-1692',
  representativeSei: '代表',
  representativeMei: '者名',
  representativeSeiKana: 'ダイヒョウ',
  representativeMeiKana: 'シャメイ',
  clerkSei: '登録',
  clerkMei: '確認',
  clerkSeiKana: 'トウロク',
  clerkMeiKana: 'カクニン',
  email: 'test@raccoon.ne.jp',
}

const checkOrdersByPaidParams = {
  contents: '○○製××',
  code: '1234567',
  price: 1000,
  taxRateCode: '10_standard',
  tradingAt: '2023/03/02',
  provider: 'paid' as const,
  taxRate: 10,
  productId: 1,
  quantity: 1,
}

const checkRegisterOrdersByPaidParams = {
  contents: '○○製××',
  code: '1234567',
  note: '備考',
  price: 1000,
  taxRateCode: '10_standard',
  tradingAt: '2023/03/02',
}

export const DemoPage = () => {
  const { message, severity, isOpen, showBanner, hideBanner } = useBanner()
  const [orderId, setOrderId] = useState<string>('')
  const history = useHistory()
  const { request: requestRegisterPaidMember } = useRegisterPaidMember({
    onSuccess: ({ message }) => {
      showBanner({
        message,
        severity: 'success',
      })
    },
    onError: (error) => {
      if (error.response) {
        showBanner({
          message: error.response.data.errors,
          severity: 'error',
        })
      }
    },
  })
  const { request: requestCheckOrdersByPaid } = useCheckOrdersByPaid({
    onSuccess: ({ order_id: orderId }) => {
      console.log({ orderId })
      setOrderId(orderId)
      showBanner({
        message: '受注可否チェック成功',
        severity: 'success',
      })
    },
    onError: (error) => {
      if (error.response) {
        showBanner({
          message: error.response.data.errors,
          severity: 'error',
        })
      }
    },
  })
  const { request: requestRegisterOrdersByPaid } = useRegisterOrdersByPaid({
    onSuccess: ({ message }) => {
      showBanner({
        message,
        severity: 'success',
      })
    },
    onError: (error) => {
      if (error.response) {
        showBanner({
          message: error.response.data.errors,
          severity: 'error',
        })
      }
    },
  })
  const { request: requestConfirmPaymentsByPaid } = useConfirmPaymentsByPaid({
    onSuccess: ({ message }) => {
      showBanner({
        message,
        severity: 'success',
      })
    },
    onError: (error) => {
      if (error.response) {
        showBanner({
          message: error.response.data.errors,
          severity: 'error',
        })
      }
    },
  })
  const { request: requestCheckPaidMemberStatus } = useCheckPaidMemberStatus({
    onSuccess: ({ status }) => {
      showBanner({
        message: PAID_MEMBER_STATUS[status],
        severity: 'success',
      })
    },
    onError: (error) => {
      if (error.response) {
        showBanner({
          message: error.response.data.errors,
          severity: 'error',
        })
      }
    },
  })

  if (process.env.REACT_APP_PUBLIC_ENV !== 'local') {
    history.push('/v2/themes')
  }

  const frdKey = useFrdKey()

  const onRegisterPaidMember = async () => {
    console.log({ registerMemberParams })
    await requestRegisterPaidMember({
      ...registerMemberParams,
      frdKey,
    })
  }

  const onCheckOrdersByPaid = async () => {
    console.log({ checkOrdersByPaidParams })
    await requestCheckOrdersByPaid({
      ...checkOrdersByPaidParams,
      params: null,
      frdKey,
    })
  }

  const onRegisterOrdersByPaid = async () => {
    console.log({ checkRegisterOrdersByPaidParams })
    await requestRegisterOrdersByPaid({
      ...checkRegisterOrdersByPaidParams,
      orderId: Number(orderId),
      frdKey,
    })
  }

  const onConfirmPaymentsByPaid = async () => {
    await requestConfirmPaymentsByPaid({
      orderId: Number(orderId),
    })
  }

  const onCheckPaidMemberStatus = async () => {
    await requestCheckPaidMemberStatus()
  }

  if (!frdKey) {
    return null
  }

  return (
    <>
      <button onClick={onRegisterPaidMember}>Register Paid Member</button>
      <button onClick={onCheckOrdersByPaid}>Check Orders By Paid</button>
      <button onClick={onRegisterOrdersByPaid}>Register Orders By Paid</button>
      <button onClick={onConfirmPaymentsByPaid}>Confirm Orders By Paid</button>
      <button onClick={onCheckPaidMemberStatus}>
        Check Paid Member Status
      </button>
      <BaseSnackbar
        message={message}
        open={isOpen}
        handleClose={hideBanner}
        severity={severity}
        autoHideDuration={60000}
      />
      <TourGuideStepDialog
        open={false}
        disabled={false}
        onNext={() => null}
        onPrev={() => null}
        step={1}
      />
      <StartTourGuideDialog open={false} disabled={false} onNext={() => null} />
      <CompleteTourGuideDialog
        open={false}
        disabled={false}
        onNext={() => null}
      />
    </>
  )
}
