import axios from 'axios'
import React, {
  Suspense,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom'
import Sugar from 'sugar'
import 'sugar/locales/ja'
import { SWRConfig } from 'swr'
import './App.css'

// Common

import { ThemeProvider } from '@mui/material/styles'
import { usePageTracking } from 'hooks/useTracking'
import { tourDriver } from './hooks/useTour'
import Loading from './includes/Loading'
import SignupVerification from './pages/signupVerification/SignupVerification'

import useGTM from 'hooks/useGTM'
import PasswordReset from 'pages/passwordReset/PasswordReset'
import PasswordResetEmail from 'pages/passwordResetEmail/PasswordResetEmail'
import { AuthenticatedRouter } from 'pages/router'
import Signup from 'pages/signup/Signup'
import { muiTheme } from 'theme'
import { notifyError } from './util/errorTracking'

type ApiContextType = {
  tourDriver: any
  headerOpenModalName: string
  setHeaderOpenModalName: (name: string) => void
  tourIndex: number
  setTourIndex: (index: number) => void
  tourStep: number
  setTourStep: (step: number) => void
  me: any
  setMe: (me: any) => void
  axiosStatusCode: number
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  isAuthorized: boolean
  setIsAuthorized: (isAuthorized: boolean) => void
  isFirstLoading: boolean
  setIsFirstLoading: (isFirstLoading: boolean) => void
  randomNumber: number

  // API
  host: string
  user: string
  auth: string
  announcements_list_api: string
  notification_list_api: string
  analysis_list_api: string
  questionnaire_list_api: string
  scheduled_list_api: string
  oneshot_api: string
  labelings_api: string
  users_api: string
  storage_list_api: string
  hashtag_list_api: string
  entity_list_api: string
  trend: string
}

export const ApiContext = createContext<ApiContextType | undefined>(undefined)
const Login = React.lazy(() => import('./pages/login/Login'))

Sugar.extend()

const NoRedirectRoutes = [
  '/login',
  '/register',
  '/email-verification',
  '/password-reset',
  '/password-reset-email',
]

const RouterInitializer = () => {
  const api = useApiContext()
  const history = useHistory()

  // @ts-expect-error TS(2697): An async function or method must return a 'Promise... Remove this comment to see the full error message
  useEffect(async () => {
    try {
      api.setIsFirstLoading(true)
      const res = await axios.get('/api/me')
      api.setMe(res.data)
      api.setIsFirstLoading(false)
      api.setIsAuthorized(true)
    } catch (error: any) {
      notifyError(error)
      api.setIsFirstLoading(false)
      api.setIsAuthorized(false)
      if (
        NoRedirectRoutes.findIndex((r) => r === history.location.pathname) < 0
      ) {
        history.push({
          pathname: '/login',
        })
      }
    }
  }, [])

  usePageTracking()

  return <></>
}

type AppRouterProps = {
  isLoading: boolean
  isFirstLoading: boolean
  isAuthorized: boolean
}

const AppRouter: React.VFC<AppRouterProps> = ({
  isLoading,
  isFirstLoading,
  isAuthorized,
}) => {
  useGTM()

  return (
    <>
      <RouterInitializer />
      {isFirstLoading ? (
        <Loading />
      ) : !isAuthorized ? (
        <>
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Signup} />
          <Route exact path="/password-reset" component={PasswordReset} />
          <Route
            exact
            path="/password-reset-email"
            component={PasswordResetEmail}
          />
          <Route
            exact
            path="/email-verification"
            component={SignupVerification}
          />
          {isLoading && <Loading />}
        </>
      ) : (
        <Switch>
          <AuthenticatedRouter isLoading={isLoading} />
        </Switch>
      )}
    </>
  )
}

const App = () => {
  const [me, setMe] = useState({})
  const [axiosStatusCode, setAxiosStatusCode] = useState(200)
  const [isFirstLoading, setIsFirstLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [tourIndex, setTourIndex] = useState(
    // @ts-expect-error TS(2304): Cannot find name 'parseInt'.
    parseInt(localStorage.getItem('tourIndex') ?? 0, 10),
  )
  const [tourStep, setTourStep] = useState(
    // @ts-expect-error TS(2304): Cannot find name 'parseInt'.
    parseInt(localStorage.getItem('tourStep') ?? 0, 10),
  )
  const [headerOpenModalName, setHeaderOpenModalName] = useState('')

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        console.log(error)
        if (error?.response?.status === 401) {
          if (localStorage.getItem('jfc')) {
            let f = document.createElement('form')
            f.method = 'post'
            f.action = '/users/auth/login'
            f.innerHTML =
              '<input name="email" value=' +
              localStorage.getItem('jfe') +
              '>' +
              '<input name="password" value=' +
              localStorage.getItem('jfp') +
              '>'
            document.body.append(f)
            f.submit()

            return
          }

          setAxiosStatusCode(error.response.status)
        }
        return Promise.reject(error)
      },
    )
  }, [])

  return (
    <ThemeProvider theme={muiTheme}>
      <Suspense fallback={<div>Loading...</div>}>
        <ApiContext.Provider
          value={{
            tourDriver,
            headerOpenModalName,
            setHeaderOpenModalName,
            tourIndex,
            setTourIndex,
            tourStep,
            setTourStep,
            me,
            setMe,
            axiosStatusCode,
            isLoading,
            setIsLoading,
            isAuthorized,
            setIsAuthorized,
            isFirstLoading,
            setIsFirstLoading,
            randomNumber: Math.random(),

            // API
            host: '/api',
            user: '/users',
            auth: '/users/auth',
            announcements_list_api: '/announcements',
            notification_list_api: '/notifications',
            analysis_list_api: '/results',
            questionnaire_list_api: '/questionnaires',
            scheduled_list_api: '/analysis/periodic',
            oneshot_api: '/results/oneshot/latest',
            labelings_api: '/labelings',
            users_api: '/me',
            storage_list_api: '/storages',
            hashtag_list_api: '/hashtags',
            entity_list_api: '/entities',
            trend: '/trends',
          }}
        >
          <SWRConfig
            value={{
              fetcher: (url) => axios.get(url).then((res) => res.data),
            }}
          >
            <BrowserRouter>
              <AppRouter
                isLoading={isLoading}
                isFirstLoading={isFirstLoading}
                isAuthorized={isAuthorized}
              />
            </BrowserRouter>
          </SWRConfig>
        </ApiContext.Provider>
      </Suspense>
    </ThemeProvider>
  )
}

export const useApiContext = () => {
  const context = useContext(ApiContext)
  if (!context) {
    throw new Error('useApiContext must be used within a ApiContext')
  }
  return context
}

export default App
