import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

type RegisterOrdersByPaidInput = {
  contents: string
  price: number
  frdKey: string
  orderId: number
}

type RegisterOrdersByPaidPayload = {
  message: string
}

export const registerOrdersByPaid = async (
  input: RegisterOrdersByPaidInput,
) => {
  const response = await axios.post<RegisterOrdersByPaidPayload>(
    '/api/paid/register_orders',
    {
      contents: input.contents,
      price: input.price,
      frd_key: input.frdKey,
      order_id: input.orderId,
    },
  )

  return response.data
}

export const useRegisterOrdersByPaid = (
  option?: UseMutationOption<RegisterOrdersByPaidPayload>,
) => useMutation(registerOrdersByPaid, option)
