import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { CreateQuestionnaireV2Input } from 'api/createQuestionnaire'
import { useCurrentUser } from 'api/getCurrentUser'
import { useNeedQuestionnaireCheckout } from 'hooks/useNeedCheckout'
import useProductInfo from 'hooks/useProductInfo'
import formatYen, { withTax } from 'libs/formatYen'
import { isValidQuestionnaire } from 'pages/v2/KeywordDetail/components/Questionnaire/schema'
import NoRemainingCountMessage from 'pages/v2/components/NoRemainingCountMessage'
import {
  genderLabel,
  generatePrefectureLabel,
  generationLabel,
} from 'pages/v2/components/ResearchTargetInputForm'
import { useFormContext } from 'react-hook-form'

type CreateQuestionnaireFormInput = Omit<
  CreateQuestionnaireV2Input,
  'analysisResultId'
>

export const CreateQuestionnairePreviewForm = ({
  onClickCancel,
  isLoading,
  checkoutMode,
}: {
  onClickCancel: () => void
  isLoading: boolean
  checkoutMode: boolean
}) => {
  const { getValues } = useFormContext<CreateQuestionnaireFormInput>()
  const { currentUser } = useCurrentUser()
  const { productInfo } = useProductInfo('questionnaire')
  const needCheckout = useNeedQuestionnaireCheckout(!!checkoutMode)
  const value = getValues()

  return (
    <>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Typography sx={{ fontSize: 'large', fontWeight: 'bold', pt: '24px' }}>
          以下の内容でアンケートを依頼します。
        </Typography>
        <Box display="flex" sx={{ p: '24px 20px' }}>
          <Typography fontSize="medium" width="240px" minWidth="240px">
            タイトル
          </Typography>
          <Typography
            fontSize="medium"
            width="500px"
            sx={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}
          >
            {value.title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '24px 20px',
            border: 'solid #ccdae5',
            borderWidth: '1px 0',
          }}
        >
          <Typography fontSize="medium" width="240px" minWidth="240px">
            アンケート対象
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Typography fontSize="medium">性別</Typography>
              <Typography fontSize="small">
                {genderLabel(value.researchTarget.gender)}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Typography fontSize="medium">年代</Typography>
              <Typography fontSize="small">
                {value.researchTarget.generations
                  .map(generationLabel)
                  .join(', ')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Typography fontSize="medium">都道府県</Typography>
              <Typography fontSize="small">
                {value.researchTarget.prefectures
                  .map(generatePrefectureLabel)
                  .join(', ')}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '24px 20px',
            border: 'solid #ccdae5',
            borderWidth: '1px 0',
          }}
        >
          <Typography fontSize="medium" width="240px" minWidth="240px">
            スペシャリティパネル
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Typography fontSize="medium">
                スペシャリティパネルの利用
              </Typography>
              <Typography fontSize="small">
                {value.researchTarget.specialityPanel ? '有り' : '無し'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                width: '500px',
              }}
            >
              <Typography fontSize="medium">その他依頼</Typography>
              <Typography
                fontSize="small"
                sx={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}
              >
                {value.researchTarget.note}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: '24px 20px',
            border: 'solid #ccdae5',
            borderWidth: '1px 0',
          }}
        >
          <Typography fontSize="medium" width="240px" minWidth="240px">
            質問内容
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            {value.questions
              .filter(isValidQuestionnaire)
              .map((question, index) => {
                return (
                  <Box
                    key={`question-preview-${index}`}
                    sx={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <Typography fontSize="medium" width="100px">
                      設問{index + 1}
                    </Typography>
                    <Box
                      sx={{
                        width: '400px',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                      }}
                    >
                      <Typography fontSize="medium">
                        {question.question}
                      </Typography>
                      <RadioGroup name={`radio-buttons-group-${index}`}>
                        {question.selections.map((selection, idx) => {
                          return (
                            <FormControlLabel
                              sx={{
                                '& .MuiFormControlLabel-label': {
                                  fontSize: 'small',
                                  color: '#000 !important',
                                },
                                '& .MuiTypography-root': {
                                  width: '320px',
                                  wordWrap: 'break-word',
                                  overflowWrap: 'break-word',
                                },
                              }}
                              key={`preview-checkbox-${idx}`}
                              label={selection}
                              control={
                                question.answerType === 1 ? (
                                  <Radio
                                    sx={{
                                      '& .MuiSvgIcon-root': { fontSize: 24 },
                                    }}
                                    value={`${index}-${idx}`}
                                  />
                                ) : (
                                  <Checkbox
                                    sx={{
                                      '& .MuiSvgIcon-root': { fontSize: 24 },
                                    }}
                                  />
                                )
                              }
                            />
                          )
                        })}
                      </RadioGroup>
                    </Box>
                  </Box>
                )
              })}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '42px',
        }}
      >
        <Button
          disabled={isLoading}
          variant="contained"
          sx={{
            px: 2,
            fontSize: '14px',
            backgroundColor: '#9697af',
            color: 'white',
          }}
          onClick={onClickCancel}
        >
          キャンセル
        </Button>
        {needCheckout ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Button
              variant="contained"
              type="submit"
              sx={{ px: 7, fontSize: '20px', color: 'white' }}
              disabled={isLoading}
            >
              アンケートを依頼する /{' '}
              {formatYen(
                productInfo?.price ? withTax(Number(productInfo.price)) : 0,
              )}
              （税込）
            </Button>
            <NoRemainingCountMessage
              remainingCount={currentUser?.remaining_questionnaire_count ?? 0}
              isCampaign={productInfo?.isCampaign ?? false}
            />
          </Box>
        ) : (
          <>
            <Button
              variant="contained"
              type="submit"
              sx={{ px: 7, fontSize: '20px', color: 'white' }}
              disabled={
                isLoading ||
                (currentUser?.remaining_questionnaire_count ?? 0) <= 0
              }
            >
              アンケートを依頼する
            </Button>
            <Typography sx={{ mt: 2 }}>
              ※簡易アンケート1回分を1消費します
            </Typography>
          </>
        )}
      </Box>
    </>
  )
}
