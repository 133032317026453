import { useEffect, useState } from 'react'
import { Slug } from 'types/product'

const useOpenSummaryDialog = ({
  orderId,
  orderProductSlug,
}: {
  orderId: string | undefined
  orderProductSlug: Slug | undefined
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const key = `openSummaryDialogAfterCheckout-${orderId}`

  useEffect(() => {
    if (!orderId || !orderProductSlug) {
      return
    }

    // 購入した商品のページ以外の場合は処理を終了
    if (orderProductSlug !== 'summary') {
      return
    }

    const dialogClosed = localStorage.getItem(key)

    if (!dialogClosed) {
      // ダイアログがまだ閉じられていない場合
      setIsDialogOpen(true)
    }
  }, [orderId, orderProductSlug])

  const closeDialog = () => {
    if (!orderId) return

    setIsDialogOpen(false)
    localStorage.setItem(key, 'true')
  }

  return { isDialogOpen, closeDialog }
}

export default useOpenSummaryDialog
