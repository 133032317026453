import {
  Box,
  Button,
  CircularProgress,
  FormLabel,
  TextField,
} from '@mui/material'
import { useCurrentUser } from 'api/getCurrentUser'
import axios from 'axios'
import { useDisclosure } from 'hooks/useDisclosure'
import BaseSnackbar from 'pages/v2/components/BaseSnackbar'
import React, { useState } from 'react'
import { notifyError } from 'util/errorTracking'

const ApiUrl = process.env.REACT_APP_HELP_URL as string

const ContactForm = () => {
  const { currentUser } = useCurrentUser()

  const [formTitle, setFormTitle] = useState('')
  const [formText, setFormText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [resultMessage, setResultMessage] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!currentUser) return
    try {
      const formData = new FormData()
      formData.append('title', formTitle)
      formData.append('body', formText)
      formData.append('name', currentUser.name)
      formData.append('email', currentUser.email)
      formData.append('user_id', String(currentUser.id))

      setIsLoading(true)
      const res = await axios.post(ApiUrl, formData)
      setFormTitle('')
      setFormText('')
      setResultMessage('お問い合わせを受け付けました。')
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
      setResultMessage('お問い合わせに失敗しました。')
    } finally {
      setIsLoading(false)
      onOpen()
    }
  }

  const isEntered = formTitle.length > 0 && formText.length > 0

  return (
    <>
      <Box
        component="form"
        onSubmit={onSubmit}
        noValidate
        sx={{ mt: 9, px: 4 }}
      >
        <Box sx={{ mt: 6, mb: 6 }}>
          <Box
            sx={{
              display: {
                xs: 'block',
                sm: 'flex',
              },
              px: 2.5,
              py: 5,
              borderWidth: '1px 0',
            }}
          >
            <FormLabel
              sx={{ fontSize: 'medium', color: '#000', minWidth: '230px' }}
            >
              タイトル
            </FormLabel>
            <TextField
              size="small"
              value={formTitle}
              label="タイトルを入力"
              variant="outlined"
              onChange={(e) => setFormTitle(e.target.value)}
              required
              sx={{
                flex: '1',
                '& .MuiInputBase-root > .MuiOutlinedInput-notchedOutline': {
                  fontSize: 'medium',
                },
              }}
              InputLabelProps={{
                sx: { fontSize: 'medium' },
              }}
              inputProps={{
                style: {
                  fontSize: 'medium',
                  padding: '8px 16px',
                  boxSizing: 'content-box',
                  border: 'none',
                },
              }}
              FormHelperTextProps={{
                sx: { fontSize: 'small' },
              }}
            />
          </Box>
          <Box
            sx={{
              display: {
                xs: 'block',
                sm: 'flex',
              },
              px: 2.5,
              py: 5,
              borderWidth: '1px 0',
            }}
          >
            <FormLabel
              sx={{ fontSize: 'medium', color: '#000', minWidth: '230px' }}
            >
              お問い合わせ内容
            </FormLabel>
            <TextField
              fullWidth
              label="お問い合わせ内容"
              placeholder="内容を入力してください"
              multiline
              rows={5}
              variant="outlined"
              value={formText}
              onChange={(e) => setFormText(e.target.value)}
              required
              sx={{
                flex: '1',
                '& .MuiInputBase-root > .MuiOutlinedInput-notchedOutline': {
                  fontSize: 'medium',
                },
              }}
              InputLabelProps={{
                sx: { fontSize: 'medium' },
              }}
              inputProps={{
                style: {
                  fontSize: 'medium',
                  padding: '8px 16px',
                  boxSizing: 'content-box',
                  border: 'none',
                },
              }}
              FormHelperTextProps={{
                sx: { fontSize: 'small' },
              }}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            disabled={!isEntered || isLoading}
            type="submit"
            endIcon={isLoading ? <CircularProgress size={20} /> : null}
          >
            {isLoading ? '送信中...' : '問い合わせ送信'}
          </Button>
        </Box>
      </Box>

      <BaseSnackbar
        message={resultMessage}
        open={isOpen}
        handleClose={onClose}
      />
    </>
  )
}

export default ContactForm
