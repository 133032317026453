import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Typography } from '@mui/material'
import { useCurrentUser } from 'api/getCurrentUser'
import { useRecordLog } from 'api/recordLog'
import { useDisclosure } from 'hooks/useDisclosure'
import { useNeedWeightedCheckout } from 'hooks/useNeedCheckout'
import useProductInfo from 'hooks/useProductInfo'
import useSelectedKeyword from 'hooks/useSelectedKeyword'
import formatYen, { withTax } from 'libs/formatYen'
import { researchTargetSchema } from 'pages/v2/NewAnalysisTheme/schema'
import WeightedCheckoutDialog from 'pages/v2/checkout/components/WeightedCheckoutDialog'
import NoRemainingCountMessage from 'pages/v2/components/NoRemainingCountMessage'
import {
  AgeRecord,
  GenderRecord,
  ResearchTargetInputForm,
} from 'pages/v2/components/ResearchTargetInputForm'
import { VFC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

type Props = {
  onSubmit: (data: FormData) => void
  requesting?: boolean
  checkoutMode: boolean
  topicId: string
  defaultValues?: FormData
}

const weightedFilterSchema = z.object({
  researchTarget: researchTargetSchema,
})

export type FormData = z.infer<typeof weightedFilterSchema>

const WeightedFilterForm: VFC<Props> = ({
  onSubmit,
  requesting,
  checkoutMode,
  topicId,
  defaultValues,
}) => {
  const { request: requestRecordLog } = useRecordLog({})
  const { currentUser } = useCurrentUser()
  const { productInfo } = useProductInfo('survey')
  const { selectedKeywordId } = useSelectedKeyword()
  const {
    isOpen: isOpenWeighted,
    onOpen: onOpenWeighted,
    onClose: onCloseWeighted,
  } = useDisclosure()
  const needCheckout = useNeedWeightedCheckout(checkoutMode)
  const method = useForm<FormData>({
    resolver: zodResolver(weightedFilterSchema),
    defaultValues: {
      researchTarget: {
        gender: defaultValues?.researchTarget?.gender ?? 'both',
        generations: defaultValues?.researchTarget?.generations ?? [],
        prefectures: defaultValues?.researchTarget?.prefectures ?? [],
        specialityPanel:
          defaultValues?.researchTarget?.specialityPanel ?? false,
        note: defaultValues?.researchTarget?.note ?? '',
      },
    },
  })

  const { handleSubmit, watch } = method

  const currentResearchTarget = watch('researchTarget')

  return (
    <FormProvider {...method}>
      <form
        onSubmit={handleSubmit(
          needCheckout
            ? async () => {
                onOpenWeighted()
                await requestRecordLog({
                  event: 'survey_checkout_dialog_open',
                  userId: currentUser?.id ?? 0,
                  parameters: {
                    checkout_dialog_open_at: new Date().toISOString(),
                  },
                })
              }
            : onSubmit,
        )}
        style={{
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: 5,
            flexDirection: 'column',
            mb: 5,
          }}
        >
          <ResearchTargetInputForm />
        </Box>
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          {needCheckout ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{ px: 7, fontSize: '20px', color: 'white' }}
                disabled={requesting}
              >
                投稿に共感度を付与する /{' '}
                {formatYen(
                  productInfo?.price ? withTax(Number(productInfo.price)) : 0,
                )}
                （税込）
              </Button>
              <NoRemainingCountMessage
                remainingCount={currentUser?.remaining_weighted_count ?? 0}
                isCampaign={productInfo?.isCampaign ?? false}
              />
              <WeightedCheckoutDialog
                isOpen={isOpenWeighted}
                onClose={onCloseWeighted}
                topicId={topicId}
                createSurveyParams={{
                  analysisResultId: selectedKeywordId
                    ? selectedKeywordId.toString()
                    : '',
                  labelId: topicId,
                  tags: [
                    ...GenderRecord[currentResearchTarget.gender],
                    ...currentResearchTarget.generations.map(
                      (generation) => AgeRecord[generation],
                    ),
                  ],
                  prefectures: currentResearchTarget.prefectures,
                  specialityPanel: currentResearchTarget.specialityPanel,
                  note: currentResearchTarget.note ?? '',
                }}
                surveyFormData={{
                  researchTarget: currentResearchTarget,
                }}
              />
            </Box>
          ) : (
            <>
              <Button
                variant="contained"
                type="submit"
                sx={{ px: 7, fontSize: '20px', color: 'white', mt: 2 }}
              >
                投稿に共感度を付与する
              </Button>
              <Typography sx={{ mt: 2 }}>
                ※共感度付与チケットを1消費します。
              </Typography>
            </>
          )}
        </Box>
      </form>
    </FormProvider>
  )
}

export default WeightedFilterForm
