import { useRef } from 'react'

export const useScrollHelper = () => {
  const ref = useRef<HTMLDivElement | null>(null)

  const handleScroll = () => {
    if (ref === null) return
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  return { ref, handleScroll }
}
