export const dateFormatter = (date: any) => {
  var _date = new Date(date)
  var y = _date.getFullYear()
  var m = _date.getMonth() + 1
  var d = ('0' + _date.getDate()).slice(-2)
  var h = ('0' + _date.getHours()).slice(-2)
  var mm = ('0' + _date.getMinutes()).slice(-2)
  var ss = ('0' + _date.getSeconds()).slice(-2)

  var result = `${y}-${m}-${d} ${h}:${mm}:${ss}`
  return result
}

export const v2DateFormatter = (date: Date) => {
  var y = date.getFullYear()
  var m = date.getMonth() + 1
  var d = ('0' + date.getDate()).slice(-2)
  var h = ('0' + date.getHours()).slice(-2)
  var mm = ('0' + date.getMinutes()).slice(-2)
  var ss = ('0' + date.getSeconds()).slice(-2)

  var result = `${y}-${m}-${d} ${h}:${mm}:${ss}`
  return result
}

export const v3DateFormatter = (
  date: string,
  mode?:
    | 'ISODateTime'
    | 'JapaneseDateTime'
    | 'JapaneseDate'
    | 'SimpleTime'
    | 'SlashDate',
) => {
  if (!date) return '不明'
  let _date = new Date(date)
  let y = _date.getFullYear()
  let m = _date.getMonth() + 1
  // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'number'.
  m = ('0' + m).slice(-2)
  let d = ('0' + _date.getDate()).slice(-2)
  let h = ('0' + _date.getHours()).slice(-2)
  let mm = ('0' + _date.getMinutes()).slice(-2)
  let ss = ('0' + _date.getSeconds()).slice(-2)

  let formatedDate = ''
  switch (mode) {
    case 'ISODateTime':
      formatedDate = `${y}-${m}-${d} ${h}:${mm}:${ss}`
      break
    case 'JapaneseDateTime':
      formatedDate = `${y}年${m}月${d}日 ${h}時${mm}分`
      break
    case 'JapaneseDate':
      formatedDate = `${y}年${m}月${d}日`
      break
    case 'SimpleTime':
      formatedDate = `${h}:${mm}:${ss}`
      break
    case 'SlashDate':
      formatedDate = `${y}/${m}/${d}`
      break
    default:
      formatedDate = `${y}-${m}-${d}`
  }

  return formatedDate
}
