import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

type UpdateSumamryInput = {
  id: number
  title: string
}

type UpdateSummaryPayload = {
  id: number
}

export const updateSummary = async (
  input: UpdateSumamryInput,
): Promise<UpdateSummaryPayload> => {
  const res = await axios.put<UpdateSummaryPayload>(
    `/api/analysis_summaries/${input.id}`,
    {
      title: input.title,
    },
  )
  return res.data
}

export const useUpdateSummary = (
  option?: UseMutationOption<UpdateSummaryPayload>,
) => useMutation(updateSummary, option)
