export type ImportInfo = {
  id: number
  totalSurvey: number | undefined
  totalEmpathy: number | undefined
  mainGeneration: string | undefined
  mainGender: string | undefined
}

export type ImportInfoResponse = {
  id: number
  total_surveys: number | null
  total_empathy: number | null
  main_generation: string | null
  main_gender: string | null
}

export const parseImportInfo = (info: ImportInfoResponse): ImportInfo => {
  return {
    id: info.id,
    totalSurvey: info.total_surveys ?? undefined,
    totalEmpathy: info.total_empathy ?? undefined,
    mainGeneration: info.main_generation ?? undefined,
    mainGender: info.main_gender ?? undefined,
  }
}
