/* eslint-disable react/jsx-key */
import React from 'react'
import AliceCarousel from 'react-alice-carousel'

const PopupWelcome = ({ openname, modalCloseCallback, okCallback }) => {
  return (
    <div
      className={openname === 'welcome' ? 'p-pop is-open' : 'p-pop'}
      data-name="pop6"
    >
      <div className="p-pop__overlay" style={{ backgroundColor: 'gray' }}>
        <div className="u-w100per u-h100per">
          <div className="c-carousel">
            <AliceCarousel
              mouseTracking
              disableDotsControls
              controlsStrategy="alternate"
              infinite
              items={[
                <div className="item" data-value="1">
                  <img
                    src="/assets/images/tour/img01.png"
                    style={{ pointerEvents: 'none' }}
                  />
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    }}
                  >
                    <div
                      className="c-closeButton"
                      style={{ position: 'absolute', top: 10, right: 10 }}
                      onClick={modalCloseCallback}
                    />
                    <div
                      className="c-btn"
                      style={{
                        position: 'absolute',
                        bottom: 50,
                        right: '24.8%',
                        transform: 'translateX(50%)',
                      }}
                    >
                      <div
                        className="c-btn__link c-btn__link--size-l"
                        style={{ cursor: 'pointer' }}
                        onClick={okCallback}
                      >
                        はじめてのご利用をサポートします（プロダクトツアーへ）
                      </div>
                    </div>
                  </div>
                </div>,
                <div className="item" data-value="2">
                  <img
                    src="/assets/images/tour/img02.png"
                    style={{ pointerEvents: 'none' }}
                  />
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    }}
                  >
                    <div
                      className="c-closeButton"
                      style={{ position: 'absolute', top: 10, right: 10 }}
                      onClick={modalCloseCallback}
                    />
                  </div>
                </div>,
                <div className="item" data-value="3">
                  <img
                    src="/assets/images/tour/img03.png"
                    style={{ pointerEvents: 'none' }}
                  />
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    }}
                  >
                    <div
                      className="c-closeButton"
                      style={{ position: 'absolute', top: 10, right: 10 }}
                      onClick={modalCloseCallback}
                    />
                    <div
                      className="c-btn"
                      style={{
                        position: 'absolute',
                        bottom: 50,
                        right: '24.8%',
                        transform: 'translateX(50%)',
                      }}
                    >
                      <div
                        className="c-btn__link c-btn__link--size-l"
                        style={{ cursor: 'pointer' }}
                        onClick={okCallback}
                      >
                        はじめてのご利用をサポートします（プロダクトツアーへ）
                      </div>
                    </div>
                  </div>
                </div>,
              ]}
              renderNextButton={({ isDisabled }) => (
                <div
                  className="c-roundIcon c-roundIcon--color-white c-roundIcon--hov-bgGray"
                  style={{ width: 60, height: 60 }}
                >
                  <span className="c-roundIcon__arrow c-roundIcon__arrow--right"></span>
                </div>
              )}
              renderPrevButton={({ isDisabled }) => (
                <div
                  className="c-roundIcon c-roundIcon--color-white c-roundIcon--hov-bgGray"
                  style={{ width: 60, height: 60 }}
                >
                  <span className="c-roundIcon__arrow c-roundIcon__arrow--left"></span>
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupWelcome
