import {
  AnalysisSummary,
  AnalysisSummaryResponse,
  parseAnalysisSummary,
} from './analysisSummary'

export type AnalysisResultResponse = {
  id: number
  analysis_status_id: number
  target_words: string
  summaries: AnalysisSummaryResponse[]
}
export enum AnalysisStatus {
  Collecting = 1,
  TopicAnalysing = 2,
  TopicLabeling = 3,
  SuggestWords = 4,
  ErrorCollecting = 11,
  ErrorTopicAnalysing = 12,
  ErrorTopicLabeling = 13,
  Complete = 20,
  Close = 21,
}

export type AnalysisResult = {
  id: number
  analysisStatus: AnalysisStatus
  targetWords: string
  summaries: AnalysisSummary[]
}

const parseAnalysisResultStatus = (value: number): AnalysisStatus => {
  switch (value) {
    case 1:
      return AnalysisStatus.Collecting
    case 2:
      return AnalysisStatus.TopicAnalysing
    case 3:
      return AnalysisStatus.TopicLabeling
    case 4:
      return AnalysisStatus.SuggestWords
    case 11:
      return AnalysisStatus.ErrorCollecting
    case 12:
      return AnalysisStatus.ErrorTopicAnalysing
    case 13:
      return AnalysisStatus.ErrorTopicLabeling
    case 20:
      return AnalysisStatus.Complete
    case 21:
      return AnalysisStatus.Close
    default:
      throw new Error(`Invalid AnalysisStatus value: ${value}`)
  }
}

export const parseAnalysisResult = (
  input: AnalysisResultResponse,
): AnalysisResult => {
  return {
    id: input.id,
    analysisStatus: parseAnalysisResultStatus(input.analysis_status_id),
    targetWords: input.target_words,
    summaries: input.summaries.map(parseAnalysisSummary),
  }
}
