import useSelectedKeyword from 'hooks/useSelectedKeyword'
import useSelectedTheme from 'hooks/useSelectedTheme'
import { useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import { AnalysisStatus } from 'types/analysisResult'
import { Loading } from './components/Loading'

export const KeywordDetailPage = () => {
  // pollingで状態を更新するような場合は、軽量なAPI Endpoingを作成する
  const { selectedThemeId } = useSelectedTheme()
  const { selectedKeywordId, selectedKeyword } = useSelectedKeyword()

  const status = useMemo(
    () => selectedKeyword?.analysisStatus,
    [selectedKeyword],
  )

  if (selectedKeyword === undefined) return <Redirect to="/v2/themes" />

  if (status === AnalysisStatus.Collecting) return <Loading type="collecting" />

  if (status === AnalysisStatus.TopicAnalysing)
    return <Loading type="analysing" />

  if (
    status === AnalysisStatus.TopicLabeling ||
    status === AnalysisStatus.SuggestWords
  )
    return <Loading type="createReport" finishAt="2024-01-24" />

  if (status === AnalysisStatus.Complete || status === AnalysisStatus.Close)
    return selectedKeyword.summaries.length === 0 ? (
      <Loading type="summaryDoesNotExist" />
    ) : (
      // TODO: サマリーの比較等決定次第、redirect=>
      <Redirect
        to={`/v2/themes/${selectedThemeId}/keywords/${selectedKeywordId}/summary/${selectedKeyword.summaries[0].id}`}
      />
    )

  return <Loading type="createSummaryFailed" />
}
