import { Box, Button, Typography } from '@mui/material'
import { useDisclosure } from 'hooks/useDisclosure'
import BaseDialog from 'includes/BaseDialog'
import React from 'react'
import { useHistory } from 'react-router-dom'

type CancelDialogProps = {}

const CancelDialog: React.VFC<CancelDialogProps> = (props) => {
  const { isOpen, onClose } = useDisclosure({ defaultOpen: true })

  const history = useHistory()
  const handleClose = () => {
    history.push('/v2/themes')
    onClose()
  }

  return (
    <BaseDialog
      open={isOpen}
      onClose={handleClose}
      title="購入がキャンセルされました"
      breakPoints="md"
    >
      <Box sx={{ px: 4, py: 2 }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6" sx={{ color: '#000', mb: 5 }}>
            購入がキャンセルされました。
            <br />
            もう一度お試しください。
          </Typography>
          <Typography variant="body1" sx={{ color: '#000', mb: 5 }}>
            もしお困りの場合は、サポートまでご連絡ください。
          </Typography>

          <Box>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                textTransform: 'none',
                fontSize: 16,
                color: 'white',
                borderRadius: '20px',
              }}
            >
              閉じる
            </Button>
          </Box>
        </Box>
      </Box>
    </BaseDialog>
  )
}

export default CancelDialog
