import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'
import { castToV2TourStep, V2TourStep } from 'types/v2TourHistory'

type UpdateV2TourStepInput = {
  v2TourStep: V2TourStep
}

type UpdateV2TourStepPayload = {
  v2_tour_step: string
}

let lastRequestCall: V2TourStep | null = null

export const updateV2TourStep = async (
  input: UpdateV2TourStepInput,
): Promise<{ v2TourStep: V2TourStep }> => {
  // 同一のリクエストが送信された場合は無効
  if (lastRequestCall === null) lastRequestCall = input.v2TourStep
  else if (lastRequestCall === input.v2TourStep)
    throw new Error('Request already sent')
  lastRequestCall = input.v2TourStep

  const res = await axios.post<UpdateV2TourStepPayload>(`/api/v2_tour_step`, {
    v2_tour_step: input.v2TourStep,
  })
  return {
    v2TourStep: castToV2TourStep(res.data.v2_tour_step),
  }
}

export const useUpdateV2TourStep = (
  option?: UseMutationOption<{ v2TourStep: V2TourStep }>,
) => useMutation(updateV2TourStep, option)
