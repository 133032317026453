import { CheckoutInput, CheckoutPayload } from 'api/order/checkout'
import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

type CheckoutSummaryInput = CheckoutInput & {
  keywordId: number
}

export const checkoutSummary = async (input: CheckoutSummaryInput) => {
  const {
    keywordId,
    provider,
    taxRate,
    productId,
    quantity,
    successUrl,
    cancelUrl,
    params,
  } = input
  const response = await axios.post<CheckoutPayload>(
    `/api/results/${keywordId}/summaries/checkout`,
    {
      provider,
      tax_rate: taxRate,
      product_id: productId,
      quantity,
      success_url: successUrl,
      cancel_url: cancelUrl,
      params,
    },
  )
  return response.data
}

export const useCheckoutSummary = (
  option?: UseMutationOption<CheckoutPayload>,
) => useMutation(checkoutSummary, option)
