import { ArrowBack } from '@mui/icons-material'
import { Box, Card, IconButton, Typography } from '@mui/material'
import useQuestionnaireByTopicData from 'hooks/useQuestionnaireByTopicData'
import useScroll from 'hooks/useScroll'
import QuestionnaireDownloadLink from 'pages/v2/KeywordDetail/components/Questionnaire/Result/QuestionnaireDownloadLink'
import QuestionnaireInfo from 'pages/v2/KeywordDetail/components/Questionnaire/Result/QuestionnaireInfo'
import { V2Paths } from 'pages/v2/paths'
import { generatePath, Link, useParams } from 'react-router-dom'

const QuestionnaireResult = () => {
  const { topicId, ...rest } = useParams<{
    themeId: string
    keywordId: string
    summaryId: string
    topicId: string
  }>()
  const { questionnaire, targetText, answerCount, specialityPannelText } =
    useQuestionnaireByTopicData(topicId)
  const SCROLL_TARGET = 'js-scrollWrap'

  useScroll({ target: `.${SCROLL_TARGET}` })

  if (!questionnaire) {
    return null
  }

  return (
    <Card
      sx={{
        mx: {
          md: 0,
          lg: 3,
        },
        my: 3,
        borderRadius: 4,
        pt: 4,
        pb: 0,
        px: { xs: 4, md: 8, lg: 16 },
        position: 'relative',
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mb: 4,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconButton sx={{ position: 'absolute', left: 12, top: 12 }}>
              <Link
                to={generatePath(V2Paths.Summary, {
                  ...rest,
                })}
              >
                <ArrowBack />
              </Link>
            </IconButton>
            <Typography
              style={{
                textAlign: 'center',
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            >
              「{questionnaire?.title}」についてのアンケート
            </Typography>
          </Box>
          <Box
            sx={{
              alignSelf: 'flex-end',
              mt: 2,
            }}
          >
            <QuestionnaireDownloadLink
              status={questionnaire.status}
              fileUrl={questionnaire.id < 0 ? null : questionnaire.fileUrl}
            />
          </Box>
        </Box>
        <Box
          className={SCROLL_TARGET}
          sx={{
            overflow: 'auto',
          }}
        >
          <QuestionnaireInfo
            targetText={targetText}
            answerCount={answerCount}
            questions={questionnaire.resultContent?.answers ?? []}
            specialityPannelText={specialityPannelText}
          />
        </Box>
      </Box>
    </Card>
  )
}

export default QuestionnaireResult
