import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

export type CreateSurveyInput = {
  analysisResultId: string
  labelId: string
  tags: number[]
  specialityPanel: boolean
  note: string
  prefectures?: string[]
}

export const createSurvey = async (input: CreateSurveyInput) => {
  await axios.post('/api/surveys', {
    analysis_result_id: input.analysisResultId,
    label_id: input.labelId,
    tags: input.tags,
    speciality_panel: input.specialityPanel,
    note: input.note,
    prefectures: input.prefectures ?? [],
  })
}

export const useCreateSurvey = (option?: UseMutationOption<void>) =>
  useMutation(createSurvey, option)
