import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const usePreventSuccessPageNavigation = ({
  isLoading,
  orderId,
  isWaitingWebhook,
}: {
  isLoading: boolean
  orderId: string | undefined
  isWaitingWebhook: boolean
}) => {
  const history = useHistory()

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
      history.push('/themes/v2')
    }

    const handleUnload = () => {
      history.push('/themes/v2')
    }

    const handlePopState = () => {
      history.push('/themes/v2')
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('unload', handleUnload)
    window.addEventListener('popstate', handlePopState)

    if (!isLoading && !orderId && !isWaitingWebhook) {
      history.push('/themes/v2')
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('unload', handleUnload)
      window.removeEventListener('popstate', handlePopState)
    }
  }, [isLoading, orderId, isWaitingWebhook, history])
}

export default usePreventSuccessPageNavigation
