import MenuIcon from '@mui/icons-material/Menu'
import { Box, CircularProgress, Grid } from '@mui/material'
import { useGetSummaryDetail } from 'api/getSummaryDetail'
import { useDisclosure } from 'hooks/useDisclosure'
import useSelectedSummary from 'hooks/useSelectedSummary'
import { AnalysisSummaryDetail } from 'pages/v2/sp/KeywordDetail/components/AnalysisSummaryDetail'
import { SummaryBackButton } from 'pages/v2/sp/KeywordDetail/components/SummaryBackButton'
import { memo, Suspense } from 'react'

export const SPSummaryDetailPage = memo(() => {
  const { selectedSummaryId } = useSelectedSummary()
  const { data: summary } = useGetSummaryDetail({
    input: { summaryId: selectedSummaryId },
  })
  const isPublic = summary?.isPublic ?? false
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <Box sx={{ py: 3 }}>
      <Grid container spacing={1}>
        <Grid item xs={isPublic ? 11 : 12}>
          <SummaryBackButton />
        </Grid>
        {isPublic && (
          <Grid item xs={1}>
            <MenuIcon
              sx={{ color: '#F39422' }}
              onClick={isOpen ? onClose : onOpen}
            />
          </Grid>
        )}
      </Grid>
      <Suspense
        fallback={
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        }
      >
        <AnalysisSummaryDetail showTableOfContents={isOpen} />
      </Suspense>
    </Box>
  )
})

SPSummaryDetailPage.displayName = 'SPSummaryDetailPage'
