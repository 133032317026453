import { useApiContext } from 'App'
import axios from 'axios'
import { driver } from 'driver.js'

const step1 = [
  {
    element: "[data-tour='analysis-add']",
    popover: {
      title: '1-1．調査したいキーワードを指定し分析を始めます',
      description: '「＋分析を新規追加」をクリックしてください',
    },
    isHighlighted: true,
  }, // 9
  {
    element: "[data-tour='target_word']",
    popover: {
      title: '1-2. 分析を実行する',
      description: '1．調査したいキーワードを入力してください',
      showButtons: ['next'],
    },
  }, // 1
  {
    element: "[data-tour='execute-analysis']",
    popover: {
      title: '1-3. キーワードの分析を始めます',
      description: 'クリックしてください',
      showButtons: ['previous', 'close'],
    },
  }, // 2
  {
    element: "[data-tour='lets-execute-analysis']",
    popover: {
      title: '1-5. キーワードの分析を開始',
      description: '実際にキーワードを入力して分析をはじめてみましょう',
      showButtons: ['next'],
    },
  }, // 3
]

const step2 = [
  {
    element: "[data-tour='analysis-result-list']",
    popover: {
      title: '2-1. 分析したキーワードを表示します',
      showButtons: ['next'],
    },
  },
  {
    element: "[data-tour='analysis-result-list-0']",
    popover: {
      title: '2-2. キーワードの分析状況を表示します',
      description: '分析状況が「完了」になったら、分析結果を確認できます',
      showButtons: ['next'],
    },
  },
]

const step3 = [
  {
    element: "[data-tour='analysis-result-list-link-0']",
    popover: {
      title: '3-1. キーワードを分析した結果を表示します',
      description: '閲覧したいキーワードをクリックしてください',
      showButtons: [],
    },
    isHighlighted: true,
  }, // 0
  {
    element: "[data-tour='analysis-result-title']",
    popover: {
      title: '3-2. 指定したキーワード、投稿件数を表示します',
      showButtons: ['next'],
    },
  }, // 1
  {
    element: "[data-tour='analysis-result-topics-0']",
    popover: {
      title: '3-3. ラベリング担当者が設定したトピックタイトルを表示します',
      showButtons: ['previous', 'next'],
    },
  }, // 2
  {
    element: "[data-tour='analysis-result-posts']",
    popover: {
      title:
        '3-4. 選択したタイトルに紐づいた投稿が一覧で表示されます。\nタイトルに関連すると判断した投稿が上位に表示されます',
      showButtons: ['previous', 'next'],
    },
  }, // 3
  {
    element: "[data-tour='analysis-result-labeling-detail']",
    popover: {
      title: '3-5. ラベリング結果の詳細を表示します',
      description: 'クリックしてください',
      showButtons: [],
    },
    isHighlighted: true,
  }, // 4
  {
    element: "[data-tour='analysis-result-labeling-comment']",
    popover: {
      title:
        '3-6. ラベリング担当者がトピックタイトルを設定した意図を記載します',
      showButtons: ['close'],
    },
  }, // 5
  {
    element: "[data-tour='analysis-result-posts-filter']",
    popover: {
      title: '3-7. 「絞り込む」をクリックすると投稿内容の絞り込みができます',
      description: 'クリックしてください',
    },
    isHighlighted: true,
  }, // 6
  {
    element: "[data-tour='analysis-result-posts-filter-keyword']",
    popover: {
      title: '3-8. 任意のキーワードで投稿を絞ります',
      showButtons: ['next'],
    },
    config: {
      disableActiveInteraction: true,
    },
  }, // 7
  {
    element: "[data-tour='analysis-result-posts-filter-flags']",
    popover: {
      title:
        '3-9. 紐づけされているか（投稿に色がついているか）で投稿を絞り込みます',
      showButtons: ['previous', 'next'],
    },
    config: {
      disableActiveInteraction: true,
    },
  }, // 8
  {
    element: "[data-tour='analysis-result-posts-filter-images']",
    popover: {
      title: '3-10. 画像が添付されているかで投稿を絞り込みます',
      showButtons: ['previous', 'next'],
    },
    config: {
      disableActiveInteraction: true,
    },
  }, // 9
  {
    element: "[data-tour='analysis-result-posts-filter-submit']",
    popover: {
      title: '3-11. 設定した条件で投稿を絞り込みます',
      description: 'クリックしてください',
      showButtons: [],
    },
    isHighlighted: true,
  }, // 10
  {
    element: "[data-tour='analysis-result-change-title']",
    popover: {
      title: '3-12. タイトルや紐づいた投稿を自分で編集できます',
      description: 'クリックしてください',
      showButtons: [],
    },
    isHighlighted: true,
  }, // 11
  {
    element: "[data-tour='analysis-result-change-title-input']",
    popover: {
      title: '3-13. タイトルを編集できます',
      showButtons: ['next'],
    },
  }, // 12
  {
    element: "[data-tour='analysis-result-change-flag-0']",
    popover: {
      title: '3-14. タイトルに紐づける投稿を編集できます',
      showButtons: ['previous', 'next'],
    },
  }, // 13
  {
    element: "[data-tour='analysis-result-change-title-input-submit']",
    popover: {
      title: '3-15. 操作が完了したら保存しましょう',
      description: 'クリックしてください',
      showButtons: [],
    },
    isHighlighted: true,
  }, // 14
  {
    element: "[data-tour='analysis-result-open-menu']",
    popover: {
      title: '3-16. メニューを開きます',
      description: 'クリックしてください',
      showButtons: [],
    },
    isHighlighted: true,
  }, // 15
  {
    element: "[data-tour='analysis-result-csv-download']",
    popover: {
      title: '3-17. 分析結果のCSVデータをダウンロードします',
      showButtons: ['next'],
    },
  }, // 16
]

const steps = [step1, step2, step3]

export const tourDriver = driver()

const useTour = (step, index) => {
  const {
    tourIndex,
    setTourIndex,
    tourStep,
    setTourStep,
    tourDriver,
    host,
    users_api,
  } = useApiContext()
  const TourProgressPath = host + users_api + '/tour_progress'
  const tourStepData = steps[tourStep]
  const additionalConfig = steps?.[tourStep]?.[tourIndex]?.config ?? {}

  tourDriver.setConfig({
    allowClose: false,
    doneBtnText: '閉じる',
    closeBtnText: '閉じる',
    nextBtnText: '次へ',
    prevBtnText: '前へ',
    allowKeyboardControl: false,
    showProgress: false,
    progressText: '{{current}} / {{total}}',
    steps: tourStepData,
    onNextClick: () => {
      const activeIndex = tourDriver.getActiveIndex() + 1
      console.log('next', activeIndex)
      setTourIndex(activeIndex)
      tourDriver.moveNext()
      localStorage.setItem('tourIndex', activeIndex.toString())
    },
    onPrevClick: () => {
      const activeIndex = tourDriver.getActiveIndex() - 1
      console.log('prev', activeIndex)
      setTourIndex(activeIndex)
      tourDriver.movePrevious()
      localStorage.setItem('tourIndex', activeIndex.toString())
    },
    ...additionalConfig,
  })

  return {
    tourIndex,
    tourStep,
    show: (delay) => {
      if (index !== tourIndex || step !== tourStep) return
      setTimeout(() => {
        localStorage.setItem('tourStep', step)
        localStorage.setItem('tourIndex', index)
        const tourData = steps[step][index]
        if (tourData.isHighlighted) {
          tourDriver.highlight(tourData)
        } else {
          tourDriver.moveTo(index)
        }
        setTourIndex(index)
        axios.put(TourProgressPath, {
          tour_step: step,
          tour_index: index,
        })
      }, delay || 0)
    },
    next: () => {
      setTourIndex(tourIndex + 1)
    },
    prev: () => {
      setTourIndex(tourIndex - 1)
    },
    nextStep: () => {
      const nextStepIndex = tourStep + 1
      const nextIndex = 0
      setTourStep(nextStepIndex)
      setTourIndex(nextIndex)
      localStorage.setItem('tourStep', nextStepIndex.toString())
      localStorage.setItem('tourIndex', nextIndex.toString())
      axios.put(TourProgressPath, {
        tour_step: nextStepIndex,
        tour_index: nextIndex,
      })
    },
    moveTo: (index) => {
      setTourIndex(index)
      localStorage.setItem('tourIndex', index.toString())
    },
    moveToStep: (step) => {
      setTourStep(step)
      setTourIndex(0)
      localStorage.setItem('tourStep', step.toString())
      localStorage.setItem('tourIndex', '0')
      axios.put(TourProgressPath, {
        tour_step: step,
        tour_index: 0,
      })
    },
  }
}

export default useTour
