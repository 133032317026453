import { useEffect, useState } from 'react'
import { Slug } from 'types/product'

const useOpenWeightedDialog = ({
  orderId,
  orderProductSlug,
  topicId,
  orderTopicId,
}: {
  orderId: string | undefined
  orderProductSlug: Slug | undefined
  topicId: string | undefined
  orderTopicId: string | undefined
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const key = `openSurveyDialogAfterCheckout-${orderId}`

  useEffect(() => {
    if (!orderId || !orderProductSlug) {
      return
    }

    // 購入した商品のページ以外の場合は処理を終了
    if (orderProductSlug !== 'survey') {
      return
    }

    // 購入した商品のアンケートIDと表示中のアンケートIDが異なる場合は処理を終了
    if (String(topicId) !== String(orderTopicId)) {
      return
    }

    const dialogClosed = localStorage.getItem(key)

    if (!dialogClosed) {
      // ダイアログがまだ閉じられていない場合
      setIsDialogOpen(true)
    }
  }, [orderId, orderProductSlug, topicId, orderTopicId])

  const closeDialog = () => {
    if (!orderId) return

    setIsDialogOpen(false)
    localStorage.setItem(key, 'true')
  }

  return { isDialogOpen, closeDialog }
}

export default useOpenWeightedDialog
