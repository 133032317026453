import {
  ConfirmPaymentsByPaidInput,
  ConfirmPaymentsByPaidPayload,
} from 'api/checkoutByPaid/confirmPaymentsByPaid'
import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

type ConfirmSummaryPaymentsByPaidInput = ConfirmPaymentsByPaidInput & {
  keywordId: number
}

export const confirmSummaryPaymentsByPaid = async (
  input: ConfirmSummaryPaymentsByPaidInput,
) => {
  const { keywordId, orderId } = input
  const response = await axios.post<ConfirmPaymentsByPaidPayload>(
    `/api/results/${keywordId}/summaries/confirm_payments`,
    {
      order_id: orderId,
    },
  )
  return response.data
}

export const useConfirmSummaryPaymentsByPaid = (
  option?: UseMutationOption<ConfirmPaymentsByPaidPayload>,
) => useMutation(confirmSummaryPaymentsByPaid, option)
