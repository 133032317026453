import { Button } from '@mui/material'
import { useGetOrder } from 'api/order/getOrder'
import { useDisclosure } from 'hooks/useDisclosure'
import useFirstVisitAfterCheckout from 'hooks/useFirstVisitAfterCheckout'
import useOpenQuestionnaireDialog from 'hooks/useOpenQuestionnaireDialog'
import useQuery from 'hooks/useQuery'
import useSelectedKeyword from 'hooks/useSelectedKeyword'
import useSelectedSummary from 'hooks/useSelectedSummary'
import useSelectedTheme from 'hooks/useSelectedTheme'
import BaseDialog from 'includes/BaseDialog'
import { QuestionnaireMake } from 'pages/v2/KeywordDetail/components/Questionnaire/QuestionnaireMake'
import { V2Paths } from 'pages/v2/paths'
import { useEffect } from 'react'
import { generatePath, Link } from 'react-router-dom'
import { AnalysisStatus } from 'types/analysisResult'
import { AnalysisSummaryIdea } from 'types/analysisSummaryIdea'
import {
  QUESTIONNAIRE_STATUS,
  QuestionnaireForTopic,
} from 'types/questionnaire'
import { Survey } from 'types/survey'

type Props = {
  summaryIdea: AnalysisSummaryIdea
  importInfo: Survey['importInfo']
  isPublic: boolean
  topicId: string | undefined
  questionnaire: QuestionnaireForTopic | null
  analysisStatus: AnalysisStatus | null
}

export const QuestionnaireDialogButton = ({
  summaryIdea,
  importInfo,
  isPublic,
  topicId,
  questionnaire,
  analysisStatus,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { selectedKeywordId } = useSelectedKeyword()
  const { selectedThemeId } = useSelectedTheme()
  const { selectedSummaryId } = useSelectedSummary()
  const query = useQuery()
  const orderId = query.get('order_id') ? query.get('order_id') : null
  const { data: order } = useGetOrder({ orderId: orderId })
  const isFirstVisit = useFirstVisitAfterCheckout({
    orderId: orderId?.toString(),
  })
  const { isDialogOpen, closeDialog } = useOpenQuestionnaireDialog({
    orderId: orderId?.toString(),
    orderProductSlug: order ? order.orderItems[0].product.slug : undefined,
    topicId,
    orderTopicId: order ? order.topicId : undefined,
  })
  const { isDemo } = useSelectedSummary()

  useEffect(() => {
    if (isDialogOpen) {
      onOpen()
    } else {
      onClose()
    }
  }, [isDialogOpen])

  return (
    <>
      {questionnaire?.statusId ? (
        questionnaire?.statusId === QUESTIONNAIRE_STATUS.completed ||
        questionnaire?.statusId === QUESTIONNAIRE_STATUS.passback ? (
          <Button
            variant="contained"
            component={Link}
            to={generatePath(V2Paths.QuestionnaireResult, {
              themeId: selectedThemeId,
              keywordId: selectedKeywordId,
              summaryId: selectedSummaryId,
              topicId: questionnaire.topicId ?? undefined,
            })}
          >
            深掘り調査結果を見る
          </Button>
        ) : (
          <Button variant="outlined" disabled>
            調査中
          </Button>
        )
      ) : (
        <Button
          variant="outlined"
          onClick={onOpen}
          disabled={
            isDemo ||
            (analysisStatus !== AnalysisStatus.Complete &&
              analysisStatus !== AnalysisStatus.TopicLabeling)
          }
        >
          深掘り調査をする
        </Button>
      )}
      <BaseDialog
        open={isOpen}
        onClose={isDialogOpen ? closeDialog : onClose}
        breakPoints="xl"
      >
        <QuestionnaireMake
          summaryIdea={summaryIdea}
          importInfo={importInfo}
          isPublic={isPublic}
          topicId={topicId}
          isDialogOpen={isDialogOpen}
          isFirstVisit={isFirstVisit}
          orderId={orderId}
        />
      </BaseDialog>
    </>
  )
}
