import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import { Button, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import { useCurrentUser } from 'api/getCurrentUser'
import { MouseEvent, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

export const AccountInformationSection = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { currentUser } = useCurrentUser()

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl],
  )

  const onClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const handleLogout = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault()
      if (localStorage.getItem('jfc')) {
        localStorage.clear()
      }
      window.location.href = '/users/auth/logout'
    },
    [localStorage],
  )

  return (
    <>
      <Button
        variant="text"
        sx={{
          px: '24px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}
        onClick={handleClick}
      >
        <Typography
          sx={{ color: '#2E2E2E', fontSize: '14px', textTransform: 'none' }}
        >
          {currentUser?.name || currentUser?.email}
        </Typography>
        <Typography
          sx={{ color: '#F39422', fontSize: '14px', fontWeight: 'bold' }}
        >
          {currentUser?.plan.name}
        </Typography>
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem sx={{ color: 'red', px: 2, py: 1 }}>
          <Link
            to="/v2/themes/settings/user"
            style={{
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ManageAccountsIcon />
            <ListItemText
              primary="設定"
              sx={{ pl: 0.5, px: 1 }}
              primaryTypographyProps={{ variant: 'h6', fontSize: 14 }}
            />
          </Link>
        </MenuItem>
        <MenuItem sx={{ color: 'red', px: 2, py: 1 }} onClick={handleLogout}>
          ログアウトする
        </MenuItem>
      </Menu>
    </>
  )
}
