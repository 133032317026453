import useSWR, { SWRConfiguration } from 'swr'
import { Suggestion } from 'types/suggestKeywords'

type GetSuggestionsResponse = {
  data: Suggestion[]
}

type Props = {
  query: {
    keyword: string | undefined
  }
  options: SWRConfiguration<GetSuggestionsResponse, Error>
}
export const useGetSuggestions = ({ query, options }: Props) =>
  useSWR<GetSuggestionsResponse>(
    () =>
      process.env.REACT_APP_PUBLIC_ENV === 'production' &&
      query.keyword !== undefined
        ? `/api/suggest_keywords?keyword=${query.keyword}`
        : null,
    options,
  )
