import { Box, Typography } from '@mui/material'
import { useCurrentUser } from 'api/getCurrentUser'
import { BannerSeverity } from 'hooks/useBanner'
import { ProductInfo } from 'hooks/useProductInfo'
import { useRegisteredPaidMemberStatus } from 'hooks/useRegisteredPaidMember'
import BaseDialog from 'includes/BaseDialog'
import CampaignDetails from 'pages/v2/checkout/components/CampaignDetails'
import PaymentButtons from 'pages/v2/checkout/components/PaymentButtons'
import Precautions from 'pages/v2/checkout/components/Precautions'
import PriceDetails from 'pages/v2/checkout/components/PriceDetails'
import ServiceOverview from 'pages/v2/checkout/components/ServiceOverview'
import AdditionalUserInputForm from 'pages/v2/checkout/components/additionalUserInput/AdditionalUserInputForm'
import { additionalUserInputSchema } from 'pages/v2/checkout/components/additionalUserInput/scheme'
import BaseSnackbar from 'pages/v2/components/BaseSnackbar'
import React from 'react'
import { z } from 'zod'

type FormData = z.infer<typeof additionalUserInputSchema>
type BaseCheckoutDialogProps = {
  isOpen: boolean
  onClose: () => void
  productInfo: ProductInfo
  isCheckoutInput: boolean
  onPurchaseViaStripe: () => void
  onPurchaseViaPaid: () => void
  requesting: boolean
  message: string
  isOpenBanner: boolean
  hideBanner: () => void
  severity: BannerSeverity
  onNextCheckout: (data: FormData) => void
  needUserAdditionalInput: boolean
  isProcessing: boolean
  onMoveToPaidSetting: () => void
}

const BaseCheckoutDialog: React.VFC<BaseCheckoutDialogProps> = ({
  isOpen,
  onClose,
  productInfo,
  isCheckoutInput,
  onPurchaseViaStripe,
  onPurchaseViaPaid,
  requesting,
  message,
  isOpenBanner,
  hideBanner,
  severity,
  onNextCheckout,
  needUserAdditionalInput,
  isProcessing,
  onMoveToPaidSetting,
}) => {
  const { currentUser } = useCurrentUser()
  const paidMemberStatus = useRegisteredPaidMemberStatus()

  return (
    <>
      <BaseDialog
        open={isOpen}
        onClose={onClose}
        title={needUserAdditionalInput ? '初回登録' : productInfo.title}
        breakPoints="md"
        disabledBackdrop
      >
        <Box sx={{ px: 4, py: 2 }}>
          {isCheckoutInput ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                }}
              >
                {productInfo.isCampaign ? (
                  <>
                    <PriceDetails price={productInfo.price} />
                    <CampaignDetails campaign={productInfo.campaign} />
                  </>
                ) : (
                  <>
                    <ServiceOverview descriptions={productInfo.descriptions} />
                    <Precautions precautions={productInfo.precautions} />
                    <PriceDetails price={productInfo.price} />
                  </>
                )}
              </Box>
              <PaymentButtons
                onPurchaseViaStripe={onPurchaseViaStripe}
                onPurchaseViaPaid={onPurchaseViaPaid}
                requesting={requesting}
                isProcessing={isProcessing}
                paidMemberStatus={paidMemberStatus}
                onMoveToPaidSetting={onMoveToPaidSetting}
                product={productInfo}
              />
            </>
          ) : (
            <>
              <Typography
                variant="h6"
                sx={{ fontSize: '18px', fontWeight: 'bold' }}
              >
                ご利用にあたって情報の登録が必要です
              </Typography>
              {currentUser && (
                <AdditionalUserInputForm
                  user={{
                    id: currentUser.id,
                    name: currentUser.name,
                    companyName: currentUser.company_name,
                    phoneNumber: currentUser.phone_number,
                    isDisplayV2Sample: currentUser.is_display_v2_sample,
                  }}
                  onSubmit={onNextCheckout}
                  label="決済ページへ"
                />
              )}
            </>
          )}
        </Box>
      </BaseDialog>
      <BaseSnackbar
        message={message}
        open={isOpenBanner}
        handleClose={hideBanner}
        severity={severity}
        autoHideDuration={100000}
      />
    </>
  )
}

export default BaseCheckoutDialog
