import { CreateAnalysisThemeInput } from 'api/createAnalysisTheme'
import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

export type PrefectureOptions =
  | 'all'
  | 'hokkaido'
  | 'aomori'
  | 'iwate'
  | 'miyagi'
  | 'akita'
  | 'yamagata'
  | 'fukushima'
  | 'ibaraki'
  | 'tochigi'
  | 'gunma'
  | 'saitama'
  | 'chiba'
  | 'tokyo'
  | 'kanagawa'
  | 'niigata'
  | 'toyama'
  | 'ishikawa'
  | 'fukui'
  | 'yamanashi'
  | 'nagano'
  | 'gifu'
  | 'shizuoka'
  | 'aichi'
  | 'mie'
  | 'shiga'
  | 'kyoto'
  | 'osaka'
  | 'hyogo'
  | 'nara'
  | 'wakayama'
  | 'tottori'
  | 'shimane'
  | 'okayama'
  | 'hiroshima'
  | 'yamaguchi'
  | 'tokushima'
  | 'kagawa'
  | 'ehime'
  | 'kochi'
  | 'fukuoka'
  | 'saga'
  | 'nagasaki'
  | 'kumamoto'
  | 'oita'
  | 'miyazaki'
  | 'kagoshima'
  | 'okinawa'

export type CreateKeywordInput = {
  analysisThemeId: number | undefined
  name: string
  excludedWords: string[]
  tags: number[]
  prefectures: PrefectureOptions[]
  speciality_panel: boolean
  note?: string | undefined
}

export type CreateKeywordWithAnalysisThemeInput = {
  name: string
  excludedWords: string[]
  tags: number[]
  prefectures: PrefectureOptions[]
  speciality_panel: boolean
  note?: string | undefined
  theme: CreateAnalysisThemeInput
}

type createKeywordPayload = {
  id: number
  analysis_theme_id: number
}

export const createKeyword = async (input: CreateKeywordInput) => {
  const { analysisThemeId, excludedWords, ...rest } = input
  const response = await axios.post<createKeywordPayload>(
    `/api/analysis_themes/${analysisThemeId}/keywords`,
    {
      excluded_words: excludedWords,
      ...rest,
    },
  )
  return response.data
}

type createKeywordWithAnalysisThemePayload = {
  id: number
  analysis_theme_id: number
}

export const createKeywordWithAnalysisTheme = async (
  input: CreateKeywordWithAnalysisThemeInput,
) => {
  const { excludedWords, theme, ...rest } = input
  const response = await axios.post<createKeywordWithAnalysisThemePayload>(
    `/api/keywords`,
    {
      excluded_words: excludedWords,
      theme: {
        name: theme.name,
        kind: theme.kind,
        user_voice_type: theme.userVoiceType,
        other_memo: theme.otherMemo,
        tags: theme.tags,
        prefectures: theme.prefectures,
        speciality_panel: theme.speciality_panel,
        note: theme.note,
      },
      ...rest,
    },
  )
  return response.data
}

export const useCreateKeyword = (
  option?: UseMutationOption<createKeywordPayload>,
) => useMutation(createKeyword, option)

export const useCreateKeywordWithAnalysisTheme = (
  option?: UseMutationOption<createKeywordWithAnalysisThemePayload>,
) => useMutation(createKeywordWithAnalysisTheme, option)
