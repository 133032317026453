import phoneRegex from 'constants/regex/phoneRegex'
import { z } from 'zod'

// ハイフンを含む電話番号の正規表現
export const additionalUserInputSchema = z.object({
  name: z.preprocess(
    (val) => (val === null ? '' : val),
    z.string().nonempty('お名前を入力してください'),
  ),
  companyName: z.preprocess(
    (val) => (val === null ? '' : val),
    z.string().nonempty('御社名を入力してください'),
  ),
  phoneNumber: z.preprocess((val: any) => {
    if (typeof val !== 'string') {
      return ''
    }
    return val
  }, z.string().regex(phoneRegex, '電話番号はハイフンを含む形式で、10桁または11桁の数字で入力してください').nonempty('電話番号を入力してください')),
  isDisplayV2Sample: z.number(),
})
