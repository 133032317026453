import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  Skeleton,
  Typography,
} from '@mui/material'
import { useGetAnalysisThemes } from 'api/getAnalysisThemes'
import useSelectedKeyword from 'hooks/useSelectedKeyword'
import useSelectedSummary from 'hooks/useSelectedSummary'
import useSelectedTheme from 'hooks/useSelectedTheme'
import { Suspense, useMemo } from 'react'
import { Link, generatePath } from 'react-router-dom'
import { AnalysisResult } from 'types/analysisResult'
import { AnalysisSummary } from 'types/analysisSummary'
import { AnalysisTheme } from 'types/analysisTheme'
import { V2Paths } from '../paths'
import { useRecordLog } from 'api/recordLog'
import { useCurrentUser } from 'api/getCurrentUser'

export const AnalysisThemeSideList = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'start',
    }}
  >
    <Typography
      sx={{
        color: '#9B9B9B',
        fontSize: '14px',
        fontWeight: 'bold',
        px: '24px',
      }}
    >
      テーマ一覧
    </Typography>
    <Suspense
      fallback={<Skeleton variant="rectangular" width={240} height={160} />}
    >
      <ThemeList />
    </Suspense>
  </Box>
)

const ThemeList = () => {
  const { data: analysisThemes } = useGetAnalysisThemes({})

  if (analysisThemes?.length === 0) {
    return <Typography sx={{ px: 2 }}>テーマが作成されていません。</Typography>
  }

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxHeight: 'calc(100vh - 400px)',
        overflowY: 'scroll',
      }}
    >
      {analysisThemes?.map((analysisTheme) => (
        <AnalysisThemeSideItem
          key={`side-analysisTheme-${analysisTheme.id}`}
          analysisTheme={analysisTheme}
        />
      ))}
    </List>
  )
}

const AnalysisThemeSideItem = ({
  analysisTheme,
}: {
  analysisTheme: AnalysisTheme
}) => {
  const { selectedThemeId } = useSelectedTheme()
  const { currentUser } = useCurrentUser()

  const isSelected = analysisTheme.id === selectedThemeId

  const { request } = useRecordLog()

  const linkUrl = useMemo(() => {
    if (isSelected) {
      return generatePath(V2Paths.NewKeyword, { themeId: analysisTheme.id })
    }

    const analysisResult =
      analysisTheme.analysisResults.length !== 0
        ? analysisTheme.analysisResults[0]
        : undefined
    if (analysisResult === undefined) {
      return generatePath(V2Paths.NewKeyword, { themeId: analysisTheme.id })
    }
    if (analysisResult.summaries.length === 0) {
      return generatePath(V2Paths.Keyword, {
        themeId: analysisTheme.id,
        keywordId: analysisResult.id,
      })
    }
    return generatePath(V2Paths.Summary, {
      themeId: analysisTheme.id,
      keywordId: analysisResult.id,
      summaryId: analysisResult.summaries[0].id,
    })
  }, [analysisTheme, isSelected])

  return (
    // TODO: linkの二重wrapを避けたい。
    <Link
      style={{
        textDecoration: 'none',
      }}
      to={linkUrl}
    >
      <ListItemButton
        sx={{
          py: '8px',
          px: '24px',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          color: isSelected ? '#FF6600' : '#2E2E2E',
        }}
        onClick={() => {
          if (isSelected || analysisTheme.id > 0 || currentUser === undefined)
            return
          request({
            event: 'sample_open',
            userId: currentUser.id,
            parameters: {
              sample_open_at: new Date().toISOString(),
              theme_name: analysisTheme.name,
            },
          })
        }}
      >
        <Box
          sx={{
            ':hover': {
              textDecoration: isSelected ? 'underline' : 'none',
            },
            display: 'flex',
            alignItems: 'center',
            textDecoration: isSelected ? 'underline' : 'none',
          }}
        >
          {analysisTheme.name}
        </Box>
        {isSelected && (
          <AddCircleOutlineIcon sx={{ pb: '2px', color: '#FF6600' }} />
        )}
      </ListItemButton>
      <Collapse
        in={isSelected}
        timeout="auto"
        unmountOnExit
        sx={{ p: 0, m: 0 }}
      >
        <List disablePadding>
          {analysisTheme.analysisResults.length === 0 ? (
            <Typography sx={{ pl: '36px' }} fontSize={14}>
              キーワードが存在しません。
            </Typography>
          ) : (
            analysisTheme.analysisResults.map((keyword) => (
              <KeywordSideItem
                key={`keyword-${keyword.id}`}
                themeId={analysisTheme.id}
                keyword={keyword}
              />
            ))
          )}
        </List>
      </Collapse>
    </Link>
  )
}

const KeywordSideItem = ({
  themeId,
  keyword,
}: {
  themeId: number
  keyword: AnalysisResult
}) => {
  const { selectedKeywordId } = useSelectedKeyword()
  const isSelected = keyword.id === selectedKeywordId

  const linkUrl = useMemo(() => {
    if (keyword.summaries.length === 0) {
      return generatePath(V2Paths.Keyword, {
        themeId: themeId,
        keywordId: keyword.id,
      })
    }
    return generatePath(V2Paths.Summary, {
      themeId: themeId,
      keywordId: keyword.id,
      summaryId: keyword.summaries[0].id,
    })
  }, [keyword, themeId, isSelected])

  return (
    <Link
      to={linkUrl}
      style={{
        textDecoration: 'none',
      }}
    >
      <ListItemButton
        sx={{
          py: '8px',
          pr: '24px',
          pl: '32px',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          color: isSelected ? '#FF8800' : '#2E2E2E',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              marginBottom: '8px',
              textDecoration: 'none',
              paddingRight: '4px',
            }}
          >
            L
          </span>
          <Typography
            sx={{ textDecoration: isSelected ? 'underline' : 'none' }}
            fontSize={14}
          >
            {keyword.targetWords}
          </Typography>
        </Box>
      </ListItemButton>
      <Collapse in={isSelected} timeout="auto" unmountOnExit>
        <List disablePadding>
          {keyword.summaries.map((summary) => (
            <SummarySideItem
              key={`summary-${summary.id}`}
              themeId={themeId}
              keywordId={keyword.id}
              summary={summary}
            />
          ))}
        </List>
      </Collapse>
    </Link>
  )
}

const SummarySideItem = ({
  themeId,
  keywordId,
  summary,
}: {
  themeId: number
  keywordId: number
  summary: AnalysisSummary
}) => {
  const { selectedSummaryId } = useSelectedSummary()
  const isSelected = selectedSummaryId === summary.id

  return (
    <Link
      to={generatePath(V2Paths.Summary, {
        themeId,
        keywordId,
        summaryId: summary.id,
      })}
      style={{
        textDecoration: 'none',
      }}
    >
      <ListItemButton
        sx={{
          py: '8px',
          px: '24px',
          pl: '48px',
          width: '100%',
          color: isSelected ? '#FF8800' : '#2E2E2E',
        }}
      >
        <span
          style={{
            textDecoration: 'none',
            marginBottom: '8px',
            paddingRight: '4px',
          }}
        >
          L
        </span>
        <Typography fontSize={14}>{summary.title}</Typography>
      </ListItemButton>
    </Link>
  )
}
