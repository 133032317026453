import { CreateKeywordInput } from 'api/createKeyword'
import useKeywordWithThemeCheckoutDialog from 'pages/v2/checkout/components/useKeywordWithThemeCheckoutDialog'
import BaseCheckoutDialog from 'pages/v2/sp/checkout/components/BaseCheckoutDialog'
import { FormData as SpKeywordFormData } from 'pages/v2/sp/NewKeyword/components/NewKeywordForm'
import React from 'react'

type KeywordCheckoutDialogProps = {
  onClose: () => void
  isOpen: boolean
  createKeywordParams: CreateKeywordInput
  keywordFormData: SpKeywordFormData
}

const KeywordCheckoutDialog: React.VFC<KeywordCheckoutDialogProps> = ({
  onClose,
  isOpen,
  createKeywordParams,
  keywordFormData,
}) => {
  const {
    isCheckoutInput,
    onNextCheckout,
    onPurchaseViaStripe,
    onPurchaseViaPaid,
    message,
    severity,
    isOpenBanner,
    hideBanner,
    productInfo,
    requesting,
    needUserAdditionalInput,
    isProcessing,
    onMoveToPaidSetting,
  } = useKeywordWithThemeCheckoutDialog({
    createKeywordParams,
    keywordFormData,
  })

  if (productInfo == null) {
    return null
  }

  return (
    <BaseCheckoutDialog
      isProcessing={isProcessing}
      isOpen={isOpen}
      onClose={onClose}
      productInfo={productInfo}
      isCheckoutInput={isCheckoutInput}
      onPurchaseViaStripe={onPurchaseViaStripe}
      onPurchaseViaPaid={onPurchaseViaPaid}
      requesting={requesting}
      message={message}
      isOpenBanner={isOpenBanner}
      hideBanner={hideBanner}
      severity={severity}
      onNextCheckout={onNextCheckout}
      needUserAdditionalInput={needUserAdditionalInput}
      onMoveToPaidSetting={onMoveToPaidSetting}
    />
  )
}

export default KeywordCheckoutDialog
