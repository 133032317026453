import { Box, Card, Typography } from '@mui/material'
import { VFC } from 'react'

type SummaryIdeaDetailProps = {
  detail: string
  isPublic: boolean
}

const SummaryIdeaDetail: VFC<SummaryIdeaDetailProps> = ({
  detail,
  isPublic,
}) => {
  return (
    <Box>
      <Typography
        variant="h6"
        sx={{
          mb: 1,
          color: '#F39422',
          fontSize: '14px',
        }}
      >
        どんな話題
      </Typography>
      <Card
        sx={{
          backgroundColor: '#f5f5f5',
          p: 2,
          height: '100%',
        }}
      >
        {isPublic
          ? detail
          : '解析中です、しばらくお待ち下さい。約1〜2営業日で表示されます。'}
      </Card>
    </Box>
  )
}

export default SummaryIdeaDetail
