import { Grid, Typography } from '@mui/material'
import { ProductInfo } from 'hooks/useProductInfo'
import React from 'react'

type PriceDetailsProps = {
  campaign: ProductInfo['campaign']
}

const CampaignDetails: React.VFC<PriceDetailsProps> = ({ campaign }) => {
  if (!campaign) {
    return null
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ color: '#000' }}>
          キャンペーンについて
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          sx={{
            mb: 2,
            whiteSpace: 'pre-wrap',
            lineHeight: '1.8',
            color: '#f00',
            fontWeight: 'bold',
            fontSize: '15px',
          }}
        >
          {campaign}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default CampaignDetails
