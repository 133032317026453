import { Grid, Link, Typography } from '@mui/material'
import { ProductInfo } from 'hooks/useProductInfo'
import formatYen, { withTax } from 'libs/formatYen'
import React from 'react'

type PriceDetailsProps = {
  price: ProductInfo['price']
}

const PriceDetails: React.VFC<PriceDetailsProps> = ({ price }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ color: '#000' }}>
          料金
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" sx={{ color: '#000', mb: 2 }}>
          {formatYen(withTax(Number(price)))}（税込）
        </Typography>
        <Typography variant="body1" sx={{ color: '#000' }}>
          料金についての詳細は
          <Link
            href="https://viewpers.com/plan/"
            target="_blank"
            rel="noreferrer noreferrer"
            sx={{
              display: 'inline',
              fontWeight: 'bolder',
              cursor: 'pointer',
            }}
          >
            こちら
          </Link>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default PriceDetails
