import { Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import { ProductInfo } from 'hooks/useProductInfo'
import React from 'react'

type ServiceOverviewProps = {
  descriptions: ProductInfo['descriptions']
}

const ServiceOverview: React.VFC<ServiceOverviewProps> = ({ descriptions }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ color: '#000' }}>
          サービス概要
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <List sx={{ listStyleType: 'disc', pt: 0 }}>
          {descriptions.map((description, index) => (
            <ListItem
              key={`list-item-${index}`}
              sx={{ display: 'list-item', pl: '2px', py: 0 }}
            >
              <ListItemText primary={description.text} sx={{ color: '#000' }} />
              {description.sub && description.sub.length > 0 && (
                <List sx={{ listStyleType: 'circle', pl: '40px', pt: 0 }}>
                  {description.sub.map((sub, subIndex) => (
                    <ListItem
                      key={`child-list-item-${subIndex}`}
                      sx={{ display: 'list-item', paddingLeft: '2px' }}
                    >
                      <ListItemText primary={sub} sx={{ color: '#000' }} />
                    </ListItem>
                  ))}
                </List>
              )}
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  )
}

export default ServiceOverview
