import { useCurrentUser } from 'api/getCurrentUser'
import PasswordChangeForm from 'pages/v2/sp/User/PasswordSetting/components/PasswordChangeForm'

const PasswordSetting = () => {
  const { currentUser } = useCurrentUser()

  if (!currentUser) {
    return null
  }

  return <PasswordChangeForm />
}

export default PasswordSetting
