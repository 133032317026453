import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { Box } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'

type BackButtonProps = {
  children: React.ReactNode
  path?: string
}
const BackButton: React.VFC<BackButtonProps> = ({ children, path }) => {
  const history = useHistory()

  const handleBackClick = () => {
    if (path) {
      history.push(path)
    } else {
      history.goBack()
    }
  }

  return (
    <Box
      onClick={handleBackClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: '4px',
      }}
    >
      <ArrowBackIosNewIcon sx={{ color: '#F39422', marginRight: '8px' }} />
      <Box
        sx={{
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default BackButton
