import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

export type SignupInput = {
  email: string
  password: string
  passwordConfirmation: string
}

type SignupPayload = {
  status: number
}

export const signup = async (input: SignupInput) => {
  const { email, password, passwordConfirmation } = input
  const response = await axios.post<SignupPayload>(`/api/auth/user/register`, {
    email,
    password,
    password_confirmation: passwordConfirmation,
  })

  console.error('response', response)

  return response
}

export const useSignup = (option?: UseMutationOption<SignupPayload>) =>
  useMutation(signup, option)
