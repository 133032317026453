import { useCurrentUser } from 'api/getCurrentUser'
import routes from 'config/routes'
import Layout from 'includes/Layout'
import Loading from 'includes/Loading'
import { useMemo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { V2Routes } from './v2/router'

type Props = {
  isLoading: boolean
}
export const AuthenticatedRouter = ({ isLoading }: Props) => {
  const { currentUser } = useCurrentUser()

  const isV2Enabled = useMemo(
    () => currentUser?.v2_enabled,
    [currentUser?.v2_enabled],
  )

  if (!currentUser) return <></>

  if (isV2Enabled) {
    return (
      <Switch>
        <Route path="/v2" component={V2Routes} />
        <Redirect from="" to="/v2/themes" />
      </Switch>
    )
  }

  return (
    <Route path="/">
      <Layout>
        <Switch>
          {routes.map((route: any, index: any) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              // name={route.name}
              render={(props: any) => <route.component {...props} />}
            />
          ))}
          <Redirect from="" to="/" />
        </Switch>
        {isLoading && <Loading />}
      </Layout>
    </Route>
  )
}
