import { V2TourStep } from 'types/v2TourHistory'

export type V2TourStepKey = V2TourStep

export const V2TourSteps: Record<
  V2TourStepKey,
  {
    next: V2TourStepKey | null
    prev: V2TourStepKey | null
  }
> = {
  START: {
    next: 'INTRO',
    prev: null,
  },
  INTRO: {
    next: 'COLLECTING_WORDS',
    prev: 'START',
  },
  COLLECTING_WORDS: {
    prev: 'INTRO',
    next: 'REPORT',
  },
  REPORT: {
    prev: 'COLLECTING_WORDS',
    next: 'TOPIC_ANALYSIS',
  },
  TOPIC_ANALYSIS: {
    prev: 'REPORT',
    next: 'POST_ANALYSIS',
  },
  POST_ANALYSIS: {
    prev: 'TOPIC_ANALYSIS',
    next: 'COMPLETE',
  },
  COMPLETE: {
    prev: 'POST_ANALYSIS',
    next: null,
  },
}
