import { AgeType } from 'types/age'
import { GenderType } from 'types/gender'
import { Prefecture } from 'types/prefecture'

export type QuestionnaireResponse = {
  id: number
  title: string
  status: {
    id: (typeof QUESTIONNAIRE_STATUS)[keyof typeof QUESTIONNAIRE_STATUS]
    name: string
  } | null
  request_content: {
    target: {
      gender: GenderType[]
      age: AgeType[]
      prefecture: Prefecture[] | null
    }
    speciality_pannel: {
      use: boolean
      memo: string
    }
    questions: {
      answer_type: 1 | 2
      question: string
      selections: string[]
    }[]
  }
  result_content: {
    target: {
      total_count: number
    }[]
    answers: {
      question: string
      answer_type: 1 | 2
      answers: {
        count: number
        rate: number
        selection: string
      }[]
    }[]
  } | null
  complete_at: string | null
  created_at: string
  file_url: string | null
  topic_id: number | null
  user: {
    id: number
    name: string
    family_name: string
    given_name: string
    company_name: string
    phone_number: string
    email: string
  }
  target_words: string | null
}

export type QuestionnairesResponse = {
  data: QuestionnairesItem[]
  links: {
    first: string
    last: string
    prev: string | null
    next: string | null
  }
  meta: {
    current_page: number
    from: number
    last_page: number
    per_page: number
    total: number
  }
}

export type QuestionnairesItem = {
  id: number
  title: string
  status: {
    id: (typeof QUESTIONNAIRE_STATUS)[keyof typeof QUESTIONNAIRE_STATUS]
    name: string
  } | null
  topicId: number | null
  completeAt: string | null
  createdAt: string
  analysisResultId: number | null
}

export type Questionnaire = {
  id: number
  title: string
  status: {
    id: (typeof QUESTIONNAIRE_STATUS)[keyof typeof QUESTIONNAIRE_STATUS]
    name: string
  } | null
  requestContent: {
    target: {
      gender: GenderType[]
      age: AgeType[]
      prefecture: Prefecture[]
    }
    specialityPannel: {
      use: boolean
      memo: string
    }
    questions: {
      answerType: 1 | 2
      question: string
      selections: string[]
    }[]
  }
  resultContent: {
    target: {
      totalCount: number
    }[]
    answers: {
      question: string
      answerType: 1 | 2
      answers: {
        count: number
        rate: number
        selection: string
      }[]
    }[]
  } | null
  topicId: number | null
  completeAt: string | null
  createdAt: string
  fileUrl: string | null
  targetWords: string | null
}

export type QuestionnaireResponseForTopic = {
  id: number
  title: string
  status_id:
    | (typeof QUESTIONNAIRE_STATUS)[keyof typeof QUESTIONNAIRE_STATUS]
    | null
  complete_at: string | null
  topic_id: number | null
  created_at: string
}

export type QuestionnaireForTopic = {
  id: number
  title: string
  statusId:
    | (typeof QUESTIONNAIRE_STATUS)[keyof typeof QUESTIONNAIRE_STATUS]
    | null
  completeAt: string | null
  topicId: number | null
  createdAt: string
}

export const parseQuestionnaire = (
  input: QuestionnaireResponse,
): Questionnaire => {
  return {
    id: input.id,
    title: input.title,
    status: input.status,
    requestContent: {
      target: {
        ...input.request_content.target,
        prefecture: input.request_content.target.prefecture || [],
      },
      specialityPannel: input.request_content.speciality_pannel,
      questions: input.request_content.questions.map((question) => ({
        answerType: question.answer_type,
        question: question.question,
        selections: question.selections,
      })),
    },
    resultContent: input.result_content
      ? {
          target: input.result_content.target.map((target) => ({
            totalCount: target.total_count,
          })),
          answers: input.result_content.answers.map((answer) => ({
            question: answer.question,
            answerType: answer.answer_type,
            answers: answer.answers,
          })),
        }
      : null,
    topicId: input.topic_id,
    completeAt: input.complete_at,
    createdAt: input.created_at,
    fileUrl: input.file_url,
    targetWords: input.target_words,
  }
}

export const parseQuestionnaireForTopic = (
  input: QuestionnaireResponseForTopic,
): QuestionnaireForTopic => {
  return {
    id: input.id,
    title: input.title,
    statusId: input.status_id,
    completeAt: input.complete_at,
    topicId: input.topic_id,
    createdAt: input.created_at,
  }
}

export const QUESTIONNAIRE_STATUS = {
  requested: 1,
  researching: 2,
  completed: 10,
  passback: 20, // 差し戻し
} as const
