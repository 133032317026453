import useSWR, { SWRConfiguration } from 'swr'
import { OrderResponseData, parseOrder } from 'types/order'

type OrderResponse = {
  data: OrderResponseData
}

export const useGetOrder = ({
  orderId,
  options,
}: {
  orderId: string | null
  options?: SWRConfiguration<OrderResponse, Error>
}) => {
  const { data, ...rest } = useSWR<OrderResponse>(
    orderId ? `/api/orders/${orderId}` : null,
    options,
  )
  const order = data?.data ? parseOrder(data.data) : undefined
  return { data: order, ...rest }
}
