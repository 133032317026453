import { Box, Typography } from '@mui/material'

export const RatioCircle = ({
  top,
  bottom,
}: {
  top?: number | undefined
  bottom?: number | undefined
}) => {
  return (
    <Box
      sx={{
        mt: 2,
        backgroundColor: '#c9edc0',
        borderRadius: '50%',
        width: {
          xs: 100,
          md: 140,
        },
        height: {
          xs: 100,
          md: 140,
        },
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          lineHeight: '1',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {top === undefined || bottom === undefined ? (
          <Typography sx={{ fontWeight: 'bold' }}>集計中</Typography>
        ) : (
          <>
            <Box
              sx={{
                fontSize: 45,
                color: '#37bd17',
                pb: 0.5,
                borderBottom: '3px solid #37bd17',
              }}
            >
              {top}
            </Box>
            <Box
              sx={{
                fontSize: 18,
                pt: 1,
              }}
            >
              {bottom}
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}
