const PAID_PENDING = 1
const PAID_AVAILABLE_TRADING = 2
const PAID_AVAILABLE_NOT_TRADING = 3
const PAID_NOT_AVAILABLE = 4

const PAID_MEMBER_STATUS = {
  [PAID_PENDING]: '審査中',
  [PAID_AVAILABLE_TRADING]: '利用可能-取引中',
  [PAID_AVAILABLE_NOT_TRADING]: '利用可能-取引不可',
  [PAID_NOT_AVAILABLE]: '利用不可',
} as const

export {
  PAID_AVAILABLE_NOT_TRADING,
  PAID_AVAILABLE_TRADING,
  PAID_NOT_AVAILABLE,
  PAID_PENDING,
}
export default PAID_MEMBER_STATUS
