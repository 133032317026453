import { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const keywordKey = 'keyword'
const hashtagsKey = 'hashtags[]'

export const useAnalysisResultSearchQuery = () => {
  const history = useHistory()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)

  const keyWord = useMemo(() => {
    const keywordString = queryParams.get(keywordKey)
    if (keywordString === null || keywordString.length === 0) return null
    return keywordString
  }, [queryParams])

  const hashTags = useMemo(() => {
    const hashTags = queryParams.getAll(hashtagsKey)
    if (hashTags === null || hashTags.length === 0) return []
    return hashTags
  }, [queryParams])

  const setSearchString = (val: string) => {
    const texts = val.trim().split(/\s+/)
    const words = texts.filter((x) => !/^#/.test(x))
    const hashTags = texts
      .filter((x) => /^#/.test(x))
      .map((x) => x.substring(1))

    if (words.length === 1 && words[0] === '') queryParams.delete(keywordKey)
    else queryParams.set(keywordKey, words.join(' '))

    // 設定済みのものをresetする。
    queryParams.delete(hashtagsKey)

    hashTags.forEach((tag) => {
      queryParams.append(hashtagsKey, tag)
    })
    history.push({
      pathname: '/analysis',
      search: queryParams.toString(),
    })
  }

  const formDefaultValue = useMemo(() => {
    const hashTagsString = hashTags.map((tag) => '#' + tag).join(' ') ?? ''
    if (keyWord === null) return hashTagsString
    if (hashTagsString === '') return keyWord
    return keyWord + ' ' + hashTagsString
  }, [keyWord, hashTags])

  return { keyWord, hashTags, setSearchString, formDefaultValue }
}
