import passwordRegex from 'constants/regex/passwordRegex'
import { z } from 'zod'

export const passwordChangeSchema = z
  .object({
    currentPassword: z
      .string()
      .min(8, '8文字以上で入力してください')
      .regex(passwordRegex, '半角英数字で入力してください'),
    newPassword: z
      .string()
      .min(8, '8文字以上で入力してください')
      .regex(passwordRegex, '半角英数字で入力してください'),
    newPasswordConfirmation: z
      .string()
      .min(8, '8文字以上で入力してください')
      .regex(passwordRegex, '半角英数字で入力してください'),
  })
  .refine((data) => data.newPassword === data.newPasswordConfirmation, {
    message: '新しいパスワードが一致しません',
    path: ['newPasswordConfirmation'],
  })
