import { Box } from '@mui/material'
import { useGetSummaryDetail } from 'api/getSummaryDetail'
import { useLatestTourStep } from 'api/getTourHistory'
import useSelectedSummary from 'hooks/useSelectedSummary'
import useSelectedTheme from 'hooks/useSelectedTheme'
import { Loading } from 'pages/v2/KeywordDetail/components/Loading'
import { AnalysisSurveyCard } from 'pages/v2/sp/KeywordDetail/components/AnalysisSurveyCard'
import { TipsForUseButton } from 'pages/v2/sp/KeywordDetail/components/Summary/Detail/Detail/TipsForUse/TipsForUseButton'
import ScrollTopButton from 'pages/v2/sp/KeywordDetail/components/Summary/Detail/ScrollTopButton'
import TableOfContents from 'pages/v2/sp/KeywordDetail/components/TableOfContents'
import React, { useMemo } from 'react'
import { Element } from 'react-scroll'

const ELEMENT_ID = 'summary-detail-top'

type AnalysisSummaryDetailProps = {
  showTableOfContents: boolean
}

const castDemoThemeId = (themeId: number | undefined) => {
  if (
    themeId === -1 ||
    themeId === -2 ||
    themeId === -3 ||
    themeId === -4 ||
    themeId === -5
  ) {
    return themeId
  }
}

export const AnalysisSummaryDetail: React.VFC<AnalysisSummaryDetailProps> = ({
  showTableOfContents,
}) => {
  const { selectedSummaryId, isDemo } = useSelectedSummary()
  const { selectedThemeId } = useSelectedTheme()
  const { data: summary } = useGetSummaryDetail({
    input: { summaryId: selectedSummaryId },
  })
  const {
    data: { v2TourStep },
  } = useLatestTourStep()

  const summaryDetailSections = useMemo(() => {
    return (summary?.surveys ?? [])
      .map((survey) => ({
        elementId: String(survey.id),
        title: survey.analysisSummaryIdea?.title,
      }))
      .filter((s) => s.title) as { elementId: string; title: string }[]
  }, [summary])

  const isPublic = summary?.isPublic ?? false

  const demoThemeId = useMemo(
    () => castDemoThemeId(selectedThemeId),
    [selectedThemeId],
  )

  if (!summary) {
    return <Loading type="summaryDoesNotExist" />
  }

  return (
    <Box
      id={ELEMENT_ID}
      sx={{
        mt: 4,
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      {isPublic && showTableOfContents && (
        <TableOfContents sections={summaryDetailSections} />
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {summary.surveys.map((survey, idx) => (
          <Element key={survey.id} name={String(survey.id)}>
            <AnalysisSurveyCard
              key={`summary-card-${survey.id}`}
              survey={survey}
              isPublic={isPublic}
              isFirstSurvey={idx === 0}
            />
          </Element>
        ))}
      </Box>

      <ScrollTopButton elementId={ELEMENT_ID} bottom={32} />

      {isDemo && demoThemeId !== undefined && v2TourStep === 'COMPLETE' && (
        <TipsForUseButton themeId={demoThemeId} />
      )}
    </Box>
  )
}
