import Bugsnag from '@bugsnag/js'
import useSWR, { SWRConfiguration } from 'swr'

type GetCurrentUserResponse = {
  data: {
    company_name: string
    company_name_kana: string
    contract_end_at: null
    contract_start_at: null
    created_at: string
    email: string
    family_name: string
    family_name_kana: string
    given_name: string
    given_name_kana: string
    zip_code: string
    prefecture: string
    address1: string
    address2: string
    address3: string
    paid_id: string
    id: number
    name: string
    phone_number: string
    plan: { id: number; name: string }
    remaining_analysis_count: number
    remaining_questionnaire_count: number
    remaining_weighted_count: number
    remaining_adjustment_request_count: number
    tour_index: number
    tour_step: number
    type: number
    use_vector_search: number
    is_display_v2_sample: 0 | 1
    stripe_customer_id: string | null
    v2_enabled: boolean
    representative: {
      id: number
      given_name: string
      family_name: string
      given_name_kana: string
      family_name_kana: string
    } | null
  }
}

export const useCurrentUser = (
  config?: SWRConfiguration<GetCurrentUserResponse>,
) => {
  const { data, error, isLoading, mutate } = useSWR<GetCurrentUserResponse>(
    '/api/me',
    {
      suspense: true,
      ...config,
    },
  )

  if (!data) {
    return { currentUser: undefined, mutate, isLoading }
  }

  if (error) {
    window.location.href = '/login'
  }

  Bugsnag.setUser(data.data.id.toString(), data.data.email, data.data.name)

  return {
    currentUser: data.data,
    mutate,
    isLoading,
  }
}
