export const withTax = (price: number): number => {
  const TAX_RATE = 0.1
  return Math.floor(price * (1 + TAX_RATE))
}

/**
 * 数値を日本円の形式にフォーマットします
 * @param amount - フォーマットする数値
 * @returns 日本円形式にフォーマットされた文字列
 */

const formatYen = (amount: number): string => {
  return new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY',
  }).format(amount)
}

export default formatYen
