import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, FormControl, TextField } from '@mui/material'
import { useChangePassword } from 'api/resetPassword'
import { useBanner } from 'hooks/useBanner'
import useQuery from 'hooks/useQuery'
import { passwordResetSchema } from 'pages/passwordReset/scheme'
import BaseSnackbar from 'pages/v2/components/BaseSnackbar'
import React, { useCallback } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { z } from 'zod'

type PasswordResetFormProps = {}

type FormData = z.infer<typeof passwordResetSchema>

const PasswordResetForm: React.VFC<PasswordResetFormProps> = () => {
  const history = useHistory()
  const query = useQuery()
  const { message, severity, isOpen, showBanner, hideBanner } = useBanner()
  const id = query.get('id')
  const expires = query.get('expires')
  const hash = query.get('hash')
  const signature = query.get('signature')

  const { request, requesting } = useChangePassword({
    onSuccess: () => {
      showBanner({
        message: 'パスワードの再設定が完了しました',
        severity: 'success',
      })

      setTimeout(() => {
        history.push('/login')
      }, 3000)
    },
    onError: (error) => {
      if (error.response) {
        showBanner({
          message:
            error.response.data?.errors || 'パスワードの再設定に失敗しました',
          severity: 'error',
        })
      } else {
        showBanner({
          message: 'パスワードの再設定に失敗しました',
          severity: 'error',
        })
      }
    },
  })

  const method = useForm({
    resolver: zodResolver(passwordResetSchema),
    defaultValues: {
      password: '',
    },
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = method

  const onSubmit = useCallback(
    (data: FormData) => {
      if (!id || !expires || !hash || !signature) {
        showBanner({
          message: 'URLが不正です',
          severity: 'error',
        })
        return
      }

      request({
        id: Number(id),
        expires: Number(expires),
        hash,
        signature,
        password: data.password,
      })
    },
    [request, id, expires, hash, signature],
  )

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            py: 4,
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              flexDirection: 'column',
            }}
          >
            <FormControl
              error={!!errors?.password}
              sx={{
                width: '100%',
              }}
            >
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    type="password"
                    label="パスワード"
                    placeholder="8文字以上の半角英数字"
                    variant="outlined"
                    error={!!errors?.password}
                    helperText={
                      errors?.password?.message ?? errors?.password?.message
                    }
                    sx={{
                      maxWidth: '360px',
                      '& .MuiInputBase-input': {
                        height: 'auto',
                        padding: '10px 14px',
                      },
                    }}
                    InputProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Button
            variant="contained"
            type="submit"
            sx={{ px: 7, fontSize: '16px', color: 'white' }}
            disabled={
              requesting ||
              !id ||
              !expires ||
              !hash ||
              !signature ||
              !!errors.password
            }
          >
            パスワード再設定
          </Button>
        </Box>
      </form>
      <BaseSnackbar
        message={message}
        open={isOpen}
        handleClose={hideBanner}
        severity={severity}
        autoHideDuration={60000}
      />
    </FormProvider>
  )
}

export default PasswordResetForm
