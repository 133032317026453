import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material'
import styled from 'styled-components'

const HtmlTooltip = styled(
  ({
    className,
    maxWidth = 220,
    ...props
  }: TooltipProps & { maxWidth?: number }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)(({ maxWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: maxWidth,
    fontSize: 12,
    border: '1px solid #dadde9',
  },
}))

export default HtmlTooltip
