import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { V2Paths } from 'pages/v2/paths'
import { VFC } from 'react'
import { Link } from 'react-router-dom'

type Props = { open: boolean; disabled: boolean; onNext: () => void }
export const CompleteTourGuideDialog: VFC<Props> = ({
  open,
  disabled,
  onNext,
}) => {
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      fullWidth
      maxWidth="lg"
      open={open}
    >
      <DialogTitle
        sx={{
          m: 0,
          py: 8,
          px: 2,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 4,
          maxWidth: '650px',
          width: '100%',
          margin: '0 auto',
        }}
      >
        <Box>
          <img
            src="/assets/images/vpfull-logo.png"
            alt="ViewPoint"
            width="200px"
          />
        </Box>
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 'bold',
          }}
        >
          さあ、使ってみましょう。
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          fontWeight: 'bold',
          maxWidth: '650px',
          width: '100%',
          margin: '0 auto',
        }}
      >
        ご案内は以上です。
        <br />
        活用例のサンプルをいくつか用意しております。
        <br />
        ご利用シーンに合わせてご参照ください。
        <br />
        <br />
        ViewPersでは価値のあるSNS分析を
        <Typography
          component="span"
          sx={{ color: '#FF8800', fontWeight: 'bold' }}
        >
          高速・低価格・少ない手間
        </Typography>
        で実施できる設計となっております。
        <br />
        <br />
        あなたのアイデアを形にできるお手伝いができれば幸いです。
        <br />
        ご利用にあたりサポートが必要でしたら、
        <Link
          to={V2Paths.Contact}
          style={{ color: '#FF8800' }}
          onClick={onNext}
        >
          こちら
        </Link>
        からお問い合わせください。
        <br />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', py: 8, justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ color: 'white' }}
          onClick={onNext}
          disabled={disabled}
        >
          利用を開始する
        </Button>
      </DialogActions>
    </Dialog>
  )
}
