import { Button } from '@mui/material'
import { useDisclosure } from 'hooks/useDisclosure'
import useSelectedSummary from 'hooks/useSelectedSummary'
import { VFC } from 'react'
import { WeightedFilterDialog } from './WeightedFilterDialog'

type Props = {
  title: string
  topicId: string
}
export const CreateWeightedFilterButton: VFC<Props> = ({ title, topicId }) => {
  const { onClose, isOpen, onOpen } = useDisclosure()
  const { isDemo } = useSelectedSummary()
  return (
    <>
      <Button variant="outlined" onClick={onOpen} disabled={isDemo}>
        投稿に共感度を付与する
      </Button>
      <WeightedFilterDialog
        open={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        title={title}
        topicId={topicId}
      />
    </>
  )
}
