import React from 'react'

import { Link } from 'react-router-dom'

const PopupTourProgress = ({
  openname,
  progress,
  modalCloseCallback,
  okCallback,
  skipCallback,
}) => {
  const toActiveCss = (index) => {
    if (progress === index) {
      return 'c-stepList__item--current'
    } else if (progress > index) {
      return 'c-stepList__item--end'
    } else {
      return ''
    }
  }

  return (
    <div
      className={openname === 'tourProgress' ? 'p-pop is-open' : 'p-pop'}
      data-name="pop6"
    >
      <div className="p-pop__overlay" onClick={() => modalCloseCallback()} />
      <div className="p-pop__inner">
        {progress < 3 && (
          <div
            className="p-pop__closeBtn"
            onClick={() => modalCloseCallback()}
          />
        )}
        <div className="p-pop__cont">
          <h2 className="c-popHeading">
            {progress === 0
              ? 'プロダクトツアーへようこそ！'
              : progress === 1
                ? '実行中の分析を確認'
                : progress === 2
                  ? '分析結果の確認'
                  : progress === 3
                    ? '完了'
                    : ''}
          </h2>

          <div className="c-vTable">
            <div className="c-vTable__headInfo">
              <div className="c-stepList">
                <ul className="c-stepList__list">
                  <li className={`c-stepList__item ${toActiveCss(0)}`}>
                    <div className="c-stepList__state">
                      <span>1</span>
                    </div>
                    <div className="c-stepList__text">キーワード分析を実行</div>
                  </li>
                  <li className={`c-stepList__item ${toActiveCss(1)}`}>
                    <div className="c-stepList__state">
                      <span>2</span>
                    </div>
                    <div className="c-stepList__text">実行中の分析を確認</div>
                  </li>
                  <li className={`c-stepList__item ${toActiveCss(2)}`}>
                    <div className="c-stepList__state">
                      <span>3</span>
                    </div>
                    <div className="c-stepList__text">分析結果の確認</div>
                  </li>
                  <li className={`c-stepList__item ${toActiveCss(3)}`}>
                    <div className="c-stepList__state">
                      <span>4</span>
                    </div>
                    <div className="c-stepList__text">完了</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="l-flex l-flex--noEven u-mt50">
              <div className="l-flex__item u-w100per u-tc">
                {progress === 0 ? (
                  <div
                    className="u-w100per"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <p>キーワード分析の実行方法についてサポートします</p>
                    <p className="u-mt40 u-fontB" style={{ fontSize: '2rem' }}>
                      キーワードを入力すると、独自開発の3つのフィルターで
                      <br />
                      膨大なソーシャルメディアのコメントを効率的・効果的に集めます。
                    </p>
                    <img
                      className="u-mt30 u-pb30 u-pl40 u-pr40 u-w80per"
                      src="/assets/images/tour/img04.png"
                      style={{ backgroundColor: 'rgb(251, 245, 238)' }}
                    />
                  </div>
                ) : progress === 1 ? (
                  '実行中の分析を確認する方法についてサポートします'
                ) : progress === 2 ? (
                  <div
                    className="u-w100per"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <p className="u-mt40 u-fontB" style={{ fontSize: '2rem' }}>
                      分析が完了しました！マーケティングのヒントにつながる情報である
                      <br />
                      市場の新たな「兆し（キザシ）」を発見していきましょう。
                    </p>
                    <img
                      className="u-mt30"
                      src="/assets/images/tour/img05.png"
                    />
                  </div>
                ) : progress === 3 ? (
                  'プロダクトツアーは以上になります'
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>

          <div className="u-mt50 c-btn u-tc">
            <Link
              to="#"
              className="c-btn__link c-btn__link--size-l"
              onClick={() => okCallback()}
              style={{ cursor: 'pointer' }}
            >
              {progress < 3 ? 'ツアーを開始する' : 'ツアーを終了する'}
            </Link>
          </div>

          {progress < 3 && (
            <div className="u-mt40">
              <Link
                to="#"
                onClick={() => skipCallback()}
                style={{ cursor: 'pointer' }}
              >
                スキップする
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PopupTourProgress
