import useSWR, { SWRConfiguration } from 'swr'
import {
  AnalysisSummaryDetailResponse,
  parseAnalysisSummaryDetail,
} from 'types/analysisSummary'

type GetSummaryDetailInput = {
  summaryId?: number
}
type GetSummaryDetailResponse = AnalysisSummaryDetailResponse

const getRequestUrl = (input: GetSummaryDetailInput) => {
  if (input.summaryId === undefined) {
    return null
  }

  if (input.summaryId < 0) {
    return `/assets/v2-example/summary/${input.summaryId}.json`
  }

  return `/api/analysis_summaries/${input.summaryId}`
}

export const useGetSummaryDetail = ({
  input,
  options,
}: {
  input: GetSummaryDetailInput
  options?: SWRConfiguration<GetSummaryDetailResponse, Error>
}) => {
  const isMock = (input.summaryId ?? 0) < 0
  const { data, ...rest } = useSWR<AnalysisSummaryDetailResponse>(
    getRequestUrl(input),
    isMock ? (url) => fetch(url).then((res) => res.json()) : null,
    {
      ...options,
      ...(isMock
        ? {
            revalidateIfStale: false,
            revalidateOnMount: false,
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
          }
        : {}),

      suspense: true,
    },
  )

  return {
    data: data !== undefined ? parseAnalysisSummaryDetail(data) : undefined,
    ...rest,
  }
}
