import { Box, Divider, Link, Typography } from '@mui/material'
import BaseDialog from 'includes/BaseDialog'
import React from 'react'

export type PaidPaymentConfirmationDialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  product: {
    title: string
    priceWithTax: number
  }
  disabled: boolean
}

const PaidPaymentConfirmationDialog: React.VFC<
  PaidPaymentConfirmationDialogProps
> = ({ open, onClose, onConfirm, product, disabled }) => {
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title="Paid（後払い）決済確認"
      breakPoints="md"
      disabledBackdrop
      actions={[
        {
          text: 'キャンセル',
          onClick: onClose,
          color: 'error',
          disabled,
        },
        {
          text: '決済する',
          onClick: onConfirm,
          color: 'primary',
          disabled,
        },
      ]}
    >
      <Box sx={{ py: 2, px: 5 }}>
        <Typography variant="h6" gutterBottom>
          商品情報
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <Typography variant="body1" gutterBottom>
          <strong>商品名:</strong> {product.title}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>料金:</strong> {product.priceWithTax}円
        </Typography>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        <Typography variant="body2" gutterBottom>
          注文内容をご確認の上、決済ボタンを押してください。
        </Typography>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        <Typography variant="body2" gutterBottom>
          請求先情報の変更や確認については、以下のリンクからアカウントを登録し、Paidの管理画面にアクセスしてください：
        </Typography>
        <Link
          href="https://paid.jp/s/reissue/info"
          target="_blank"
          rel="noopener"
        >
          https://paid.jp/s/reissue/info
        </Link>
      </Box>
    </BaseDialog>
  )
}

export default PaidPaymentConfirmationDialog
