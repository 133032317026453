import { Box, Button, Link as MLink } from '@mui/material'
import useSelectedKeyword from 'hooks/useSelectedKeyword'
import useSelectedSummary from 'hooks/useSelectedSummary'
import useSelectedTheme from 'hooks/useSelectedTheme'
import { downloadDemoTweetCsv } from 'pages/v2/KeywordDetail/components/downloadDemoTweetCsv'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { CreateSummaryButton } from './Summary'
import { DisplayAllTweetsButton } from './TweetDialog'

type AnalysisSummaryDetailActionButtonsProps = {
  fileUrl: string | undefined
}

export const AnalysisSummaryDetailActionButtons: FC<
  AnalysisSummaryDetailActionButtonsProps
> = (props) => {
  const { fileUrl } = props
  const { selectedThemeId } = useSelectedTheme()
  const { selectedKeywordId } = useSelectedKeyword()
  const { isDemo } = useSelectedSummary()

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      {selectedThemeId && (
        <Button
          variant="contained"
          sx={{ px: 2, fontSize: '14px', color: 'white' }}
          component={Link}
          disabled={isDemo}
          to={`/v2/themes/${selectedThemeId}/keywords/new`}
        >
          他のキーワードで分析する
        </Button>
      )}
      <CreateSummaryButton />
      <DisplayAllTweetsButton />
      <Button
        variant="outlined"
        component={MLink}
        href={isDemo ? undefined : fileUrl}
        onClick={
          isDemo
            ? () => downloadDemoTweetCsv({ keywordId: selectedKeywordId })
            : undefined
        }
        download="Result.xlsx"
        sx={{
          px: 2,
          fontSize: '14px',
        }}
        data-tour="analysis-result-csv-download"
      >
        投稿結果のダウンロード
      </Button>
    </Box>
  )
}
