import LaunchIcon from '@mui/icons-material/Launch'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import {
  Box,
  Button,
  CssBaseline,
  Drawer,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material'
import useIsSpScreen from 'hooks/useIsSpScreen'
import AdditionalUserInputDialog from 'pages/v2/checkout/components/additionalUserInput/AdditionalUserInputDialog'
import AdditionalUserInputSPDialog from 'pages/v2/sp/checkout/components/additionalUserInput/AdditionalUserInputDialog'
import {
  MouseEvent,
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Link, generatePath } from 'react-router-dom'
import { AnalysisThemeSideList } from './components'
import { AccountInformationSection } from './components/AccountInformationSection'
import { PCRecommendDialog } from './components/PcRecommendDialog'
import { V2Paths } from './paths'

const DRAWER_WIDTH = '240px'

type Props = {
  children: ReactNode
}

export const Layout = ({ children }: Props) => {
  const isSpScreen = useIsSpScreen()

  useEffect(() => {
    document.body.style.minWidth = '100vw'
    document.documentElement.style.fontSize = '100%'
    return () => {
      document.body.style.minWidth = '1340px'
      document.documentElement.style.fontSize = '62.5%'
    }
  }, [])

  return (
    <>
      {isSpScreen ? (
        <SpLayout>{children}</SpLayout>
      ) : (
        <PCLayout>{children}</PCLayout>
      )}
    </>
  )
}

const PCLayout = ({ children }: Props) => {
  const { palette } = useTheme()

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        sx={{
          width: DRAWER_WIDTH,
          height: '100%',
          '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH,
            height: '100vh',
            justifyContent: 'space-between',
          },
          display: 'flex',
        }}
        anchor="left"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '36px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Link
            to="/v2/themes"
            style={{
              width: '100%',
            }}
          >
            <Box
              sx={{
                p: '24px',
                color: 'white',
                borderLeft: `6px solid ${palette.primary.light}`,
              }}
            >
              <img
                src="/assets/images/vpfull-logo.png"
                alt="ViewPers"
                style={{ width: '120px', height: 'auto' }}
              />
            </Box>
          </Link>
          <Link to={generatePath(V2Paths.NewTheme)}>
            <Button
              variant="outlined"
              sx={{ borderRadius: '18px', elevation: 3 }}
              endIcon={<LaunchIcon />}
            >
              <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                新規テーマ作成
              </Typography>
            </Button>
          </Link>
          <AnalysisThemeSideList />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            pt: '24px',
          }}
        >
          <Suspense
            fallback={
              <Skeleton variant="rectangular" width={240} height={50} />
            }
          >
            <AccountInformationSection />
          </Suspense>
          <Box
            sx={{
              px: '18px',
              pb: '18px',
              color: 'white',
            }}
          >
            <img
              src="/assets/images/logo_discover-cloud.svg"
              alt="Discover cloud"
              style={{ width: '90px', height: 'auto' }}
            />
          </Box>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          minHeight: '100vh',
          width: `calc(100vw - ${DRAWER_WIDTH})`,
          overflow: 'auto',
          backgroundColor: '#f5f5f5',
        }}
      >
        {children}
      </Box>

      <PCRecommendDialog />
      <AdditionalUserInputDialog />
    </Box>
  )
}

const HEADER_HEIGHT = 65
const SpLayout = ({ children }: Props) => {
  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: '#f8ecdc',
        minHeight: '100vh',
      }}
    >
      <CssBaseline />
      <SPHeader />
      <AdditionalUserInputSPDialog />
      <Box
        sx={{
          pt: `${HEADER_HEIGHT + 16}px`,
          px: 2,
          pb: 2,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

const SPHeader = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl],
  )

  const onClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const handleLogout = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault()
      if (localStorage.getItem('jfc')) {
        localStorage.clear()
      }
      window.location.href = '/users/auth/logout'
    },
    [localStorage],
  )

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 2,
        borderBottom: '1px solid #e0e0e0',
        backgroundColor: 'white',
        position: 'fixed',
        width: '100%',
        zIndex: 999,
        height: `${HEADER_HEIGHT}px`,
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Link to="/v2/themes">
        <img
          src="/assets/images/vpfull-logo.png"
          alt="ViewPers"
          style={{ width: '120px', height: 'auto' }}
        />
      </Link>
      <Box
        onClick={handleClick}
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: '8px',
          cursor: 'pointer',
        }}
      >
        <img
          src="/assets/images/userIcon.svg"
          alt="ViewPers"
          style={{ width: '30px', height: '30px' }}
        />
      </Box>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem sx={{ color: 'red', px: 2, py: 1 }}>
          <Link
            to={generatePath(V2Paths.UserSetting)}
            style={{
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ManageAccountsIcon />
            <ListItemText
              primary="設定"
              sx={{ pl: 0.5, px: 1 }}
              primaryTypographyProps={{ variant: 'h6', fontSize: 14 }}
            />
          </Link>
        </MenuItem>
        <MenuItem sx={{ color: 'red', px: 2, py: 1 }} onClick={handleLogout}>
          ログアウトする
        </MenuItem>
      </Menu>
    </Box>
  )
}
