import { CreateSummaryInput } from 'api/createSummary'
import BaseCheckoutDialog from 'pages/v2/checkout/components/BaseCheckoutDialog'
import useSummaryCheckoutDialog from 'pages/v2/checkout/components/useSummaryCheckoutDialog'
import { FormData } from 'pages/v2/KeywordDetail/components/Summary/CreateSummary/CreateSummaryDialog'
import React from 'react'

type SummaryCheckoutDialogProps = {
  onClose: () => void
  isOpen: boolean
  createSummaryParams: CreateSummaryInput
  summaryFormData: FormData
}

const SummaryCheckoutDialog: React.VFC<SummaryCheckoutDialogProps> = ({
  onClose,
  isOpen,
  createSummaryParams,
  summaryFormData,
}) => {
  const {
    isCheckoutInput,
    onNextCheckout,
    onPurchaseViaStripe,
    onPurchaseViaPaid,
    message,
    severity,
    isOpenBanner,
    hideBanner,
    productInfo,
    requesting,
    needUserAdditionalInput,
    isProcessing,
    onMoveToPaidSetting,
  } = useSummaryCheckoutDialog({ createSummaryParams, summaryFormData })

  if (productInfo == null) {
    return null
  }

  return (
    <BaseCheckoutDialog
      isProcessing={isProcessing}
      isOpen={isOpen}
      onClose={onClose}
      productInfo={productInfo}
      isCheckoutInput={isCheckoutInput}
      onPurchaseViaStripe={onPurchaseViaStripe}
      onPurchaseViaPaid={onPurchaseViaPaid}
      requesting={requesting}
      message={message}
      isOpenBanner={isOpenBanner}
      hideBanner={hideBanner}
      severity={severity}
      onNextCheckout={onNextCheckout}
      needUserAdditionalInput={needUserAdditionalInput}
      onMoveToPaidSetting={onMoveToPaidSetting}
    />
  )
}

export default SummaryCheckoutDialog
