export const V2Paths = {
  Home: '/v2/themes',
  Theme: '/v2/themes/:themeId',
  NewTheme: '/v2/themes/new',
  Demo: '/v2/themes/demo',
  NewKeyword: '/v2/themes/:themeId/keywords/new',
  Keyword: '/v2/themes/:themeId/keywords/:keywordId',
  Summary: '/v2/themes/:themeId/keywords/:keywordId/summaries/:summaryId',
  QuestionnaireResult:
    '/v2/themes/:themeId/keywords/:keywordId/summaries/:summaryId/questionnaires/:topicId',
  Success: '/v2/themes/checkout/success',
  SuccessPaid: '/v2/themes/checkout/success/paid',
  Cancel: '/v2/themes/checkout/cancel',
  UserSetting: '/v2/themes/settings/user',
  PasswordSetting: '/v2/themes/settings/password',
  Contact: '/v2/themes/settings/contact',
  PaidSetting: '/v2/themes/settings/paid',
  Settings: '/v2/themes/settings',

  // SP
  SPNewKeyword: '/v2/themes/keywords/new',
}
