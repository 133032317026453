import { CheckoutInput, CheckoutPayload } from 'api/order/checkout'
import { UseMutationOption, useMutation } from 'api/useMutate'
import axios from 'axios'

type CheckoutSurveyInput = CheckoutInput & {
  analysisResultId: string
}

export const checkoutSurvey = async ({
  provider,
  taxRate,
  productId,
  quantity,
  successUrl,
  cancelUrl,
  topicId,
  params,
  analysisResultId,
}: CheckoutSurveyInput) => {
  const response = await axios.post<CheckoutPayload>('/api/surveys/checkout', {
    provider,
    tax_rate: taxRate,
    product_id: productId,
    quantity,
    success_url: successUrl,
    cancel_url: cancelUrl,
    topic_id: topicId,
    params,
    analysis_result_id: analysisResultId,
  })

  return response.data
}

export const useCheckoutSurvey = (
  option?: UseMutationOption<CheckoutPayload>,
) => useMutation(checkoutSurvey, option)
