import { Empathy, EmpathyResponse, parseEmpathy } from './empathy'

// @deprecated
export type Tweet = {
  tweet_id: string
  label_id: string
  tweet_url: string
  has_media: boolean
  tweet_at: Date
  text: string
  is_labeling_source: boolean
  score: null | any
  similar_tweet_count: number
  empathy: number
}

export type V2Tweet = {
  id: number
  tweetId: string
  url: string
  text: string
  tweetAt?: Date
  empathies: Empathy[]
}

export type V2TweetResponse = {
  id: number
  tweet_id: string
  url: string
  text: string
  tweet_at: string | null
  empathies: EmpathyResponse[]
}

export const parseV2Tweet = (tweet: V2TweetResponse): V2Tweet => ({
  id: tweet.id,
  tweetId: tweet.tweet_id,
  url: tweet.url,
  text: tweet.text,
  tweetAt: tweet.tweet_at !== null ? new Date(tweet.tweet_at) : undefined,
  empathies: tweet.empathies.map(parseEmpathy),
})
