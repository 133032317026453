import useSelectedTheme from 'hooks/useSelectedTheme'
import { useMemo } from 'react'

function extractKeywordId(path: string): number | undefined {
  const pattern = /^\/v2\/themes\/-?\d+\/keywords\/(-?\d+)(\/|$)/

  const match = path.match(pattern)

  if (match && match.length > 1) {
    const res = parseInt(match[1], 10)
    if (isNaN(res)) {
      return undefined
    }
    return res
  }
  return undefined
}

const useSelectedKeyword = () => {
  const { selectedTheme } = useSelectedTheme()
  const selectedKeywordId = useMemo(
    () => extractKeywordId(location.pathname),
    [location.pathname],
  )

  const selectedKeyword = useMemo(
    () =>
      selectedTheme?.analysisResults?.find(
        (keyword) => keyword.id === selectedKeywordId,
      ),
    [selectedTheme, selectedKeywordId],
  )

  return { selectedKeyword, selectedKeywordId }
}

export default useSelectedKeyword
