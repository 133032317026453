import {
  Box,
  Button,
  FormControl,
  Link,
  TextField,
  Typography,
} from '@mui/material'
import { SignupInput } from 'api/signup'
import { signupSchema } from 'pages/signup/schema'
import React from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { Controller, FormProvider } from 'react-hook-form'
import { z } from 'zod'

type FormData = z.infer<typeof signupSchema>

type SignupFormProps = {
  request: (data: SignupInput) => Promise<void>
  requesting: boolean
  method: UseFormReturn<
    {
      email: string
      password: string
      passwordConfirmation: string
    },
    any
  >
}

const SignupForm: React.VFC<SignupFormProps> = ({
  request,
  requesting,
  method,
}) => {
  const onSubmit = async (data: FormData) => {
    await request(data)
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = method

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            py: 4,
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              flexDirection: 'column',
            }}
          >
            <FormControl
              error={!!errors?.email}
              sx={{
                width: '100%',
              }}
            >
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    type="email"
                    label="メールアドレス"
                    placeholder="viewpers@example.com"
                    variant="outlined"
                    error={!!errors?.email}
                    helperText={
                      errors?.email?.message ?? errors?.email?.message
                    }
                    sx={{
                      maxWidth: '360px',
                      '& .MuiInputBase-input': {
                        height: 'auto',
                        padding: '10px 14px',
                      },
                    }}
                    InputProps={{
                      autoComplete: 'email',
                      style: {
                        fontSize: 16,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl
              error={!!errors?.password}
              sx={{
                width: '100%',
              }}
            >
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    type="password"
                    placeholder="8文字以上の半角英数字"
                    label="パスワード"
                    variant="outlined"
                    error={!!errors?.password}
                    helperText={
                      errors?.password?.message ?? errors?.password?.message
                    }
                    sx={{
                      maxWidth: '360px',
                      '& .MuiInputBase-input': {
                        height: 'auto',
                        padding: '10px 14px',
                      },
                    }}
                    InputProps={{
                      autoComplete: 'password',
                      style: {
                        fontSize: 16,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl
              error={!!errors?.passwordConfirmation}
              sx={{
                width: '100%',
              }}
            >
              <Controller
                name="passwordConfirmation"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    type="password"
                    placeholder="8文字以上の半角英数字"
                    label="パスワード確認"
                    variant="outlined"
                    error={!!errors?.passwordConfirmation}
                    helperText={
                      errors?.passwordConfirmation?.message ??
                      errors?.passwordConfirmation?.message
                    }
                    sx={{
                      maxWidth: '360px',
                      '& .MuiInputBase-input': {
                        height: 'auto',
                        padding: '10px 14px',
                      },
                    }}
                    InputProps={{
                      autoComplete: 'password-confirmation',
                      style: {
                        fontSize: 16,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 16,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box
            style={{
              width: '100%',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontSize: '14px',
                display: 'inline',
              }}
            >
              登録を持って
            </Typography>
            <Link
              href="https://storage.googleapis.com/studio-design-asset-files/projects/ogO0eVMEq2/s-1x1_9a5615f9-70a5-404e-af75-37bfad995b56.pdf"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                fontSize: '14px',
                textDecoration: 'none',
                display: 'inline',
              }}
            >
              利用規約
            </Link>
            <Typography
              variant="body2"
              sx={{
                fontSize: '14px',
                display: 'inline',
              }}
            >
              および
            </Typography>
            <Link
              href="https://www.metasite.co.jp/privacypolicy"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                fontSize: '14px',
                textDecoration: 'none',
                display: 'inline',
              }}
            >
              プライバシーポリシー
            </Link>
            <Typography
              variant="body2"
              sx={{
                fontSize: '14px',
                display: 'inline',
              }}
            >
              に同意するものとします
            </Typography>
          </Box>
          <Button
            variant="contained"
            type="submit"
            sx={{ px: 7, fontSize: '14px', color: 'white' }}
            disabled={requesting}
          >
            サインアップ
          </Button>
        </Box>
      </form>
    </FormProvider>
  )
}

export default SignupForm
