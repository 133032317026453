import { useEffect, useState } from 'react'

const useFirstVisitAfterCheckout = ({
  orderId,
}: {
  orderId: string | undefined
}) => {
  const [isFirstVisit, setIsFirstVisit] = useState(false)

  useEffect(() => {
    if (!orderId) {
      return
    }

    const orderKey = `isFirstVisitAfterCheckout-A8-${orderId}`
    const firstVisit = localStorage.getItem(orderKey)

    if (!firstVisit) {
      // 初回アクセスの場合
      setIsFirstVisit(true)
      localStorage.setItem(orderKey, 'true')
    }
  }, [orderId])

  return isFirstVisit
}

export default useFirstVisitAfterCheckout
