import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  CircularProgress,
  Input,
  InputAdornment,
} from '@mui/material'
import { useDebounce } from 'hooks/useDebounce'
import { useDisclosure } from 'hooks/useDisclosure'
import BaseDialog from 'includes/BaseDialog'
import { Suspense } from 'react'
import { V2TweetList } from '../V2TweetList'

export const DisplayAllTweetsButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button variant="outlined" onClick={onOpen}>
        全ての投稿を見る
      </Button>
      <AnalysisDetailTweetsDialog open={isOpen} onClose={onClose} />
    </>
  )
}

const AnalysisDetailTweetsDialog = ({
  onClose,
  open,
}: {
  onClose: () => void
  open: boolean
}) => {
  const { value, debouncedValue, onChange } = useDebounce({ defaultValue: '' })

  return (
    <BaseDialog open={open} onClose={onClose} breakPoints="xl">
      <Input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="検索"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
      <Suspense
        fallback={
          <Box
            height={400}
            mt={2.5}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        }
      >
        <V2TweetList type="all" text={debouncedValue} />
      </Suspense>
    </BaseDialog>
  )
}
